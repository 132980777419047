#services {
    //baner inicial
    .intro-banner{
        position: relative;
        @media (max-width: 1100px){
            padding-top: 0px;
        }
        .mySwiper{
            .swiper-slide{
                width: 100%;
                height: Min(100dvh, 836px);
                @media (max-width: 1100px){
                    height: auto;
                    padding-top: 84px;
                    padding-bottom: 144px;
                }
            }
            .swiper-button-next, .swiper-rtl .swiper-button-prev{
                right: 0;
                left: auto;
                top: 88.6%;
                width: 54px;
                height: 54px;
                background: $blue;
                &::after{
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }
                @media (max-width: 1100px){
                    right: 0;
                    left: 65px;
                    top: 55px;
                    width: 65px;
                    height: 65px;
                    bottom: 0;
                }
            }
            .swiper-button-prev, .swiper-rtl .swiper-button-next{
                left: calc(100% - 108px);
                right: auto;
                top: 88.6%;
                width: 54px;
                height: 54px;
                background: $blue;
                &::after{
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }
                @media (max-width: 1100px){
                    left: 0;
                    right: auto;
                    top: 55px;
                    width: 65px;
                    height: 65px;
                    bottom: 0;
                }
            }
        }
        .wrapper-img{
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            &::after{
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: linear-gradient(83deg, #003C65 38.12%, rgba(0, 60, 101, 0.00) 89.54%);
                mix-blend-mode: multiply;
            }
        }
        .wrapper{
            max-width: 1280px;
            margin: 0 auto;
            position: relative;
            z-index: 1;
            height: 100%;
            padding-top: 193px;
            @media (max-width: 1310px){
                max-width: 1180px;
            }
            @media (max-width: 1100px){
                padding-inline: 25px;
                padding-top: 80px;
            }
        }
        .banner-img{
            width: 100%;
            height: 100%;
            object-position: bottom;
            object-fit: cover;
        }
        .content{
            max-width: 631px;
            display: flex;
            flex-direction: column;
        }
        .subtitle{
            color: #FFF ;
            font-family: $font-main;
            font-size: 16px ;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 8px;
            @media (max-width: 1100px){
                font-size: 15px;
            }
        }
        .title, title > p{
            color: #FFF ;
            font-family: $font-main ;
            font-size: 95px ;
            font-style: normal;
            font-weight: 600 ;
            line-height: 80px ;
            margin-top: 31px;
            @media (max-width: 1100px){
                font-size: 50px;
                line-height: 48px;
                max-width: 320px;
                word-wrap: break-word;
            }
            p{
                color: #FFF ;
                font-family: $font-main ;
                font-size: 95px ;
                font-style: normal;
                font-weight: 600 ;
                line-height: 80px ;
                margin-top: 31px;
                @media (max-width: 1100px){
                    font-size: 50px;
                    line-height: 48px;
                    max-width: 320px;
                    word-wrap: break-word;
                }
            }
        }
        .text{
            color: #FFF ;
            font-family: $font-main;
            font-size: 18px ;
            font-style: normal;
            font-weight: 400;
            line-height: 25px ;
            margin-top: 54px;
            @media (max-width: 1100px){
                font-size: 16px;
                line-height: 20px;
                max-width: 250px;
                margin-top: 20px;
            }
        }
        .text + .text { margin-top: 20px; }
        .btn-more{
            color: #FFF;
            font-family: $font-main;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 60px;
            margin-top: 49px;
            width: fit-content;
            @media (max-width: 1100px){
                display: none;
            }
        }
        .video-btn{
            position: absolute;
            bottom: 72px;
            left: 0;
            color: #FFF;
            font-family: $font-main;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            align-items: center;
            gap: 20px;
            @media (max-width: 1100px){
                right: 21px;
                left: auto;
                bottom: -130px;
            }
        }
    }

    .assistance {
        padding: 100px 77px 120px;
        @media ( width < 1100px ) {
            padding-inline: 0px;
            padding-top: 40px;
            padding-bottom: 40px;
        }
        &-container {
            max-width: 1395px;
            margin-inline: auto;
            translate: 56px 0;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            gap: 32px;

            @media ( width < 1500px ) {
                translate: 0 0;
            }
            @media ( width < 1100px ) {
                display: flex;
                flex-direction: column-reverse;
            }
            &--img {
                img { max-width: 100%; }
            }
            &--text {
                max-width: 541px;
                transform: translateY(-4px);
                @media ( width < 1100px ) {
                    transform: translateY(0px);
                    padding-inline: 25px;
                }
                .title { margin-block: 4px 42px; }
                .text {
                    font-family: $font-main;
                    color: #575756;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px; /* 138.889% */
                    @media ( width < 1100px ) {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; /* 137.5% */
                    }
                }
                .text + .text { margin-top: 20px; }
                .info {
                    margin-top: 40px;
                    @media ( width < 1100px ) {
                        display: flex;
                        flex-direction: column;
                        gap: 14px;
                    }
                    &-item + &-item { margin-top: 12px; }
                    &-item {
                        gap: 12px;
                        color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
                        font-family: $font-main;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 25px; /* 125% */
                        @media ( width < 1100px ) {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 22px; /* 137.5% */
                        }
                        i {
                            font-size: 24px;
                            color: #005DB1;
                        }
                    }
                }
            }
        }
    }
    .treinamentos {
        padding: 0 77px calc(152px - 62px);
        @media ( width < 1100px ) {
            padding-inline: 0px;
            padding-bottom: 0px;
        }
        &-container {
            max-width: 1470px;
            margin-inline: auto;
            translate: -22px 0;
            display: flex;
            justify-content: space-between;
            gap: 32px;

            @media ( width < 1500px ) {
                translate: 0 0;
            }
            @media ( width < 1100px ) {
                display: flex;
                flex-direction: column-reverse;
            }
            &--img {
                img { max-width: 100%; }
            }
            &--text {
                width: 620px;
                @media ( width < 1100px ) {
                    padding-inline: 25px;
                    width: auto;
                }
                .title { margin-block: 4px 42px; }
                .text {
                    font-family: $font-main;

                    color: #575756;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px; /* 138.889% */
                }
                .text + .text { margin-top: 20px; }
            }
        }
    }
    .produtos-slider {
        padding-block: 62px 152px;
        @media ( width < 1100px ) {
            padding-bottom: 115px;
        }
        .card-product {
            background-color: #F3F3F3;

            .title {
                color: var(--San-Martin-Neutral, #575756);
                font-family: $font-main;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 100% */
            }
        }
    }
    .news {
        padding-top: 150px;
        @media ( width < 1100px ) {
            padding-top: 30px;
        }
        .title-big { top: -83px; }
    }

    .sub-title {
        color: var(--San-Martin-Neutral, #575756);
        font-family: $font-main, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 375% */
        letter-spacing: 8px;
        text-transform: uppercase;
        @media ( max-width: 1100px ) {
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 60px; /* 400% */
            letter-spacing: 7.5px;
        }
    }
    .title {
        color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
        font-family: $font-main, sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 300;
        line-height: 50px; /* 100% */

        strong { font-weight: bold; }
        @media ( max-width: 1100px ) {
            font-size: 35px;
            font-style: normal;
            font-weight: 300;
            line-height: 40px; /* 114.286% */
        }
    }
}
