#page404{
  padding: 108px 0 186px 71px;
  position: relative;

  .page404 {
      min-width: Min(100%, 996px);
      height: 691px;
      position: relative;

      & > img {
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
      }
  }
  .floating {
      position: absolute;
      inset: auto auto 0 0;
      min-width: min(100%, 996px);
      height: 691px;

      padding: 120px 120px 120px 300px;
      background-color: #fff;
      box-shadow: 25px 25px 30px 0px rgba(0, 0, 0, 0.20);

      @media (width < 1300px) {
        padding: 120px;
        width: Min(100%, 996px);
      }
      .title {
          color: var(--San-Martin-primary---Blue-primary-500, #005DB1);

          font-family: $font-main;
          font-size: 50px;
          font-style: normal;
          font-weight: 300;
          line-height: 50px;  
        
          strong { font-weight: 600; }
      }
      .icon-wrapper { 
        margin-top: 33px;
        gap: 20px;
        
        .msg {
          color: #B12000;

          font-family: $font-main;
          font-size: 25px;
          font-style: normal;
          font-weight: 600;
          line-height: 30px; /* 120% */          
        }
      }
      .text {
          color: var(--San-Martin-Neutral, #575756);
          
          font-family: $font-main;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 25px;
          margin-block: 33px 32px; 
          max-width: 390px;        
      }
      .btn-wrapper {
        .default-btn {
          color: #FFF;
          font-family: $font-main;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 60px; /* 300% */
          width: 216px;
          height: 62px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: $blue;
          transition: all 0.3s ease-out;
          position: relative;
          isolation: isolate;
          span{
              position: relative;
              z-index: 9;
          }
          &::after{
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0.00) 92.38%);
              transition: all 0.3s ease-out;
              transform: scaleX(0);
              transform-origin: 100% 100%;
              transition: transform .6s cubic-bezier(.53,.21,0,1);
              will-change: transform;
              z-index: -1;
          }
          &:hover{
              &::after{
                  transform-origin: 0 0;
                  transform: scaleX(1);
              }
          }
          @media (max-width: 1100px){
              width: 100%;
          }
        }
      }
  }

  @media (width < 1100px) {
    padding: 84px 0 0;

    .page404 {
      min-width: 100%;
      height: 390px;
    }
    .f-wrapper { 
      padding-inline: 25px; 
      margin-bottom: 60px;
    }
    .floating {
      position: initial;
      margin-inline: auto;
      margin-top: -125px;
      padding: 40px 32px;
      width: Min(340px, 100%) !important;
      height: 100%;

      .title {
        font-size: 35px;
        line-height: 40px;
        text-align: center;
      }
      .icon-wrapper {
        gap: 12px;
        flex-direction: column;
        justify-content: center;

        .msg {
          font-size: 16px;
          line-height: 21px;
        }
      }
      .text {
        font-size: 16px;
        line-height: 32px;
        margin-block: 22px;
        text-align: center;
        max-width: 100%;
      }
    }
  }
}