#downloads {
    @media ( width < 1100px ) {
        padding-top: 84px;
    }
    #bannerHero{
        @media ( width < 1100px ) {
            padding-top: 55px;
            min-height: 420px;
        }
    }
    .info {
        padding: 64.8px 77px 156px 318px;

        @media ( width < 1570px ) {
            padding-left: 77px;
        }
        @media ( width < 1100px ) {
            padding-inline: 25px;
            padding-top: 44px;
            padding-bottom: 80px;
        }
        &-container {
            max-width: 520px;

            .title { margin-block-end: 42px; }
            .text { line-height: 25px; }
        }
    }
    .downloads {
        padding: 123px 77px 199px;
        background: #F0F0F0;
        text-align: center;
        position: relative;
        @media ( width < 1100px ) {
            padding-inline: 25px;
            padding-bottom: 70px;
        }
        .btns-swiper-card{
            display: none;
            @media ( width < 1100px ) {
                display: flex;
                justify-content: center;
                margin-top: 32px;
                .btn-prev-card{
                    transform: translate(2px, 0px);
                }
                .btn-next-card{
                    transform: rotate(180deg);
                }
            }
        }
        .title { margin-block: 4px 60px;
        @media ( width < 1100px ) {
            margin-bottom: 20px;

        }}
        &--filter {
            margin-bottom: 60px;
            max-width: 253px;
            margin-inline: auto;
            @media ( width < 1100px ) {
                max-width: inherit;
                margin-inline: auto;
                margin: 0 auto;
            }
            .dropdown {
                position: relative;
                @media ( width < 1100px ) {
                    margin-bottom: 60px;
                }
                &-toggle {
                    height: 64px;
                    width: 100%;
                    padding: 20px;
                    background: var(--Support, #8F8F8F);
                    text-align: center;

                    color: var(--San-Martin-White, #FFF);
                    font-family: $font-main, sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 16px; /* 100% */
                    letter-spacing: 4.8px;
                    text-transform: uppercase;

                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &::after { display: none; }
                    .counter {
                        background-color: #fff;
                        border-radius: 50%;
                        display: grid;
                        place-items: center;
                        width: 26px;
                        height: 26px;
                        @media ( width < 1100px ) {
                            background-color: #fff;
                            border-radius: 50%;
                            padding: 0px;
                            display: grid;
                            padding-left: 2px;
                            place-items: center;
                            width: 24px;
                            height: 24px;
                        }
                        span {
                            color: var(--San-Martin-Neutral, #575756);
                            font-family: $font-main;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 16px; /* 100% */
                            translate: 2px 0;
                            width: 50%;
                            height: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            @media ( width < 1100px ) {
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 16px;
                                translate: 1px 0;
                                letter-spacing: 4px;
                            }
                        }
                    }
                }
                &-menu {
                    min-width: 253px;
                    border-radius: 0;

                    .form-check-input {
                        width: 12px;
                        height: 12px;
                        margin-right: 12px;
                    }
                }
            }
        }
        &--cards {
            max-width: 1282px;
            margin-inline: auto;
            z-index: -1;
            position: relative;
            .cards-container {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(300.9px, .1fr));
                gap: 26px;
                justify-content: start;
                @media ( width < 1100px ) {
                    display: block;
                }
                .card-product {
                    background-color: #fff;
                    width: 100%;
                    text-align: left;

                    &:hover{
                        .wrapper-img{
                            img{
                                transform: scale(1.1);
                            }
                        }
                    }
                    .wrapper-img{
                        width: 100%;
                        height: 222;
                        overflow: hidden;
                        margin-bottom: 20px;

                        display: grid;
                        place-items: center;
                    }
                    img{
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        transition:  all 0.3s ease-out;
                    }
                    .text-content{
                        padding-left: 30px;
                        padding-right: 27px;
                        @media (max-width: 1100px){
                            padding-left: 49px;
                            padding-right: 100px;
                        }
                    }
                    .subtitle{
                        color: #005DB1;
                        font-family: $font-main;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        letter-spacing: 4.8px;
                        @media (max-width: 1100px){
                            font-size: 15px;
                        }
                    }
                    .title{
                        color: #575756;
                        font-family: $font-main;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        margin-block: 20px 0;
                    }
                    p{
                        color: #575756;
                        font-family: $font-main;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        margin-top: 19px;
                        @media (max-width: 1100px){
                            font-size: 16px;
                        }
                    }
                    .detail{
                        margin-top: 25px;
                        width: 100%;
                        background: $blue;
                        color: #fff;
                        padding-left: 30px;
                        padding-right: 33px;
                        display: flex;
                        justify-content: space-between;
                        height: 62px;
                        align-items: center;
                        font-family: $font-main;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        overflow: hidden;
                        position: relative;
                        isolation: isolate;
                        span{
                            position: relative;
                            z-index: 9;
                        }
                        &::after{
                            content: "";
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background:  linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0.00) 92.38%);
                            transition: all 0.3s ease-out;
                            transform: scaleX(0);
                            transform-origin: 100% 100%;
                            transition: transform .6s cubic-bezier(.53,.21,0,1);
                            will-change: transform;
                            z-index: -1;
                        }
                        @media (max-width: 1100px){
                            font-size: 18px;
                        }
                        img{
                            height: 16px ;
                            width: 23px ;
                            z-index: 9;
                            position: relative;
                        }
                        &:hover{
                            &::after{
                                transform-origin: 0 0;
                                transform: scaleX(1);
                            }
                        }
                    }
                }
            }
        }
        .mask-text {
            font-size: 154px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            color: transparent;
            -webkit-text-stroke: 2px #8F8F8F;
            -webkit-background-clip: text;
            background-clip: text;
            position: absolute;
            right: 56px;
            top: -103px;
            text-align: end;
            @media (max-width: 1100px) {
                right: 15px;
                font-size: 18vw;
                -webkit-text-stroke: 1px #8F8F8F;
                top: -40px;
            }
        }
    }
    .news {
        padding-top: 242px;
        @media (max-width: 1100px) {
            padding-top: 150px;
        }
        .title-big {
            font-size: 154px;
            top: 10px;
            left: 22px;
            @media (max-width: 1100px) {
                font-size: 20vw;
                top: 30px;
                left: 22px;
            }
        }
    }

    .sub-title {
        color: var(--San-Martin-Neutral, #575756);
        font-family: $font-main, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 375% */
        letter-spacing: 8px;
        text-transform: uppercase;
        @media (max-width: 1100px) {
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 60px; /* 400% */
            letter-spacing: 7.5px;
        }
    }
    .title {
        color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
        font-family: $font-main, sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 300;
        line-height: 50px; /* 100% */
        strong { font-weight: bold; }
        @media (max-width: 1100px) {
            font-size: 35px;
            font-style: normal;
            font-weight: 300;
            line-height: 40px; /* 114.286% */
        }
    }
    .text {
        color: #575756;
        font-family: $font-main, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        @media (max-width: 1100px) {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 137.5% */
        }
    }
    .default-btn {
        color: #FFF;
        font-family: $font-main;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 300% */
        width: 216px;
        height: 62px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $blue;
        transition: all 0.3s ease-out;
        position: relative;
        isolation: isolate;
        span{
            position: relative;
            z-index: 9;
        }
        &::after{
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0.00) 92.38%);
            transition: all 0.3s ease-out;
            transform: scaleX(0);
            transform-origin: 100% 100%;
            transition: transform .6s cubic-bezier(.53,.21,0,1);
            will-change: transform;
            z-index: -1;
        }
        &:hover{
            &::after{
                transform-origin: 0 0;
                transform: scaleX(1);
            }
        }
        @media (max-width: 1100px){
            width: 100%;
        }
    }
}
// call to action contato
.cta-contato {
    position: relative;
    height: 509px;
    background-color: #005DB1;
    isolation: isolate;

    &::after {
        content: '';
        position: absolute;
        inset: 0;
        background: #005DB1;
        opacity: .6;
        z-index: -1;
    }
    .bg-img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: -2;
    }
    .top-img {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        @media (max-width: 1100px) {
            left: inherit;
            right: 0;
        }
    }
    &-container {
        display: flex;

        .blank { width: 681px;
            @media (max-width: 1100px) {
                display: none;
            }
        }
        .wrapper {
            max-width: 241px;
            z-index: 2;
            padding-top: 150px;
            @media (max-width: 1100px) {
                padding-inline: 25px;
            }
            .sub-title,
            .title,
            .title strong {
                color: #fff !important;
            }
            .title { margin-block: 1px 26px; }
            .btn-light {
                width: 216px;
                height: 62px;
                background: #fff;
                transition: all .3s ease-in-out;

                color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
                font-family: $font-main;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 60px; /* 300% */

                display: grid;
                place-items: center;

                &:hover {
                    background: var(--White-OFF, #F0F0F0);
                }
            }
        }
    }
    .mask-text {
        font-size: 154px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        color: transparent;
        -webkit-text-stroke: 2px #8F8F8F;
        -webkit-background-clip: text;
        background-clip: text;
        position: absolute;
        right: 56px;
        top: -103px;
        text-align: end;
    }

    @media (width < 1100px) {
        height: 478px;

        &-container {
            flex-direction: column;

            .blank { display: none; }
            .wrapper {
                max-width: 100%;
                padding-top: 136px;
                padding-inline: 25px;

                .title {
                    margin-block: 4px 33px;

                    strong { display: block; }
                }
            }
        }
        .top-img {
            inset: auto 0 0 auto;
            z-index: 0;
        }
        .mask-text {
            font-size: 70px;
            line-height: 50px;
            width: 100%;
            text-align: center;
            -webkit-text-stroke: 1px #8F8F8F;

            top: -34px;
            right: 0;
        }
    }
}
