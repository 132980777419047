@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

// componente banner topo
#bannerHero {
    $navbarHeight: 108px;

    height: Min(100vh, 720px);
    min-height: 570px;
    padding: calc(87px + $navbarHeight) 0 152px;
    position: relative;
    isolation: isolate;

    @media (max-height: 650px) {
        display: flex;
        align-items: center;
        padding: $navbarHeight 0 0 0 !important;
    }
    @media (max-width: 1100px) {
        padding: 120px 25px 50px !important;
        height: auto;
        min-height: auto;
    }
    &::after {
        content: '';
        position: absolute;
        inset: 0;
        background: linear-gradient(83deg, #003C65 38.12%, rgba(0, 60, 101, 0.00) 89.54%);
        mix-blend-mode: multiply;
        z-index: -1;
    }
    .banner-img {
        inset: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: -2;
    }
    .banner-container {
        max-width: 631px;
        color: var(--San-Martin-White, #FFF);
        z-index: 1;

        @media (max-height: 600px) {
            padding-block: 32px;
        }
        @media (max-width: 1100px) {
            padding-block: 0 !important;
        }
        .main-sub-title {
            font-family: $font-main, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 60px;
            /* 375% */
            letter-spacing: 8px;
            text-transform: uppercase;
        }
        .main-title {
            font-family: $font-main, sans-serif;
            font-size: 95px;
            font-style: normal;
            font-weight: 600;
            line-height: 80px;
            /* 84.211% */
            margin-block: 12px 54px;
        }
        .text {
            font-family: $font-main, sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            /* 138.889% */
        }
    }
    @media (width < 1100px) {
        $navbarHeight: 84px;

        min-height: auto;
        padding: calc(60px + $navbarHeight) 25px 49px;

        .container {
            margin: 0;
            padding: 0;
        }
        .banner-container {
            max-width: 100%;

            .main-sub-title {
                font-size: 13px;
                font-style: normal;
                font-weight: 600;
                line-height: 60px; /* 461.538% */
                letter-spacing: 6.5px;
            }
            .main-title {
                font-size: 50px;
                font-style: normal;
                font-weight: 600;
                line-height: 48px;
                margin-block-start: 0;
                margin-bottom: 20px;
            }
            .text {
                font-size: 16px !important;
                font-weight: 400;
                line-height: 22px !important;
                max-width: 631px;
            }
        }
    }
}

// breadcumbs
.breadcumbs {
    background: #F3F3F3;
    padding: 25px 77px;

    @media (max-width: 1100px) {
        padding-inline: 25px;
    }

    .wrapper {
        max-width: 1280px;
        margin: 0 auto;
    }

    ul {
        display: flex;
        align-items: center;
        gap: 21px;

        @media (max-width: 1100px) {
            gap: 12px;
        }

        li {
            color: #575756;
            font-family: $font-main;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            align-items: center;
            gap: 21px;

            @media (max-width: 1100px) {
                gap: 12px;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                white-space: nowrap;

                svg { height: 15px; }
            }
        }

        .active {
            color: $blue;

            svg {
                fill: $blue;

                path {
                    fill: $blue;
                }
            }
        }
    }
}

#culture {
    overflow: hidden;

    #bannerHero {
        .banner-container {
            max-width: 710px;

            .text {
                max-width: 631px;
            }
        }
    }
    .compromissos {
        display: flex;
        justify-content: center;
        padding-inline: 77px;
        @media (width < 1100px) {
            padding-inline: 0;
        }

        &-container {
            max-width: 1512px;
            display: flex;
            flex-direction: column;
            gap: 47px;
            margin-block: 68px 152px;
            @media (width < 1100px) {
                gap: 60px;
                margin-block: 44px 88px;
            }

            .img-and-text {
                .content-text {
                    max-width: 585px;

                    @media (max-width: 1100px) {
                        max-width: 100%;
                        padding-inline: 25px;
                    }
                }

                .wrapper {
                    width: fit-content;
                    display: flex;
                    justify-content: space-between;
                    gap: 40px;

                    @media (max-width: 1100px) {
                        flex-direction: column;
                        gap: 60px;
                    }
                }

                .title {
                    margin-block: 0 42px;
                    @media (max-width: 1100px) {
                        margin-block: 4px 32px;
                    }
                }

                .text {
                    color: #575756;
                    font-family: $font-main;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;

                    @media (max-width: 1100px) {
                        color: #575756;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 25px;
                    }
                }
                .right-img-hold{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                }
                .img-holder {
                    height: 516px;
                    aspect-ratio: 1.44;
                    transform: translate(107px, 0px);
                    overflow: hidden;

                    @media (max-width: 1100px) {
                        transform: translate(0, 0);
                        width: 100%;
                        height: 285px;
                    }

                    &:hover {
                        img {
                            transform: scale(1.1);
                        }
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: all 0.3s ease;
                    }
                    .right-img{
                        height: 19.323vw;
                        @media (max-width: 1100px) {
                            height: 100%;
                        }
                    }
                    .text-big{
                        font-size: 150px;
                        color: transparent;
                        -webkit-text-stroke: 1px #8f8f8f;
                        font-family: "Source Sans Pro", sans-serif;
                        font-style: normal;
                        font-weight: bold;
                        line-height: 220px;
                        @media (max-width: 1100px) {
                            display: none;
                        }
                    }
                    &.small {
                        width: 744px;
                        height: 341px;
                    }
                }
            }

            .reverse {
                .wrapper {
                    flex-direction: row-reverse;
                    @media (max-width: 1100px) {
                        flex-direction: column-reverse;
                    }

                    .content-text {
                        padding-top: 30px;
                        @media (max-width: 1100px) {
                            padding-top: 0;
                        }
                    }
                    .img-holder {
                        @media (max-width: 1100px) {
                            display: none;
                        }
                    }
                }

                .img-holder {
                    transform: translate(-107px, 0px);

                    @media (max-width: 1100px) {
                        transform: translate(0);
                    }
                }
            }
        }
        .mask-text {
            font-size: 154px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            color: transparent;
            -webkit-text-stroke: 2px #8F8F8F;
            -webkit-background-clip: text;
            background-clip: text;
            position: relative;
            right: -99px;
            text-align: end;
            top: -63px;
            width: 100%;
        }
    }

    .praticas {
        margin-right: 63px;
        height: 856px;
        position: relative;
        @media (max-width: 1100px) {
            margin-right: 0;
            height: 422px;
        }

        &-floating-div {
            position: absolute;
            top: -1px;
            left: -71px;
            background-color: #fff;
            width: min(75vw, 1037px);
            height: 572px;
            padding: 0px 107px 145px;
            z-index: 1;
            filter: drop-shadow(20px 70px 30px rgba(0, 0, 0, 0.1));
            display: flex;
            justify-content: end;
            @media (max-width: 1100px) {
                display: none;
            }

            .floating-wrapper {
                max-width: 656px;

                .title {
                    max-width: 305px;
                    margin-block: 4px 42px;
                }

                .text {
                    color: var(--San-Martin-Neutral, #575756);

                    font-family: $font-main, sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;
                    /* 138.889% */
                    margin-bottom: 42px;
                }
            }
        }

        .bg-img {
            position: absolute;
            inset: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            z-index: -1;
        }
    }

    .slide {
        position: relative;
        padding: 146px 0 201px 0;
        background: linear-gradient(138deg, #005DB1 -11.68%, #0C3E62 68.14%);

        h2 {
            color: #FFF;
            text-align: center;
            font-size: 50px;
            font-style: normal;
            font-weight: 300;
            line-height: 50px;

            & strong {
                color: #FFF;

                font-size: 50px;
                font-style: normal;
                font-weight: 600;
                line-height: 60px;
            }
            @media (max-width: 1100px) {
                font-size: 35px;
                line-height: 40px;

                & strong {
                    font-size: inherit;
                    line-height: inherit;
                    color: inerit;
                }
            }
        }

        h3 {
            color: #F0F0F0;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 60px;
            letter-spacing: 8px;
            @media (max-width: 1100px) {
                font-size: 15px;
                line-height: 60px;
                letter-spacing: 50%;
            }
        }

        .mask-text {
            font-size: 154px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            color: transparent;
            -webkit-text-stroke: 2px #FFF;
            -webkit-background-clip: text;
            background-clip: text;
            position: absolute;
            right: 100px;
            text-align: end;
            top: -78px;
            width: 100%;

            @media (max-width: 1100px) {
                -webkit-text-stroke: 1px #FFF;
                font-size: 70px;
                line-height: 50px;
                text-align: end;
                right: 15px;
                top: -21px;
            }
        }

        .blocos {
            margin: 0 auto;
            margin-top: 91px;
            max-width: 1166px;
            position: relative;

            @media (max-width: 1100px) {
                margin-top: 60px;

                .col-lg-3 {
                    display: flex !important;
                    justify-content: center;
                }
            }
        }

        .bloco {
            text-align: center;
            padding: 41px 14px;
            max-width: 272px;
            min-height: 342px;
            background: #FFF;

            img {
                margin: 0 auto;
                margin-bottom: 24px;
            }

            .titulo {
                color: #575756;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                margin-bottom: 20px;
            }

            .descricao {
                color: #575756;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }

        }

        .bloco-prev,
        .bloco-next {
            position: absolute;
            top: 42%;
            cursor: pointer;
            @media (max-width: 1100px) {
                position: initial;
            }

            svg {
                &, & path { transition: all .3s ease; }
            }
            &:hover {
                svg { fill: #fff; }
                path { fill: #005DB1; }
            }
        }

        .bloco-prev {
            left: -113px;
        }

        .bloco-next {
            right: -113px;
            @media (max-width: 1100px) {
                translate: 0 -1px;
            }
        }

        @media (max-width: 1100px) {
            padding: 84px 0 60px;

            .container { max-width: 100% !important; }
            .arrow-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-block-start: 60px;
            }
        }
    }

    .banner-footer {
        padding: 124px 0 119px 0;
        position: relative;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;

        @media (max-width: 1100px) {
            padding: 60px 0 60px;

            .col-lg-6 {
                padding-inline: 25px;
            }
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0.6;
            background: #8AB4DB;
            mix-blend-mode: multiply;
            z-index: 1;
        }

        .col-lg-10.m-auto {
            max-width: 1018px;
            position: relative;
            z-index: 2;
        }

        h3 {
            color: #F0F0F0;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 60px;
            letter-spacing: 8px;
            @media (max-width: 1100px) {
                font-size: 15px;
                line-height: 60px;
                letter-spacing: 50%;
            }
        }

        h2 {
            color: #FFF;
            font-size: 50px;
            font-style: normal;
            font-weight: 300;
            line-height: 55px;
            margin-bottom: 31px;

            & strong {
                color: #FFF;
                font-size: 50px;
                font-style: normal;
                font-weight: 600;
                line-height: 60px;
            }
            @media (max-width: 1100px) {
                font-size: 35px;
                line-height: 40px;

                & strong {
                    font-size: inherit;
                    line-height: inherit;
                    color: inerit;
                }
            }
        }

        .link {
            margin-top: 24px;
            width: 100%;
            max-width: 301px;
            background: $blue;
            color: #fff;
            padding-left: 30px;
            padding-right: 33px;
            display: flex;
            justify-content: space-between;
            height: 64px;
            align-items: center;
            font-family: $font-main;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            overflow: hidden;
            position: relative;
            isolation: isolate;

            span {
                position: relative;
                z-index: 9;
            }

            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0.00) 92.38%);
                transition: all 0.3s ease-out;
                transform: scaleX(0);
                transform-origin: 100% 100%;
                transition: transform .6s cubic-bezier(.53, .21, 0, 1);
                will-change: transform;
                z-index: -1;
            }

            @media (max-width: 1100px) {
                font-size: 20px;
                width: 100%;
                margin-top: 26px;
                transform: translateY(172px);
            }

            img {
                height: 16px;
                width: 23px;
                z-index: 9;
                position: relative;
            }

            &:hover {
                &::after {
                    transform-origin: 0 0;
                    transform: scaleX(1);
                }
            }
        }

        p {
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            max-width: 393px;
            float: right;
            margin-top: 69px;
            @media (max-width: 1100px) {
                max-width: 100%;
                margin-top: 34px;
                transform: translateY(-102px);
            }
        }
    }

    //novidades
    .news {
        background: #fff;
        min-height: 95dvh;
        padding-top: 242px;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 20dvh;
            background: linear-gradient(90deg, #005DB1 -11.68%, #0C3E62 68.14%);
            bottom: 0;
            left: 0;

            @media (max-width: 1100px) {
                display: none;
            }
        }

        .title-big {
            font-size: 154px;
            color: transparent;
            -webkit-text-stroke-width: 2px;
            -webkit-text-stroke-color: #8f8f8f;
            font-family: $font-main;
            font-style: normal;
            font-weight: 600;
            line-height: 220px;
            position: absolute;
            top: 18px;
            left: 24px;
            z-index: 9;

            @media (max-width: 1100px) {
                font-size: 85px;
                line-height: 80px;
                left: 0;
                top: 40px;
                -webkit-text-stroke-width: 1px;
            }
        }

        .container-news {
            max-width: 85vw;
            margin: 0 auto;
            position: relative;
            z-index: 9;

            @media (max-width: 1100px) {
                max-width: 100%;
            }

            .parent {
                display: grid;
                grid-template-columns: repeat(8, 1fr);
                grid-template-rows: repeat(4, 1fr);
                grid-column-gap: 0px;
                grid-row-gap: 0px;
                height: 83.5dvh;

                @media (max-width: 1100px) {
                    height: auto;
                    display: flex;
                    flex-direction: column;

                    .div1,
                    .div2,
                    .div3,
                    .div4,
                    .div5 {
                        aspect-ratio: 1;
                    }
                }
            }

            .div1 {
                grid-area: 1 / 1 / 5 / 5;
                position: relative;
            }

            .div2 {
                grid-area: 1 / 5 / 3 / 7;
                position: relative;

                .content-text {
                    width: 70%;
                }
            }

            .div3 {
                grid-area: 1 / 7 / 3 / 9;
                position: relative;

                .content-text {
                    width: 70%;
                }
            }

            .div4 {
                grid-area: 3 / 5 / 5 / 7;
                position: relative;

                .content-text {
                    width: 70%;
                }
            }

            .div5 {
                grid-area: 3 / 7 / 5 / 9;
                position: relative;

                .content-text {
                    width: 70%;
                }
            }

            .div1,
            .div2,
            .div3,
            .div4,
            .div5 {
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background: linear-gradient(210deg, rgba(0, 93, 177, 0.00) 40.33%, rgba(0, 93, 177, 0.80) 65.7%);
                    mix-blend-mode: multiply;
                    transition: all 0.3s ease-out;
                    will-change: opacity;
                    opacity: 0;

                }

                &:hover {
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }

        .wrapper-img {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: linear-gradient(210deg, rgba(1, 20, 37, 0.00) 40.33%, rgba(1, 20, 37, 0.80) 65.7%);
                mix-blend-mode: multiply;
            }
        }

        .content-text {
            position: relative;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-left: 25px;
            padding-bottom: 30px;
            width: 75%;
            gap: 18px;
            position: relative;
            z-index: 9;
        }

        .subtitle {
            color: #FFF;
            font-family: $font-main;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 8px;
            text-transform: uppercase;
        }

        .title {
            color: #FFF;
            font-family: $font-main;
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            /* 100% */

        }

        .text {
            color: #FFF;
            font-family: $font-main;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            /* 138.889% */
        }

        .tag {
            position: absolute;
            top: 0;
            left: 0;
            height: 51px;
            padding-inline: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $blue;
            color: #FFF;
            font-family: $font-main;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 4.8px;
            text-transform: uppercase;
        }

        .read-more {
            color: var(--san-martin-white, #FFF);
            font-family: $font-main;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 60px;
            /* 300% */
            display: flex;
            gap: 32px;
            align-items: center;
            height: 0px;
            opacity: 0;
        }

        .date {
            position: absolute;
            right: 26px;
            bottom: 24px;
            color: #FFF;
            /* H6 - textBody */
            font-family: $font-main;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            /* 138.889% */
            z-index: 99;
            opacity: 0;
        }
    }

    .sub-title {
        color: var(--San-Martin-Neutral, #575756);

        font-family: $font-main, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px;
        /* 375% */
        letter-spacing: 8px;
        text-transform: uppercase;
        @media (max-width: 1100px) {
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 60px; /* 400% */
            letter-spacing: 7.5px;
        }
    }

    .title {
        color: var(--San-Martin-primary---Blue-primary-500, #005DB1);

        font-family: $font-main, sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 300;
        line-height: 50px;
        /* 100% */

        strong {
            font-weight: bold;
        }
        @media (max-width: 1100px) {
            font-size: 35px;
            font-style: normal;
            font-weight: 300;
            line-height: 35px; /* 100% */
        }
    }
}
