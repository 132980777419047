#work-with-us {
    .info {
        padding: 64px 77px 120px;
        position: relative;
        overflow: hidden;
        @media (width < 1100px) {
            padding: 60px 0 0;
        }
        &-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            // descomentar caso haja 2 ou mais blocos de texto-img ativos
            
            // .info-container--item + .info-container--item {
            //     .img-and-text {
            //         margin-top: 161px;

            //         @media (width < 1100px) {
            //             margin-top: 100px;
            //         }
            //     }
            // }
            .img-and-text{
                .content-text{
                    max-width: 541px;
                    @media (max-width: 1100px){
                        max-width: 100%;
                        padding-inline: 25px;
                    }
                }
                .wrapper{
                    width: fit-content;
                    display: flex;
                    justify-content: space-between;
                    gap: 40px;
                    @media (max-width: 1100px) {
                        flex-direction: column !important;
                        gap: 60px !important;
                    }
                }
                .subtitle{
                    color: #575756;
                    margin-bottom: 20px;
                }
                .title{
                    margin-bottom: 42px;
                    strong{
                        font-weight: bold;
                    }
                    @media (max-width: 1100px){
                        font-size: 35px;
                        line-height: 35px;
                        margin-top: 14px;
                        margin-bottom: 42px;
                    }
                }
                .text{
                    color: #575756;
                    font-family: $font-main;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;
                    @media (max-width: 1100px){
                        color: #575756;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 25px;
                    }
                }
                .img-holder{
                    height: 516px;
                    aspect-ratio: 1.44;
                    transform: translate(107px, 0px);
                    overflow: hidden;
                    @media (max-width: 1100px){
                        transform: translate(0, 0);
                        width: 100%;
                        height: 285px;
                        margin-top: 43px;
                    }
                    &:hover{
                        img{
                            transform: scale(1.1);
                        }
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: all 0.3s ease;
                    }
                }
            }
            .reverse{
                .wrapper{
                    flex-direction: row-reverse;

                    .content-text { padding-top: 30px; }
                }
                .img-holder{
                    transform: translate(-107px, 0px);
                    @media (max-width: 1100px){
                        display: none;
                    }
                }
            }
        }
        .mask-text {
            font-size: 154px;
            text-align: end;
            right: -149px;
            bottom: -74px;
            z-index: 5;
            @media (max-width: 1500px){
                right: 0;
            }
            @media (max-width: 1100px){
                font-size: 23vw;
                -webkit-text-stroke: 1px #8F8F8F;
            }
            @media (max-width: 500px){
                font-size: 70px;
                right: 15px;
            }
        }
    }
    .beneficios {
        padding: 120px 77px 152px;
        background: var(--White-OFF, #F0F0F0);
        text-align: center;
        @media (width < 1100px) {
            padding-top: 60px;
            background: linear-gradient(0deg, #f0f0f0 94.5%, #fff 82%);
            padding-inline: 45px;
            padding-bottom: 60px;
        }
        .title { margin-block: 1px 80px; }
        &-container {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            justify-content: center;
            gap: 26px;

            max-width: 1280px;
            margin-inline: auto;

            .card-item {
                background-color:#fff;
                text-align: center;
                padding: 32px;
                width: 100%;
                height: 220px;

                img {
                    width: 90px;
                    aspect-ratio: 1;
                    display: block;
                    margin-inline: auto;
                }
                &-title {
                    color: var(--San-Martin-Neutral, #575756);

                    font-family: $font-main;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px; /* 100% */
                    margin-top: 24px;
                }
            }
        }
    }
    .selecao {
        padding: 143px 77px 199px;
        background: var(--gradiente-cheio, linear-gradient(138deg, #005DB1 -11.68%, #0C3E62 68.14%));
        text-align: center;
        @media (width < 1100px) {
            padding: 60px 25px;
        }
        .sub-title, .title { color: #fff; }
        .title {
            margin-block: 1px 120px;

            @media (width < 1100px) {
                margin-block-end: 60px;
            }
        }
        &-container {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(301px, 1fr));
            justify-content: center;
            gap: 80px 56px;
            max-width: 1372px;
            margin-inline: auto;
            @media (width < 1100px) {
                gap: 56px;
            }
            .card-item {
                background-color: transparent;
                width: 100%;
                text-align: start;
                @media (width < 1100px) {
                    text-align: center;
                }
                &-counter {
                    position: relative;

                    color: var(--San-Martin-White, #FFF);
                    font-family: $font-main;
                    font-size: 60.48px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 100.8px;
                }
                .divider {
                    width: 89px;
                    height: 3px;
                    background-color: #f0f0f0;
                    @media (width < 1100px) {
                        right: -37%;
                        position: relative;
                    }
                }
                &-title {
                    color: var(--San-Martin-White, #FFF);

                    font-family: $font-main;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 30px; /* 120% */
                    max-width: 186px;
                    margin-block: 20px 32px;
                    @media (width < 1100px) {
                        margin: 0 auto;
                    }
                }
                &-text {
                    color: var(--San-Martin-White, #FFF);

                    font-family: $font-main;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px; /* 138.889% */
                }
            }
        }
    }
    .floating {
        height: 718px;
        background-color: #fff;
        position: relative;
        margin-right: 71px;
        @media (width < 1100px) {
            height: auto;
            margin: 0;
            flex-direction: column-reverse;
            display: flex;
            gap: 60px;
        }
        .bg-img {
            height: 856px;
            width: 100%;
            object-fit: cover;
            @media (width < 1100px) {
                height: 421px;
            }
        }
        &-container {
            position: absolute;
            left: 0;
            top: -50px;
            background-color: #fff;
            z-index: 3;
            width: Min(948px, 70vw);
            height: 649px;
            padding: 131px 83px;
            display: flex;
            justify-content: end;
            @media (width < 1100px) {
                position: relative;
                height: auto;
                width: 100%;
                padding: 0;
                top: 0;
                padding-inline: 25px;
                padding-top: 60px;
            }
            .text-wrapper { max-width: 544px; }
            &--title {
                display: flex;
                gap: 20px;
                margin-bottom: 42px;
                @media (width < 1100px) {
                    flex-direction: column;
                    img{
                        width: 60px;
                        height: 60px;
                    }
                }
            }
            &--text {
                .text { line-height: 25px; }
            }
        }
    }
    .testimonials {
        padding: 258px 77px 0;
        background: var(--San-Martin-primary---Blue-primary-500, #005DB1);
        max-height: 804px;
        text-align: center;
        @media (width < 1100px) {
            padding: 0;
            max-height: inherit;
            padding-top: 60px;

        }
        .btns-monial{
            display: none;
            position: relative;
            background: var(--White-OFF, #F0F0F0);
            justify-content: center;
            @media (width < 1100px) {
                display: flex;
                transform: translate(0px, -1px);
                display: flex;
                padding-bottom: 60px;
                padding-bottom: 0;
                z-index: 9999;
            }
            .btn-next{
                transform: rotate(180deg);
                right: 27px;
                left: auto;

                @media (width < 1100px) {
                    translate: -2px 0;
                }
            }
        }
        .sub-title, .title { color: #fff; }
        .title { margin-block: 4px 107px;
            @media (width < 1100px) {
                margin-bottom: 150px;
            }
        }

        &-container {
            padding: 65px 56px 63px 87px;
            max-width: 1280px;
            margin-inline: auto;
            margin-bottom: -119px;
            background: var(--White-OFF, #F0F0F0);
            position: relative;
            z-index: 3;

            @media (width < 1100px) {
                padding-inline: 45px;
                margin-bottom: 0;
                margin-bottom: -130px;
                padding-bottom: 0;
            }
            .testimonial {
                display: flex;
                gap: 47px;
                @media (width < 1100px) {
                    flex-direction: column;
                    gap: 72px;
                }
                &--img {
                    padding: 12px 20px;
                    min-width: 302px;
                    height: 316px;
                    position: relative;
                    display: flex;
                    align-items: end;
                    @media (width < 1100px) {
                        justify-content: center;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        inset: 0;
                        background: linear-gradient(210deg, rgba(1, 20, 37, 0.00) 40.33%, rgba(1, 20, 37, 0.80) 65.7%);
                        mix-blend-mode: multiply;
                        z-index: 1;
                    }
                    img {
                        position: absolute;
                        inset: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        z-index: 0;
                    }
                    .desc {
                        color: #fff;
                        z-index: 2;
                        text-align: start;
                        @media (width < 1100px) {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        }
                        &-nome {
                            font-family: $font-main;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 26px; /* 130% */
                        }
                        .divider {
                            margin-block: 12px;
                            background-color: #005DB1;
                            width: 93px;
                            height: 3px;

                        }
                        &-cargo {
                            color: #fff;
                            line-height: 25px;
                            @media (width < 1100px) {
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 25px; /* 138.889% */
                                text-align: center;
                            }
                        }
                    }
                }
                &--text {
                    display: grid;
                    place-items: center;
                    text-align: center;
                    width: 100%;

                    .text {
                        line-height: 25px;
                        max-width: 464px;
                    }
                }
            }
        }
        .swiper {
            max-width: 100%;
            @media (width < 1100px) {
                transform: translate(0px, -165px);
            }
            &-slide {
                max-width: 100%;
            }
            &-button-next, &-button-prev {
                &::after { display: none; }
            }
            &-button-next {
                right: 30px;
                .off{
                    display: none;
                }
                &:hover{
                    .on{
                        display: none;
                    }
                    .off{
                        display: block;
                    }
                }
                img {
                    rotate: 180deg;
                }
            }
            &-button-prev {
                left: 360px;
                .off{
                    display: none;
                }
                &:hover{
                    .on{
                        display: none;
                    }
                    .off{
                        display: block;
                    }
                }
            }
        }
    }
    .be-part {
        padding: 240px 77px 204px;
        @media (width < 1100px) {
            padding-inline: 0;
            padding-top: 60px;
            padding-bottom: 60px;
            padding-top: 150px;
        }
        &-container {
            max-width: 1395px;
            margin-inline: auto;
            translate: -62px 0;
            display: flex;
            justify-content: space-between;
            gap: 32px;

            @media ( width < 1500px ) {
                translate: 0 0;
            }
            @media ( width < 1100px ) {
                flex-direction: column-reverse;
                gap: 60px;
            }
            &--img {
                img {
                    max-width: 100%;
                }
            }
            &--text {
                max-width: 517px;
                display: flex;
                flex-direction: column;
                gap: 42px;
                @media ( width < 1100px ) {
                    width: 100%;
                    padding-inline: 30px;
                    margin: 0 auto;
                    gap: 34px;
                }
                .title { margin-top: 4px; }
                p {
                    font-family: $font-main, sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;
                }
                .default-btn {
                    width: 301px;
                    padding-inline: 30px;
                    @media ( width < 1100px ) {
                        width: 100%;
                        padding-inline: 30px;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
    .escritorios {
        padding: 0 77px 283px;
        position: relative;
        @media (width < 1100px) {
            padding-inline: 0;
            padding-bottom: 60px;
        }
        &-container {
            max-width: 1395px;
            margin-inline: auto;
            translate: 58px 0;
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            gap: 32px;
            @media ( width < 1500px ) {
                translate: 0 0;
            }
            @media ( width < 1100px ) {
                flex-direction: column-reverse;
            }
            &--img {
                .swiper {
                    max-width: 744px;

                    &-slide {
                        width: 744px;
                        height: 516px;
                        @media ( width < 1100px ) {
                            height: 400px;
                        }
                    }
                    &-button-next, &-button-prev {
                        z-index: 2;

                        &::after { display: none; }
                    }
                    &-button-next {
                        right: 35px;
                        top: auto;
                        bottom: 40px;
                        @media ( width < 1100px ) {
                            top: 79px;
                            bottom: inherit;
                            right: inherit;
                            left: 82px;
                        }
                        img {
                            rotate: 180deg;
                        }
                    }
                    &-button-prev {
                        left: auto;
                        top: auto;
                        right: 162px;
                        bottom: 40px;
                        @media ( width < 1100px ) {
                            bottom: inherit;
                            top: 79.5px;
                            right: inherit;
                            left: 30px;
                        }
                    }
                    &-button-prev, &-button-next {
                        width: 53px;
                        height: 53px;
                        svg {
                            &, & path { transition: all .3s ease; }
                        }
                        &:hover {
                            svg { fill: #fff; }
                            path { fill: #005DB1; }
                        }
                    }
                }
                .img-wrapper {
                    position: relative;
                    padding: 40px;
                    min-width: 100%;
                    min-height: 100%;
                    isolation: isolate;
                    display: flex;
                    align-items: flex-end;

                    &::after {
                        content: '';
                        position: absolute;
                        inset: 0;
                        background: linear-gradient(39deg, rgba(0, 0, 0, 0.68) 17.59%, rgba(0, 0, 0, 0.00) 92.38%);
                        opacity: 0.7;
                        z-index: 1;
                    }
                    img {
                        position: absolute;
                        inset: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        z-index: 0;
                    }
                    .txt-container {
                        max-width: 328px;
                        z-index: 3;

                        &--title {
                            color: #FFF;
                            font-family: $font-main, sans-serif;
                            font-size: 25px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 30px; /* 120% */
                            margin-block-end: 20px;
                            @media ( width < 1100px ) {
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 21px; /* 131.25% */
                                margin-block-end: 12px;
                            }
                        }
                        .text {
                            line-height: 25px;
                            color: #fff;
                            @media ( width < 1100px ) {
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px; /* 128.571% */
                            }
                        }
                    }
                }
            }
            &--text {
                max-width: 517px;
                display: flex;
                flex-direction: column;
                gap: 42px;
                padding-top: 26px;
                @media ( width < 1100px ) {
                    padding-inline: 25px;
                }
                .title { margin-top: 4px; }
                p {
                    font-family: $font-main, sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;
                }
                .default-btn {
                    width: 169px;
                    background: transparent;
                    color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
                    &::after{
                        background:$blue;
                        content: '';
                        display: none;
                    }
                    &:hover {
                        background: transparent;
                        text-decoration: underline;
                        text-underline-offset: 6px;
                    }
                }
            }
        }
        .mask-text {
            font-size: 154px;
            right: 120px;
            top: -117px;
            text-align: end;
            z-index: 5;
            @media ( width < 1100px ) {
                display: none;
            }
        }
    }
    .news {
        .title-big {
            top: -205px;
            left: 15px;
            @media ( width < 1100px ) {
                left: 0;
                top: 0;
            }
        }
    }

    .sub-title {
        color: var(--San-Martin-Neutral, #575756);
        font-family: $font-main, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 375% */
        letter-spacing: 8px;
        text-transform: uppercase;
        @media ( width < 1100px ) {
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 133.333% */
            letter-spacing: 7.5px;
        }
    }
    .title {
        color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
        font-family: $font-main, sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 300;
        line-height: 50px; /* 100% */
        strong { font-weight: bold; }
        @media ( width < 1100px ) {
            font-size: 35px;
            font-style: normal;
            font-weight: 300;
            line-height: 40px; /* 114.286% */
        }
    }
    .text {
        color: #575756;
        font-family: $font-main, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 111.111% */
    }
    .default-btn {
        color: #FFF;
        font-family: $font-main;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 300% */
        width: 216px;
        height: 62px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $blue;
        transition: all 0.3s ease-out;
        position: relative;
        isolation: isolate;
        span{
            position: relative;
            z-index: 9;
        }
        &::after{
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0.00) 92.38%);
            transition: all 0.3s ease-out;
            transform: scaleX(0);
            transform-origin: 100% 100%;
            transition: transform .6s cubic-bezier(.53,.21,0,1);
            will-change: transform;
            z-index: -1;
        }
        &:hover{
            &::after{
                transform-origin: 0 0;
                transform: scaleX(1);
            }
        }
        @media (max-width: 1100px){
            width: 100%;
        }
    }
    .mask-text {
        // font-size: 154px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        color: transparent;
        -webkit-text-stroke: 2px #8F8F8F;
        -webkit-background-clip: text;
        background-clip: text;

        position: absolute;
    }
}
