#robot {
    .cta-contato{
        margin-top: 100px;
    }
    .robot-noResult{
        padding-top: 50px;
        padding-bottom: 50px;
        h4{
            font-size: 50px;
            line-height: 48px;
            font-family: $font-main;
            color: $blue;
        }
    }
    //baner inicial
    .intro-banner{
        position: relative;
        @media (max-width: 1100px){
            padding-top: 0px;
        }
        .mySwiper{
            .swiper-slide{
                width: 100%;
                height: Min(100dvh, 836px);
                @media (max-width: 1100px){
                    height: auto;
                    padding-top: 84px;
                    padding-bottom: 144px;
                }
            }
            .swiper-button-next, .swiper-rtl .swiper-button-prev{
                right: 0;
                left: auto;
                top: 88.6%;
                width: 54px;
                height: 54px;
                background: $blue;
                &::after{
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }
                @media (max-width: 1100px){
                    right: 0;
                    left: 65px;
                    top: 55px;
                    width: 65px;
                    height: 65px;
                    bottom: 0;
                }
            }
            .swiper-button-prev, .swiper-rtl .swiper-button-next{
                left: calc(100% - 108px);
                right: auto;
                top: 88.6%;
                width: 54px;
                height: 54px;
                background: $blue;
                &::after{
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }
                @media (max-width: 1100px){
                    left: 0;
                    right: auto;
                    top: 55px;
                    width: 65px;
                    height: 65px;
                    bottom: 0;
                }
            }
        }
        .wrapper-img{
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            &::after{
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: linear-gradient(83deg, #003C65 38.12%, rgba(0, 60, 101, 0.00) 89.54%);
                mix-blend-mode: multiply;
            }
        }
        .wrapper{
            max-width: 1280px;
            margin: 0 auto;
            position: relative;
            z-index: 1;
            height: 100%;
            padding-top: 193px;
            @media (max-width: 1310px){
                max-width: 1180px;
            }
            @media (max-width: 1100px){
                padding-inline: 25px;
                padding-top: 80px;
            }
        }
        .banner-img{
            width: 100%;
            height: 100%;
            object-position: bottom;
            object-fit: cover;
        }
        .content{
            max-width: 631px;
            display: flex;
            flex-direction: column;
        }
        .subtitle{
            color: #FFF ;
            font-family: $font-main;
            font-size: 16px ;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 8px;
            @media (max-width: 1100px){
                font-size: 15px;
            }
        }
        .title{
            color: #FFF ;
            font-family: $font-main ;
            font-size: 95px ;
            font-style: normal;
            font-weight: 600 ;
            line-height: 80px ;
            margin-top: 31px;
            @media (max-width: 1100px){
                font-size: 50px;
                line-height: 48px;
                max-width: 320px;
                word-wrap: break-word;
            }
        }
        .text{
            color: #FFF ;
            font-family: $font-main;
            font-size: 18px ;
            font-style: normal;
            font-weight: 400;
            line-height: 25px ;
            margin-top: 54px;
            @media (max-width: 1100px){
                font-size: 16px;
                line-height: 20px;
                max-width: 250px;
                margin-top: 20px;
            }
        }
        .text + .text { margin-top: 20px; }
        .btn-more{
            color: #FFF;
            font-family: $font-main;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 60px;
            margin-top: 49px;
            width: fit-content;
            @media (max-width: 1100px){
                display: none;
            }
        }
        .video-btn{
            position: absolute;
            bottom: 72px;
            left: 0;
            color: #FFF;
            font-family: $font-main;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            align-items: center;
            gap: 20px;
            @media (max-width: 1100px){
                right: 21px;
                left: auto;
                bottom: -130px;
            }
        }
    }

    .robot {
        &-container {
            display: flex;
            justify-content: space-between;
            gap: 32px;
            margin-inline: auto;

            @media ( width < 1500px ) {
                translate: 0 0;
            }
            &--img {
                height: fit-content;

                img { max-width: 100%; }
            }
            &--text {
                display: flex;
                flex-direction: column;
                gap: 42px;

                .title { margin-top: 4px; }
                p { line-height: 25px; }
                .default-btn {
                    width: 301px;
                    padding-inline: 30px;
                }
            }
        }
        .reverse {
            flex-direction: row-reverse;
        }

        @media (width < 1100px) {
            padding: 44px 0 0 !important;

            &-container {
                flex-direction: column-reverse;
                gap: 60px;
                padding-inline: 0 !important;

                &--img {
                    width: Min(550px, 100%);
                    margin-inline: auto;
                }
                &--text {
                    padding-inline: 25px !important;
                    padding-block: 0 !important;
                    align-self: flex-start !important;
                    gap: 32px;
                    max-width: 100% !important;

                    .text {
                        font-size: 16px;
                        line-height: 22px;
                    }
                    .default-btn {
                        width: Min(550px, 100%);
                        margin-inline: auto;
                    }
                }
            }
            .reverse { flex-direction: column-reverse; }
            &:last-of-type { padding: 44px 0 94px !important; }
        }
    }
    .item-1 {
        padding: 96px 77px 73px;

        .robot-container {
            max-width: 1395px;
            translate: 56px 0;

            @media (width < 1500px) {
                translate: 0 0;
            }
            &--text { max-width: 541px; }
            &--img { align-self: center; }
        }
    }
    .item-2 {
        padding: 0 77px 71px;

        .robot-container {
            max-width: 1503px;
            translate: -9px 0;

            @media (width < 1500px) {
                translate: 0 0;
            }
            &--text {
                max-width: 653px;
                align-self: center;

                .text { max-width: 541px; }
            }
        }
    }
    .item-3 {
        padding: 0 77px 77px;

        .robot-container {
            max-width: 1395px;
            translate: 56px 0;

            @media (width < 1500px) {
                translate: 0 0;
            }
            &--text {
                max-width: 541px;
                translate: 0 -5px;

                .text { max-width: 541px; }
            }
        }
    }
    .item-4 {
        padding: 0 77px 80px;

        .robot-container {
            max-width: 1503px;
            translate: -9px 0;

            @media (width < 1500px) {
                translate: 0 0;
            }
            &--text {
                width: 653px;
                padding-top: 26px;

                .text { max-width: 541px; }
            }
        }
    }
    .item-5 {
        padding: 0 77px 93px;

        .robot-container {
            max-width: 1395px;
            translate: 56px 0;

            @media (width < 1500px) {
                translate: 0 0;
            }
            &--text {
                max-width: 541px;
                translate: 0 -5px;

                .text { max-width: 541px; }
            }
        }
    }
    .item-6 {
        padding: 0 77px 152px;

        .robot-container {
            max-width: 1503px;
            translate: -9px 0;

            @media (width < 1500px) {
                translate: 0 0;
            }
            &--text {
                width: 653px;
                padding-top: 26px;

                .text { max-width: 541px; }
            }
        }
    }
    .news {
        padding-top: 250px;

        .title-big { top: 15px; }
    }

    .sub-title:not(.intro-banner .sub-title) {
        color: var(--San-Martin-Neutral, #575756);

        font-family: $font-main, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 375% */
        letter-spacing: 8px;
        text-transform: uppercase;
        margin: 0;

        @media (width < 1100px) {
            font-size: 15px !important;
            line-height: 60px !important; /* 400% */
            letter-spacing: 7.5px !important;
        }
    }
    .title:not(.intro-banner .title) {
        color: var(--San-Martin-primary---Blue-primary-500, #005DB1);

        font-family: $font-main, sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 300;
        line-height: 50px; /* 100% */

        strong { font-weight: bold; }

        @media (width < 1100px) {
            font-size: 35px !important;
            line-height: 40px !important;

            strong {
                font-size: inherit;
                line-height: inherit;
            }
        }
    }
    .text:not(.intro-banner .text) {
        color: #575756;
        font-family: $font-main, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 111.111% */
    }
    .default-btn {
        color: #FFF;
        font-family: $font-main;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 300% */
        width: 216px;
        height: 62px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $blue;
        transition: all 0.3s ease-out;
        position: relative;
        isolation: isolate;
        span{
            position: relative;
            z-index: 9;
        }
        &::after{
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0.00) 92.38%);
            transition: all 0.3s ease-out;
            transform: scaleX(0);
            transform-origin: 100% 100%;
            transition: transform .6s cubic-bezier(.53,.21,0,1);
            will-change: transform;
            z-index: -1;
        }
        &:hover{
            &::after{
                transform-origin: 0 0;
                transform: scaleX(1);
            }
        }
        @media (max-width: 1100px){
            width: 100%;
        }
    }
}
