* {
    font-family: $font-main;
}
// body{
//     background: url(#{$img}/home/Home.png) no-repeat top;
// }
#home {
    padding-top: 108px;
    @media (max-width: 1100px) {
        padding-top: 84px;
    }
    .intro-banner {
        position: relative;
        .mySwiper {
            .swiper-slide {
                width: 100%;
                height: calc(100dvh - 108px);
                @media (max-width: 1100px) {
                    height: auto;
                    min-height: 592px;
                }
            }
            .bts-control {
                bottom: 100px;
                @media (max-width: 1100px) {
                    bottom: 100%;
                }
            }
            .swiper-button-next,
            .swiper-rtl .swiper-button-prev {
                right: 3px;
                left: auto;
                top: 88.6%;
                width: 54px;
                height: 54px;
                background: linear-gradient(#005DB1 100%, #003C65 0% );
                transition: all .3s ease;
                &:hover{
                    background: linear-gradient(#003C65 68%, #003C65
                    0% );
                }
                &::after {
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }
                @media (max-width: 1100px) {
                    right: 0;
                    left: 65px;
                    top: auto;
                    width: 65px;
                    height: 65px;
                    bottom: 0;
                }
            }
            .swiper-button-prev,
            .swiper-rtl .swiper-button-next {
                left: inherit;
                right: 57px;
                top: 88.6%;
                width: 54px;
                height: 54px;
                background: linear-gradient(#005DB1 100%, #003C65 0% );
                transition: all .3s ease;
                &:hover{
                    background: linear-gradient(#003C65 68%, #003C65
 0% );
                }
                &::after {
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }
                @media (max-width: 1100px) {
                    left: 0;
                    right: auto;
                    top: auto;
                    width: 65px;
                    height: 65px;
                    bottom: 0;
                }
            }
        }
        .wrapper-img {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            video{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .wrapper-img.blue_filter {
            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: linear-gradient(
                    39deg,
                    rgba(0, 60, 101) 17.59%,
                    rgba(0, 60, 101, 0) 92.38%
                );
                mix-blend-mode: multiply;
            }
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: linear-gradient(
                    83deg,
                    #003c65 38.12%,
                    rgba(0, 60, 101, 0) 89.54%
                );
                mix-blend-mode: multiply;
            }
        }
        .wrapper {
            max-width: 1280px;
            margin: 0 auto;
            position: relative;
            z-index: 1;
            height: 100%;
            padding-top: 7.292vw;
            @media (max-width: 1310px) {
                max-width: 1180px;
            }
            @media (max-width: 1100px) {
                padding-inline: 25px;
                padding-top: 80px;
            }
        }
        .banner-img {
            width: 100%;
            height: 100%;
            object-position: bottom;
            object-fit: cover;
        }
        .content {
            max-width: 631px;
            display: flex;
            flex-direction: column;
        }
        .subtitle {
            color: #fff;
            font-family: $font-main;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 8px;
            @media (max-width: 1100px) {
                font-size: 15px;
            }
        }
        .title {
            color: #fff;
            font-family: "Source Sans Pro", sans-serif;
            font-size: 9.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: 9rem;
            margin-top: 1.615vw;
            @media (max-width: 1100px) {
                font-size: 50px;
                line-height: 48px;
                max-width: 300px;
            }
        }
        .text {
            color: #fff;
            font-family: $font-main;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            margin-top: 2.813vw;
            @media (max-width: 1100px) {
                font-size: 16px;
                line-height: 20px;
                max-width: 250px;
            }
        }
        .btn-wrapper {
            margin-top: 2.552vw;

            .video-btn {
                display: none;

                @media (max-height: 860px) {
                    display: flex;
                    position: initial;
                }
            }
        }
        .btn-more {
            color: #fff;
            font-family: $font-main;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 4.688vw;
            width: fit-content;
            position: relative;
            &::after{
                content: "";
                position: absolute;
                bottom: 1.688vw;
                left: 0;
                width: 0%;
                height: 1px;
                background: #fff;
                transition: all .3s ease;
            }
            &:hover{
                &::after{
                    width: 100%;
                }
            }
            @media (max-width: 1100px) {
                display: none;
            }
        }
        .video-btn {
            position: absolute;
            bottom: 72px;
            left: 0;
            color: #fff;
            font-family: $font-main;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            align-items: center;
            gap: 20px;
            @media (max-width: 1100px) {
                left: inherit;
                right: 25px;
                bottom: 22px;
            }
            @media (max-height: 860px) {
                display: none;
            }
        }
        .blog-slider {
            width: 40vw;
            height: 180px;
            position: absolute;
            bottom: 0;
            right: 0;
            @media (max-width: 1100px) {
                display: none;
            }
            @media (max-width: 1310px) {
                width: 45vw;
                height: 150px;
            }
            .mySwiper2 {
                width: 100%;
                height: 100%;
                .swiper-button-prev,
                .swiper-rtl .swiper-button-next {
                    display: none;
                }
                .swiper-button-next,
                .swiper-rtl .swiper-button-prev {
                    right: 0;
                    left: auto;
                    height: 100%;
                    top: 12%;
                    width: 76px;
                    background: linear-gradient(#003C65 68%, #003C65
                    0% );
                    &::after {
                        color: #fff;
                        font-size: 20px;
                        font-weight: bold;
                        padding-left: 5px;
                    }
                }
            }
            .swiper-slide {
                display: flex;
                background: linear-gradient(
                    106deg,
                    rgba(255, 255, 255, 0.65) -71.2%,
                    rgba(255, 255, 255, 0) 100%
                );
                backdrop-filter: blur(7.5px);
            }
            img {
                width: 227px;
                height: 100%;
                object-fit: cover;
            }
            .text-content {
                padding-left: 64px;
                width: 50%;
                padding-top: 30px;
                display: flex;
                flex-direction: column;
                gap: 20px;
                @media (max-width: 1450px) {
                    gap: 20px;
                    padding-top: 30px;
                    width: 40%;
                    padding-left: 30px;
                }
            }
            .title-blog {
                color: #005db1;
                font-family: $font-main;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                letter-spacing: 8px;
                text-transform: uppercase;
                @media (max-width: 1310px) {
                    font-size: 1.8rem;
                    line-height: 2rem;
                }
            }
            .text-blog {
                color: #fff;
                font-family: $font-main;
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 22px;
                @media (max-width: 1450px) {
                    font-size: 18px;
                    line-height: 18px;
                }
            }
        }
    }

    //atuation section
    .atuation {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding-top: 72px;
        @media (max-width: 1100px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 0px;
        }
        &--card {
            height: 566px;
            width: 100%;
            position: relative;
            --opacity: 0;
            @media (max-width: 1100px) {
                height: 207px;
                &:nth-child(1) {
                    grid-area: 1 / 1 / 2 / 2;
                }
                &:nth-child(2) {
                    grid-area: 1 / 2 / 2 / 3;
                }
                &:nth-child(3) {
                    grid-area: 2 / 1 / 3 / 3;
                }
            }
            .wrapper-img {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background: linear-gradient(
                        210deg,
                        rgba(0, 93, 177, 0) 34.44%,
                        rgba(0, 93, 177, 0.94) 63.22%
                    );
                    opacity: var(--opacity);
                }
                &::before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background: linear-gradient(
                        233deg,
                        rgba(0, 60, 101, 0) 40.4%,
                        rgba(0, 60, 101, 0.8) 64.49%
                    );
                    mix-blend-mode: multiply;
                }
            }
            .content-text {
                position: relative;
                height: 100%;
                padding-left: 71px;
                padding-top: 50px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-bottom: 50px;
                @media (max-width: 1100px) {
                    padding-left: 24px;
                    padding-top: 25px;
                    padding-bottom: 25px;
                }
            }
            .title {
                color: #fff;
                font-family: $font-main;
                font-size: 25px;
                font-style: normal;
                font-weight: 600;
                line-height: 28px; /* 112% */
                @media (max-width: 1100px) {
                    line-height: 15px;
                    font-size: 16px;
                }
            }
            .subtitle {
                font-weight: 600;
                line-height: 60px;
                color: #fff;
                font-family: $font-main;
                font-size: 36px;
                font-style: normal;
                font-weight: 600;
                line-height: 60px; /* 166.667% */
                letter-spacing: 0;
                @media (max-width: 1100px) {
                    font-size: 22px;
                    font-weight: 600;
                    line-height: 22px;
                    letter-spacing: 0;
                    margin-top: 8px;
                }
            }
            .icon {
                width: 44px;
                height: auto;
                @media (max-width: 1100px) {
                    width: 18px;
                }
            }
            .text {
                color: #fff;
                font-family: $font-main;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px; /* 138.889% */
                width: 65%;
                opacity: 0;
                display: flex;
                height: 0;
                @media (max-width: 1100px) {
                    display: none !important;
                }
            }
        }
    }

    //service section
    .service {
        position: relative;
        margin-top: 222px;
        height: min(100vw, 683px);
        background: rgb(255, 255, 255);
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 1) 55%,
            rgba(0, 93, 177, 1) 55%
        );
        @media (max-width: 1100px) {
            background: #fff;
            display: flex;
            flex-direction: column-reverse;
            margin-top: 131px;
        }
        .text-big {
            position: absolute;
            top: -124px;
            right: 150px;
            text-align: right;
            color: transparent;
            font-family: "Source Sans Pro", sans-serif;
            font-size: 150px;
            font-style: normal;
            font-weight: 600;
            -webkit-text-stroke-width: 2px;
            -webkit-text-stroke-color: #8f8f8f;
            z-index: 9;
            @media (max-width: 1100px) {
                font-size: 18vw;
                right: inherit;
                top: -130px;
                -webkit-text-stroke-width: 0.7px;
                left: 10vw;
            }
        }
        &::after {
            content: "";
            position: absolute;
            width: 50.7%;
            height: 569px;
            left: 0;
            top: 0;
            background: #fff;
            @media (max-width: 1100px) {
                display: none;
            }
        }
        .wrapper-img {
            height: Min(100%, 50vw);
            position: absolute;
            top: 0;
            right: 0;
            width: 97%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            @media (max-width: 1100px) {
                height: 422px;
                position: relative;
                width: 100%;
            }
        }
        .container-text {
            max-width: 1280px;
            margin: 0 auto;
            position: relative;
            z-index: 9;
            @media (max-width: 1100px) {
                padding-inline: 25px;
                padding-bottom: 50px;
                display: block;
                margin: 0;
            }
            .subtitle {
                color: #575756;
                font-family: $font-main;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                letter-spacing: 8px;
                text-transform: uppercase;
                padding-top: 20px;
            }
            .title {
                color: #005db1;
                font-family: $font-main;
                font-size: 50px;
                font-style: normal;
                font-weight: 300;
                line-height: 50px;
                margin-top: 28px;
                span {
                    font-weight: bold;
                }
                @media (max-width: 1100px) {
                    font-size: 35px;
                    line-height: 35px; /* 100% */
                    br {
                        display: none;
                    }
                }
            }
            .text {
                color: #575756;
                font-family: $font-main;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px; /* 138.889% */
                max-width: 433px;
                margin-top: 42px;
                @media (max-width: 1100px) {
                    font-size: 16px;
                }
            }
            .know-more {
                color: #fff;
                font-family: $font-main;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 60px; /* 300% */
                width: 216px;
                height: 62px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $blue;
                margin-top: 77px;
                transition: all 0.3s ease-out;
                position: relative;
                isolation: isolate;
                span {
                    position: relative;
                    z-index: 9;
                }
                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background: linear-gradient(
                        39deg,
                        rgba(0, 60, 101, 0.68) 17.59%,
                        rgba(0, 60, 101, 0) 92.38%
                    );
                    transition: all 0.3s ease-out;
                    transform: scaleX(0);
                    transform-origin: 100% 100%;
                    transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
                    will-change: transform;
                    z-index: -1;
                }
                &:hover {
                    &::after {
                        transform-origin: 0 0;
                        transform: scaleX(1);
                    }
                }
                @media (max-width: 1100px) {
                    width: 100%;
                }
            }
        }
    }

    // nossos clientes
    .clients {
        background: $blue;
        padding-top: 68px;
        padding-bottom: 174px;
        @media (max-width: 1100px) {
            padding-bottom: 60px;
        }
        .btns-swiper-4{
            display: none;
            @media (max-width: 1100px) {
                display: flex;
                justify-content: center;
                margin-top: 55px;
            }
            .btn-next-4{
                transform: rotate(180deg);
            }
            .btn-prev-4{
                transform: translate(1px, -0.2px);
            }
            .btn-next-5{
                transform: rotate(180deg);
                img{
                    width: 55px;
                    height: 65px;
                    object-fit: cover;

                }
            }
            .btn-prev-5{
                transform: translate(1px, -0.2px);
                img{
                    width: 55px;
                    height: 65px;
                    object-fit: cover;
                }
            }
        }
        .swiper {
            width: 100%;
            height: 100%;
            margin-left: auto;
            margin-right: auto;
          }

        .swiper-slide {
        text-align: center;
        font-size: 18px;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 100%;
        }
        }
        .wrapper {
            display: flex;
            flex-direction: column;
            max-width: 1280px;
            margin: 0 auto;
            align-items: center;
            @media (max-width: 1100px) {
                padding-inline: 26px;
            }
        }
        .content-text {
            display: flex;
            flex-direction: column;
            gap: 24px;
            align-items: center;
        }
        .subtitle {
            color: #f0f0f0;
            font-family: $font-main;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 8px;
            text-transform: uppercase;
            @media (max-width: 1100px) {
                font-size: 15px;
            }
        }
        .title {
            color: #fff;
            font-family: $font-main;
            font-size: 50px;
            font-style: normal;
            font-weight: 300;
            line-height: 60px; /* 120% */
            span {
                font-weight: 600;
            }
            @media (max-width: 1100px) {
                font-size: 35px;
            }
        }
        .grid-clients {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            row-gap: 50px;
            column-gap: 70px;
            margin-top: 87px;
            .logo{
                max-width: 99px;
                max-height: 65px;
                object-fit: contain;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            img{
                width: 100%;
                height: auto;
            }
            @media (max-width: 1100px) {
                grid-template-columns: repeat(3, 1fr);
                -moz-column-gap: 97px;
                column-gap: 47px;
                row-gap: 50px;
                width: 100%;
                display: none;
                .logo {
                    max-width: 91px;
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
    .partner {
        background: #f0f0f0;
        .subtitle {
            color: #575756 !important;
        }
        .title {
            color: $blue !important;
        }
    }
}
//produtos slider section
.produtos-slider {
    background: #fff;
    padding-top: 62px;
    .content-text {
        max-width: 1280px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .subtitle {
            color: #575756;
            font-family: $font-main;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 60px;
            letter-spacing: 8px;
            @media (max-width: 1100px) {
                font-size: 15px;
            }
        }
        .title {
            color: #005db1;
            font-family: $font-main;
            font-size: 50px;
            font-style: normal;
            font-weight: 300;
            line-height: 60px;
            span {
                font-weight: bold;
            }
            @media (max-width: 1100px) {
                font-size: 35px;
                line-height: 35px;
            }
        }
    }
    .btn-nextOut, .btn-prevOut {
        width: 53px;
        height: 53px;
        position: absolute;
        display: block;
        transition: all 0.3s ease-out;
        .off{
            display: none;
        }
        &:hover{
            .on{
                display: none;
            }
            .off{
                display: block;
            }
        }
        @media (max-width: 1100px) {
            display: none;
        }
        img{
            width: 100%;
            height: 100%;
        }

    }
    .btn-nextOut{
        transform: rotate(-180deg);
        right: 50px;
        top: 32vh;

    }
    .btn-prevOut{
        left: 50px;
        top: 32vh;
    }
    .wrapper-slider {
        width: 87vw;
        padding-inline: 10vw;
        margin: 0 auto;
        padding-top: 88px;
        position: relative;
        @media (max-width: 1100px) {
            width: 100%;
            padding-inline: 0;
            padding-top: 30px;
        }
    }
    .mySwiper3 {
        .swiper-button-next,
        .swiper-rtl .swiper-button-prev {
            left: auto;
            width: 54px;
            height: 54px;
            background: $blue;
            &::after {
                font-size: 20px;
                font-weight: bold;
                color: #fff;
            }
            &:hover {
                background: linear-gradient(45deg, #003c65 0%, #003c65 68%);
            }
            @media (max-width: 1100px) {
                right: 0;
                height: 65px;
            }
        }
        .swiper-button-prev,
        .swiper-rtl .swiper-button-next {
            right: auto;
            width: 54px;
            height: 54px;
            background: $blue;
            &::after {
                font-size: 20px;
                font-weight: bold;
                color: #fff;
            }
            &:hover {
                background: linear-gradient(45deg, #003c65 0%, #003c65 68%);
            }
            @media (max-width: 1100px) {
                left: 0;
                height: 65px;
            }
        }
        .card-product {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 400px;
            @media (max-width: 1100px) {
                max-width: 338px;
                margin: 0 auto;
                background: #f3f3f3;
            }
            &:hover {
                .wrapper-img {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
            .wrapper-img {
                width: 100%;
                height: 227px;
                overflow: hidden;
                flex-shrink: 0;
            }
            .wrapper-content{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            img {
                object-fit: cover;
                transition: all 0.3s ease-out;
                height: 100%;
                width: 100%;
            }
            .text-content {
                padding-left: 30px;
                padding-right: 27px;
                margin-block: auto;

                @media (max-width: 1100px) {
                    padding-left: 49px;
                    padding-right: 100px;
                }
            }
            .subtitle {
                color: #005db1;
                font-family: $font-main;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                letter-spacing: 4.8px;
                line-height: 60px;
                @media (max-width: 1100px) {
                    font-size: 15px;
                }
            }
            .title {
                color: #575756;
                font-family: $font-main;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
            }
            p {
                color: #575756;
                font-family: $font-main;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                @media (max-width: 1100px) {
                    font-size: 16px;
                }
            }
            .detail {
                width: 100%;
                background: $blue;
                color: #fff;
                padding-left: 30px;
                padding-right: 33px;
                display: flex;
                justify-content: space-between;
                height: 64px;
                align-items: center;
                font-family: $font-main;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                overflow: hidden;
                position: relative;
                isolation: isolate;
                span {
                    position: relative;
                    z-index: 9;
                }
                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: linear-gradient(
                        39deg,
                        rgba(0, 60, 101, 0.68) 17.59%,
                        rgba(0, 60, 101, 0) 92.38%
                    );
                    transition: all 0.3s ease-out;
                    transform: scaleX(0);
                    transform-origin: 100% 100%;
                    transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
                    will-change: transform;
                    z-index: -1;
                }
                @media (max-width: 1100px) {
                    font-size: 18px;
                }
                img {
                    height: 16px;
                    width: 23px;
                    z-index: 9;
                    position: relative;
                }
                &:hover {
                    &::after {
                        transform-origin: 0 0;
                        transform: scaleX(1);
                    }
                }
            }
        }
    }
}
//novidades
#novidades{
    .news {
    background: #fff;
    min-height: 95dvh;
    padding-top: 150px;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 20dvh;
        background: linear-gradient(90deg, #005db1 -11.68%, #0c3e62 68.14%);
        bottom: 0;
        left: 0;
        @media (max-width: 1100px) {
            display: none;
        }
    }
    .title-big {
        font-size: 150px;
        color: transparent;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #8f8f8f;
        font-family: "Source Sans Pro", sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 220px;
        position: absolute;
        top: -112px;
        left: 30px;
        z-index: 9;
        @media (max-width: 1100px) {
            font-size: 22vw;
            line-height: 80px;
            left: 0;
            top: 40px;
            -webkit-text-stroke-width: 1px;
        }
    }
    .container-news {
        max-width: 85vw;
        margin: 0 auto;
        position: relative;
        z-index: 9;
        @media (max-width: 1100px) {
            max-width: 100%;
        }
        .parent {
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            grid-template-rows: repeat(4, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            height: 83.5dvh;
            @media (max-width: 1100px) {
                height: auto;
                display: flex;
                flex-direction: column;
                .div1,
                .div2,
                .div3,
                .div4,
                .div5 {
                    aspect-ratio: 1;
                }
            }
        }
        .div1 {
            grid-area: 1 / 1 / 5 / 5;
            position: relative;
        }
        .div2 {
            grid-area: 1 / 5 / 3 / 7;
            position: relative;
            .content-text {
                width: 70%;
            }
        }
        .div3 {
            grid-area: 1 / 7 / 3 / 9;
            position: relative;
            .content-text {
                width: 70%;
            }
        }
        .div4 {
            grid-area: 3 / 5 / 5 / 7;
            position: relative;
            .content-text {
                width: 70%;
            }
        }
        .div5 {
            grid-area: 3 / 7 / 5 / 9;
            position: relative;
            .content-text {
                width: 70%;
            }
        }
        .div1,
        .div2,
        .div3,
        .div4,
        .div5 {
            position: relative;
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: linear-gradient(
                    210deg,
                    rgba(0, 93, 177, 0) 40.33%,
                    rgba(0, 93, 177, 0.8) 65.7%
                );
                mix-blend-mode: multiply;
                transition: all 0.3s ease-out;
                will-change: opacity;
                opacity: 0;
            }
            &:hover {
                &::after {
                    opacity: 1;
                }
            }
        }
    }
    .wrapper-img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: linear-gradient(
                210deg,
                rgba(1, 20, 37, 0) 40.33%,
                rgba(1, 20, 37, 0.8) 65.7%
            );
            mix-blend-mode: multiply;
        }
    }
    .content-text {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-left: 25px;
        padding-bottom: 30px;
        width: 75%;
        gap: 18px;
        position: relative;
        z-index: 9;
    }
    .subtitle {
        color: #fff;
        font-family: $font-main;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 8px;
        text-transform: uppercase;
    }
    .title {
        color: #fff ;
        font-family: $font-main;
        font-size: 30px ;
        font-style: normal;
        font-weight: 600 ;
        line-height: 30px ; /* 100% */
        line-clamp: 3;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .text {
        color: #fff;
        font-family: $font-main;
        font-size: 18px;
        font-style: normal;
        font-weight: 400 ;
        line-height: 25px ; /* 138.889% */
        line-clamp: 3;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .tag {
        position: absolute;
        top: 0;
        left: 0;
        height: 51px;
        padding-inline: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $blue;
        color: #fff;
        font-family: $font-main;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 4.8px;
        text-transform: uppercase;
    }
    .read-more {
        color: var(--san-martin-white, #fff);
        font-family: $font-main;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 300% */
        display: flex;
        gap: 32px;
        align-items: center;
        height: 0px;
        opacity: 0;
    }
    .date {
        position: absolute;
        right: 26px;
        bottom: 24px;
        color: #fff;
        /* H6 - textBody */
        font-family: $font-main;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px; /* 138.889% */
        z-index: 99;
        opacity: 0;
    }
    .all-cards{
        .wrapper-img{
            img{
                transition: all 0.3s ease-out;
                will-change: transform;
            }

        }
        &:hover{
            .wrapper-img{
                img{
                    transform: scale(1.1);
                }
            }
        }
        @media (min-width: 1250px){
            &:not(:first-child){
                .read-more{
                    line-height: 3.125vw;
                    font-size: 1.042vw;
                }
                .tag{
                    height: 2.656vw;
                    font-size: 0.833vw;
                }
                .text{
                    font-size: 0.938vw;
                    line-height: 1.302vw;
                }
                .title{
                    font-size: 1.563vw;
                    line-height: 1.563vw;
                }
                .subtitle{
                    font-size: 0.833vw;
                }
                .content-text{
                    padding-bottom: 1.563vw;
                    gap: 0.938vw;
                }
            }
        }
    }
    }
}
