#footer{
    background: linear-gradient(90deg, #005DB1 -11.68%, #0C3E62 68.14%);
    padding-top: 108px;
    @media (max-width: 1100px){
        padding-top: 45px;
    }
    .main-wrapper{
        max-width: 1280px;
        margin: 0 auto;
        @media (max-width: 1100px){
            padding-inline: 23px;
        }

        .line-news{
            display: flex;
            @media (max-width: 1100px){
                flex-direction: column;
            }
            p{
                color: #FFF;
                font-family: $font-main;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px; /* 125% */
                max-width: 293px;
                margin-left: 53px;
                margin-right: 31px;
                @media (max-width: 1100px){
                    margin: 0;
                    margin-top: 40px;
                    margin-bottom: 45px;
                }
            }
        }
    }
    form{
        .input-group{
            display: flex;
            gap: 17px;
            margin-bottom: 27px;
            @media (max-width: 1100px){
                flex-direction: column;
            }
                button{
                    height: 62px;
                    width: 284px;
                    background: $blue;
                    color: #FFF;
                    font-family: $font-main;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-inline: 27px;
                    cursor: pointer;
                    transition: all 0.3s ease-out;
                    overflow: hidden;
                    position: relative;
                    @media (max-width: 1100px){
                        width: 100%;
                    }
                    img{
                        position: relative;
                        z-index: 9;
                    }
                    span{
                        position: relative;
                        z-index: 9;
                    }
                    &::after{
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        background:  linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0.00) 92.38%);
                        transition: all 0.3s ease-out;
                        transform: scaleX(0);
                        transform-origin: 100% 100%;
                        transition: transform .6s cubic-bezier(.53,.21,0,1);
                        will-change: transform;
                        }
                    &:hover{
                        &::after{
                            transform-origin: 0 0;
                            transform: scaleX(1);
                        }
                    }

                }
            }
        }
        input{
            height: 62px;
            border: none;
            background: #fff;
            width: 384px;
            padding-left: 27px;
            font-size: 18px;
            @media (max-width: 1100px){
                width: 100%;
            }
            &::placeholder{
                color: #575756;
                font-family: $font-main;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 60px; /* 333.333% */
            }
        }
        .box {
            display: block;
            position: relative;
            padding-left: 35px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            color: rgba(255, 255, 255, 0.40);
            font-family: $font-main;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            max-width: 320px;
            a{
                text-decoration: underline;
                color: rgba(255, 255, 255, 0.40);
            }
          }

          /* Hide the browser's default checkbox */
          .box input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }

          /* Create a custom checkbox */
          .checkmark {
            position: absolute;
            top: 8px;
            left: 0;
            height: 18px;
            width: 18px;
            border-radius: 3px;
            background-color: transparent;
            border: 1px solid #fff;
          }


          /* When the checkbox is checked, add a blue background */
          .box input:checked ~ .checkmark {
            background-color: #2196F3;
          }

          /* Create the checkmark/indicator (hidden when not checked) */
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }

          /* Show the checkmark when checked */
          .box input:checked ~ .checkmark:after {
            display: block;
          }

          /* Style the checkmark/indicator */
          .box .checkmark:after {
            left: 5px;
            top: 3px;
            width: 6px;
            height: 8px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }

    .site-map{
        margin-top: 71px;
        margin-bottom: 123px;
        position: relative;
        @media (max-width: 1100px){
            margin-top: 85px;
        }
        .redes-footer{
            position: absolute;
            display: flex;
            gap: 24px;
            align-items: center;
            right: 39px;
            bottom: 54px;
            svg{
                transition: all 0.3s ease-out;
                path{
                    transition: all 0.3s ease-out;
                }
            }
            a{
                &:hover{
                    svg{
                        fill: #8AB4DB;
                        path{
                            fill: #8AB4DB;
                        }
                    }
                }
            }
        }
        .site-map-links{
            display: flex;
            justify-content: space-between;
            @media (max-width: 1100px){
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 59px;
                row-gap: 50px;
            }
            h4{
                color: #FFF;
                font-family: $font-main;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 25px; /* 125% */
                margin-bottom: 45px;
                a{
                    color: #FFF;
                }
                @media (max-width: 1100px){
                    font-size: 22px;
                }
            }
            li{
                a{
                    color: rgba(255, 255, 255, 0.40);
                    font-family: $font-main;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    &:hover{
                        color: #fff;
                    }
                    @media (max-width: 1100px){
                        font-size: 17px;
                    }
                }
            }
        }
        .box-link{
            @media (max-width: 1100px){
                max-width: 125px;
            }
            ul{
                display: flex;
                flex-direction: column;
                gap: 26px;
            }
        }
        .blog-one{
            @media (max-width: 1100px){
                display: none;
            }
        }
    }

    .bottom{
        height: 97px;
        border-top: .5px solid #fff;
        @media (max-width: 1100px){
            border-top: none;
            background: #063558;
            padding-top: 30px;
            padding-bottom: 30px;
            height: auto;
        }
        .politics{
            font-size: 14px ;
            color: rgba(255, 255, 255, 0.49);
            text-decoration: none;
            font-family: $font-main;
            font-weight: 300;
        }
        p{
            color: rgba(255, 255, 255, 0.49);
            font-family: $font-main;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px; /* 187.5% */
            @media (max-width: 1100px){
                font-size: 18px;
            }
        }
        .wrapper{
            max-width: 1280px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            @media (max-width: 1100px){
                flex-direction: column-reverse;
                gap: 28px;
            }

            a{
                svg{
                    transition: all .2s ease-in-out;
                }
                &:hover{
                    svg{
                        path{
                            fill-opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
