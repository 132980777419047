#blog {
    // banner topo blog
    #bannerHero {
        height: auto;
        padding-bottom: 61px;

        .info {
            display: flex;
            justify-content: space-between;
            gap: 32px;
            max-width: 679px;
            margin-block-start: 32px;

            & > article {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            &-item {
                gap: 12px;

                &-text {
                    color: #fff;

                    font-family: $font-main, sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;
                }
            }
            .anchor {
                &-text {
                    color: var(--White-OFF, #F0F0F0);
                    font-family: $font-main, sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 60px;
                    margin-right: 20px;
                }
                img {
                    width: 17px;
                    height: 11px;
                }
            }
            .divider {
                width: 1px;
                height: 16px;
                background: #fff;
                opacity: .8;
                margin-inline: 20px;
                align-self: center;
            }
        }
        @media (width < 1100px) {
            padding-bottom: 49px;

            .info { display: none; }
        }
    }
    // breadcumbs with search bar
    .breadcumbs {
        padding: 11px 77px;
        max-height: 70px;

        .wrapper {
            display: flex;
            justify-content: space-between;

            .input-group {
                max-width: 301px;
                transition: all .5s ease;
            }
            .form-control {
                border: 2px solid var(--San-Martin-primary---Blue-primary-500, #005DB1);
                padding: 14px 20px 14px 44px;
                height: 49px;
                min-width: 100%;

                &, &::placeholder {
                    color: var(--San-Martin-Neutral, #575756);

                    font-family: $font-main, sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px; /* 138.889% */
                }
            }
            .input-group-text {
                img {
                    position: absolute;
                    z-index: 10;
                    left: 20px;
                    top: 16px;
                }
            }
        }
        @media (width < 1100px) {
            padding: 15px 25px;

            .wrapper {
                gap: 32px;

                .input-group {
                    max-width: 54px;

                    &.expand {
                        max-width: 60% !important;
                        flex-grow: 1;
                    }
                    &.default { max-width: 54px; }
                }
                form {
                    min-width: 100%;
                    height: 40px;
                }
                .form-control {
                    padding: 13px 20px;
                    height: 100%;
                    transition: all .5s ease;

                    &, &::placeholder {
                        font-size: 14px;
                        line-height: 1;
                    }
                    &::placeholder { opacity: 0; }
                    &:focus {
                        padding: 13px 45px;
                        &::placeholder { opacity: 1; }
                    }
                }
                .input-group-text {
                    img { top: 13px; }
                }
            }
        }
    }
    .categories {
        padding: 80px 77px 83px;

        &-container {
            max-width: Min(100%, 1280px);
            margin-inline: auto;
            padding: 5.5px 8px;

            display: flex;
            flex-wrap: nowrap;

            .nav-link {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-grow: 1;

                height: 56px;
                padding-inline: 37px;
                background-color: var(--White-OFF, #F0F0F0);
                transition: all .3s ease-in-out;

                &:hover {
                    background: var(--San-Martin-primary---Blue-primary-500, #005DB1);

                    span { color: #fff; }
                }
                span {
                    color: var(--San-Martin-Neutral, #575756);

                    font-family: $font-main, sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 16px; /* 100% */
                    letter-spacing: 4.8px;
                    text-transform: uppercase;
                }
                &.active {
                    background: var(--San-Martin-primary---Blue-primary-500, #005DB1);

                    span { color: #fff; }
                }
            }
            .swiper-slide + .swiper-slide {
                a {
                    border-left: 1px solid var(--Support, #8F8F8F);
                }
            }
            .swiper {
                &-slide {
                    width: fit-content;
                }
            }
        }
        @media (width < 1100px) {
            padding: 0 0 44px 0;

            &-container {
                padding: 0;

                .nav-link {
                    height: 54px;
                    padding-inline: 17px;

                    &:not(:has(.active)) {
                        border-block: 1px solid var(--Support, #8F8F8F);
                    }
                    span {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 14px; /* 100% */
                        letter-spacing: 3.36px;
                    }
                }
                .swiper {
                    &-slide {
                        &:nth-child(1) {
                            max-width: 175px;
                            border-left: 1px solid var(--Support, #8F8F8F);
                        }
                        &:nth-child(2) { max-width: 247px; }
                        &:nth-child(3) { max-width: 240px; }
                        &:nth-child(4) { max-width: 141px; }
                        &:nth-child(5) { max-width: 203px; }
                        &:last-child { border-right: 1px solid var(--Support, #8F8F8F); }
                    }
                }
            }
        }

    }
    .card-top {
        max-width: 1280px;
        margin-inline: auto;
        margin-bottom: 64.5px;

        .card-treinamentos { height: 470px; }

        @media (width < 1100px) {
            margin-bottom: 64px;

            .card-treinamentos { height: 390px; }
        }
    }
    .blog-wrapper {
        max-width: 1280px;
        margin-inline: auto;
        margin-bottom: 64px;

        display: flex;
        gap: 20px;
        overflow: hidden;

        .posts {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;

            .card-item {
                width: 412px;
                height: 402px;
                position: relative;
                isolation: isolate;

                &::after {
                    content: '';
                    position: absolute;
                    inset: 0;
                    background: linear-gradient(210deg, rgba(1, 20, 37, 0.00) 40.33%, rgba(1, 20, 37, 0.80) 65.7%);
                    mix-blend-mode: multiply;
                    z-index: 1;
                }
                .read-more {
                    height: 60px !important;
                    opacity: 1 !important;
                }
            }
            @media (width < 850px) {
                & > a { width: fit-content !important; }
                .card-item {
                    width: Min(400px, 100%) !important;
                }
            }
        }
        .aside {
            min-width: Min(100%, 410px);
            flex-grow: 1;

            &-categories {
                width: 100%;
                margin-bottom: 56px;

                display: flex;
                flex-wrap: wrap;

                .nav-link,
                .title-cat {
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    flex-grow: 1;
                    margin: 0;
                    width: 100%;

                    height: 56px;
                    padding-inline: 40px 37px;
                    background-color: var(--White-OFF, #F0F0F0);
                    transition: all .3s ease-in-out;

                    &:hover {
                        background: var(--San-Martin-primary---Blue-primary-500, #005DB1);

                        span { color: #fff; }
                    }
                    span {
                        color: var(--San-Martin-Neutral, #575756);

                        font-family: $font-main, sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 16px; /* 100% */
                        letter-spacing: 4.8px;
                        text-transform: uppercase;
                    }
                    &.active {
                        background: var(--San-Martin-primary---Blue-primary-500, #005DB1);

                        span { color: #fff; }
                    }
                }
            }
            &-newsletter {
                width: 100%;
                max-height: 540px;
                padding: 40px;
                background: var(--gradiente-cheio, linear-gradient(138deg, #005DB1 -11.68%, #0C3E62 68.14%));

                .sub-title,
                .title,
                .text { color: #fff !important; }
                .title {
                    font-size: 32px;
                    line-height: 43px;
                    margin-block-end: 20px;
                }
                .text {
                    max-width: 270px;
                    margin-block-end: 40px;
                }
                form {
                    .form-control {
                        height: 62px;
                        width: 100%;
                        padding: 1px 27px;
                        border: 2px solid var(--San-Martin-White, #FFF);
                        border-radius: 0;
                        background-color: transparent;

                        &, &::placeholder {
                            color: var(--San-Martin-White, #FFF);
                            font-family: $font-main, sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 60px; /* 428.571% */
                        }
                    }
                    .btn-light {
                        width: 100%;
                        height: 62px;
                        background: #fff;
                        transition: all .3s ease-in-out;

                        color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
                        font-family: $font-main;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 60px; /* 300% */

                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-block: 17px 27px;
                        padding-inline: 30px;

                        &:hover {
                            background: var(--White-OFF, #F0F0F0);
                        }
                        img { translate: -45px 0; }
                    }
                    .form-check {
                        margin-block: 20px 56px;
                        display: flex;
                        align-items: center;

                        &-input {
                            width: 18px;
                            height: 18px;
                            border-radius: 3px;
                            border: 1.5px solid var(--White-OFF, #F0F0F0);
                            background-color: rgba(217, 217, 217, 0.00);
                            margin-right: 24px;
                        }
                        &-label {
                            color: var(--Support, #8F8F8F);
                            font-family: $font-main, sans-serif;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;

                            a {
                                font-size: inherit;
                                font-family: inherit;
                                color: inherit;
                                text-decoration-line: underline;
                            }
                        }
                    }
                }
            }
        }
        @media (width < 1100px) {
            margin-bottom: 44px;
            flex-direction: column;
            gap: 44px;

            .posts {
                & > a { width: min-content; }
                .card-item {
                    min-width: Min(400px, 100%);
                    height: 194px;
                    margin-inline: auto;
                    padding-inline: 25px;

                    display: flex;
                    align-items: center;
                    gap: 20px;

                    &::after { background: transparent !important; }
                    .tag, .date, br { display: none; }
                    .bg-img {
                        position: inherit;
                        display: block;

                        width: 155.943px;
                        height: 171.996px;
                    }
                    .content-text {
                        gap: 12px;
                        padding: 0;

                        .subtitle {
                            color: var(--San-Martin-Neutral, #575756);
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 1; /* 291.667% */
                            letter-spacing: 6px;
                            margin: 0;
                        }
                        .title {
                            color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 25px;
                            margin: 0;
                        }
                        .text {
                            color: var(--San-Martin-Neutral, #575756);
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 16px;
                        }
                        .read-more {
                            color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 39px;
                            max-height: 39px !important;

                            svg {
                                width: 16px;
                                height: 10.647px;

                                path { fill: var(--San-Martin-primary---Blue-primary-500, #005DB1); }
                            }
                        }
                    }
                }
            }
            .aside {
                justify-self: center;
                padding-inline: 25px;

                &-categories {
                    margin-bottom: 0;

                    .nav-link,
                    .title-cat {
                        height: 54px;
                        padding-inline: 40px;

                        span {
                            font-size: 14px;
                            line-height: 14px; /* 100% */
                            letter-spacing: 3.36px;
                        }
                        &.active {
                            background: var(--San-Martin-primary---Blue-primary-500, #005DB1);

                            span { color: #fff; }
                        }
                    }
                }
                &-newsletter { display: none; }
            }
        }
    }
    .btn-wrapper {
        @media (width < 1100px) {
            display: none !important;
        }
    }
    .newsletter {
        padding: 128px 0 98px 0;
        position: relative;
        width: 90.5%;
        background: #F0F0F0;
        box-shadow: 10px 10px 24px 0px rgba(0, 0, 0, 0.15);
        margin-top: 105px;

        h4 {
            color: #575756;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 60px;
            letter-spacing: 8px;
            margin-right: 186px;
            margin-bottom: 22px;
            float: right;
        }
        h3 {
            color: #005DB1;
            font-size: 50px;
            font-style: normal;
            font-weight: 300;
            line-height: 60px;
            margin-right: 19px;
            margin-bottom: 19px;
            float: right;
            clear: both;

            & strong {
                color: #005DB1;
                font-size: 50px;
                font-style: normal;
                font-weight: 600;
                line-height: 60px;
            }
        }
        p {
            color: #575756;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-right: 15px;
            max-width: 300px;
            float: right;
            clear: both;
        }
        form {
            margin-top: 143px;
            padding: 0 79px;

            input {
                width: 100%;
                padding: 0 20px;
                border: none;
                margin-bottom: 30px;
                background-color: #fff;
            }

            input,
            input::placeholder {
                color: #575756;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 60px;
            }

            button {
                color: #FFF;
                font-family: $font-main;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 60px; /* 300% */
                width: 100%;
                height: 62px;
                gap: 20px;
                background: $blue;
                transition: all 0.3s ease-out;
                position: relative;
                isolation: isolate;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 2px 36px;
                span{
                    position: relative;
                    z-index: 9;
                }
                &::after{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0.00) 92.38%);
                    transition: all 0.3s ease-out;
                    transform: scaleX(0);
                    transform-origin: 100% 100%;
                    transition: transform .6s cubic-bezier(.53,.21,0,1);
                    will-change: transform;
                    z-index: -1;
                }
                &:hover{
                    &::after{
                        transform-origin: 0 0;
                        transform: scaleX(1);
                    }
                }
                @media (max-width: 1600px){
                    padding: 2px 20px;
                    gap: 0;
                }
                @media (max-width: 1100px){
                    width: 100%;
                }
            }

            .aceite {
                color: #8F8F8F;
                font-family: 'Source Sans Pro';
                font-size: 17px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                max-width: 350px;
                display: flex;
                align-items: center;

                a {
                    text-decoration: underline;
                    color: inherit;
                }

                .checkbox.style-c {
                    display: inline-block;
                    position: relative;
                    padding-left: 30px;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }

                .checkbox.style-c input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }

                .checkbox.style-c input:checked~.checkbox__checkmark {
                    background-color: #0084B5;
                }

                .checkbox.style-c input:checked~.checkbox__checkmark:after {
                    opacity: 1;
                }

                .checkbox.style-c:hover input~.checkbox__checkmark {
                    background-color: transparent;
                }

                .checkbox.style-c:hover input:checked~.checkbox__checkmark {
                    background-color: #0084B5;
                }

                .checkbox.style-c .checkbox__checkmark {
                    position: absolute;
                    top: -9px;
                    left: 0;
                    height: 20px;
                    width: 20px;
                    background-color: transparent;
                    transition: background-color 0.25s ease;
                    border-radius: 4px;
                    border: solid 1px #8F8F8F;
                }

                .checkbox.style-c .checkbox__checkmark:after {
                    content: "";
                    position: absolute;
                    left: 7px;
                    top: 2px;
                    width: 5px;
                    height: 10px;
                    border: solid #fff;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                    opacity: 0;
                    transition: opacity 0.25s ease;
                }

                .checkbox.style-c .checkbox__body {
                    color: #fff;
                    line-height: 1.4;
                    font-size: 16px;
                }
            }
        }
        .mulher {
            position: absolute;
            bottom: 0;
            left: 17px;

            @media(max-width: 1600px){
                left: -150px;
            }
        }

        @media (width < 1500px) {
            width: 95.5%;

            form {
                padding: 0 60px 0 20px;

                button { padding: 2px 20px; }
            }
            .mulher { left: -268px; }
        }
        @media (width < 1100px) {
            padding: 60px 0 0 0;
            margin-top: 44px;
            width: 100% !important;
            text-align: center;
            box-shadow: none;

            h4 {
                font-size: 15px;
                line-height: 60px; /* 400% */
                letter-spacing: 7.5px;
                margin-right: 0;
                margin-bottom: 4px;
            }
            h3 {
                font-size: 35px;
                font-style: normal;
                font-weight: 300;
                line-height: 40px;
                margin-bottom: 20px;

                & strong {
                    font-size: inherit;
                    font-weight: 700;
                }
            }
            p {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                max-width: 244px;
            }
            h4, h3, p {
                float: none;
                margin-inline: auto;
            }
            form {
                margin-top: 32px;
                padding-inline: 29px 21px;

                input { margin-bottom: 0; }
                .aceite {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    text-align: start;
                    margin-block: 27px 26px;

                    & > div {
                        br { display: none; }
                    }
                }
            }
            .col-md-12:not(:first-of-type) {
                padding: 0;
            }
            .mulher {
                display: block;
                position: inherit;
                height: fit-content;
                left: 0;

                img { max-width: 100%; }
            }
        }
    }
    .produtos-slider {
        padding-block: 120px 108px;

        @media (width < 1100px) {
            padding-block: 60px;

            .btn-ver-mais { display: none; }
        }
        .card-product {
            background-color: #F3F3F3;

            .title {
                color: var(--San-Martin-Neutral, #575756);
                font-family: $font-main;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 100% */
            }
        }
    }
    .back-to-top {
        max-width: 100px;
        display: block;
        margin-inline: auto;
        margin-bottom: 120px;

        color: #005DB1;
        font-size: 16px;
        font-weight: 600;
        line-height: 50px;
        text-align: center;

        &:hover {
            color: inherit;

            .icon { translate: 0 -2px; }
        }
        .icon {
            transform: rotate(180deg);
            display: block;
            margin-inline: auto;
            transition: all .2s ease-out;

            path { fill: #005DB1; }
        }
        @media (width < 1100px) {
            margin-bottom: 60px;
            font-size: 14px;
            line-height: 40px;
        }
    }

    .all-cards {
        position: relative;
        overflow: hidden;
        transition: all .3s ease;

        &:hover {
            .bg-img { scale: 1.1; }
        }
        .bg-img {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 0;
            transition: all .3s ease;
        }
        .content-text{
            position: relative;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-left: 25px;
            padding-bottom: 30px;
            width: 75%;
            gap: 18px;
            position: relative;
            z-index: 9;
        }
        .subtitle{
            color: #FFF;
            font-family: $font-main;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 8px;
            text-transform: uppercase;
        }
        .title{
            color: #FFF;
            font-family: $font-main;
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px; /* 100% */
        }
        .text{
            color: #FFF;
            font-family: $font-main;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px; /* 138.889% */
        }
        .tag{
            position: absolute;
            top: 0;
            left: 0;
            height: 51px;
            padding-inline: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $blue;
            color: #FFF;
            font-family: $font-main;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 4.8px;
            text-transform: uppercase;
            z-index: 2;

            @media (width < 1100px) {
                height: 27px;
                padding-inline: 26px;
                font-size: 15px;
                line-height: 16px;
                letter-spacing: 4.5px;
            }
        }
        .read-more{
            color: var(--san-martin-white, #FFF);
            font-family: $font-main;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 60px; /* 300% */
            display: flex;
            gap: 32px;
            align-items: center;
            height: 0px;
            opacity: 0;
        }
        .date{
            position: absolute;
            right: 26px;
            bottom: 24px;
            color: #FFF;
            /* H6 - textBody */
            font-family: $font-main;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px; /* 138.889% */
            z-index: 99;
            opacity: 0;
        }
        &::after{
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: linear-gradient(210deg, rgba(1, 20, 37, 0.00) 40.33%, rgba(1, 20, 37, 0.80) 65.7%);
            mix-blend-mode: multiply;
            transition:  all 0.3s ease-out;
            will-change: opacity;
            opacity: 1;
            z-index: 1;
        }

        @media (width < 1100px) {
            .subtitle {
                font-size: 15px;
                line-height: 16px; /* 106.667% */
                letter-spacing: 7.5px;
            }
            .title {
                font-size: 20px;
                line-height: 25px;
            }
            .text {
                font-size: 16px;
                line-height: 25px;
            }
            .tag {
                height: 27px;
                padding-inline: 26px;
                font-size: 15px;
                line-height: 16px;
                letter-spacing: 4.5px;
            }
            // .read-more {}
        }
    }
    .sub-title {
        color: var(--San-Martin-Neutral, #575756);

        font-family: $font-main, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 375% */
        letter-spacing: 8px;
        text-transform: uppercase;
        margin: 0;
    }
    .title {
        color: var(--San-Martin-primary---Blue-primary-500, #005DB1);

        font-family: $font-main, sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 300;
        line-height: 50px; /* 100% */

        strong { font-weight: bold; }
    }
    .text {
        color: #575756;
        font-family: $font-main, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 111.111% */
    }
    .default-btn {
        color: #FFF;
        font-family: $font-main;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 300% */
        width: 216px;
        height: 62px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $blue;
        transition: all 0.3s ease-out;
        position: relative;
        isolation: isolate;
        span{
            position: relative;
            z-index: 9;
        }
        &::after{
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0.00) 92.38%);
            transition: all 0.3s ease-out;
            transform: scaleX(0);
            transform-origin: 100% 100%;
            transition: transform .6s cubic-bezier(.53,.21,0,1);
            will-change: transform;
            z-index: -1;
        }
        &:hover{
            &::after{
                transform-origin: 0 0;
                transform: scaleX(1);
            }
        }
        @media (max-width: 1100px){
            width: 100%;
        }
    }
    // cdn lib reset
    p, ul {
        margin: 0;
        padding: 0;
        font-family: inherit;
        color: inherit;
    }
}
