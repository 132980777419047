.show--error {
    input {
        color: red !important;
        border: 1px solid red !important;

        &::placeholder {
            color: red !important;
        }
    }
    select {
        color: red !important;
        border: 1px solid red !important;
    }
    .error--message {
        display: block;
        color: red !important;
    }
    .checkmark {
        color: red !important;
        border: 1px solid red !important;
    }
    textarea {
        color: red !important;
        border: 1px solid red !important;
    }
}
.error--message {
    display: none;
}
.error-message-forms {
    width: 100%;
    height: 100%;
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10000;
    display: none;
    opacity: 1;
    &.open-modal {
        display: flex;
    }

    &--container {
        position: absolute;
        transform: translateY(-65%) translateX(-50%);
        background-color: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        top: 50%;
        left: 50%;

        width: 450px;
        padding: 30px 50px;
        gap: 20px;

        #btn--close-error-message {
            position: absolute;
            top: 2vw;
            right: 2vw;

            ion-icon {
                font-size: 3.4rem;
                color: $black;
            }
        }

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px;
            aspect-ratio: 1;
            border-radius: 50%;
            background-color: $white;
            font-size: 4.5rem;
            color: white;
        }

        .description {
            font-family: $font-main;
            font-size: 24px;
            font-style: normal;
            font-weight: 300;
            line-height: 50px;   
        }

        .confirm {
            color: #FFF;
            font-family: $font-main;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 60px;
            width: 216px;
            height: 62px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #005DB1;
            margin-top: 77px;
            transition: all 0.3s ease-out;
            position: relative;
            isolation: isolate;  

            &::after{
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0.00) 92.38%);
                transition: all 0.3s ease-out;
                transform: scaleX(0);
                transform-origin: 100% 100%;
                transition: transform .6s cubic-bezier(.53,.21,0,1);
                will-change: transform;
                z-index: -1;
            }
            &:hover{
                &::after{
                    transform-origin: 0 0;
                    transform: scaleX(1);
                }
            }

            @media (max-width: 799px) {
                margin-top: 40px;
            }
        }
    }

    @media (max-width: 799px) {
        &--container {
            width: 95%;
            padding-inline: 20px;
            padding-top: 40px;
            padding-bottom: 40px;
            gap: 30px;

            #btn--close-error-message {
                img {
                    width: 20px;
                }
            }

            .icon {
                width: 65px;
                height: 65px;
            }

            .description {
                font-size: 16px;
                line-height: 20px;
            }

            .confirm {
                margin-top: 0px;
            }
        }
    }
}
