#header{
    background: $blue;
    backdrop-filter: blur(9px);
    height: 108px;
    position: fixed;
    width: 100%;
    z-index: 99;
    @media (max-width: 1100px){
        height: 84px;
    }
    .search-menu{
        display: none;
        gap: 15px;
        align-items: center;
        width: 100%;
        background: #fff;
        padding: 1rem;
        transform-origin: right;
        form{
            width: 100%;
            display: flex;
            input{
                width: 100%;
                border: none;
                font-size: 18px;
                &::placeholder{
                    font-family: "Source Sans Pro", sans-serif;
                    color: #005DB1;
                    font-size: 18px;
                }
            }
            button{
                filter: brightness(0);
            }
        }
        .close-search{
            width: 15px;
            height: 15px;

        }
    }
    .nav-header{
        display: flex;
        align-items: center;
        max-width: 1280px;
        margin: 0 auto;
        padding-top: 31px;
        justify-content: space-between;
        @media (max-width: 1310px){
            max-width: 1180px;
            .logo{
                width: 150px;
            }
        }
        @media (max-width: 1100px){
            padding-top: 22px;
            padding-inline: 25px;
            justify-content: space-between;
            .logo{
                width: 185px;
                img{
                    width: 185px;
                }
            }
        }
    }
    .links{
        display: flex;
        gap: 20px;
        padding-left: 82px;
        margin-bottom: 0;
        @media (max-width: 1100px){
            display: none;
        }
    }
    .link{
        color: #FFF;
        font-family: $font-main;
        font-size: 1.7rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;
        display: flex;
        gap: 6px;
        align-items: center;
        position: relative;
        a{
            color: #FFF;
            font-family: $font-main;
            font-size: 1.7rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            cursor: pointer;
        }
        img{
            height: 7px;
        }
        @media (max-width: 1310px){
            font-size: 1.6rem;
        }
        &:hover{
            &::after{
                display: block;
            }
        }

    }
    .not-after{
        &::after{
            display: none !important;
        }
    }
    .search{
        padding-left: 52px;
        display: flex;
        gap: 36px;
        @media (max-width: 1100px){
            padding-left: 0;
        }
    }
    .search-btn{
        cursor: pointer;
        @media (max-width: 1100px){
            display: none;
        }
    }
    .form-select{
        $bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
        background-image: $bs-form-select-bg-img !important;
        background-color: $blue;
        border: none;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
        width: 50px;
        @media (max-width: 1100px){
            font-size: 22px;
            width: auto;
        }
    }
    .menu-insider{
        position: fixed;
        top: 90px;
        max-width: 979px;
        z-index: 99;
        display: flex;
        transform: translate(-109px, 0px);
        transform: scaleY(0);
        transform-origin: 100% 0;
        will-change: transform;
        box-shadow: 4px 4px 50px 0px #00000066;
        width: fit-content;
        &::after{
            content: "";
            width: 0;
            height: 0;
            border-left: 13px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 13px solid #F0F0F0;
            position: absolute;
            top: -13px;
            left: 109px;
        }
        &.active{
            top: 65px;

        }
        .primary-links{
            background: #F0F0F0;
            max-width: 460px;
            width: 100%;
            padding-left: 54px;
            padding-right: 54px;
            padding-top: 63px;
            padding-bottom: 41px;
            border-bottom: 4px solid  $blue;
            ul{
                padding: 0;
                li {
                    color: #575756;
                    font-family: $font-main;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px; /* 100% */
                    cursor: pointer;
                    margin-bottom: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    a{
                        color: #575756 ;
                        font-family: $font-main ;
                        font-size: 20px ;
                        font-style: normal ;
                        font-weight: 600 ;
                        line-height: 20px ; /* 100% */
                    }
                }
                .ativo{
                    color: $blue;
                    a{
                        color: $blue;
                    }
                    svg{
                        fill: $blue;
                    }
                }
            }
        }
        .secondary-links{
            background: #fff;
            max-width: 519px;
            width: 100%;
            padding-top: 56px;
            padding-left: 63px;
            padding-right: 54px;
            border-bottom: 4px solid  $blue;
            padding-bottom: 41px;
            ul{
                flex-direction: column;
                padding: 0;
                li, a{
                    color: #575756;
                    font-family: $font-main;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 35px; /* 194.444% */
                    &:hover{
                        color: $blue;
                    }
                    a{
                        color: #575756;
                        font-family: $font-main;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 35px; /* 194.444% */
                        &:hover{
                            color: $blue;
                        }
                    }
                }
            }
        }
        .d-none{
            display: none;
        }
        .d-flex{
            display: flex;
        }
    }
    .menu-insider-solutions{
        display: grid;
        grid-template-columns: 460px 520px;
        .primary-links{
            padding-left: 109px;
        }
    }
}

//HAMBURGER MENU
#nav-icon3{
    display: none;
    @media (max-width: 1100px){
        display: block;
        width: 60px;
        height: 45px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
        transform: scale(.75);
        span{
            display: block;
            position: absolute;
            height: 9px;
            width: 100%;
            background: #fff;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;
        }
    }
}
#nav-icon3 span:nth-child(1) {
    top: 0px;
  }

  #nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
    top: 18px;
  }

  #nav-icon3 span:nth-child(4) {
    top: 36px;
  }

  #nav-icon3.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  #nav-icon3.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #nav-icon3.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #nav-icon3.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

//MOBILE MENU INSIDE
    .menu-inside-mobile{
        position: fixed;
        top: 84px;
        background: #005DB1;
        backdrop-filter: blur(9px);
        width: 100%;
        min-height: calc(100dvh - 84px);
        left: 0;
        z-index: 99;
        display: none;
        &.open{
            display: block;
        }
        .btn-search-mobile{
            position: absolute;
            right: 55px;
            top: 22px;
            width: 19px;
            height: 19px;
        }
        .input-group{
            width: 100%;
            position: relative;
            padding-inline: 25px;
            margin-bottom: 38px;
            input{
                height: 62px;
                background: #fff;
                border: none;
                padding-left: 30px;
                color: #575756;
                font-family: $font-main;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 60px; /* 300% */
                width: 100%;
                &::placeholder{
                    color: #575756;
                    font-family: $font-main;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 60px; /* 300% */
                }
            }
        }
    }
    /* Add some basic styles for the accordion */
    .accordion {
        display: none;
        background: #E7E7E7;
        h3{
            color: #575756;
            font-family: $font-main;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            padding-inline: 25px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 45px;
            &.active{
                svg{
                    transform: rotate(90deg);
                }
            }
            a{
                color: #575756;
                font-family: $font-main;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                padding-inline: 25px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                line-height: 45px;
                background: inherit;
            }
        }
        a{
            color: #575756;
            font-family: $font-main;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 45px; /* 250% */
            background: #fff;
            padding-inline: 25px;
            display: block;
        }
        svg { transition: all .3s ease; }
    }

    .accordion-label {
        cursor: pointer;
        &.active{

        }
        h2{
            color: #FFF;
            font-family: $font-main;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding-inline: 25px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 60px;
            &.active{
                svg{
                    transform: rotate(90deg);
                }
            }
        }
        .inside-two{
            display: flex;
        }
        svg { transition: all .3s ease; }
    }
