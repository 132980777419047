// componente banner topo
#bannerHero {
    $navbarHeight: 108px;

    height: Min(100vh, 720px);
    padding: calc(87px + $navbarHeight) 77px 152px 316px;
    position: relative;
    isolation: isolate;

    &::after {
        content: '';
        position: absolute;
        inset: 0;
        background: linear-gradient(83deg, #003C65 38.12%, rgba(0, 60, 101, 0.00) 89.54%);
        mix-blend-mode: multiply;
        z-index: -1;
    }
    .banner-img {
        inset: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: -2;
    }
    .banner-container {
        max-width: 631px;
        color: var(--San-Martin-White, #FFF);
        z-index: 1;

        .main-sub-title {
            font-family: $font-main, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 60px; /* 375% */
            letter-spacing: 8px;
            text-transform: uppercase;
        }
        .main-title {
            font-family: $font-main, sans-serif;
            font-size: 95px;
            font-style: normal;
            font-weight: 600;
            line-height: 80px; /* 84.211% */
            margin-block: 12px 54px;
        }
        .text {
            color: var(--San-Martin-White, #FFF);
            font-family: $font-main, sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px; /* 138.889% */
        }
    }
}
// breadcumbs
.breadcumbs{
    background: #F3F3F3;
    padding: 25px 77px;

    @media (max-width: 1100px){
        padding-left: 25px;
    }
    .wrapper{
        max-width: 1280px;
        margin: 0 auto;
    }
    ul{
        display: flex;
        align-items: center;
        gap: 21px;
        @media (max-width: 1100px){
            gap: 10px;
        }
        li{
            color: #575756;
            font-family: $font-main;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            align-items: center;
            gap: 21px;
            @media (max-width: 1100px){
                gap: 16px;
                font-size: 16px;
            }
        }
        .active{
            color: $blue;
            svg{
                fill: $blue;
                @media (max-width: 1100px){
                    height: 15px;
                }
                path{
                    fill: $blue;
                }
            }
        }
    }
}

#quality {
    .breadcumbs {
        overflow: auto;
        -webkit-line-clamp: 1;
        white-space: nowrap;
    }

    .compromissos {
        display: flex;
        justify-content: center;
        padding-inline: 77px;
        @media (max-width: 1100px){
            padding-inline: 0;
            flex-direction: column;
            padding-top: 60px;

        }
        &-container {
            max-width: 1512px;
            display: flex;
            flex-direction: column;
            gap: 108px;
            margin-block: 68px 238px;
            @media (max-width: 1100px){
                margin-block: inherit;
                gap: 44px;
            }
            .img-and-text{
                .content-text{
                    max-width: 541px;
                    @media (max-width: 1100px){
                        max-width: 100%;
                        padding-inline: 25px;
                    }
                }
                .wrapper{
                    width: fit-content;
                    display: flex;
                    justify-content: space-between;
                    gap: 40px;
                    @media (max-width: 1100px) {
                        flex-direction: column;
                        gap: 0;
                        max-width: 100%;
                    }
                }
                .subtitle{
                    color: #575756;
                    margin-bottom: 20px;
                }
                .title{
                    margin-bottom: 42px;
                    strong{
                        font-weight: bold;
                    }
                    @media (max-width: 1100px){
                        font-size: 35px;
                        line-height: 35px;
                        margin-top: 14px;
                        margin-bottom: 42px;
                    }
                }
                .text{
                    color: #575756;
                    font-family: $font-main;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;
                    @media (max-width: 1100px){
                        color: #575756;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 25px;
                    }
                }
                .img-holder{
                    height: 516px;
                    aspect-ratio: 1.44;
                    transform: translate(107px, 0px);
                    overflow: hidden;
                    @media (max-width: 1100px){
                        transform: translate(0, 0);
                        width: 100%;
                        height: 285px;
                        margin-top: 43px;
                    }
                    &:hover{
                        img{
                            transform: scale(1.1);
                        }
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: all 0.3s ease;
                    }
                }
            }
            .reverse{
                .wrapper{
                    flex-direction: row-reverse;
                    @media (max-width: 1100px){
                        flex-direction: column;
                    }
                    .content-text { padding-top: 30px; }
                }
                .img-holder{
                    transform: translate(-107px, 0px);
                    @media (max-width: 1100px){
                        transform: translate(0, 0);
                    }
                }
            }
        }
    }
    .praticas {
        margin-left: 71px;
        height: 945px;
        position: relative;
        @media (max-width: 1100px){
            margin-left: 0;
            height: auto;
            display: flex;
            flex-direction: column-reverse;
            margin-top: 44px;
        }
        &-floating-div {
            position: absolute;
            top: 148px;
            left: -71px;
            background-color: #fff;
            width: Min(75vw, 1082px);
            height: 908px;
            padding: 108px 108px 145px;
            z-index: 1;
            filter: drop-shadow(20px 20px 30px rgba(0, 0, 0, 0.10));
            display: flex;
            justify-content: end;
            @media (max-width: 1100px){
                top: 0;
                left: 0;
                height: auto;
                position: relative;
                padding: 0;
                width: 100%;
                padding-inline: 25px;
                filter: none;
                margin-bottom: 60px;
            }
            .floating-wrapper {
                max-width: 656px;

                .title {
                    max-width: 305px;
                    margin-block: 4px 42px;
                }
                .text {
                    color: var(--San-Martin-Neutral, #575756);
                    font-family: $font-main, sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px; /* 138.889% */
                    margin-bottom: 42px;
                    @media (max-width: 1100px){
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; /* 137.5% */
                    }
                }
                .list {
                    list-style-type: disc;

                    li + li { margin-top: 6px; }
                    li {
                        color: var(--San-Martin-Neutral, #575756);
                        position: relative;
                        font-family: $font-main, sans-serif;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 25px; /* 138.889% */
                        display: flex;
                        @media (max-width: 1100px){
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px; /* 142.857% */
                            gap: 12px;
                        }
                        img {
                            padding-right: 12px;
                            align-self: center;
                            @media (max-width: 1100px){
                                padding-right: 0;
                                width: 8px;
                                height: 8px;
                            }
                        }
                    }
                }
            }
        }
        .mask-text {
            font-size: 154px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            color: transparent;
            -webkit-text-stroke: 2px #8F8F8F;
            -webkit-background-clip: text;
            background-clip: text;
            position: relative;
            right: 62px;
            text-align: end;
            top: -113px;
            @media (max-width: 1100px){
                top: 0;
                order: 1;
                left: 0;
                font-size: 13vw;
                right: inherit;
                text-align: center;
                -webkit-text-stroke: 1px #8F8F8F;
            }
        }
        .bg-img {
            position: absolute;
            inset: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            z-index: -1;
            @media (max-width: 1100px){
                position: relative;
                height: 442px;
                object-fit: cover;
                object-position: right;
            }
        }
    }
    .numeros {
        padding: 191px 77px 200px;
        background: var(--White-OFF, #F0F0F0);
        text-align: center;
        padding-inline: 77px;
        @media (max-width: 1100px){
            padding-top: 60px;
            padding-inline: 89.5px;
            padding-bottom: 60px;
        }
        .title { margin-inline: auto; }
        &-container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 30px;

            margin-inline: auto;
            max-width: 1280px;
            margin-top: 80px;

            @media (max-width: 660px) {
                justify-content: center;
            }
            &--item {
                max-width: 228px;
                text-align: center;

                .title {
                    font-weight: 600;
                    line-height: 50px; /* 100% */
                }
                .text {
                    color: var(--Support, #8F8F8F);

                    font-family: $font-main, sans-serif;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 30px; /* 120% */
                    margin-top: 22px;
                }
            }
        }
    }
    .politica {
        padding: 110px 124px;
        position: relative;
        min-height: 492px;
        display: flex;
        align-items: center;
        .wrapper-img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background: #005DB1;
            img{
                object-fit: cover;
                width: 100%;
                mix-blend-mode: multiply;
            }
        }
        @media (max-width: 1100px){
            padding-inline: 0px;
            padding-top: 60px;
            padding-bottom: 60px;
        }
        &-container {
            display: flex;
            gap: 50px;
            max-width: 1280px;
            margin-inline: auto;
            justify-content: center;
            @media (max-width: 1100px){
                flex-direction: column;
            }
            &--text {
                text-align: center;
                @media (max-width: 1100px){
                    padding-inline: 25px;
                }
                .title { margin-block: 4px 42px;
                    color: #fff;
                }
                .text {
                    color: var(--San-Martin-Neutral, #fff);
                    font-family: $font-main, sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px; /* 138.889% */
                    max-width: 1065px;
                }
            }
            &--img {
                height: fit-content;
                overflow: hidden;
                @media (max-width: 1100px){
                    height: 285px;
                }
                &:hover {
                    img { scale: 1.1; }
                }
                img { transition: .3s all ease; width: 100%; }
            }
        }
    }

    .sub-title {
        color: var(--San-Martin-Neutral, #575756);
        font-family: $font-main, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 375% */
        letter-spacing: 8px;
        text-transform: uppercase;
        @media (max-width: 1100px) {
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 60px; /* 400% */
            letter-spacing: 7.5px;
        }
    }
    .title {
        color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
        font-family: $font-main, sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 300;
        line-height: 50px; /* 100% */
        @media (max-width: 1100px) {
            font-size: 35px;
            font-style: normal;
            font-weight: 300;
            line-height: 35px; /* 100% */
        }
        strong { font-weight: 600; }
    }
}
