#success {
    padding: 216px 265px 192px;
    position: relative;

    @media (width < 1500px) {
        padding: 216px 77px 192px;
    }
    @media (width < 1100px) {
        padding: 0;
        padding-top: 84px;
    }
    .success {
        display: flex;
        justify-content: end;
        min-width: Min(100%, 1334px);
        height: 779px;
        @media (width < 1100px) {
            flex-direction: column;
            height: auto;
        }
        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @media (width < 1100px) {
                height: 390px;
            }
        }
    }
    .floating {
        position: absolute;
        inset: auto auto 112px 155px;
        min-width: min(100%, 739px);
        padding: 120px;
        background-color: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

        @media (width < 1500px) {
            inset: auto auto 112px 77px;
        }
        @media (width < 1100px) {
            position: relative;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            min-width: unset;
            width: calc(100% - 50px);
            padding: 0;
            inset: inherit;
            margin: 0 auto;
            padding: 40px 15px;
            transform: translate(0px, -150px);
            margin-bottom: -85px;
        }
        .title {
            color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
            font-family: $font-main;
            font-size: 50px;
            font-style: normal;
            font-weight: 300;
            line-height: 50px;
            text-align: center;
            strong { font-weight: 600; }
            @media (width < 1100px) {
                font-size: 35px;
                font-style: normal;
                font-weight: 300;
                line-height: 40px; /* 114.286% */
            }
        }
        .text {
            color: var(--San-Martin-Neutral, #575756);
            font-family: $font-main;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 25px; /* 125% */
            margin-block: 32px 40px;
            max-width: 245px;
            margin-inline: auto;
            text-align: center;
            @media (width < 1100px) {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 21px; /* 131.25% */
            }
        }
        .icon-wrapper { margin-top: 40px; }
        .btn-wrapper {
            gap: 32px;
            @media (width < 1100px) {
                flex-direction: column;
            }
            a {
                font-family: $font-main;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 60px; /* 300% */
                width: 100%;
                height: 62px;
                flex-grow: 1;

                position: relative;
                isolation: isolate;
            }
        }
        .default-btn {
            color: #FFF;
            font-family: $font-main;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 60px; /* 300% */
            display: flex;
            justify-content: center;
            align-items: center;
            background: $blue;
            transition: all 0.3s ease-out;
            position: relative;
            isolation: isolate;
            span{
                position: relative;
                z-index: 9;
            }
            &::after{
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0.00) 92.38%);
                transition: all 0.3s ease-out;
                transform: scaleX(0);
                transform-origin: 100% 100%;
                transition: transform .6s cubic-bezier(.53,.21,0,1);
                will-change: transform;
                z-index: -1;
            }
            &:hover{
                &::after{
                    transform-origin: 0 0;
                    transform: scaleX(1);
                }
            }
            @media (max-width: 1100px){
                width: 100%;
            }
        }
        .light-btn {
            background: #fff;
            border: 1px solid var(--San-Martin-primary---Blue-primary-500, #005DB1);

            display: flex;
            justify-content: center;
            align-items: center;

            transition: .3s all ease-in-out;

            span { color: #005DB1; }
            &:hover { background: var(--San-Martin-primary---Blue-primary-500, #005DB1); }
        }
    }
}
