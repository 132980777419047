#contact {
    .branches {
        padding: 65px 77px 199px;
        background: linear-gradient(138deg, #005DB1 -11.68%, #0C3E62 68.14%);
        position: relative;
        isolation: isolate;

        & > img {
            position: absolute;
            inset: 0 0 0 auto;
            z-index: -1;
        }
        &-container {
            display: flex;
            gap: 50px;
            justify-content: space-between;

            max-width: 1280px;
            margin-inline: auto;

            &--select {
                max-width: 540px;

                .title {
                    color: #fff;
                    margin-block: 0 38px;
                }
                .text {
                    color: #F0F0F0;
                    margin-bottom: 56px;
                }
                .select-wrapper {
                    max-width: 301px;
                }
            }
            &--info {
                padding: 40px 56px;
                margin-top: 69px;
                max-width: 467px;

                .sub-title {
                    line-height: 24px;
                    margin-bottom: 20px;
                }
                ul {
                    margin-bottom: 42px;

                    li + li { margin-top: 20px; }
                    a {
                        color: var(--San-Martin-White, #FFF);
                        font-family: $font-main;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 25px; /* 138.889% */
                        gap: 12px;

                        ion-icon {
                            color: #f0f0f0;
                            font-size: 15px;
                        }
                    }
                }
                .como-chegar {
                    gap: 32px;
                    color: var(--San-Martin-White, #FFF);
                    font-family: $font-main;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 60px; /* 300% */
                    &:hover{
                        text-decoration: underline;
                        text-underline-offset: 10px;
                    }
                }
            }
            .sub-title { color: #F0F0F0; }
        }

        @media (width < 1100px) {
            padding: 44px 25px 60px;
            height: auto;

            &-container {
                flex-direction: column;
                gap: 60px;
                max-width: 100%;

                &--select {
                    max-width: 100%;

                    .title { margin-block: 4px 32px; }
                    .text {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                        margin-bottom: 32px;
                    }
                    .select-wrapper {
                        max-width: 500px;
                        margin-inline: auto;
                    }
                }
                &--info {
                    padding: 0;
                    margin-top: 0;

                    ul {
                        margin-bottom: 43px;

                        li + li { margin-top: 21.5px; }
                        li {
                            p {
                                font-size: 14px;
                                line-height: 21px;
                            }
                            &:first-of-type {
                                p {
                                    font-size: 16px;
                                    line-height: 24px;
                                    max-width: 228px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .contato {
        padding: 108px 77px 200px;
        background-color: #f0f0f0;
        position: relative;

        &-container {
            max-width: 1280px;
            margin-inline: auto;

            display: flex;
            flex-direction: column;
            gap: 56px;

            &--text {
                display: flex;
                justify-content: space-between;
                gap: 32px;

                .info {
                    max-width: 326px;

                    .title {
                        max-width: 272px;
                        margin-block: 1px 26px;
                    }
                }
                .selects {
                    width: 650px;
                    display: flex;
                    align-self: flex-end;
                    gap: 19px;

                    &-container { flex-grow: 1; }
                }
            }
            &--forms {
                .form-row + .form-row { margin-top: 20px; }
                .form-row {
                    display: flex;
                    gap: 20px;
                    textarea{
                        height: 220px;
                    }
                    .input-wrapper {
                        flex-grow: 1;
                        width: 100%;
                    }
                    .form-control {
                        padding-inline: 27px;
                        border: 2px solid transparent;
                        border-radius: 0;
                        width: 100%;

                        &, &::placeholder {
                            color: var(--San-Martin-Neutral, #575756);
                            font-family: $font-main, sans-serif;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 60px; /* 333.333% */
                        }
                        &:focus {
                            box-shadow: none;
                            border-bottom: 2px solid #005DB1;
                        }
                    }
                }
                .form-check {
                    margin-block: 20px 56px;
                    display: flex;
                    align-items: center;

                    &-input {
                        width: 18px;
                        height: 18px;
                        border-radius: 3px;
                        border: 1.5px solid var(--Support, #8F8F8F);
                        margin-right: 24px;
                    }
                    &-label {
                        color: var(--Support, #8F8F8F);
                        font-family: $font-main, sans-serif;
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;

                        a {
                            font-size: inherit;
                            font-family: inherit;
                            color: inherit;
                            text-decoration-line: underline;
                        }
                    }
                }
            }
        }
        .mask-text {
            font-size: 154px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            color: transparent;
            -webkit-text-stroke: 2px #8F8F8F;
            -webkit-background-clip: text;
            background-clip: text;
            position: absolute;
            right: 56px;
            top: -83px;
            text-align: end;
        }

        @media (width < 1100px) {
            padding: 83px 24px 60px;

            &-container {
                gap: 20px;

                &--text {
                    text-align: center;
                    max-width: 100%;
                    margin-bottom: 0;

                    flex-direction: column;
                    gap: 60px;

                    .info {
                        max-width: 100%;

                        .title {
                            margin-block-end: 20px;
                            max-width: 100%;
                        }
                        .text {
                            font-size: 16px;
                            line-height: 22px;
                            max-width: 100%;
                            margin-inline: auto;
                        }
                    }
                    .selects {
                        flex-direction: column;
                        gap: 20px;
                        width: 100%;
                    }
                }
                &--forms {
                    .form-row {
                        flex-direction: column;

                        textarea { height: 220px; }
                    }
                    .input-wrapper {
                        flex-grow: 1 !important;
                        width: 100% !important;
                    }
                    .three {
                        & > .input-wrapper {
                            &:nth-child(2) { width: 100% !important; }
                        }
                    }
                    .form-check {
                        margin-block: 20px 40px;

                        &-label {
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;

                            a {
                                font-size: inherit;
                                font-family: inherit;
                                color: inherit;
                            }
                        }
                    }
                    button { width: 100%; }
                }
            }
            .mask-text {
                font-size: 66px;
                line-height: 50px;
                -webkit-text-stroke: 1px #8F8F8F;
                text-align: center;
                width: 100%;
                right: 0;
                top: -26px;
            }
        }
    }
    .produtos-slider {
        padding-block: 146px 152px;

        @media (width < 1100px) {
            padding-block: 60px;

            .btn-ver-mais { display: none; }
        }
        .card-product {
            background-color: #F3F3F3;

            .title {
                color: var(--San-Martin-Neutral, #575756);
                font-family: $font-main;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 100% */
            }
        }
    }

    .sub-title {
        color: var(--San-Martin-Neutral, #575756);

        font-family: $font-main, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 375% */
        letter-spacing: 8px;
        text-transform: uppercase;

        @media (width < 1100px) {
            font-size: 15px;
            line-height: 60px; /* 400% */
            letter-spacing: 7.5px;
        }
    }
    .title {
        color: var(--San-Martin-primary---Blue-primary-500, #005DB1);

        font-family: $font-main, sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 300;
        line-height: 50px; /* 100% */

        strong { font-weight: bold; }

        @media (width < 1100px) {
            font-size: 35px;
            line-height: 40px;

            strong {
                font-size: inherit;
                line-height: inherit;
            }
        }
    }
    .text {
        color: #575756;
        font-family: $font-main, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 111.111% */
    }
    .form-select {
        $bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='gray' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
        background-image: $bs-form-select-bg-img;
        background-position: right 24px center;
        background-repeat: no-repeat;
        background-size: 24px;
        width: 100%;

        padding-inline: 27px;
        border: 2px solid transparent;
        border-radius: 0;

        &, &::placeholder {
            color: var(--San-Martin-Neutral, #575756);
            font-family: $font-main, sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 60px; /* 333.333% */
        }
        &:focus {
            box-shadow: none;
            border-bottom: 2px solid #005DB1;
        }
    }
    .default-btn {
        color: #FFF;
        font-family: $font-main;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 300% */
        width: 216px;
        height: 62px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $blue;
        transition: all 0.3s ease-out;
        position: relative;
        isolation: isolate;
        span{
            position: relative;
            z-index: 9;
        }
        &::after{
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0.00) 92.38%);
            transition: all 0.3s ease-out;
            transform: scaleX(0);
            transform-origin: 100% 100%;
            transition: transform .6s cubic-bezier(.53,.21,0,1);
            will-change: transform;
            z-index: -1;
        }
        &:hover{
            &::after{
                transform-origin: 0 0;
                transform: scaleX(1);
            }
        }
        @media (max-width: 1100px){
            width: 100%;
        }
    }
}
