#blog-detail {
    #bannerHero {
        height: auto;
        padding-bottom: 61px;

        .info {
            display: flex;
            justify-content: space-between;
            gap: 32px;
            max-width: 679px;
            margin-block-start: 32px;
            @media (width < 1100px) {
                flex-direction: column;
            }
            &>article {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &-item {
                gap: 12px;
                @media (width < 1100px) {
                    gap: 8px;
                }
                &-text {
                    color: #fff;
                    font-family: $font-main, sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;
                    @media (width < 1100px) {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 21px; /* 150% */
                        white-space: nowrap;
                    }
                }
            }

            .anchor {
                &-text {
                    color: var(--White-OFF, #F0F0F0);
                    font-family: $font-main, sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 60px;
                    margin-right: 20px;
                    @media (width < 1100px) {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 35px; /* 250% */
                    }
                }

                img {
                    width: 17px;
                    height: 11px;
                    @media (width < 1100px) {
                        width: 12px;
                        height: 7px;
                    }
                }
            }

            .divider {
                width: 1px;
                height: 16px;
                background: #fff;
                opacity: .8;
                margin-inline: 20px;
                align-self: center;
                @media (width < 1100px) {
                    margin-inline: 0;
                }
            }
        }
    }

    .breadcumbs {
        padding: 11px 77px;
        max-height: 70px;

        .wrapper {
            display: flex;
            justify-content: space-between;

            .input-group {
                max-width: 301px;
                transition: all .5s ease;
            }
            .active{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 110px; /* Adjust the width as needed */
                display: inline-block;
                transition: all .3s ease;
            }
            .form-control {
                border: 2px solid var(--San-Martin-primary---Blue-primary-500, #005DB1);
                padding: 14px 20px 14px 44px;
                height: 49px;
                min-width: 100%;

                &, &::placeholder {
                    color: var(--San-Martin-Neutral, #575756);

                    font-family: $font-main, sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px; /* 138.889% */
                }
            }
            .input-group-text {
                img {
                    position: absolute;
                    z-index: 10;
                    left: 20px;
                    top: 16px;
                }
            }
        }
        @media (width < 1100px) {
            padding: 15px 25px;

            .wrapper {
                gap: 32px;

                svg {
                    max-height: 15px;
                }
                .input-group {
                    max-width: 54px;

                    &.expand {
                        max-width: 60% !important;
                        flex-grow: 1;
                    }
                    &.default { max-width: 54px; }
                }
                form {
                    min-width: 100%;
                    height: 40px;
                }
                .form-control {
                    padding: 13px 20px;
                    height: 100%;
                    transition: all .5s ease;

                    &, &::placeholder {
                        font-size: 14px;
                        line-height: 1;
                    }
                    &::placeholder { opacity: 0; }
                    &:focus {
                        padding: 13px 45px;
                        &::placeholder { opacity: 1; }
                    }
                }
                .input-group-text {
                    img { top: 13px; }
                }
            }
        }
    }

    //conteúdo
    .conteudo {
        padding-top: 84px;
        margin-left: 20px;
        margin-bottom: 65px;
        @media (max-width: 1100px) {
            margin-left: 0;
            padding-top: 70px;
            margin-bottom: 15px;
        }
        figure{
            img{
                @media (max-width: 1100px) {
                    width: 100%;
                }
            }
        }
        h3 {
            color: #575756;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 60px;
            letter-spacing: 8px;
            @media (max-width: 1100px) {
                font-size: 15px;
                font-style: normal;
                font-weight: 600;
                line-height: 60px; /* 400% */
                letter-spacing: 7.5px;
                padding-inline: 25px;
            }
        }

        h2 {
            color: #005DB1;
            font-size: 50px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 80px;
            max-width: 500px;
            line-height: 1.0;
            @media (max-width: 1100px) {
                font-size: 35px;
                font-style: normal;
                font-weight: 600;
                line-height: 40px; /* 114.286% */
                margin-bottom: 32px;
                padding-inline: 25px;
            }
        }

        p {
            color: #575756;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            margin-bottom: 44px;
            @media (max-width: 1100px) {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 137.5% */
                padding-inline: 25px;

            }
        }
    }

    .data {
        margin-left: 20px;

        .atualizado {
            color: #575756;
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: 25px;
            height: 100%;
            display: flex;
            align-items: center;
            @media (max-width: 1100px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 22px; /* 157.143% */
                margin-bottom: 32px;

                .row { margin-inline: 0 !important; }
            }
        }

        .itens {
            display: flex;
            align-items: center;
            justify-content: end;
            gap: 42px;
            @media (max-width: 1100px) {
                justify-content: space-between;
                padding-inline: 25px;
            }

            .compartilhar {
                color: #8F8F8F;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 25px;
                text-align: center;

                .icones {
                    display: flex;
                    gap: 24px;
                    align-items: center;

                    svg {
                        path { transition: all .3s ease; }

                        &:hover {
                            path { fill: #005DB1; }
                        }
                    }
                }
            }
        }
    }

    //sidebar
    .sidebar {
        margin-top: 156px;
        margin-left: 121px;
        @media (max-width: 1100px) {
            margin-top: 32px;
            margin-left: 0;
            padding-inline: 17.5px;
        }
        h4 {
            color: #575756;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 42px;
            letter-spacing: 8px;
            padding-bottom: 8px;
            border-bottom: solid 2px #F0F0F0;
            margin-bottom: 43px;
            width: 100%;
        }

        .posts-similares {
            .post {
                width: 100%;
                display: flex;
                margin-bottom: 42px;
                @media (max-width: 1100px) {
                    margin-bottom: 42px;
                    display: grid;
                    grid-template-columns: 155px 1fr;
                    gap: 20px;
                }
                .img-post {
                    width: 156px;
                    height: 171px;
                    position: relative;
                    margin-right: 20px;
                    @media (max-width: 1100px) {
                        width: 155px;
                        height: 172px;
                        margin-right: 0;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(210deg, rgba(1, 20, 37, 0.00) 40.33%, rgba(1, 20, 37, 0.80) 65.7%);
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                h6 {
                    color: #575756;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 35px;
                    letter-spacing: 6px;
                    margin-bottom: 7px;
                }

                h5 {
                    color: #005DB1;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 25px;
                    margin-bottom: 13px;
                }

                .desc-post {
                    max-width: 209px;
                    @media (max-width: 1100px) {
                        padding-top: 10px;
                    }
                }

                .desc-post p {
                    color: #575756;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                }

                .data {
                    color: #005DB1;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 19px;
                    margin-left: 0;
                    margin-top: 12px;
                }
            }
        }

        .bloco-news {
            padding: 40px;
            margin-top: 69px;
            background: var(--gradiente-cheio, linear-gradient(138deg, #005DB1 -11.68%, #0C3E62 68.14%));
            @media (max-width: 1100px) {
                display: none;
            }
            h6 {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 60px;
                letter-spacing: 8px;
                margin-bottom: 2px;
            }

            h5 {
                color: #F0F0F0;
                font-size: 32px;
                font-style: normal;
                font-weight: 300;
                line-height: 43px;
                margin-bottom: 17px;

                &strong {
                    color: #F0F0F0;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 43px;
                }
            }

            p {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                max-width: 265px;
            }

            form {
                margin-top: 51px;

                input {
                    width: 100%;
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 60px;
                    padding: 0 27px;
                    background-color: transparent;
                    border: 2px solid #FFF;

                    &::placeholder {
                        color: #FFF;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 60px;
                    }
                }

                button {
                    color: #005DB1;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 60px;
                    width: 100%;
                    margin-top: 11px;
                    text-align: left;
                    padding: 0 31px;
                    display: flex;
                    gap: 94px;
                    align-items: center;
                    background: #FFF;

                    @media (max-width: 1600px) {
                        justify-content: space-between;
                        padding: 0 20px;
                        gap: 0;
                    }
                }

                .aceite {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    color: #F0F0F0;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    margin-top: 29px;
                    gap: 14px;

                    .checkbox.style-c {
                        display: inline-block;
                        position: relative;
                        padding-left: 30px;
                        cursor: pointer;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                    }

                    .checkbox.style-c input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                    }

                    .checkbox.style-c input:checked~.checkbox__checkmark {
                        background-color: #0084B5;
                    }

                    .checkbox.style-c input:checked~.checkbox__checkmark:after {
                        opacity: 1;
                    }

                    .checkbox.style-c:hover input~.checkbox__checkmark {
                        background-color: transparent;
                    }

                    .checkbox.style-c:hover input:checked~.checkbox__checkmark {
                        background-color: #0084B5;
                    }

                    .checkbox.style-c .checkbox__checkmark {
                        position: absolute;
                        top: -9px;
                        left: 0;
                        height: 20px;
                        width: 20px;
                        background-color: transparent;
                        transition: background-color 0.25s ease;
                        border-radius: 4px;
                        border: solid 1px #fff;
                    }

                    .checkbox.style-c .checkbox__checkmark:after {
                        content: "";
                        position: absolute;
                        left: 7px;
                        top: 2px;
                        width: 5px;
                        height: 10px;
                        border: solid #fff;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                        opacity: 0;
                        transition: opacity 0.25s ease;
                    }

                    .checkbox.style-c .checkbox__body {
                        color: #fff;
                        line-height: 1.4;
                        font-size: 16px;
                    }
                }
            }
        }

        .categorias {
            margin-top: 58px;

            h4 {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 4.8px;
                border-bottom: none;
                margin: 0;
                padding: 20px 40px;
                background-color: #005DB1;
                @media (max-width: 1100px) {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 14px; /* 100% */
                    letter-spacing: 3.36px;
                }
            }

            ul {
                background-color: #F0F0F0;
            }

            ul li {
                padding: 20px 0;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    max-width: 272px;
                    height: 1px;
                    background-color: #B0CDE7;
                    bottom: 4px;
                    right: 72px;
                    @media (max-width: 1100px) {
                        right: 34px;
                    }
                }

                &:last-child::after {
                    display: none;
                }
            }

            ul li a {
                color: #575756;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 4.8px;
                padding: 20px 40px;
                transition: .3s ease-in;
                @media (max-width: 1100px) {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 14px; /* 100% */
                    letter-spacing: 3.36px;
                }
                &:hover {
                    transition: .3s ease-in;
                    color: #005DB1;
                }
            }
        }
    }

    .newsletter {
        padding: 128px 0 98px 0;
        position: relative;
        width: 90.5%;
        background: #F0F0F0;
        box-shadow: 10px 10px 24px 0px rgba(0, 0, 0, 0.15);
        margin-top: 105px;

        h4 {
            color: #575756;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 60px;
            letter-spacing: 8px;
            margin-right: 186px;
            margin-bottom: 22px;
            float: right;
        }
        h3 {
            color: #005DB1;
            font-size: 50px;
            font-style: normal;
            font-weight: 300;
            line-height: 60px;
            margin-right: 19px;
            margin-bottom: 19px;
            float: right;
            clear: both;

            & strong {
                color: #005DB1;
                font-size: 50px;
                font-style: normal;
                font-weight: 600;
                line-height: 60px;
            }
        }
        p {
            color: #575756;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-right: 15px;
            max-width: 300px;
            float: right;
            clear: both;
        }
        form {
            margin-top: 143px;
            padding: 0 79px;

            input {
                width: 100%;
                padding: 0 20px;
                border: none;
                margin-bottom: 30px;
                background-color: #fff;
            }

            input,
            input::placeholder {
                color: #575756;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 60px;
            }

            button {
                color: #FFF;
                font-family: $font-main;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 60px; /* 300% */
                width: 100%;
                height: 62px;
                gap: 20px;
                background: $blue;
                transition: all 0.3s ease-out;
                position: relative;
                isolation: isolate;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 2px 36px;
                span{
                    position: relative;
                    z-index: 9;
                }
                &::after{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0.00) 92.38%);
                    transition: all 0.3s ease-out;
                    transform: scaleX(0);
                    transform-origin: 100% 100%;
                    transition: transform .6s cubic-bezier(.53,.21,0,1);
                    will-change: transform;
                    z-index: -1;
                }
                &:hover{
                    &::after{
                        transform-origin: 0 0;
                        transform: scaleX(1);
                    }
                }
                @media (max-width: 1600px){
                    padding: 2px 20px;
                    gap: 0;
                }
                @media (max-width: 1100px){
                    width: 100%;
                }
            }

            .aceite {
                color: #8F8F8F;
                font-family: 'Source Sans Pro';
                font-size: 17px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                max-width: 350px;
                display: flex;
                align-items: center;

                a {
                    text-decoration: underline;
                    color: inherit;
                }

                .checkbox.style-c {
                    display: inline-block;
                    position: relative;
                    padding-left: 30px;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }

                .checkbox.style-c input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }

                .checkbox.style-c input:checked~.checkbox__checkmark {
                    background-color: #0084B5;
                }

                .checkbox.style-c input:checked~.checkbox__checkmark:after {
                    opacity: 1;
                }

                .checkbox.style-c:hover input~.checkbox__checkmark {
                    background-color: transparent;
                }

                .checkbox.style-c:hover input:checked~.checkbox__checkmark {
                    background-color: #0084B5;
                }

                .checkbox.style-c .checkbox__checkmark {
                    position: absolute;
                    top: -9px;
                    left: 0;
                    height: 20px;
                    width: 20px;
                    background-color: transparent;
                    transition: background-color 0.25s ease;
                    border-radius: 4px;
                    border: solid 1px #8F8F8F;
                }

                .checkbox.style-c .checkbox__checkmark:after {
                    content: "";
                    position: absolute;
                    left: 7px;
                    top: 2px;
                    width: 5px;
                    height: 10px;
                    border: solid #fff;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                    opacity: 0;
                    transition: opacity 0.25s ease;
                }

                .checkbox.style-c .checkbox__body {
                    color: #fff;
                    line-height: 1.4;
                    font-size: 16px;
                }
            }
        }
        .mulher {
            position: absolute;
            bottom: 0;
            left: 17px;

            @media(max-width: 1600px){
                left: -150px;
            }
        }

        @media (width < 1500px) {
            width: 95.5%;

            form {
                padding: 0 60px 0 20px;

                button { padding: 2px 20px; }
            }
            .mulher { left: -268px; }
        }
        @media (width < 1100px) {
            padding: 60px 0 0 0;
            margin-top: 44px;
            width: 100%;
            text-align: center;
            box-shadow: none;
            display: flex;
            flex-direction: column-reverse;
            h4 {
                font-size: 15px;
                line-height: 60px; /* 400% */
                letter-spacing: 7.5px;
                margin-right: 0;
                margin-bottom: 4px;
            }
            h3 {
                font-size: 35px;
                font-style: normal;
                font-weight: 300;
                line-height: 40px;
                margin-bottom: 20px;

                & strong {
                    font-size: inherit;
                    font-weight: 700;
                }
            }
            p {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                max-width: 244px;
            }
            h4, h3, p {
                float: none;
                margin-inline: auto;
            }
            form {
                margin-top: 32px;
                padding-inline: 29px 21px;

                input { margin-bottom: 0; }
                .aceite {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    text-align: start;
                    margin-block: 27px 26px;

                    & > div {
                        br { display: none; }
                    }
                }
            }
            .col-md-12:not(:first-of-type) {
                padding: 0;
            }
            .mulher {
                display: block;
                position: inherit;
                height: fit-content;
                left: 0;

                img { max-width: 100%; }
            }
        }
    }

    //produtos slider section
    .produtos-slider {
        background: #fff;
        padding-top: 62px;
        margin-bottom: 107px;
        @media (max-width: 1100px) {
            margin-bottom: 60px;
        }
        .content-text {
            max-width: 1280px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            .subtitle {
                color: #575756;
                font-family: $font-main;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 60px;
                letter-spacing: 8px;

                @media (max-width: 1100px) {
                    font-size: 15px;
                }
            }

            .title {
                color: #005DB1;
                font-family: $font-main;
                font-size: 50px;
                font-style: normal;
                font-weight: 300;
                line-height: 60px;

                span {
                    font-weight: bold;
                }

                @media (max-width: 1100px) {
                    font-size: 35px;
                    line-height: 35px;
                }
            }
        }

        .wrapper-slider {
            width: 87vw;
            padding-inline: 10vw;
            margin: 0 auto;
            padding-top: 88px;

            @media (max-width: 1100px) {
                width: 100%;
                padding-inline: 0;
                padding-top: 30px;
            }
        }

        .mySwiper3 {

            .swiper-button-next,
            .swiper-rtl .swiper-button-prev {
                left: auto;
                width: 54px;
                height: 54px;
                background: $blue;

                &::after {
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }

                @media (max-width: 1100px) {
                    right: 0;
                    height: 65px;
                }
            }

            .swiper-button-prev,
            .swiper-rtl .swiper-button-next {
                right: auto;
                width: 54px;
                height: 54px;
                background: $blue;

                &::after {
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }

                @media (max-width: 1100px) {
                    left: 0;
                    height: 65px;
                }
            }

            .card-product {
                @media (max-width: 1100px) {
                    max-width: 338px;
                    margin: 0 auto;
                    background: #f3f3f3;
                }

                &:hover {
                    .wrapper-img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }

                .wrapper-img {
                    width: 100%;
                    height: 227px;
                    overflow: hidden;
                }

                img {
                    object-fit: cover;
                    transition: all 0.3s ease-out;
                }

                .text-content {
                    padding-left: 30px;
                    padding-right: 27px;

                    @media (max-width: 1100px) {
                        padding-left: 49px;
                        padding-right: 100px;
                    }
                }

                .subtitle {
                    color: #005DB1;
                    font-family: $font-main;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    letter-spacing: 4.8px;

                    @media (max-width: 1100px) {
                        font-size: 15px;
                    }
                }

                .title {
                    color: #575756;
                    font-family: $font-main;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                    margin-top: 27px;
                }

                p {
                    color: #575756;
                    font-family: $font-main;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    margin-top: 22px;

                    @media (max-width: 1100px) {
                        font-size: 16px;
                    }
                }

                .detail {
                    margin-top: 24px;
                    width: 100%;
                    background: $blue;
                    color: #fff;
                    padding-left: 30px;
                    padding-right: 33px;
                    display: flex;
                    justify-content: space-between;
                    height: 64px;
                    align-items: center;
                    font-family: $font-main;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    overflow: hidden;
                    position: relative;
                    isolation: isolate;

                    span {
                        position: relative;
                        z-index: 9;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0.00) 92.38%);
                        transition: all 0.3s ease-out;
                        transform: scaleX(0);
                        transform-origin: 100% 100%;
                        transition: transform .6s cubic-bezier(.53, .21, 0, 1);
                        will-change: transform;
                        z-index: -1;
                    }

                    @media (max-width: 1100px) {
                        font-size: 18px;
                    }

                    img {
                        height: 16px;
                        width: 23px;
                        z-index: 9;
                        position: relative;
                    }

                    &:hover {
                        &::after {
                            transform-origin: 0 0;
                            transform: scaleX(1);
                        }
                    }
                }
            }
        }
    }

    // cdn lib reset
    p,
    ul,
    a {
        margin: 0;
        padding: 0;
        font-family: inherit;
        color: inherit;
    }

    .voltar {
        color: #005DB1;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        width: 100%;
        display: block;
        margin-block: 120px;
        @media (max-width: 1100px) {
           margin-block: 60px;
        }
    }
}
