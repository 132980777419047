#product{
    //NAV PAGES
    .nav-pages{
        background: #F3F3F3;
        padding: 25px 0;
        @media (max-width: 1100px){
            padding-left: 25px;
        }
        .wrapper{
            max-width: 1280px;
            margin: 0 auto;
        }
        ul{
            display: flex;
            align-items: center;
            gap: 21px;
            @media (max-width: 1100px){
                gap: 10px;
            }
            li{
                color: #575756;
                font-family: $font-main;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                display: flex;
                align-items: center;
                gap: 21px;
                @media (max-width: 1100px){
                    gap: 16px;
                    font-size: 16px;
                }
            }
            .active{
                color: $blue;
                svg{
                    fill: $blue;
                    @media (max-width: 1100px){
                        height: 15px;
                    }
                    path{
                        fill: $blue;
                    }
                }
            }
        }
    }

    //INFO PRODUCT

    .produto-info{
        background: #fff;
        padding-top: 64px;
        padding-bottom: 5.208vh;
        @media (max-width: 1100px){
            padding-bottom: 100px;
        }
        .wrapper{
            max-width: 1280px;
            margin: 0 auto;
            @media (max-width: 1100px){
                padding-inline: 25px;
            }
        }
        .content-info{
            display: flex;
            gap: 89px;
            @media (max-width: 1100px){
                flex-direction: column;
                gap: 22px;
            }
        }
        .info-text{
            max-width: 672px;
        }
        .title{
            color: $blue;
            font-family: $font-main;
            font-size: 50px;
            font-style: normal;
            font-weight: 300;
            line-height: 60px;
            margin-bottom: 38px;
            margin-top: 30px;
            span{
                font-weight: bold;
            }
            @media (max-width: 1100px){
                font-size: 35px;
                line-height: 40px;
                margin-top: 11px;
            }
        }
        .subtitle{
            color: #575756;
        }
        .text{
            color: #575756;
            font-family: $font-main;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px; /* 138.889% */
            @media (max-width: 1100px){
                font-size: 16px;
                font-weight: 400;
            }
        }
        .info-list{
            padding-top: 150px;
            @media (max-width: 1100px){
                padding-top: 0;
            }
            ul{
                display: flex;
                flex-direction: column;
                gap: 6px;
                li{
                    color: #575756;
                    font-family: $font-main;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    position: relative;
                    display: flex;
                    align-items: center;
                    gap: 11px;
                    &::before{
                        content: "";
                        display: block;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: $blue;
                        flex-shrink: 0;
                        @media (max-width: 1100px){
                            width: 11px;
                            height: 11px;
                            position: absolute;
                            left: 0px;
                        }
                    }
                    @media (max-width: 1100px){
                        font-size: 16px;
                        line-height: 25px;
                        padding-left: 22px;
                    }
                }
            }
        }
    }


    //GALERIA

    .gallery{
       position: relative;
       padding-top: 10.677vh;
       .text-big{
        font-size: 220px;
        color: transparent;
        -webkit-text-stroke: 1px $blue;
        font-family: $font-main;
        font-style: normal;
        font-weight: bold;
        line-height: 220px;
        position: absolute;
        top: -62px;
        right: 292px;
        @media (max-width: 1100px){
            font-size: 90px;
            right: 25px;
            line-height: 90px;
            top: -65px;
        }
       }
        .parent {
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            grid-template-rows: repeat(4, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            position: relative;
            z-index: 9;
            height: 779px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                @media (max-width: 1100px){
                    height: auto;
                    display: flex;
                    flex-wrap: wrap;
                }
            }
            .icon-plus{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 104px ;
                height: 104px ;
                z-index: 99;
                pointer-events: none;
                opacity: 0;
                transition: all .3s ease-in-out;
                width: 100px !important;
                height: 100px !important;
                @media (max-width: 1100px){
                    width: 50px !important;
                    height: 50px !important;
                }
            }
            .wrapper-effect{
                height: 100%;
                position: relative;
                overflow: hidden;
                img{
                    transition: all .3s ease-in-out;
                }
                &::after{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(46deg, #003C65 -2.61%, rgba(0, 60, 101, 0.00) 90.22%);
                    mix-blend-mode: multiply;
                }
            }
            .div1, .div2, .div3, .div4 {
                position: relative;
                &:hover{
                    .icon-plus{
                        opacity: 1;
                        @media (max-width: 1100px){
                            width: 50px;
                            height: 50px;
                        }
                    }
                    .wrapper-effect{
                        img{
                            transform: scale(1.1);
                        }
                    }
                }
            }
            .div1 {
                grid-area: 1 / 1 / 7 / 4; position: relative;
                @media (max-width: 1100px){
                    width: 100%;
                    height: 305px;
                }
            }
            .div2 {
                 grid-area: 1 / 4 / 3 / 6;
                 @media (max-width: 1100px){
                    width: 33.33%;
                     height: 109px;
                 }
            }
            .div3 {
                 grid-area: 3 / 4 / 5 / 6;
                 @media (max-width: 1100px){
                    width: 33.33%;
                    height: 109px;
                }
            }
            .div4 {
                grid-area: 1 / 6 / 5 / 9;
                @media (max-width: 1100px){
                    width: 33.33%;
                    height: 109px;
                }
            }
    }

    //VIDEO DESTAQUE

    .video-destaque{
        padding: 50px 0;
        position: relative;
        @media (max-width: 1100px){
            padding: 33px 0;
        }
        .wrapper{
            max-width: 1280px;
            margin: 0 auto;
        }
        .video-play{
            width: 100%;
            height: 660px;
            position: relative;
            @media (max-width: 1100px){
                width: calc(100% - 50px);
                height: 202px;
                position: relative;
                margin: 0 auto;
            }
            &::after{
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: linear-gradient(46deg, #003C65 -2.61%, rgba(0, 60, 101, 0.00) 90.22%);
                mix-blend-mode: multiply;
            }
            .play-icon{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 75px;
                height: 75px;
                z-index: 10;
                @media (max-width: 1100px){
                    width: 50px;
                    height: 50px;
                }
            }
            .img-hold{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    //IMG TEXT
    .img-and-text{
        background: #fff;
        padding-top: 50px;
        padding-bottom: 50px;
        @media (max-width: 1100px){
            padding-top: 27px;
            padding-bottom: 43px;
        }
        .content-text{
            max-width: 541px;
            @media (max-width: 1100px){
                max-width: 100%;
                padding-inline: 25px;
            }
        }
        .wrapper{
            max-width: 1280px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width: 799px) {
                flex-direction: column;
            }
        }
        .subtitle{
            color: #575756;
            margin-bottom: 20px;
        }
        .title{
            color: $blue;
            font-family: $font-main;
            font-size: 50px;
            font-style: normal;
            font-weight: 300;
            line-height: 50px; /* 100% */
            margin-bottom: 42px;
            span{
                font-weight: bold;
            }
            @media (max-width: 1100px){
                font-size: 35px;
                line-height: 35px;
                margin-top: 14px;
                margin-bottom: 42px;
            }
        }
        .text{
            color: #575756;
            font-family: $font-main;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            @media (max-width: 1100px){
                color: #575756;
                font-size: 16px;
                font-weight: 400;
                line-height: 25px;
            }
        }
        .img-holder{
            height: 516px;
            aspect-ratio: 1.44;
            transform: translate(107px, 0px);
            overflow: hidden;
            @media (max-width: 1550px){
                transform: translate(0, 0);
                height: 490px;
                aspect-ratio: 1.24;
            }
            @media (max-width: 1100px){
                transform: translate(0, 0);
                width: 100%;
                height: 285px;
                margin-top: 43px;
            }
            &:hover{
                img{
                    transform: scale(1.1);
                }
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.3s ease;
            }
        }
    }
    .reverse{
        .wrapper{
            flex-direction: row-reverse;
        }
        .img-holder{
            transform: translate(-107px, 0px);
            @media (max-width: 1550px){
                transform: translate(0, 0);
            }
            @media (max-width: 1100px){
                display: none;
            }
        }
    }
    //DOWNLOADS

    .download-block{
        max-width: 1280px;
        margin: 0 auto;
        display: flex;
        background: #F0F0F0;
        justify-content: space-between;
        padding-left: 104px;
        padding-right: 52px;
        padding-bottom: 76px;
        padding-top: 56px;
        position: relative;
        margin-bottom: 50px;
        margin-top: 50px;
        @media (max-width: 1100px){
            top: 0;
            margin-top: 0;
            padding: 0;
            padding-inline: 25px;
            text-align: center;
            flex-direction: column;
            padding-bottom: 73px;
            margin-bottom: 0;
        }
        .content-text{
            padding-top: 45px;
            max-width: 320px;
            @media (max-width: 1100px){
                max-width: 100%;
            }
            .subtitle{
                color: #575756;
            }
            .title{
                color: #005DB1;
                font-family: $font-main;
                font-size: 50px;
                font-style: normal;
                font-weight: 300;
                line-height: 50px; /* 100% */
                margin-bottom: 33px;
                margin-top: 20px;
                span{
                    font-weight: bold;
                }
                @media (max-width: 1100px){
                    text-align: center;
                    font-size: 35px;
                    line-height: 35px;
                }
            }
            .text{
                color: #575756;
                font-family: $font-main;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 111.111% */
                @media (max-width: 1100px){
                    display: none;
                }
            }
        }
        .swiper{
            padding-left: 100px;
            padding-right: 30px;
            @media (max-width: 1100px){
                display: none;
            }
            .swiper-button-next, .swiper-rtl .swiper-button-prev{
                left: auto;
                width: 54px;
                height: 54px;
                background: $blue;
                &::after{
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }
                @media (max-width: 1100px){
                    right: 0;
                    height: 65px;
                }
            }
            .swiper-button-prev, .swiper-rtl .swiper-button-next{
                right: auto;
                width: 54px;
                height: 54px;
                background: $blue;
                &::after{
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }
                @media (max-width: 1100px){
                    left: 0;
                    height: 65px;
                }
            }
        }
        .wrapper-slider{
            width: 742px;
            @media (max-width: 1100px){
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 14px;
            }
        }
        .swiper-slide{
            width: 272px;
        }
        .card-download{
            display: flex;
            flex-direction: column;
            padding: 40px 14px;
            background: #fff;
            align-items: center;
            gap: 22px;
            max-width: 272px;
            @media (max-width: 1100px){
                margin: 0 auto;
                &:nth-child(1){
                    margin-top: 20px;
                }
            }
            .icon-wrapper{
                width: 116px;
                height: 116px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $blue;
            }
            .card-title{
                color: #575756;
                font-family: $font-main;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
            }
            .card-text{
                color: #575756;
                text-align: center;
                font-family: $font-main;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                @media (max-width: 1100px){
                    font-size: 16px;
                }
            }
        }
    }

   //PRODUCT DETAIL
    .detail-product{
        height: 912px;
        background: linear-gradient(138deg, #005DB1 -11.68%, #0C3E62 68.14%);
        @media (max-width: 1100px){
            height: auto;
            padding-bottom: 60px;
        }
        .swiper-button-next, .swiper-rtl .swiper-button-prev{
            left: auto;
            width: 54px;
            height: 54px;
            background: $blue;
            &::after{
                font-size: 20px;
                font-weight: bold;
                color: #fff;
            }
            @media (max-width: 1100px){
                right: 32.7%;
                height: 65px;
                bottom: 40px;
                top: auto;
                width: 65px;
            }
        }
        .swiper-button-prev, .swiper-rtl .swiper-button-next{
            right: auto;
            width: 54px;
            height: 54px;
            background: $blue;
            &::after{
                font-size: 20px;
                font-weight: bold;
                color: #fff;
            }
            @media (max-width: 1100px){
                width: 65px;
                left: 32.65%;
                height: 65px;
                top: auto;
                bottom: 40px;
            }
        }
        .mySwiper5{
            display: none;
            @media (max-width: 1100px){
                display: block;
                height: auto;
            }

        }
        .btns-swiper-5{
            display: flex;
            margin: 0 auto;
            justify-content: center;
            margin-top: 60px;
            display: none;
            @media (max-width: 1100px){
                display: flex;
            }
            .btn-prev-5{
                img{
                    width: 55px;
                    height: 65px;
                }
            }
            .btn-next-5{
                img{
                    width: 55px;
                    height: 65px;
                }
                transform: rotate(180deg);
            }
        }

        .wrapper{
            max-width: 1280px;
            margin: 0 auto;
            position: relative;
        }
        .subtitle{
            padding-top: 16vh;
            text-align: center;
            @media (max-width: 1500px){
                padding-top: 100px;
            }
            @media (max-width: 1100px){
                padding-top: 65px;
            }
        }
        .title{
            color: #FFF;
            font-family: $font-main;
            font-size: 50px;
            font-style: normal;
            font-weight: 300;
            line-height: 60px; /* 120% */
            text-align: center;
            margin-top: 30px;
            margin-bottom: 81px;
            span{
                font-weight: bold;
            }
            @media (max-width: 1100px){
                text-align: center;
                font-size: 35px;
                font-weight: 300;
                line-height: 35px; /* 100% */
            }
        }
        .card-detail{
            padding-inline: 25.2px;
            padding-top: 45px;
            padding-bottom: 42px;
            display: flex;
            flex-direction: column;
            background: #fff;
            width: 301px;
            height: 368px;
            align-items: center;
            transition:  all 0.3s ease-in-out;
            &:hover{
                transform: translateY(-15px);
            }
            @media (max-width: 1100px){
                margin: 0 auto;
            }
        }
        img{
            width: 104px;
            height: 104px;
        }
        .text{
            color: #575756;
            text-align: center;
            font-family: $font-main;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            margin-top: 70px;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                width: 60px;
                height: 2px;
                background: $blue;
                top: -30px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .cards-detail{
            display: flex;
            gap: 25px;
            justify-content: center;
            @media (max-width: 1100px){
                display: none;
            }

        }
    }


    // FORM INFOS
    .form-info{
        background: #F0F0F0;
        display: flex;
        width: calc(100% - 197px);
        justify-content: space-between;
        padding-right: 123px;
        align-items: center;
        position: relative;
        box-shadow: 10px 10px 24px 0px rgba(0, 0, 0, 0.15);
        @media (max-width: 1750px){
            width: 100%;
            padding-right: 0;
        }
        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 285px;
            background: #F0F0F0;
            top: 0;
            left: 0;
            z-index: -1;
            background: linear-gradient(138deg, #005DB1 -11.68%, #0C3E62 68.14%);
            width: calc(100% + 197px);
            @media (max-width: 1750px){
                width: 100%;
            }
            @media (max-width: 1100px){
                display: none;
            }
        }
        @media (max-width: 1100px){
            flex-direction: column;
            top: 0;
            margin-bottom: 0;
            padding-right: 0;
            width: 100%;
            padding-top: 34px;
        }
        .form-img{
            @media (max-width: 1500px){
                width: 35vw;
            }
            @media (max-width: 1100px){
                height: 417px;
                order: 3;
                width: 100%;
                object-fit: cover;
            }
        }
        .content-text{
            max-width: 254px;
            margin-right: 63px;
            transform: translate(-65px, 0px);
            @media (max-width: 1100px){
                margin-right: 0;
                transform: translate(0, 0);
                max-width: 100%;
            }
            .subtitle{
                color: #575756;
                @media (max-width: 1100px){
                    text-align: center;
                }
            }
            .title{
                color: #005DB1;
                font-family: $font-main;
                font-size: 50px;
                font-style: normal;
                font-weight: 300;
                line-height: 60px; /* 120% */
                span{
                    font-weight: bold;
                }
                @media (max-width: 1100px){
                    text-align: center;
                    font-size: 35px;
                    line-height: 35px; /* 100% */
                    margin-bottom: 43px;
                    margin-top: 20px;
                    br{
                        display: none;
                    }
                }
            }
        }
        form{
            width: 688px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            grid-template-rows: 62px 62px 62px 123px;
            transform: translate(-65px, 0px);
            @media (max-width: 1100px){
                transform: translate(0, 0);
                width: 100%;
                grid-template-columns: 1fr;
                grid-template-rows: 62px 62px 62px 62px 123px;
                padding-inline: 25px;
            }
            .form-select {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='gray' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
                background-position: right 24px center;
                background-repeat: no-repeat;
                background-size: 24px;
                width: 100%;
                height: 100%;
                padding-inline: 27px;
                border: 2px solid transparent;
                border-radius: 0;

                &, &::placeholder {
                    color: var(--San-Martin-Neutral, #575756);
                    font-family: "Source Sans Pro", sans-serif, sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 60px;                    
                }
            }
            input{
                width: 100%;
                height: 62px;
                background: #FFFFFF;
                border: none;
                padding-left: 27px;
                color: #575756;
                font-family: $font-main;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                &::placeholder{
                    color: #575756;
                    font-family: $font-main;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                }
            }
            textarea{
                min-height: 100%;
                width: 100%;
                border: none;
                column-span: all;
                padding-left: 27px;
                padding-top: 27px;
                color: #575756;
                font-family: $font-main;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                resize: none;
                &::placeholder{
                    color: #575756;
                    font-family: $font-main;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                }
            }
            .input-group{
                &:nth-child(5),
                &:nth-child(6) {
                    grid-column: span 2;
                }
                @media (max-width: 1100px){
                    grid-column: 1 / 3;
                }
            }
            button{
                width: 100%;
                height: 62px;
                background: $blue;
                color: #fff;
                font-family: $font-main;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }


    // PRODUTOS SLIDER
    .produtos-slider{
        background: #fff;
        padding-top: 62px;
        padding-bottom: 163px;
        @media (max-width: 1100px){
            padding-bottom: 60px;
        }
        .content-text{
            max-width: 1280px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            .subtitle{
                color: #575756;
                font-family: $font-main;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 60px;
                letter-spacing: 8px;
                @media (max-width: 1100px){
                    font-size: 15px;
                }
            }
            .title{
                color: #005DB1;
                font-family: $font-main;
                font-size: 50px;
                font-style: normal;
                font-weight: 300;
                line-height: 60px;
                span{
                    font-weight: bold;
                }
                @media (max-width: 1100px){
                    font-size: 35px;
                    line-height: 35px;
                }
            }
        }
        .wrapper-slider{
            width: 87vw;
            padding-inline: 10vw;
            margin: 0 auto;
            padding-top: 88px;
            @media (max-width: 1500px){
                padding-inline: 77px;
            }
            @media (max-width: 1100px){
                width: 100%;
                padding-inline: 0;
                padding-top: 30px;
            }
        }
        .mySwiper3 {
            .swiper-button-next,
            .swiper-rtl .swiper-button-prev {
                left: auto;
                width: 54px;
                height: 54px;
                background: $blue;
                &::after {
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }
                &:hover {
                    background: linear-gradient(45deg, #003c65 0%, #003c65 68%);
                }
                @media (max-width: 1100px) {
                    right: 0;
                    height: 65px;
                }
            }
            .swiper-button-prev,
            .swiper-rtl .swiper-button-next {
                right: auto;
                width: 54px;
                height: 54px;
                background: $blue;
                &::after {
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }
                &:hover {
                    background: linear-gradient(45deg, #003c65 0%, #003c65 68%);
                }
                @media (max-width: 1100px) {
                    left: 0;
                    height: 65px;
                }
            }
            .card-product {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                @media (max-width: 1100px) {
                    max-width: 338px;
                    margin: 0 auto;
                    background: #f3f3f3;
                }
                &:hover {
                    .wrapper-img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
                .wrapper-img {
                    width: 100%;
                    height: 227px;
                    overflow: hidden;
                    flex-shrink: 0;
                }
                .wrapper-content{
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 15px;
                }
                img {
                    object-fit: cover;
                    transition: all 0.3s ease-out;
                    height: 100%;
                    width: 100%;
                }
                .text-content {
                    padding-left: 30px;
                    padding-right: 27px;
                    @media (max-width: 1100px) {
                        padding-left: 49px;
                        padding-right: 100px;
                    }
                }
                .subtitle {
                    color: #005db1;
                    font-family: $font-main;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    letter-spacing: 4.8px;
                    line-height: 60px;
                    @media (max-width: 1100px) {
                        font-size: 15px;
                    }
                }
                .title {
                    color: #575756;
                    font-family: $font-main;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                }
                p {
                    color: #575756;
                    font-family: $font-main;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    @media (max-width: 1100px) {
                        font-size: 16px;
                    }
                }
                .detail {
                    width: 100%;
                    background: $blue;
                    color: #fff;
                    padding-left: 30px;
                    padding-right: 33px;
                    display: flex;
                    justify-content: space-between;
                    height: 64px;
                    align-items: center;
                    font-family: $font-main;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    overflow: hidden;
                    position: relative;
                    isolation: isolate;
                    span {
                        position: relative;
                        z-index: 9;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: linear-gradient(
                            39deg,
                            rgba(0, 60, 101, 0.68) 17.59%,
                            rgba(0, 60, 101, 0) 92.38%
                        );
                        transition: all 0.3s ease-out;
                        transform: scaleX(0);
                        transform-origin: 100% 100%;
                        transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
                        will-change: transform;
                        z-index: -1;
                    }
                    @media (max-width: 1100px) {
                        font-size: 18px;
                    }
                    img {
                        height: 16px;
                        width: 23px;
                        z-index: 9;
                        position: relative;
                    }
                    &:hover {
                        &::after {
                            transform-origin: 0 0;
                            transform: scaleX(1);
                        }
                    }
                }
            }
        }
    }
    //baner inicial
.intro-banner{
    position: relative;
    @media (max-width: 1100px){
        padding-top: 0px;
    }
    .mySwiper{
        .swiper-slide{
            width: 100%;
            height: calc(100dvh - 108px);
            min-height: 500px;
            @media (max-width: 1100px){
                min-height: inherit;
                height: auto;
                padding-top: 84px;
                padding-bottom: 144px;
            }
        }
        .swiper-button-next, .swiper-rtl .swiper-button-prev{
            right: 0;
            left: auto;
            top: 88.6%;
            width: 54px;
            height: 54px;
            background: $blue;
            &::after{
                font-size: 20px;
                font-weight: bold;
                color: #fff;
            }
            @media (max-width: 1100px){
                right: 0;
                left: 65px;
                top: 55px;
                width: 65px;
                height: 65px;
                bottom: 0;
            }
        }
        .swiper-button-prev, .swiper-rtl .swiper-button-next{
            left: calc(100% - 108px);
            right: auto;
            top: 88.6%;
            width: 54px;
            height: 54px;
            background: $blue;
            &::after{
                font-size: 20px;
                font-weight: bold;
                color: #fff;
            }
            @media (max-width: 1100px){
                left: 0;
                right: auto;
                top: 55px;
                width: 65px;
                height: 65px;
                bottom: 0;
            }
        }
    }
    .wrapper-img{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        &::after{
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: linear-gradient(83deg, #003C65 38.12%, rgba(0, 60, 101, 0.00) 89.54%);
            mix-blend-mode: multiply;
        }
    }
    .wrapper{
        max-width: 1280px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        height: 100%;
        padding-top: 10.052vw;
        @media (max-width: 1310px){
            max-width: 1180px;
        }
        @media (max-width: 1100px){
            padding-inline: 25px;
            padding-top: 80px;
        }
    }
    .banner-img{
        width: 100%;
        height: 100%;
        object-position: bottom;
        object-fit: cover;
    }
    .content{
        max-width: 775px;
        display: flex;
        flex-direction: column;
    }
    .subtitle{
        color: #FFF ;
        font-family: $font-main;
        font-size: 16px ;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 8px;
        @media (max-width: 1100px){
            font-size: 15px;
        }
    }
    .title{
        color: #FFF ;
        font-family: $font-main ;
        font-size: 9.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 8rem;
        margin-top: 31px;
        @media (max-width: 1100px){
            font-size: 50px;
            line-height: 48px;
            max-width: 100%;
        }
        @media (max-width: 450px) {
            font-size: 11vw;
            line-height: 1;
        }
    }
    .text{
        color: #FFF ;
        font-family: $font-main;
        font-size: 18px ;
        font-style: normal;
        font-weight: 400;
        line-height: 25px ;
        margin-top: 54px;
        @media (max-width: 1100px){
            font-size: 16px;
            line-height: 20px;
            max-width: 100%;
            margin-top: 20px;
        }
    }
    .text + .text { margin-top: 20px; }
    .btn-more{
        color: #FFF;
        font-family: $font-main;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px;
        margin-top: 49px;
        width: fit-content;
        @media (max-width: 1100px){
            display: none;
        }
    }
    .video-btn{
        position: absolute;
        bottom: 72px;
        left: 0;
        color: #FFF;
        font-family: $font-main;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        align-items: center;
        gap: 20px;
        @media (max-width: 1500px){
            bottom: 30px;
        }
        @media (max-width: 1100px){
            right: 21px;
            left: auto;
            bottom: -130px;
        }
    }
}
}
