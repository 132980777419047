#policy {
    .objective {
        padding: 96px 77px 80px;

        &-container {
            max-width: 1280px;
            margin-inline: auto;

            h3 {
                color: var(--San-Martin-Neutral, #575756);

                font-family: $font-main, sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 60px; /* 375% */
                letter-spacing: 8px;
                text-transform: uppercase;                
            }
            h2 {
                color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
                font-family: $font-main;
                font-size: 36px;
                font-style: normal;
                font-weight: 300;
                line-height: 100%; /* 36px */                    
                margin-block: 60px 32px;

                strong { 
                    font-weight: 600;
                    color: inherit;
                    font-family: inherit; 
                }
                &:first-of-type {
                    font-size: 50px !important;
                    line-height: 50px !important;                    
                    margin-block: 6px 60px !important;
                }
            }
            p, a, li {
                color: #575756;
                font-family: $font-main, sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;                
            }
            a { text-decoration: underline; }
            ul, ol {
                list-style-type: disc;

                li { position: relative; }
                li + li { margin-top: 20px; }                
            }
            ul {
                padding-left: 20px;

                li {
                    &::after {
                        content: '●';
                        color: inherit;
                        position: absolute;
                        left: -20px;
                        top: 0;
                    }
                }
            }
            ol {
                counter-reset: num;
                margin-block: 32px;

                li {
                    counter-increment: num;
                    padding-left: 20px;

                    &::after {
                        content: counter(num) '.';
                        position: absolute;
                        top: 0;
                        left: 0;

                        color: inherit;
                        font-family: inherit;
                        font-size: inherit;
                        font-weight: inherit;
                        margin-right: 20px;
                    }
                }
            }
            p + p { margin-top: 40px; }
            p + ul,
            ul + p { margin-top: 60px; }
            .table { 
                margin-block: 60px 80px;

                table {
                    border-color: var(--San-Martin-primary---Blue-primary-50, #E6EFF7);
                    
                    tr {
                        & > td {
                            &:first-child {
                                min-width: calc(243px + 65px);
                                padding-inline: 0 23px;
        
                                strong, p {
                                    color: var(--San-Martin-Neutral, #575756);
                                    font-family: $font-main;
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 20px; /* 100% */                            
        
                                    margin: 0;
                                    max-width: 280px;                            
                                }
                                @media (width < 1300px) {
                                    min-width: Min(230px, 100%);
                                }
                            }
                        }
                    }
                    th {
                        border: none;
                        position: relative;
                        color: var(--San-Martin-Neutral, #575756);
                        text-align: start;
                        font-family: $font-main;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px; /* 100% */                        
                        padding-bottom: 20px;
                        margin-bottom: 44px;

                        &::after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100px;
                            height: 2px;
                            background-color: var(--San-Martin-primary---Blue-primary-500, #005DB1);                            
                        }
                    }
                    td {
                        min-width: Min(100%, 260px);
                        height: 200px; 
                        padding: 20px;

                        li {
                            color: var(--San-Martin-Neutral, #575756);
                            font-family: $font-main;
                            font-size: 16px !important;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 21px !important;

                            max-width: 220px;
                        }
                    }
                    td + td { border-left: 2px solid var(--San-Martin-primary---Blue-primary-50, #E6EFF7); }
                }                
            }
            .dados {
                &-container {
                    padding-block: 20px;

                    .title {
                        color: var(--San-Martin-primary---Blue-primary-500, #005DB1);

                        font-family: $font-main;
                        font-size: 25px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 30px; /* 120% */                        
                    }
                    .divider {
                        width: 40px;
                        height: 2px;
                        background-color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
                        margin-block: 20px 31.5px;
                    }
                    &--wrapper {
                        display: flex;
                        align-items: end;
                        gap: 20px;

                        .info {
                            h5 {
                                color: var(--San-Martin-Neutral, #575756);

                                font-family: $font-main;
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 25px; /* 125% */                                
                                margin-bottom: 12px;
                            }
                        }
                        .rs { width: 281px; }
                        .cnpj { width: 276px; }
                        .endereco { max-width: 380px; }
                        .nome { width: 610px; }
                        .cadastro { 
                            align-self: center; 
                            width: 483px;

                            h5 { margin: 0 !important; }
                        }
                        .button {
                            .arrow-btn { 
                                gap: 32px; 

                                color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
                                font-family: $font-main;
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 60px; /* 300% */                                
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
            .bottom {
                padding-top: 20px;
                border-top: 2px solid var(--San-Martin-primary---Blue-primary-50, #E6EFF7);
                gap: 40px;
                max-width: 1064px;

                &-text {
                    max-width: 468px;
                    color: var(--San-Martin-Neutral, #575756);
                    font-family: $font-main;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 25px; /* 138.889% */                    
                    margin: 0;
                    flex-grow: 1;
                }
                &-desc {
                    color: var(--San-Martin-Neutral, #575756);
                    font-family: $font-main;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;
                    flex-grow: 1;
                    
                    strong { font-weight: 600; }
                    span { margin-inline: 12px; }
                }
            }
        }
        @media (width < 1100px) {
            padding: 44px 25px 80px;

            &-container {
                h3 {
                    font-size: 15px;
                    line-height: 60px;
                    letter-spacing: 50%;
                }
                h2 {
                    font-size: 24px;
                    line-height: 28px;
                    margin-block: 40px 32px;

                    &:first-of-type {
                        font-size: 35px !important;
                        line-height: 40px !important;                    
                        margin-block: 4px 40px !important;
                    }                
                }
                .table {
                    margin-block: 32px;
                    overflow: auto;
                    
                    table {
                        tr {
                            & > td {
                                &:first-child {
                                    max-width: 260px !important;
                                    padding-inline: 0 62px !important;
            
                                    strong, p {
                                        font-size: 18px;
                                        line-height: 21px; /* 100% */                            
            
                                        margin: 0;
                                        max-width: 155px;                   
                                    }
                                }
                            }
                        }
                        th {
                            font-size: 16px;
                            line-height: 18px;
    
                            &::after { width: 70px; }
                        }
                        td {
                            li {
                                font-size: 16px;
                                line-height: 21px;
                                max-width: 300px;
                            }
                        }
                    }
                }
                .dados {
                    &-container {
                        .title {
                            font-size: 20px;
                            line-height: 1;

                            .divider { width: 40px; }
                        }
                        &--wrapper {
                            flex-direction: column;
                            align-items: start;

                            .info {
                                h5 {
                                    font-size: 18px;
                                    line-height: 21px;
                                }
                                .text {
                                    font-size: 16px;
                                    line-height: 22px;
                                }
                            }
                            .nome,
                            .cnpj,
                            .rs,
                            .endereco,
                            .cadastro { width: 100%; }
                        }
                    }
                }
                .bottom { 
                    flex-direction: column;
                    gap: 32px;
                    border: none;
                    align-items: start !important;

                    &-text {
                        font-size: 18px;
                        line-height: 25px;
                    }
                    &-desc {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
            }
        }
    }
    .news {
        padding-top: 150px;

        .title-big { top: -83px; }
    }
}