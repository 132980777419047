#assistance {
    .info {
        padding: 68px 77px 126px;
        display: flex;
        justify-content: center;

        @media (max-width: 1100px){
            padding: 44px 0 91px;

            .img-and-text { overflow: auto; }
        }
        .img-and-text{
            overflow: hidden;

            .content-text{
                max-width: 541px;
                @media (max-width: 1100px){
                    max-width: 100%;
                    padding: 0 25px !important;
                }
            }
            .wrapper{
                width: fit-content;
                display: flex;
                justify-content: space-between;
                gap: 40px;
                @media (max-width: 1100px) {
                    flex-direction: column !important;
                    gap: 60px !important;
                }
            }
            .subtitle{
                color: #575756;
                margin-bottom: 20px;
            }
            .title{
                margin-bottom: 42px;
                strong{
                    font-weight: bold;
                }
                @media (max-width: 1100px){
                    margin-bottom: 32px;
                }
            }
            .text{
                color: #575756;
                font-family: $font-main;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
                @media (max-width: 1100px){
                    color: #575756;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 25px;
                }
            }
            .img-holder{
                height: 516px;
                aspect-ratio: 1.44;
                transform: translate(-107px, 0px);
                overflow: hidden;
                @media (max-width: 1400px){
                    transform: translate(-40px, 0);
                }
                @media (max-width: 1100px){
                    transform: translate(0, 0);
                    max-width: 500px;
                    height: 285px;
                    margin-inline: auto;
                }
                &:hover{
                    img{
                        transform: scale(1.1);
                    }
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all 0.3s ease;
                }
            }
        }      
        .reverse{
            .wrapper{
                flex-direction: row-reverse;

                .content-text { padding-top: 30px; }
            }
        }          
    }
    .contact {
        padding: 151px 77px 158px 320px;
        background: #F0F0F0;
        position: relative;

        &-container {
            max-width: 1069px;

            &--text {
                max-width: 297px;
                margin-bottom: 56px;

                .title { margin-block: 1px 26px; }
            }
            &--forms {
                .form-row + .form-row { margin-top: 20px; }
                .form-row {
                    display: flex;
                    gap: 20px;

                    .form-control,
                    .form-select {
                        padding-inline: 27px;
                        border: 2px solid transparent;
                        border-radius: 0;

                        &, &::placeholder {
                            color: var(--San-Martin-Neutral, #575756);
                            font-family: $font-main, sans-serif;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 60px; /* 333.333% */                            
                        }
                        &:focus {
                            box-shadow: none;
                            border-bottom: 2px solid #005DB1;
                        }
                    }
                    .form-select {
                        $bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"); 

                        background-image: $bs-form-select-bg-img;                        
                        background-position: right 24px center;
                        background-repeat: no-repeat;
                        background-size: 24px;                        
                        
                        option {
                            color: var(--San-Martin-Neutral, #575756);
                            font-family: $font-main, sans-serif;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 60px;                            
                        }
                    }
                }
                .one-third {
                    & > .input-wrapper {
                        &:first-child { flex-grow: 1; }
                        &:last-child { width: 347px; }
                    }
                }
                .three {
                    & > .input-wrapper {
                        flex-grow: 1;

                        &:nth-child(2) { width: Min(100%, 345px) !important; }
                    }
                }
                .half, .full-width {
                    & > .input-wrapper {
                        flex-grow: 1;
                    }                    
                }
                .radio-container {
                    margin-top: 20px;
                    display: flex;

                    p, .form-check-label {
                        color: var(--San-Martin-Neutral, #575756);
                        font-family: $font-main;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 60px; /* 333.333% */                        
                    }
                    p { margin-right: 80px; }
                    & > div { gap: 40px; }
                    .form-check-input {
                        border-radius: 50%;

                        &:checked { background: #005DB1; }
                    }
                }
                .form-check {
                    margin-block: 20px 56px;
                    display: flex;
                    align-items: center;

                    &-input {
                        width: 18px;
                        height: 18px;
                        border-radius: 3px;
                        border: 1.5px solid var(--Support, #8F8F8F);
                        margin-right: 24px;
                    }
                    &-label {
                        color: var(--Support, #8F8F8F);
                        font-family: $font-main, sans-serif;
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;

                        a { 
                            font-size: inherit;
                            font-family: inherit;
                            color: inherit;
                            text-decoration-line: underline;
                        }
                    }
                }
            }
        }
        .mask-text {
            font-size: 154px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            color: transparent;
            -webkit-text-stroke: 2px #8F8F8F;
            -webkit-background-clip: text;
            background-clip: text;

            position: absolute;
            right: 56px;
            top: -95px;  
            text-align: end;
        }
        
        @media (width < 1570px) {
            padding-left: 77px;
        }
        @media (width < 1100px) {
            padding: 76px 25px 60px;

            &-container {
                &--text {
                    text-align: center;
                    max-width: 100%;
                    margin-bottom: 60px;

                    .title { margin-block-end: 20px; }
                    .text {
                        font-size: 16px;
                        line-height: 22px;
                        max-width: 240px;
                        margin-inline: auto;
                    }
                }
                &--forms {
                    .form-row {  flex-direction: column; }
                    .input-wrapper {
                        flex-grow: 1 !important;
                        width: 100% !important;
                    }
                    .three {
                        & > .input-wrapper {  
                            &:nth-child(2) { width: 100% !important; }
                        }
                    }
                    .radio-container {
                        flex-direction: column;

                        p {
                            font-size: 18px;
                            line-height: 60px;                            
                        }
                        .form-check-label {
                            font-size: 14px;
                            line-height: 50px;                            
                        }
                        & > div {
                            justify-content: space-between;
                            gap: 20px; 
                            width: 100%;
                        }
                    }
                    .form-check {
                        margin-block: 40px;

                        &-label {
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;

                            a { 
                                font-size: inherit;
                                font-family: inherit;
                                color: inherit;
                            }
                        }
                    }
                    button { width: 100%; }
                }
            }
            .mask-text {
                font-size: 70px;
                -webkit-text-stroke: 1px #8F8F8F;
                font-style: normal;
                font-weight: 600;
                line-height: 50px;

                left: auto;
                right: 5px;
                top: -33px;
                text-align: end;
            }            
        }
    }
    .produtos-slider {
        padding-block: 146px 152px;

        @media (width < 1100px) {
            padding-block: 60px 0;
        }
        .card-product { 
            background-color: #F3F3F3; 
            
            .title {
                color: var(--San-Martin-Neutral, #575756);
                font-family: $font-main;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 100% */            
            }
        }
    }

    .sub-title {
        color: var(--San-Martin-Neutral, #575756);

        font-family: $font-main, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 375% */
        letter-spacing: 8px;
        text-transform: uppercase;

        @media (width < 1100px) {
            font-size: 15px;
            line-height: 60px; /* 400% */
            letter-spacing: 7.5px;            
        }
    }
    .title {
        color: var(--San-Martin-primary---Blue-primary-500, #005DB1);

        font-family: $font-main, sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 300;
        line-height: 50px; /* 100% */   
        
        strong { font-weight: bold; }

        @media (width < 1100px) {
            font-size: 35px;
            line-height: 40px;

            strong {
                font-size: inherit;
                line-height: inherit;
            }
        }
    }
    .text {
        color: #575756;
        font-family: $font-main, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 111.111% */        
    }
    .default-btn {
        color: #FFF;
        font-family: $font-main;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 300% */
        width: 216px;
        height: 62px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $blue;
        transition: all 0.3s ease-out;
        position: relative;
        isolation: isolate;
        span{
            position: relative;
            z-index: 9;
        }
        &::after{
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0.00) 92.38%);
            transition: all 0.3s ease-out;
            transform: scaleX(0);
            transform-origin: 100% 100%;
            transition: transform .6s cubic-bezier(.53,.21,0,1);
            will-change: transform;
            z-index: -1;
        }
        &:hover{
            &::after{
                transform-origin: 0 0;
                transform: scaleX(1);
            }
        }
        @media (max-width: 1100px){
            width: 100%;
        }
    }
}