@charset "UTF-8";
.animated-element[data-animation] {
  opacity: 1 !important;
  transform: translate(0, 0) !important;
}

.scroll-down-animation {
  animation-name: scrollDown;
  animation-iteration-count: infinite;
  animation-duration: 3s;
}

[data-animation] {
  opacity: 0 !important;
  transition: 1s;
}

[data-animation=right] {
  transform: translateX(-50px);
}

[data-animation=left] {
  transform: translateX(50px);
}
@media (max-width: 999px) {
  [data-animation=left] {
    transform: translateX(-50px) !important;
  }
}

[data-animation=up] {
  transform: translateY(50px);
}

[data-animation=down] {
  transform: translateY(-50px);
}

[data-sequencial] [data-sequencial-stagger] {
  opacity: 0;
}
[data-sequencial] [data-sequencial-stagger=right] {
  transform: translateX(-50px);
}
[data-sequencial] [data-sequencial-stagger=left] {
  transform: translateX(50px);
}
[data-sequencial] [data-sequencial-stagger=up] {
  transform: translateY(50px);
}
[data-sequencial] [data-sequencial-stagger=down] {
  transform: translateY(50px);
}

[data-animation-banner] {
  opacity: 0 !important;
  transition: 1s;
}
[data-animation-banner].animated-element {
  opacity: 1 !important;
  transform: translate(0, 0) !important;
}

body:not(.no-js) .reveal {
  position: relative;
  overflow: hidden;
}

body:not(.no-js) .image-wrap {
  transition: 1s ease-out;
  transition-delay: 0.2s;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  visibility: hidden;
}

body:not(.no-js) .image-wrap img {
  transform: scale(1.3);
  transition: 2s ease-out;
  position: relative !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body:not(.no-js) .image-wrap .enter-image-style {
  transform: scale(1.3);
  transition: 2s ease-out;
  position: relative !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body:not(.no-js) .animating .image-wrap {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  visibility: visible;
  transform: skewY(0);
}

body:not(.no-js) .animating img {
  transform: scale(1);
  transition: 4s ease-out;
}

body:not(.no-js) .animating .enter-image-style {
  transform: scale(1);
  transition: 4s ease-out;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h5,
p {
  font-weight: normal;
  margin: 0;
}

:focus,
button:focus,
a:focus {
  outline: 0;
}

ol,
ul {
  list-style: none;
  padding: 0;
}

li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}
a:focus, a:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

form {
  margin: 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
}

button {
  border: none;
  background-color: transparent;
  padding: 0;
}
button:focus, button:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

:root {
  scroll-behavior: unset !important;
}

dl, ol, ul {
  margin-bottom: 0;
}

@custom-media --desktop_big (width >= 1920px)

@custom-media --desktop (width <= 1399px)

@custom-media --desktop_min (width <= 1199px)

@custom-media --tablet_big (width <= 999px)

@custom-media --tablet (width <= 799px)

@custom-media --mobile_big (width <= 599px)

@custom-media --mobile (width <= 499px);
/*  */
/*------------- Hover background gradient --------------*/
@property --myColor1 {
  syntax: "<color>";
  initial-value: #FCA32D;
  inherits: false;
}
@property --myColor2 {
  syntax: "<color>";
  initial-value: #FF6196;
  inherits: false;
}
.button-hover-linearGradient {
  background-image: linear-gradient(105deg, var(--myColor1) 7%, var(--myColor2) 78%);
  transition: --myColor1 0.7s, --myColor2 0.7s;
}
.button-hover-linearGradient:hover {
  --myColor1: #ff933c;
  --myColor2: #ff933c;
}

.button-hover-linearGradient-others-browsers {
  background-image: linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
  background-image: -o-linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
  background-image: -moz-linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
}

/*@font-face {
	font-family: 'Branding-Medium';
	src: url('../fonts/Branding/Branding-Medium.woff2') format('woff2'),
	url('../fonts/Branding/Branding-Medium.woff') format('woff'),
	url('../fonts/Branding/Branding-Medium.eot') format('eot'),
	url('../fonts/Branding/Branding-Medium.svg') format('svg');
}*/
html {
  font-size: 62.5%;
}
@media (min-width: 700px) and (max-width: 1599px) {
  html {
    font-size: 55%;
  }
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro-Bold.woff2") format("woff2"), url("../fonts/SourceSansPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro-Black.woff2") format("woff2"), url("../fonts/SourceSansPro-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro-BlackIt.woff2") format("woff2"), url("../fonts/SourceSansPro-BlackIt.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro-Light.woff2") format("woff2"), url("../fonts/SourceSansPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro-ExtraLightIt.woff2") format("woff2"), url("../fonts/SourceSansPro-ExtraLightIt.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro-BoldIt.woff2") format("woff2"), url("../fonts/SourceSansPro-BoldIt.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro-ExtraLight.woff2") format("woff2"), url("../fonts/SourceSansPro-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro-LightIt.woff2") format("woff2"), url("../fonts/SourceSansPro-LightIt.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro-It.woff2") format("woff2"), url("../fonts/SourceSansPro-It.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro-SemiboldIt.woff2") format("woff2"), url("../fonts/SourceSansPro-SemiboldIt.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro-Semibold.woff2") format("woff2"), url("../fonts/SourceSansPro-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro-Regular.woff2") format("woff2"), url("../fonts/SourceSansPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.form-group {
  position: relative;
  margin: 0;
}

.mobile-only {
  display: none;
}
@media (max-width: 1100px) {
  .mobile-only {
    display: flex;
  }
}

.btn-ver-mais {
  width: calc(100% - 50px);
  justify-content: center;
  align-items: center;
  background: #005DB1;
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  height: 62px;
  margin: 0 auto;
  margin-top: 25px;
  margin-bottom: 25px;
}
@media (max-width: 1100px) {
  .btn-ver-mais {
    width: calc(100% - 35px);
  }
}

.subtitle {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 8px;
}
@media (max-width: 1100px) {
  .subtitle {
    font-size: 15px;
    letter-spacing: 7.5px;
  }
}

input.error, textarea.error, select.error {
  background-color: #fce4e4;
  border: 1px solid #cc0033;
  outline: none;
}

label.error {
  color: red;
  font-size: 12px;
  position: absolute;
  bottom: -20px;
}

.grecaptcha-badge {
  display: none !important;
}

#header {
  background: #005DB1;
  backdrop-filter: blur(9px);
  height: 108px;
  position: fixed;
  width: 100%;
  z-index: 99;
}
@media (max-width: 1100px) {
  #header {
    height: 84px;
  }
}
#header .search-menu {
  display: none;
  gap: 15px;
  align-items: center;
  width: 100%;
  background: #fff;
  padding: 1rem;
  transform-origin: right;
}
#header .search-menu form {
  width: 100%;
  display: flex;
}
#header .search-menu form input {
  width: 100%;
  border: none;
  font-size: 18px;
}
#header .search-menu form input::placeholder {
  font-family: "Source Sans Pro", sans-serif;
  color: #005DB1;
  font-size: 18px;
}
#header .search-menu form button {
  filter: brightness(0);
}
#header .search-menu .close-search {
  width: 15px;
  height: 15px;
}
#header .nav-header {
  display: flex;
  align-items: center;
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 31px;
  justify-content: space-between;
}
@media (max-width: 1310px) {
  #header .nav-header {
    max-width: 1180px;
  }
  #header .nav-header .logo {
    width: 150px;
  }
}
@media (max-width: 1100px) {
  #header .nav-header {
    padding-top: 22px;
    padding-inline: 25px;
    justify-content: space-between;
  }
  #header .nav-header .logo {
    width: 185px;
  }
  #header .nav-header .logo img {
    width: 185px;
  }
}
#header .links {
  display: flex;
  gap: 20px;
  padding-left: 82px;
  margin-bottom: 0;
}
@media (max-width: 1100px) {
  #header .links {
    display: none;
  }
}
#header .link {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  display: flex;
  gap: 6px;
  align-items: center;
  position: relative;
}
#header .link a {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}
#header .link img {
  height: 7px;
}
@media (max-width: 1310px) {
  #header .link {
    font-size: 1.6rem;
  }
}
#header .link:hover::after {
  display: block;
}
#header .not-after::after {
  display: none !important;
}
#header .search {
  padding-left: 52px;
  display: flex;
  gap: 36px;
}
@media (max-width: 1100px) {
  #header .search {
    padding-left: 0;
  }
}
#header .search-btn {
  cursor: pointer;
}
@media (max-width: 1100px) {
  #header .search-btn {
    display: none;
  }
}
#header .form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") !important;
  background-color: #005DB1;
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  width: 50px;
}
@media (max-width: 1100px) {
  #header .form-select {
    font-size: 22px;
    width: auto;
  }
}
#header .menu-insider {
  position: fixed;
  top: 90px;
  max-width: 979px;
  z-index: 99;
  display: flex;
  transform: translate(-109px, 0px);
  transform: scaleY(0);
  transform-origin: 100% 0;
  will-change: transform;
  box-shadow: 4px 4px 50px 0px rgba(0, 0, 0, 0.4);
  width: fit-content;
}
#header .menu-insider::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 13px solid #F0F0F0;
  position: absolute;
  top: -13px;
  left: 109px;
}
#header .menu-insider.active {
  top: 65px;
}
#header .menu-insider .primary-links {
  background: #F0F0F0;
  max-width: 460px;
  width: 100%;
  padding-left: 54px;
  padding-right: 54px;
  padding-top: 63px;
  padding-bottom: 41px;
  border-bottom: 4px solid #005DB1;
}
#header .menu-insider .primary-links ul {
  padding: 0;
}
#header .menu-insider .primary-links ul li {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 100% */
  cursor: pointer;
  margin-bottom: 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#header .menu-insider .primary-links ul li a {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 100% */
}
#header .menu-insider .primary-links ul .ativo {
  color: #005DB1;
}
#header .menu-insider .primary-links ul .ativo a {
  color: #005DB1;
}
#header .menu-insider .primary-links ul .ativo svg {
  fill: #005DB1;
}
#header .menu-insider .secondary-links {
  background: #fff;
  max-width: 519px;
  width: 100%;
  padding-top: 56px;
  padding-left: 63px;
  padding-right: 54px;
  border-bottom: 4px solid #005DB1;
  padding-bottom: 41px;
}
#header .menu-insider .secondary-links ul {
  flex-direction: column;
  padding: 0;
}
#header .menu-insider .secondary-links ul li, #header .menu-insider .secondary-links ul a {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
  /* 194.444% */
}
#header .menu-insider .secondary-links ul li:hover, #header .menu-insider .secondary-links ul a:hover {
  color: #005DB1;
}
#header .menu-insider .secondary-links ul li a, #header .menu-insider .secondary-links ul a a {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
  /* 194.444% */
}
#header .menu-insider .secondary-links ul li a:hover, #header .menu-insider .secondary-links ul a a:hover {
  color: #005DB1;
}
#header .menu-insider .d-none {
  display: none;
}
#header .menu-insider .d-flex {
  display: flex;
}
#header .menu-insider-solutions {
  display: grid;
  grid-template-columns: 460px 520px;
}
#header .menu-insider-solutions .primary-links {
  padding-left: 109px;
}

#nav-icon3 {
  display: none;
}
@media (max-width: 1100px) {
  #nav-icon3 {
    display: block;
    width: 60px;
    height: 45px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    transform: scale(0.75);
  }
  #nav-icon3 span {
    display: block;
    position: absolute;
    height: 9px;
    width: 100%;
    background: #fff;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
}

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2), #nav-icon3 span:nth-child(3) {
  top: 18px;
}

#nav-icon3 span:nth-child(4) {
  top: 36px;
}

#nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.menu-inside-mobile {
  position: fixed;
  top: 84px;
  background: #005DB1;
  backdrop-filter: blur(9px);
  width: 100%;
  min-height: calc(100dvh - 84px);
  left: 0;
  z-index: 99;
  display: none;
}
.menu-inside-mobile.open {
  display: block;
}
.menu-inside-mobile .btn-search-mobile {
  position: absolute;
  right: 55px;
  top: 22px;
  width: 19px;
  height: 19px;
}
.menu-inside-mobile .input-group {
  width: 100%;
  position: relative;
  padding-inline: 25px;
  margin-bottom: 38px;
}
.menu-inside-mobile .input-group input {
  height: 62px;
  background: #fff;
  border: none;
  padding-left: 30px;
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
  width: 100%;
}
.menu-inside-mobile .input-group input::placeholder {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
}

/* Add some basic styles for the accordion */
.accordion {
  display: none;
  background: #E7E7E7;
}
.accordion h3 {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding-inline: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 45px;
}
.accordion h3.active svg {
  transform: rotate(90deg);
}
.accordion h3 a {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding-inline: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 45px;
  background: inherit;
}
.accordion a {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 45px;
  /* 250% */
  background: #fff;
  padding-inline: 25px;
  display: block;
}
.accordion svg {
  transition: all 0.3s ease;
}

.accordion-label {
  cursor: pointer;
}
.accordion-label h2 {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-inline: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 60px;
}
.accordion-label h2.active svg {
  transform: rotate(90deg);
}
.accordion-label .inside-two {
  display: flex;
}
.accordion-label svg {
  transition: all 0.3s ease;
}

#footer {
  background: linear-gradient(90deg, #005DB1 -11.68%, #0C3E62 68.14%);
  padding-top: 108px;
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}
@media (max-width: 1100px) {
  #footer {
    padding-top: 45px;
  }
}
#footer .main-wrapper {
  max-width: 1280px;
  margin: 0 auto;
}
@media (max-width: 1100px) {
  #footer .main-wrapper {
    padding-inline: 23px;
  }
}
#footer .main-wrapper .line-news {
  display: flex;
}
@media (max-width: 1100px) {
  #footer .main-wrapper .line-news {
    flex-direction: column;
  }
}
#footer .main-wrapper .line-news p {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 125% */
  max-width: 293px;
  margin-left: 53px;
  margin-right: 31px;
}
@media (max-width: 1100px) {
  #footer .main-wrapper .line-news p {
    margin: 0;
    margin-top: 40px;
    margin-bottom: 45px;
  }
}
#footer form .input-group {
  display: flex;
  gap: 17px;
  margin-bottom: 27px;
}
@media (max-width: 1100px) {
  #footer form .input-group {
    flex-direction: column;
  }
}
#footer form .input-group button {
  height: 62px;
  width: 284px;
  background: #005DB1;
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 27px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  overflow: hidden;
  position: relative;
}
@media (max-width: 1100px) {
  #footer form .input-group button {
    width: 100%;
  }
}
#footer form .input-group button img {
  position: relative;
  z-index: 9;
}
#footer form .input-group button span {
  position: relative;
  z-index: 9;
}
#footer form .input-group button::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
}
#footer form .input-group button:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}
#footer input {
  height: 62px;
  border: none;
  background: #fff;
  width: 384px;
  padding-left: 27px;
  font-size: 18px;
}
@media (max-width: 1100px) {
  #footer input {
    width: 100%;
  }
}
#footer input::placeholder {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  /* 333.333% */
}
#footer .box {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: rgba(255, 255, 255, 0.4);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  max-width: 320px;
}
#footer .box a {
  text-decoration: underline;
  color: rgba(255, 255, 255, 0.4);
}
#footer .box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
#footer .checkmark {
  position: absolute;
  top: 8px;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid #fff;
}
#footer .box input:checked ~ .checkmark {
  background-color: #2196F3;
}
#footer .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
#footer .box input:checked ~ .checkmark:after {
  display: block;
}
#footer .box .checkmark:after {
  left: 5px;
  top: 3px;
  width: 6px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
#footer .site-map {
  margin-top: 71px;
  margin-bottom: 123px;
  position: relative;
}
@media (max-width: 1100px) {
  #footer .site-map {
    margin-top: 85px;
  }
}
#footer .site-map .redes-footer {
  position: absolute;
  display: flex;
  gap: 24px;
  align-items: center;
  right: 39px;
  bottom: 54px;
}
#footer .site-map .redes-footer svg {
  transition: all 0.3s ease-out;
}
#footer .site-map .redes-footer svg path {
  transition: all 0.3s ease-out;
}
#footer .site-map .redes-footer a:hover svg {
  fill: #8AB4DB;
}
#footer .site-map .redes-footer a:hover svg path {
  fill: #8AB4DB;
}
#footer .site-map .site-map-links {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1100px) {
  #footer .site-map .site-map-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 59px;
    row-gap: 50px;
  }
}
#footer .site-map .site-map-links h4 {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  /* 125% */
  margin-bottom: 45px;
}
#footer .site-map .site-map-links h4 a {
  color: #FFF;
}
@media (max-width: 1100px) {
  #footer .site-map .site-map-links h4 {
    font-size: 22px;
  }
}
#footer .site-map .site-map-links li a {
  color: rgba(255, 255, 255, 0.4);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
#footer .site-map .site-map-links li a:hover {
  color: #fff;
}
@media (max-width: 1100px) {
  #footer .site-map .site-map-links li a {
    font-size: 17px;
  }
}
@media (max-width: 1100px) {
  #footer .site-map .box-link {
    max-width: 125px;
  }
}
#footer .site-map .box-link ul {
  display: flex;
  flex-direction: column;
  gap: 26px;
}
@media (max-width: 1100px) {
  #footer .site-map .blog-one {
    display: none;
  }
}
#footer .bottom {
  height: 97px;
  border-top: 0.5px solid #fff;
}
@media (max-width: 1100px) {
  #footer .bottom {
    border-top: none;
    background: #063558;
    padding-top: 30px;
    padding-bottom: 30px;
    height: auto;
  }
}
#footer .bottom .politics {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.49);
  text-decoration: none;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
}
#footer .bottom p {
  color: rgba(255, 255, 255, 0.49);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 187.5% */
}
@media (max-width: 1100px) {
  #footer .bottom p {
    font-size: 18px;
  }
}
#footer .bottom .wrapper {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
@media (max-width: 1100px) {
  #footer .bottom .wrapper {
    flex-direction: column-reverse;
    gap: 28px;
  }
}
#footer .bottom .wrapper a svg {
  transition: all 0.2s ease-in-out;
}
#footer .bottom .wrapper a:hover svg path {
  fill-opacity: 1;
}

/*------------------------- Containers -----------------------*/
.termsfeed-com---palette-dark.termsfeed-com---nb {
  background: #005DB1;
  background-color: #005DB1;
}

.termsfeed-com---palette-light.termsfeed-com---nb {
  background-color: white;
}

.termsfeed-com---palette-light.termsfeed-com---nb {
  box-shadow: 0px 3px 10px #222222;
}

/*-------------------------TEXTS -----------------------*/
/*------------------------ BUTTONS -----------------------*/
.termsfeed-com---palette-dark .cc-nb-okagree {
  background: #222222;
  color: #fff;
  margin-right: 1rem !important;
  transition: background-color 1s;
}
.termsfeed-com---palette-dark .cc-nb-okagree:hover {
  background-color: #005DB1;
}

.termsfeed-com---palette-dark .cc-nb-reject {
  color: #fff;
  background: linear-gradient(141deg, #005DB1 31.89%, transparent) #005DB1 34%;
  margin-right: 1rem !important;
  transition: background-color 1s;
}
.termsfeed-com---palette-dark .cc-nb-reject:hover {
  background-color: #005DB1;
}

.termsfeed-com---palette-light .cc-nb-okagree {
  background: #005DB1;
  color: #fff;
  margin-right: 1rem !important;
  transition: background-color 1s;
}
.termsfeed-com---palette-light .cc-nb-okagree:hover {
  background-color: #005DB1;
}

.termsfeed-com---palette-light .cc-nb-reject {
  color: #fff;
  background: linear-gradient(141deg, #005DB1 31.89%, transparent) #005DB1 34%;
  margin-right: 1rem !important;
  transition: background-color 1s;
}
.termsfeed-com---palette-light .cc-nb-reject:hover {
  background-color: #005DB1;
}

.termsfeed-com---palette-dark .cc-nb-okagree,
.termsfeed-com---palette-light .cc-nb-okagree {
  background-color: #005DB1;
}

.termsfeed-com---palette-dark .cc-nb-title,
.termsfeed-com---palette-light .cc-nb-title {
  font-family: "Univers LT Std", sans-serif;
}

.termsfeed-com---nb-simple {
  left: inherit;
  right: 20vw;
  bottom: 2vh;
  border: 0.2px solid #ffffff;
  transition: 0.4s;
}
.termsfeed-com---nb-simple::after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: #005DB1;
  position: absolute;
  left: 0;
  bottom: 0;
}

.termsfeed-com---palette-light .cc-nb-okagree {
  border-radius: 0px !important;
  padding: 10px 20px !important;
}

.termsfeed-com---palette-light .cc-nb-reject {
  border-radius: 0px !important;
  padding: 10px 20px !important;
}

.termsfeed-com---palette-light .cc-nb-changep {
  border-radius: 0px !important;
  padding: 10px 20px !important;
}

#modal-zoom-images {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transform: scale(0);
  opacity: 0;
  transition: 0.4s;
  z-index: -100;
  display: flex;
  align-items: center;
  justify-content: center;
}
#modal-zoom-images.activate-modal {
  opacity: 1;
  transform: scale(1);
  z-index: 10000;
}
#modal-zoom-images #btn-close__zoom-modal {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 599px) {
  #modal-zoom-images #btn-close__zoom-modal {
    top: 10px;
    right: 10px;
  }
}
#modal-zoom-images #btn-close__zoom-modal i {
  font-size: 25px;
  color: rgb(226, 226, 226);
}
#modal-zoom-images #image__zoom-modal {
  width: 80vw;
  height: 80vh;
  object-fit: contain;
}
@media (max-width: 599px) {
  #modal-zoom-images #image__zoom-modal {
    width: 90vw;
  }
}

.images--zoom {
  cursor: pointer;
}

.show--error input {
  color: red !important;
  border: 1px solid red !important;
}
.show--error input::placeholder {
  color: red !important;
}
.show--error select {
  color: red !important;
  border: 1px solid red !important;
}
.show--error .error--message {
  display: block;
  color: red !important;
}
.show--error .checkmark {
  color: red !important;
  border: 1px solid red !important;
}
.show--error textarea {
  color: red !important;
  border: 1px solid red !important;
}

.error--message {
  display: none;
}

.error-message-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.error-message-forms.open-modal {
  display: flex;
}
.error-message-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  width: 450px;
  padding: 30px 50px;
  gap: 20px;
}
.error-message-forms--container #btn--close-error-message {
  position: absolute;
  top: 2vw;
  right: 2vw;
}
.error-message-forms--container #btn--close-error-message ion-icon {
  font-size: 3.4rem;
  color: 900;
}
.error-message-forms--container .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #ffffff;
  font-size: 4.5rem;
  color: white;
}
.error-message-forms--container .description {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
}
.error-message-forms--container .confirm {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  width: 216px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #005DB1;
  margin-top: 77px;
  transition: all 0.3s ease-out;
  position: relative;
  isolation: isolate;
}
.error-message-forms--container .confirm::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  z-index: -1;
}
.error-message-forms--container .confirm:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}
@media (max-width: 799px) {
  .error-message-forms--container .confirm {
    margin-top: 40px;
  }
}
@media (max-width: 799px) {
  .error-message-forms--container {
    width: 95%;
    padding-inline: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 30px;
  }
  .error-message-forms--container #btn--close-error-message img {
    width: 20px;
  }
  .error-message-forms--container .icon {
    width: 65px;
    height: 65px;
  }
  .error-message-forms--container .description {
    font-size: 16px;
    line-height: 20px;
  }
  .error-message-forms--container .confirm {
    margin-top: 0px;
  }
}

.success-message-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.success-message-forms.open-modal {
  display: flex;
}
.success-message-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  width: 500px;
  padding: 30px 50px;
  gap: 20px;
}
.success-message-forms--container #btn--close-success-message {
  position: absolute;
  top: 2vw;
  right: 2vw;
}
.success-message-forms--container #btn--close-success-message ion-icon {
  font-size: 3.4rem;
  color: 900;
}
.success-message-forms--container .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5vw;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #ffffff;
  font-size: 4.5rem;
  color: white;
}
.success-message-forms--container .description {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
}
.success-message-forms--container .confirm {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  width: 216px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #005DB1;
  margin-top: 77px;
  transition: all 0.3s ease-out;
  position: relative;
  isolation: isolate;
}
.success-message-forms--container .confirm::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  z-index: -1;
}
.success-message-forms--container .confirm:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}
@media (max-width: 799px) {
  .success-message-forms--container .confirm {
    margin-top: 40px;
  }
}
@media (max-width: 799px) {
  .success-message-forms--container {
    width: 95%;
    padding-inline: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 30px;
  }
  .success-message-forms--container #btn--close-success-message img {
    width: 20px;
  }
  .success-message-forms--container .icon {
    width: 65px;
    height: 65px;
  }
  .success-message-forms--container .description {
    font-size: 16px;
    line-height: 20px;
  }
  .success-message-forms--container .confirm {
    margin-top: 0px;
  }
}

.loading-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.loading-forms.open-modal {
  display: flex;
}
.loading-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  width: 30vw;
  height: 30vh;
  clip-path: polygon(0 8%, 72% 8%, 75% 0, 100% 0, 100% 100%, 19% 100%, 16% 92%, 0 92%);
}
.loading-forms .custom-loader {
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  background: conic-gradient(rgba(0, 0, 0, 0) 10%, 900);
  -webkit-mask: radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}
@media (max-width: 799px) {
  .loading-forms--container {
    width: 95%;
    padding: 0 !important;
    height: 200px;
  }
  .loading-forms .custom-loader {
    width: 40px;
    height: 40px;
  }
}

#page404 {
  padding: 108px 0 186px 71px;
  position: relative;
}
#page404 .page404 {
  min-width: min(100%, 996px);
  height: 691px;
  position: relative;
}
#page404 .page404 > img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#page404 .floating {
  position: absolute;
  inset: auto auto 0 0;
  min-width: min(100%, 996px);
  height: 691px;
  padding: 120px 120px 120px 300px;
  background-color: #fff;
  box-shadow: 25px 25px 30px 0px rgba(0, 0, 0, 0.2);
}
@media (width < 1300px) {
  #page404 .floating {
    padding: 120px;
    width: min(100%, 996px);
  }
}
#page404 .floating .title {
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
}
#page404 .floating .title strong {
  font-weight: 600;
}
#page404 .floating .icon-wrapper {
  margin-top: 33px;
  gap: 20px;
}
#page404 .floating .icon-wrapper .msg {
  color: #B12000;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 120% */
}
#page404 .floating .text {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-block: 33px 32px;
  max-width: 390px;
}
#page404 .floating .btn-wrapper .default-btn {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
  width: 216px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #005DB1;
  transition: all 0.3s ease-out;
  position: relative;
  isolation: isolate;
}
#page404 .floating .btn-wrapper .default-btn span {
  position: relative;
  z-index: 9;
}
#page404 .floating .btn-wrapper .default-btn::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  z-index: -1;
}
#page404 .floating .btn-wrapper .default-btn:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}
@media (max-width: 1100px) {
  #page404 .floating .btn-wrapper .default-btn {
    width: 100%;
  }
}
@media (width < 1100px) {
  #page404 {
    padding: 84px 0 0;
  }
  #page404 .page404 {
    min-width: 100%;
    height: 390px;
  }
  #page404 .f-wrapper {
    padding-inline: 25px;
    margin-bottom: 60px;
  }
  #page404 .floating {
    position: initial;
    margin-inline: auto;
    margin-top: -125px;
    padding: 40px 32px;
    width: min(340px, 100%) !important;
    height: 100%;
  }
  #page404 .floating .title {
    font-size: 35px;
    line-height: 40px;
    text-align: center;
  }
  #page404 .floating .icon-wrapper {
    gap: 12px;
    flex-direction: column;
    justify-content: center;
  }
  #page404 .floating .icon-wrapper .msg {
    font-size: 16px;
    line-height: 21px;
  }
  #page404 .floating .text {
    font-size: 16px;
    line-height: 32px;
    margin-block: 22px;
    text-align: center;
    max-width: 100%;
  }
}

* {
  font-family: "Source Sans Pro", sans-serif;
}

#home {
  padding-top: 108px;
}
@media (max-width: 1100px) {
  #home {
    padding-top: 84px;
  }
}
#home .intro-banner {
  position: relative;
}
#home .intro-banner .mySwiper .swiper-slide {
  width: 100%;
  height: calc(100dvh - 108px);
}
@media (max-width: 1100px) {
  #home .intro-banner .mySwiper .swiper-slide {
    height: auto;
    min-height: 592px;
  }
}
#home .intro-banner .mySwiper .bts-control {
  bottom: 100px;
}
@media (max-width: 1100px) {
  #home .intro-banner .mySwiper .bts-control {
    bottom: 100%;
  }
}
#home .intro-banner .mySwiper .swiper-button-next,
#home .intro-banner .mySwiper .swiper-rtl .swiper-button-prev {
  right: 3px;
  left: auto;
  top: 88.6%;
  width: 54px;
  height: 54px;
  background: linear-gradient(#005DB1 100%, #003C65 0%);
  transition: all 0.3s ease;
}
#home .intro-banner .mySwiper .swiper-button-next:hover,
#home .intro-banner .mySwiper .swiper-rtl .swiper-button-prev:hover {
  background: linear-gradient(#003C65 68%, #003C65 0%);
}
#home .intro-banner .mySwiper .swiper-button-next::after,
#home .intro-banner .mySwiper .swiper-rtl .swiper-button-prev::after {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
@media (max-width: 1100px) {
  #home .intro-banner .mySwiper .swiper-button-next,
#home .intro-banner .mySwiper .swiper-rtl .swiper-button-prev {
    right: 0;
    left: 65px;
    top: auto;
    width: 65px;
    height: 65px;
    bottom: 0;
  }
}
#home .intro-banner .mySwiper .swiper-button-prev,
#home .intro-banner .mySwiper .swiper-rtl .swiper-button-next {
  left: inherit;
  right: 57px;
  top: 88.6%;
  width: 54px;
  height: 54px;
  background: linear-gradient(#005DB1 100%, #003C65 0%);
  transition: all 0.3s ease;
}
#home .intro-banner .mySwiper .swiper-button-prev:hover,
#home .intro-banner .mySwiper .swiper-rtl .swiper-button-next:hover {
  background: linear-gradient(#003C65 68%, #003C65 0%);
}
#home .intro-banner .mySwiper .swiper-button-prev::after,
#home .intro-banner .mySwiper .swiper-rtl .swiper-button-next::after {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
@media (max-width: 1100px) {
  #home .intro-banner .mySwiper .swiper-button-prev,
#home .intro-banner .mySwiper .swiper-rtl .swiper-button-next {
    left: 0;
    right: auto;
    top: auto;
    width: 65px;
    height: 65px;
    bottom: 0;
  }
}
#home .intro-banner .wrapper-img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
#home .intro-banner .wrapper-img video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#home .intro-banner .wrapper-img.blue_filter::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(39deg, rgb(0, 60, 101) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  mix-blend-mode: multiply;
}
#home .intro-banner .wrapper-img.blue_filter::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(83deg, #003c65 38.12%, rgba(0, 60, 101, 0) 89.54%);
  mix-blend-mode: multiply;
}
#home .intro-banner .wrapper {
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  height: 100%;
  padding-top: 7.292vw;
}
@media (max-width: 1310px) {
  #home .intro-banner .wrapper {
    max-width: 1180px;
  }
}
@media (max-width: 1100px) {
  #home .intro-banner .wrapper {
    padding-inline: 25px;
    padding-top: 80px;
  }
}
#home .intro-banner .banner-img {
  width: 100%;
  height: 100%;
  object-position: bottom;
  object-fit: cover;
}
#home .intro-banner .content {
  max-width: 631px;
  display: flex;
  flex-direction: column;
}
#home .intro-banner .subtitle {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 8px;
}
@media (max-width: 1100px) {
  #home .intro-banner .subtitle {
    font-size: 15px;
  }
}
#home .intro-banner .title {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 9.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 9rem;
  margin-top: 1.615vw;
}
@media (max-width: 1100px) {
  #home .intro-banner .title {
    font-size: 50px;
    line-height: 48px;
    max-width: 300px;
  }
}
#home .intro-banner .text {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-top: 2.813vw;
}
@media (max-width: 1100px) {
  #home .intro-banner .text {
    font-size: 16px;
    line-height: 20px;
    max-width: 250px;
  }
}
#home .intro-banner .btn-wrapper {
  margin-top: 2.552vw;
}
#home .intro-banner .btn-wrapper .video-btn {
  display: none;
}
@media (max-height: 860px) {
  #home .intro-banner .btn-wrapper .video-btn {
    display: flex;
    position: initial;
  }
}
#home .intro-banner .btn-more {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 4.688vw;
  width: fit-content;
  position: relative;
}
#home .intro-banner .btn-more::after {
  content: "";
  position: absolute;
  bottom: 1.688vw;
  left: 0;
  width: 0%;
  height: 1px;
  background: #fff;
  transition: all 0.3s ease;
}
#home .intro-banner .btn-more:hover::after {
  width: 100%;
}
@media (max-width: 1100px) {
  #home .intro-banner .btn-more {
    display: none;
  }
}
#home .intro-banner .video-btn {
  position: absolute;
  bottom: 72px;
  left: 0;
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 1100px) {
  #home .intro-banner .video-btn {
    left: inherit;
    right: 25px;
    bottom: 22px;
  }
}
@media (max-height: 860px) {
  #home .intro-banner .video-btn {
    display: none;
  }
}
#home .intro-banner .blog-slider {
  width: 40vw;
  height: 180px;
  position: absolute;
  bottom: 0;
  right: 0;
}
@media (max-width: 1100px) {
  #home .intro-banner .blog-slider {
    display: none;
  }
}
@media (max-width: 1310px) {
  #home .intro-banner .blog-slider {
    width: 45vw;
    height: 150px;
  }
}
#home .intro-banner .blog-slider .mySwiper2 {
  width: 100%;
  height: 100%;
}
#home .intro-banner .blog-slider .mySwiper2 .swiper-button-prev,
#home .intro-banner .blog-slider .mySwiper2 .swiper-rtl .swiper-button-next {
  display: none;
}
#home .intro-banner .blog-slider .mySwiper2 .swiper-button-next,
#home .intro-banner .blog-slider .mySwiper2 .swiper-rtl .swiper-button-prev {
  right: 0;
  left: auto;
  height: 100%;
  top: 12%;
  width: 76px;
  background: linear-gradient(#003C65 68%, #003C65 0%);
}
#home .intro-banner .blog-slider .mySwiper2 .swiper-button-next::after,
#home .intro-banner .blog-slider .mySwiper2 .swiper-rtl .swiper-button-prev::after {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding-left: 5px;
}
#home .intro-banner .blog-slider .swiper-slide {
  display: flex;
  background: linear-gradient(106deg, rgba(255, 255, 255, 0.65) -71.2%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(7.5px);
}
#home .intro-banner .blog-slider img {
  width: 227px;
  height: 100%;
  object-fit: cover;
}
#home .intro-banner .blog-slider .text-content {
  padding-left: 64px;
  width: 50%;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media (max-width: 1450px) {
  #home .intro-banner .blog-slider .text-content {
    gap: 20px;
    padding-top: 30px;
    width: 40%;
    padding-left: 30px;
  }
}
#home .intro-banner .blog-slider .title-blog {
  color: #005db1;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 8px;
  text-transform: uppercase;
}
@media (max-width: 1310px) {
  #home .intro-banner .blog-slider .title-blog {
    font-size: 1.8rem;
    line-height: 2rem;
  }
}
#home .intro-banner .blog-slider .text-blog {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
}
@media (max-width: 1450px) {
  #home .intro-banner .blog-slider .text-blog {
    font-size: 18px;
    line-height: 18px;
  }
}
#home .atuation {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 72px;
}
@media (max-width: 1100px) {
  #home .atuation {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}
#home .atuation--card {
  height: 566px;
  width: 100%;
  position: relative;
  --opacity: 0;
}
@media (max-width: 1100px) {
  #home .atuation--card {
    height: 207px;
  }
  #home .atuation--card:nth-child(1) {
    grid-area: 1/1/2/2;
  }
  #home .atuation--card:nth-child(2) {
    grid-area: 1/2/2/3;
  }
  #home .atuation--card:nth-child(3) {
    grid-area: 2/1/3/3;
  }
}
#home .atuation--card .wrapper-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
#home .atuation--card .wrapper-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#home .atuation--card .wrapper-img::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(210deg, rgba(0, 93, 177, 0) 34.44%, rgba(0, 93, 177, 0.94) 63.22%);
  opacity: var(--opacity);
}
#home .atuation--card .wrapper-img::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(233deg, rgba(0, 60, 101, 0) 40.4%, rgba(0, 60, 101, 0.8) 64.49%);
  mix-blend-mode: multiply;
}
#home .atuation--card .content-text {
  position: relative;
  height: 100%;
  padding-left: 71px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 50px;
}
@media (max-width: 1100px) {
  #home .atuation--card .content-text {
    padding-left: 24px;
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
#home .atuation--card .title {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 112% */
}
@media (max-width: 1100px) {
  #home .atuation--card .title {
    line-height: 15px;
    font-size: 16px;
  }
}
#home .atuation--card .subtitle {
  font-weight: 600;
  line-height: 60px;
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 166.667% */
  letter-spacing: 0;
}
@media (max-width: 1100px) {
  #home .atuation--card .subtitle {
    font-size: 22px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0;
    margin-top: 8px;
  }
}
#home .atuation--card .icon {
  width: 44px;
  height: auto;
}
@media (max-width: 1100px) {
  #home .atuation--card .icon {
    width: 18px;
  }
}
#home .atuation--card .text {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
  width: 65%;
  opacity: 0;
  display: flex;
  height: 0;
}
@media (max-width: 1100px) {
  #home .atuation--card .text {
    display: none !important;
  }
}
#home .service {
  position: relative;
  margin-top: 222px;
  height: min(100vw, 683px);
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgb(255, 255, 255) 55%, rgb(0, 93, 177) 55%);
}
@media (max-width: 1100px) {
  #home .service {
    background: #fff;
    display: flex;
    flex-direction: column-reverse;
    margin-top: 131px;
  }
}
#home .service .text-big {
  position: absolute;
  top: -124px;
  right: 150px;
  text-align: right;
  color: transparent;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 150px;
  font-style: normal;
  font-weight: 600;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #8f8f8f;
  z-index: 9;
}
@media (max-width: 1100px) {
  #home .service .text-big {
    font-size: 18vw;
    right: inherit;
    top: -130px;
    -webkit-text-stroke-width: 0.7px;
    left: 10vw;
  }
}
#home .service::after {
  content: "";
  position: absolute;
  width: 50.7%;
  height: 569px;
  left: 0;
  top: 0;
  background: #fff;
}
@media (max-width: 1100px) {
  #home .service::after {
    display: none;
  }
}
#home .service .wrapper-img {
  height: min(100%, 50vw);
  position: absolute;
  top: 0;
  right: 0;
  width: 97%;
}
#home .service .wrapper-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 1100px) {
  #home .service .wrapper-img {
    height: 422px;
    position: relative;
    width: 100%;
  }
}
#home .service .container-text {
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  z-index: 9;
}
@media (max-width: 1100px) {
  #home .service .container-text {
    padding-inline: 25px;
    padding-bottom: 50px;
    display: block;
    margin: 0;
  }
}
#home .service .container-text .subtitle {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 8px;
  text-transform: uppercase;
  padding-top: 20px;
}
#home .service .container-text .title {
  color: #005db1;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
  margin-top: 28px;
}
#home .service .container-text .title span {
  font-weight: bold;
}
@media (max-width: 1100px) {
  #home .service .container-text .title {
    font-size: 35px;
    line-height: 35px;
    /* 100% */
  }
  #home .service .container-text .title br {
    display: none;
  }
}
#home .service .container-text .text {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
  max-width: 433px;
  margin-top: 42px;
}
@media (max-width: 1100px) {
  #home .service .container-text .text {
    font-size: 16px;
  }
}
#home .service .container-text .know-more {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
  width: 216px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #005DB1;
  margin-top: 77px;
  transition: all 0.3s ease-out;
  position: relative;
  isolation: isolate;
}
#home .service .container-text .know-more span {
  position: relative;
  z-index: 9;
}
#home .service .container-text .know-more::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  z-index: -1;
}
#home .service .container-text .know-more:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}
@media (max-width: 1100px) {
  #home .service .container-text .know-more {
    width: 100%;
  }
}
#home .clients {
  background: #005DB1;
  padding-top: 68px;
  padding-bottom: 174px;
}
@media (max-width: 1100px) {
  #home .clients {
    padding-bottom: 60px;
  }
}
#home .clients .btns-swiper-4 {
  display: none;
}
@media (max-width: 1100px) {
  #home .clients .btns-swiper-4 {
    display: flex;
    justify-content: center;
    margin-top: 55px;
  }
}
#home .clients .btns-swiper-4 .btn-next-4 {
  transform: rotate(180deg);
}
#home .clients .btns-swiper-4 .btn-prev-4 {
  transform: translate(1px, -0.2px);
}
#home .clients .btns-swiper-4 .btn-next-5 {
  transform: rotate(180deg);
}
#home .clients .btns-swiper-4 .btn-next-5 img {
  width: 55px;
  height: 65px;
  object-fit: cover;
}
#home .clients .btns-swiper-4 .btn-prev-5 {
  transform: translate(1px, -0.2px);
}
#home .clients .btns-swiper-4 .btn-prev-5 img {
  width: 55px;
  height: 65px;
  object-fit: cover;
}
#home .clients .swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
#home .clients .swiper-slide {
  text-align: center;
  font-size: 18px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
#home .clients .swiper-slide img {
  width: 100%;
}
#home .clients .wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  align-items: center;
}
@media (max-width: 1100px) {
  #home .clients .wrapper {
    padding-inline: 26px;
  }
}
#home .clients .content-text {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}
#home .clients .subtitle {
  color: #f0f0f0;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 8px;
  text-transform: uppercase;
}
@media (max-width: 1100px) {
  #home .clients .subtitle {
    font-size: 15px;
  }
}
#home .clients .title {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 60px;
  /* 120% */
}
#home .clients .title span {
  font-weight: 600;
}
@media (max-width: 1100px) {
  #home .clients .title {
    font-size: 35px;
  }
}
#home .clients .grid-clients {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  row-gap: 50px;
  column-gap: 70px;
  margin-top: 87px;
}
#home .clients .grid-clients .logo {
  max-width: 99px;
  max-height: 65px;
  object-fit: contain;
}
#home .clients .grid-clients .logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
#home .clients .grid-clients img {
  width: 100%;
  height: auto;
}
@media (max-width: 1100px) {
  #home .clients .grid-clients {
    grid-template-columns: repeat(3, 1fr);
    -moz-column-gap: 97px;
    column-gap: 47px;
    row-gap: 50px;
    width: 100%;
    display: none;
  }
  #home .clients .grid-clients .logo {
    max-width: 91px;
  }
  #home .clients .grid-clients .logo img {
    width: 100%;
  }
}
#home .partner {
  background: #f0f0f0;
}
#home .partner .subtitle {
  color: #575756 !important;
}
#home .partner .title {
  color: #005DB1 !important;
}

.produtos-slider {
  background: #fff;
  padding-top: 62px;
}
.produtos-slider .content-text {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.produtos-slider .content-text .subtitle {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 8px;
}
@media (max-width: 1100px) {
  .produtos-slider .content-text .subtitle {
    font-size: 15px;
  }
}
.produtos-slider .content-text .title {
  color: #005db1;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 60px;
}
.produtos-slider .content-text .title span {
  font-weight: bold;
}
@media (max-width: 1100px) {
  .produtos-slider .content-text .title {
    font-size: 35px;
    line-height: 35px;
  }
}
.produtos-slider .btn-nextOut, .produtos-slider .btn-prevOut {
  width: 53px;
  height: 53px;
  position: absolute;
  display: block;
  transition: all 0.3s ease-out;
}
.produtos-slider .btn-nextOut .off, .produtos-slider .btn-prevOut .off {
  display: none;
}
.produtos-slider .btn-nextOut:hover .on, .produtos-slider .btn-prevOut:hover .on {
  display: none;
}
.produtos-slider .btn-nextOut:hover .off, .produtos-slider .btn-prevOut:hover .off {
  display: block;
}
@media (max-width: 1100px) {
  .produtos-slider .btn-nextOut, .produtos-slider .btn-prevOut {
    display: none;
  }
}
.produtos-slider .btn-nextOut img, .produtos-slider .btn-prevOut img {
  width: 100%;
  height: 100%;
}
.produtos-slider .btn-nextOut {
  transform: rotate(-180deg);
  right: 50px;
  top: 32vh;
}
.produtos-slider .btn-prevOut {
  left: 50px;
  top: 32vh;
}
.produtos-slider .wrapper-slider {
  width: 87vw;
  padding-inline: 10vw;
  margin: 0 auto;
  padding-top: 88px;
  position: relative;
}
@media (max-width: 1100px) {
  .produtos-slider .wrapper-slider {
    width: 100%;
    padding-inline: 0;
    padding-top: 30px;
  }
}
.produtos-slider .mySwiper3 .swiper-button-next,
.produtos-slider .mySwiper3 .swiper-rtl .swiper-button-prev {
  left: auto;
  width: 54px;
  height: 54px;
  background: #005DB1;
}
.produtos-slider .mySwiper3 .swiper-button-next::after,
.produtos-slider .mySwiper3 .swiper-rtl .swiper-button-prev::after {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
.produtos-slider .mySwiper3 .swiper-button-next:hover,
.produtos-slider .mySwiper3 .swiper-rtl .swiper-button-prev:hover {
  background: linear-gradient(45deg, #003c65 0%, #003c65 68%);
}
@media (max-width: 1100px) {
  .produtos-slider .mySwiper3 .swiper-button-next,
.produtos-slider .mySwiper3 .swiper-rtl .swiper-button-prev {
    right: 0;
    height: 65px;
  }
}
.produtos-slider .mySwiper3 .swiper-button-prev,
.produtos-slider .mySwiper3 .swiper-rtl .swiper-button-next {
  right: auto;
  width: 54px;
  height: 54px;
  background: #005DB1;
}
.produtos-slider .mySwiper3 .swiper-button-prev::after,
.produtos-slider .mySwiper3 .swiper-rtl .swiper-button-next::after {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
.produtos-slider .mySwiper3 .swiper-button-prev:hover,
.produtos-slider .mySwiper3 .swiper-rtl .swiper-button-next:hover {
  background: linear-gradient(45deg, #003c65 0%, #003c65 68%);
}
@media (max-width: 1100px) {
  .produtos-slider .mySwiper3 .swiper-button-prev,
.produtos-slider .mySwiper3 .swiper-rtl .swiper-button-next {
    left: 0;
    height: 65px;
  }
}
.produtos-slider .mySwiper3 .card-product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
}
@media (max-width: 1100px) {
  .produtos-slider .mySwiper3 .card-product {
    max-width: 338px;
    margin: 0 auto;
    background: #f3f3f3;
  }
}
.produtos-slider .mySwiper3 .card-product:hover .wrapper-img img {
  transform: scale(1.1);
}
.produtos-slider .mySwiper3 .card-product .wrapper-img {
  width: 100%;
  height: 227px;
  overflow: hidden;
  flex-shrink: 0;
}
.produtos-slider .mySwiper3 .card-product .wrapper-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.produtos-slider .mySwiper3 .card-product img {
  object-fit: cover;
  transition: all 0.3s ease-out;
  height: 100%;
  width: 100%;
}
.produtos-slider .mySwiper3 .card-product .text-content {
  padding-left: 30px;
  padding-right: 27px;
  margin-block: auto;
}
@media (max-width: 1100px) {
  .produtos-slider .mySwiper3 .card-product .text-content {
    padding-left: 49px;
    padding-right: 100px;
  }
}
.produtos-slider .mySwiper3 .card-product .subtitle {
  color: #005db1;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 4.8px;
  line-height: 60px;
}
@media (max-width: 1100px) {
  .produtos-slider .mySwiper3 .card-product .subtitle {
    font-size: 15px;
  }
}
.produtos-slider .mySwiper3 .card-product .title {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.produtos-slider .mySwiper3 .card-product p {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
@media (max-width: 1100px) {
  .produtos-slider .mySwiper3 .card-product p {
    font-size: 16px;
  }
}
.produtos-slider .mySwiper3 .card-product .detail {
  width: 100%;
  background: #005DB1;
  color: #fff;
  padding-left: 30px;
  padding-right: 33px;
  display: flex;
  justify-content: space-between;
  height: 64px;
  align-items: center;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  overflow: hidden;
  position: relative;
  isolation: isolate;
}
.produtos-slider .mySwiper3 .card-product .detail span {
  position: relative;
  z-index: 9;
}
.produtos-slider .mySwiper3 .card-product .detail::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  z-index: -1;
}
@media (max-width: 1100px) {
  .produtos-slider .mySwiper3 .card-product .detail {
    font-size: 18px;
  }
}
.produtos-slider .mySwiper3 .card-product .detail img {
  height: 16px;
  width: 23px;
  z-index: 9;
  position: relative;
}
.produtos-slider .mySwiper3 .card-product .detail:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}

#novidades .news {
  background: #fff;
  min-height: 95dvh;
  padding-top: 150px;
  position: relative;
}
#novidades .news::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 20dvh;
  background: linear-gradient(90deg, #005db1 -11.68%, #0c3e62 68.14%);
  bottom: 0;
  left: 0;
}
@media (max-width: 1100px) {
  #novidades .news::after {
    display: none;
  }
}
#novidades .news .title-big {
  font-size: 150px;
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #8f8f8f;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 220px;
  position: absolute;
  top: -112px;
  left: 30px;
  z-index: 9;
}
@media (max-width: 1100px) {
  #novidades .news .title-big {
    font-size: 22vw;
    line-height: 80px;
    left: 0;
    top: 40px;
    -webkit-text-stroke-width: 1px;
  }
}
#novidades .news .container-news {
  max-width: 85vw;
  margin: 0 auto;
  position: relative;
  z-index: 9;
}
@media (max-width: 1100px) {
  #novidades .news .container-news {
    max-width: 100%;
  }
}
#novidades .news .container-news .parent {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 83.5dvh;
}
@media (max-width: 1100px) {
  #novidades .news .container-news .parent {
    height: auto;
    display: flex;
    flex-direction: column;
  }
  #novidades .news .container-news .parent .div1,
#novidades .news .container-news .parent .div2,
#novidades .news .container-news .parent .div3,
#novidades .news .container-news .parent .div4,
#novidades .news .container-news .parent .div5 {
    aspect-ratio: 1;
  }
}
#novidades .news .container-news .div1 {
  grid-area: 1/1/5/5;
  position: relative;
}
#novidades .news .container-news .div2 {
  grid-area: 1/5/3/7;
  position: relative;
}
#novidades .news .container-news .div2 .content-text {
  width: 70%;
}
#novidades .news .container-news .div3 {
  grid-area: 1/7/3/9;
  position: relative;
}
#novidades .news .container-news .div3 .content-text {
  width: 70%;
}
#novidades .news .container-news .div4 {
  grid-area: 3/5/5/7;
  position: relative;
}
#novidades .news .container-news .div4 .content-text {
  width: 70%;
}
#novidades .news .container-news .div5 {
  grid-area: 3/7/5/9;
  position: relative;
}
#novidades .news .container-news .div5 .content-text {
  width: 70%;
}
#novidades .news .container-news .div1,
#novidades .news .container-news .div2,
#novidades .news .container-news .div3,
#novidades .news .container-news .div4,
#novidades .news .container-news .div5 {
  position: relative;
}
#novidades .news .container-news .div1::after,
#novidades .news .container-news .div2::after,
#novidades .news .container-news .div3::after,
#novidades .news .container-news .div4::after,
#novidades .news .container-news .div5::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(210deg, rgba(0, 93, 177, 0) 40.33%, rgba(0, 93, 177, 0.8) 65.7%);
  mix-blend-mode: multiply;
  transition: all 0.3s ease-out;
  will-change: opacity;
  opacity: 0;
}
#novidades .news .container-news .div1:hover::after,
#novidades .news .container-news .div2:hover::after,
#novidades .news .container-news .div3:hover::after,
#novidades .news .container-news .div4:hover::after,
#novidades .news .container-news .div5:hover::after {
  opacity: 1;
}
#novidades .news .wrapper-img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}
#novidades .news .wrapper-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#novidades .news .wrapper-img::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(210deg, rgba(1, 20, 37, 0) 40.33%, rgba(1, 20, 37, 0.8) 65.7%);
  mix-blend-mode: multiply;
}
#novidades .news .content-text {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 25px;
  padding-bottom: 30px;
  width: 75%;
  gap: 18px;
  position: relative;
  z-index: 9;
}
#novidades .news .subtitle {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 8px;
  text-transform: uppercase;
}
#novidades .news .title {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 100% */
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
#novidades .news .text {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
#novidades .news .tag {
  position: absolute;
  top: 0;
  left: 0;
  height: 51px;
  padding-inline: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #005DB1;
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 4.8px;
  text-transform: uppercase;
}
#novidades .news .read-more {
  color: var(--san-martin-white, #fff);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
  display: flex;
  gap: 32px;
  align-items: center;
  height: 0px;
  opacity: 0;
}
#novidades .news .date {
  position: absolute;
  right: 26px;
  bottom: 24px;
  color: #fff;
  /* H6 - textBody */
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
  z-index: 99;
  opacity: 0;
}
#novidades .news .all-cards .wrapper-img img {
  transition: all 0.3s ease-out;
  will-change: transform;
}
#novidades .news .all-cards:hover .wrapper-img img {
  transform: scale(1.1);
}
@media (min-width: 1250px) {
  #novidades .news .all-cards:not(:first-child) .read-more {
    line-height: 3.125vw;
    font-size: 1.042vw;
  }
  #novidades .news .all-cards:not(:first-child) .tag {
    height: 2.656vw;
    font-size: 0.833vw;
  }
  #novidades .news .all-cards:not(:first-child) .text {
    font-size: 0.938vw;
    line-height: 1.302vw;
  }
  #novidades .news .all-cards:not(:first-child) .title {
    font-size: 1.563vw;
    line-height: 1.563vw;
  }
  #novidades .news .all-cards:not(:first-child) .subtitle {
    font-size: 0.833vw;
  }
  #novidades .news .all-cards:not(:first-child) .content-text {
    padding-bottom: 1.563vw;
    gap: 0.938vw;
  }
}

#product .nav-pages {
  background: #F3F3F3;
  padding: 25px 0;
}
@media (max-width: 1100px) {
  #product .nav-pages {
    padding-left: 25px;
  }
}
#product .nav-pages .wrapper {
  max-width: 1280px;
  margin: 0 auto;
}
#product .nav-pages ul {
  display: flex;
  align-items: center;
  gap: 21px;
}
@media (max-width: 1100px) {
  #product .nav-pages ul {
    gap: 10px;
  }
}
#product .nav-pages ul li {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 21px;
}
@media (max-width: 1100px) {
  #product .nav-pages ul li {
    gap: 16px;
    font-size: 16px;
  }
}
#product .nav-pages ul .active {
  color: #005DB1;
}
#product .nav-pages ul .active svg {
  fill: #005DB1;
}
@media (max-width: 1100px) {
  #product .nav-pages ul .active svg {
    height: 15px;
  }
}
#product .nav-pages ul .active svg path {
  fill: #005DB1;
}
#product .produto-info {
  background: #fff;
  padding-top: 64px;
  padding-bottom: 5.208vh;
}
@media (max-width: 1100px) {
  #product .produto-info {
    padding-bottom: 100px;
  }
}
#product .produto-info .wrapper {
  max-width: 1280px;
  margin: 0 auto;
}
@media (max-width: 1100px) {
  #product .produto-info .wrapper {
    padding-inline: 25px;
  }
}
#product .produto-info .content-info {
  display: flex;
  gap: 89px;
}
@media (max-width: 1100px) {
  #product .produto-info .content-info {
    flex-direction: column;
    gap: 22px;
  }
}
#product .produto-info .info-text {
  max-width: 672px;
}
#product .produto-info .title {
  color: #005DB1;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 60px;
  margin-bottom: 38px;
  margin-top: 30px;
}
#product .produto-info .title span {
  font-weight: bold;
}
@media (max-width: 1100px) {
  #product .produto-info .title {
    font-size: 35px;
    line-height: 40px;
    margin-top: 11px;
  }
}
#product .produto-info .subtitle {
  color: #575756;
}
#product .produto-info .text {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
}
@media (max-width: 1100px) {
  #product .produto-info .text {
    font-size: 16px;
    font-weight: 400;
  }
}
#product .produto-info .info-list {
  padding-top: 150px;
}
@media (max-width: 1100px) {
  #product .produto-info .info-list {
    padding-top: 0;
  }
}
#product .produto-info .info-list ul {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
#product .produto-info .info-list ul li {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  position: relative;
  display: flex;
  align-items: center;
  gap: 11px;
}
#product .produto-info .info-list ul li::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #005DB1;
  flex-shrink: 0;
}
@media (max-width: 1100px) {
  #product .produto-info .info-list ul li::before {
    width: 11px;
    height: 11px;
    position: absolute;
    left: 0px;
  }
}
@media (max-width: 1100px) {
  #product .produto-info .info-list ul li {
    font-size: 16px;
    line-height: 25px;
    padding-left: 22px;
  }
}
#product .gallery {
  position: relative;
  padding-top: 10.677vh;
}
#product .gallery .text-big {
  font-size: 220px;
  color: transparent;
  -webkit-text-stroke: 1px #005DB1;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 220px;
  position: absolute;
  top: -62px;
  right: 292px;
}
@media (max-width: 1100px) {
  #product .gallery .text-big {
    font-size: 90px;
    right: 25px;
    line-height: 90px;
    top: -65px;
  }
}
#product .gallery .parent {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  position: relative;
  z-index: 9;
  height: 779px;
}
#product .gallery .parent img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 1100px) {
  #product .gallery .parent {
    height: auto;
    display: flex;
    flex-wrap: wrap;
  }
}
#product .gallery .icon-plus {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 104px;
  height: 104px;
  z-index: 99;
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  width: 100px !important;
  height: 100px !important;
}
@media (max-width: 1100px) {
  #product .gallery .icon-plus {
    width: 50px !important;
    height: 50px !important;
  }
}
#product .gallery .wrapper-effect {
  height: 100%;
  position: relative;
  overflow: hidden;
}
#product .gallery .wrapper-effect img {
  transition: all 0.3s ease-in-out;
}
#product .gallery .wrapper-effect::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(46deg, #003C65 -2.61%, rgba(0, 60, 101, 0) 90.22%);
  mix-blend-mode: multiply;
}
#product .gallery .div1, #product .gallery .div2, #product .gallery .div3, #product .gallery .div4 {
  position: relative;
}
#product .gallery .div1:hover .icon-plus, #product .gallery .div2:hover .icon-plus, #product .gallery .div3:hover .icon-plus, #product .gallery .div4:hover .icon-plus {
  opacity: 1;
}
@media (max-width: 1100px) {
  #product .gallery .div1:hover .icon-plus, #product .gallery .div2:hover .icon-plus, #product .gallery .div3:hover .icon-plus, #product .gallery .div4:hover .icon-plus {
    width: 50px;
    height: 50px;
  }
}
#product .gallery .div1:hover .wrapper-effect img, #product .gallery .div2:hover .wrapper-effect img, #product .gallery .div3:hover .wrapper-effect img, #product .gallery .div4:hover .wrapper-effect img {
  transform: scale(1.1);
}
#product .gallery .div1 {
  grid-area: 1/1/7/4;
  position: relative;
}
@media (max-width: 1100px) {
  #product .gallery .div1 {
    width: 100%;
    height: 305px;
  }
}
#product .gallery .div2 {
  grid-area: 1/4/3/6;
}
@media (max-width: 1100px) {
  #product .gallery .div2 {
    width: 33.33%;
    height: 109px;
  }
}
#product .gallery .div3 {
  grid-area: 3/4/5/6;
}
@media (max-width: 1100px) {
  #product .gallery .div3 {
    width: 33.33%;
    height: 109px;
  }
}
#product .gallery .div4 {
  grid-area: 1/6/5/9;
}
@media (max-width: 1100px) {
  #product .gallery .div4 {
    width: 33.33%;
    height: 109px;
  }
}
#product .video-destaque {
  padding: 50px 0;
  position: relative;
}
@media (max-width: 1100px) {
  #product .video-destaque {
    padding: 33px 0;
  }
}
#product .video-destaque .wrapper {
  max-width: 1280px;
  margin: 0 auto;
}
#product .video-destaque .video-play {
  width: 100%;
  height: 660px;
  position: relative;
}
@media (max-width: 1100px) {
  #product .video-destaque .video-play {
    width: calc(100% - 50px);
    height: 202px;
    position: relative;
    margin: 0 auto;
  }
}
#product .video-destaque .video-play::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(46deg, #003C65 -2.61%, rgba(0, 60, 101, 0) 90.22%);
  mix-blend-mode: multiply;
}
#product .video-destaque .video-play .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75px;
  height: 75px;
  z-index: 10;
}
@media (max-width: 1100px) {
  #product .video-destaque .video-play .play-icon {
    width: 50px;
    height: 50px;
  }
}
#product .video-destaque .video-play .img-hold {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#product .img-and-text {
  background: #fff;
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (max-width: 1100px) {
  #product .img-and-text {
    padding-top: 27px;
    padding-bottom: 43px;
  }
}
#product .img-and-text .content-text {
  max-width: 541px;
}
@media (max-width: 1100px) {
  #product .img-and-text .content-text {
    max-width: 100%;
    padding-inline: 25px;
  }
}
#product .img-and-text .wrapper {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 799px) {
  #product .img-and-text .wrapper {
    flex-direction: column;
  }
}
#product .img-and-text .subtitle {
  color: #575756;
  margin-bottom: 20px;
}
#product .img-and-text .title {
  color: #005DB1;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
  /* 100% */
  margin-bottom: 42px;
}
#product .img-and-text .title span {
  font-weight: bold;
}
@media (max-width: 1100px) {
  #product .img-and-text .title {
    font-size: 35px;
    line-height: 35px;
    margin-top: 14px;
    margin-bottom: 42px;
  }
}
#product .img-and-text .text {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
@media (max-width: 1100px) {
  #product .img-and-text .text {
    color: #575756;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
  }
}
#product .img-and-text .img-holder {
  height: 516px;
  aspect-ratio: 1.44;
  transform: translate(107px, 0px);
  overflow: hidden;
}
@media (max-width: 1550px) {
  #product .img-and-text .img-holder {
    transform: translate(0, 0);
    height: 490px;
    aspect-ratio: 1.24;
  }
}
@media (max-width: 1100px) {
  #product .img-and-text .img-holder {
    transform: translate(0, 0);
    width: 100%;
    height: 285px;
    margin-top: 43px;
  }
}
#product .img-and-text .img-holder:hover img {
  transform: scale(1.1);
}
#product .img-and-text .img-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}
#product .reverse .wrapper {
  flex-direction: row-reverse;
}
#product .reverse .img-holder {
  transform: translate(-107px, 0px);
}
@media (max-width: 1550px) {
  #product .reverse .img-holder {
    transform: translate(0, 0);
  }
}
@media (max-width: 1100px) {
  #product .reverse .img-holder {
    display: none;
  }
}
#product .download-block {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  background: #F0F0F0;
  justify-content: space-between;
  padding-left: 104px;
  padding-right: 52px;
  padding-bottom: 76px;
  padding-top: 56px;
  position: relative;
  margin-bottom: 50px;
  margin-top: 50px;
}
@media (max-width: 1100px) {
  #product .download-block {
    top: 0;
    margin-top: 0;
    padding: 0;
    padding-inline: 25px;
    text-align: center;
    flex-direction: column;
    padding-bottom: 73px;
    margin-bottom: 0;
  }
}
#product .download-block .content-text {
  padding-top: 45px;
  max-width: 320px;
}
@media (max-width: 1100px) {
  #product .download-block .content-text {
    max-width: 100%;
  }
}
#product .download-block .content-text .subtitle {
  color: #575756;
}
#product .download-block .content-text .title {
  color: #005DB1;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
  /* 100% */
  margin-bottom: 33px;
  margin-top: 20px;
}
#product .download-block .content-text .title span {
  font-weight: bold;
}
@media (max-width: 1100px) {
  #product .download-block .content-text .title {
    text-align: center;
    font-size: 35px;
    line-height: 35px;
  }
}
#product .download-block .content-text .text {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 111.111% */
}
@media (max-width: 1100px) {
  #product .download-block .content-text .text {
    display: none;
  }
}
#product .download-block .swiper {
  padding-left: 100px;
  padding-right: 30px;
}
@media (max-width: 1100px) {
  #product .download-block .swiper {
    display: none;
  }
}
#product .download-block .swiper .swiper-button-next, #product .download-block .swiper .swiper-rtl .swiper-button-prev {
  left: auto;
  width: 54px;
  height: 54px;
  background: #005DB1;
}
#product .download-block .swiper .swiper-button-next::after, #product .download-block .swiper .swiper-rtl .swiper-button-prev::after {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
@media (max-width: 1100px) {
  #product .download-block .swiper .swiper-button-next, #product .download-block .swiper .swiper-rtl .swiper-button-prev {
    right: 0;
    height: 65px;
  }
}
#product .download-block .swiper .swiper-button-prev, #product .download-block .swiper .swiper-rtl .swiper-button-next {
  right: auto;
  width: 54px;
  height: 54px;
  background: #005DB1;
}
#product .download-block .swiper .swiper-button-prev::after, #product .download-block .swiper .swiper-rtl .swiper-button-next::after {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
@media (max-width: 1100px) {
  #product .download-block .swiper .swiper-button-prev, #product .download-block .swiper .swiper-rtl .swiper-button-next {
    left: 0;
    height: 65px;
  }
}
#product .download-block .wrapper-slider {
  width: 742px;
}
@media (max-width: 1100px) {
  #product .download-block .wrapper-slider {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
}
#product .download-block .swiper-slide {
  width: 272px;
}
#product .download-block .card-download {
  display: flex;
  flex-direction: column;
  padding: 40px 14px;
  background: #fff;
  align-items: center;
  gap: 22px;
  max-width: 272px;
}
@media (max-width: 1100px) {
  #product .download-block .card-download {
    margin: 0 auto;
  }
  #product .download-block .card-download:nth-child(1) {
    margin-top: 20px;
  }
}
#product .download-block .card-download .icon-wrapper {
  width: 116px;
  height: 116px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #005DB1;
}
#product .download-block .card-download .card-title {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
#product .download-block .card-download .card-text {
  color: #575756;
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
@media (max-width: 1100px) {
  #product .download-block .card-download .card-text {
    font-size: 16px;
  }
}
#product .detail-product {
  height: 912px;
  background: linear-gradient(138deg, #005DB1 -11.68%, #0C3E62 68.14%);
}
@media (max-width: 1100px) {
  #product .detail-product {
    height: auto;
    padding-bottom: 60px;
  }
}
#product .detail-product .swiper-button-next, #product .detail-product .swiper-rtl .swiper-button-prev {
  left: auto;
  width: 54px;
  height: 54px;
  background: #005DB1;
}
#product .detail-product .swiper-button-next::after, #product .detail-product .swiper-rtl .swiper-button-prev::after {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
@media (max-width: 1100px) {
  #product .detail-product .swiper-button-next, #product .detail-product .swiper-rtl .swiper-button-prev {
    right: 32.7%;
    height: 65px;
    bottom: 40px;
    top: auto;
    width: 65px;
  }
}
#product .detail-product .swiper-button-prev, #product .detail-product .swiper-rtl .swiper-button-next {
  right: auto;
  width: 54px;
  height: 54px;
  background: #005DB1;
}
#product .detail-product .swiper-button-prev::after, #product .detail-product .swiper-rtl .swiper-button-next::after {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
@media (max-width: 1100px) {
  #product .detail-product .swiper-button-prev, #product .detail-product .swiper-rtl .swiper-button-next {
    width: 65px;
    left: 32.65%;
    height: 65px;
    top: auto;
    bottom: 40px;
  }
}
#product .detail-product .mySwiper5 {
  display: none;
}
@media (max-width: 1100px) {
  #product .detail-product .mySwiper5 {
    display: block;
    height: auto;
  }
}
#product .detail-product .btns-swiper-5 {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  margin-top: 60px;
  display: none;
}
@media (max-width: 1100px) {
  #product .detail-product .btns-swiper-5 {
    display: flex;
  }
}
#product .detail-product .btns-swiper-5 .btn-prev-5 img {
  width: 55px;
  height: 65px;
}
#product .detail-product .btns-swiper-5 .btn-next-5 {
  transform: rotate(180deg);
}
#product .detail-product .btns-swiper-5 .btn-next-5 img {
  width: 55px;
  height: 65px;
}
#product .detail-product .wrapper {
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
}
#product .detail-product .subtitle {
  padding-top: 16vh;
  text-align: center;
}
@media (max-width: 1500px) {
  #product .detail-product .subtitle {
    padding-top: 100px;
  }
}
@media (max-width: 1100px) {
  #product .detail-product .subtitle {
    padding-top: 65px;
  }
}
#product .detail-product .title {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 60px;
  /* 120% */
  text-align: center;
  margin-top: 30px;
  margin-bottom: 81px;
}
#product .detail-product .title span {
  font-weight: bold;
}
@media (max-width: 1100px) {
  #product .detail-product .title {
    text-align: center;
    font-size: 35px;
    font-weight: 300;
    line-height: 35px;
    /* 100% */
  }
}
#product .detail-product .card-detail {
  padding-inline: 25.2px;
  padding-top: 45px;
  padding-bottom: 42px;
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 301px;
  height: 368px;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
#product .detail-product .card-detail:hover {
  transform: translateY(-15px);
}
@media (max-width: 1100px) {
  #product .detail-product .card-detail {
    margin: 0 auto;
  }
}
#product .detail-product img {
  width: 104px;
  height: 104px;
}
#product .detail-product .text {
  color: #575756;
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-top: 70px;
  position: relative;
}
#product .detail-product .text::after {
  content: "";
  position: absolute;
  width: 60px;
  height: 2px;
  background: #005DB1;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
}
#product .detail-product .cards-detail {
  display: flex;
  gap: 25px;
  justify-content: center;
}
@media (max-width: 1100px) {
  #product .detail-product .cards-detail {
    display: none;
  }
}
#product .form-info {
  background: #F0F0F0;
  display: flex;
  width: calc(100% - 197px);
  justify-content: space-between;
  padding-right: 123px;
  align-items: center;
  position: relative;
  box-shadow: 10px 10px 24px 0px rgba(0, 0, 0, 0.15);
}
@media (max-width: 1750px) {
  #product .form-info {
    width: 100%;
    padding-right: 0;
  }
}
#product .form-info::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 285px;
  background: #F0F0F0;
  top: 0;
  left: 0;
  z-index: -1;
  background: linear-gradient(138deg, #005DB1 -11.68%, #0C3E62 68.14%);
  width: calc(100% + 197px);
}
@media (max-width: 1750px) {
  #product .form-info::after {
    width: 100%;
  }
}
@media (max-width: 1100px) {
  #product .form-info::after {
    display: none;
  }
}
@media (max-width: 1100px) {
  #product .form-info {
    flex-direction: column;
    top: 0;
    margin-bottom: 0;
    padding-right: 0;
    width: 100%;
    padding-top: 34px;
  }
}
@media (max-width: 1500px) {
  #product .form-info .form-img {
    width: 35vw;
  }
}
@media (max-width: 1100px) {
  #product .form-info .form-img {
    height: 417px;
    order: 3;
    width: 100%;
    object-fit: cover;
  }
}
#product .form-info .content-text {
  max-width: 254px;
  margin-right: 63px;
  transform: translate(-65px, 0px);
}
@media (max-width: 1100px) {
  #product .form-info .content-text {
    margin-right: 0;
    transform: translate(0, 0);
    max-width: 100%;
  }
}
#product .form-info .content-text .subtitle {
  color: #575756;
}
@media (max-width: 1100px) {
  #product .form-info .content-text .subtitle {
    text-align: center;
  }
}
#product .form-info .content-text .title {
  color: #005DB1;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 60px;
  /* 120% */
}
#product .form-info .content-text .title span {
  font-weight: bold;
}
@media (max-width: 1100px) {
  #product .form-info .content-text .title {
    text-align: center;
    font-size: 35px;
    line-height: 35px;
    /* 100% */
    margin-bottom: 43px;
    margin-top: 20px;
  }
  #product .form-info .content-text .title br {
    display: none;
  }
}
#product .form-info form {
  width: 688px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  grid-template-rows: 62px 62px 62px 123px;
  transform: translate(-65px, 0px);
}
@media (max-width: 1100px) {
  #product .form-info form {
    transform: translate(0, 0);
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 62px 62px 62px 62px 123px;
    padding-inline: 25px;
  }
}
#product .form-info form .form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='gray' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right 24px center;
  background-repeat: no-repeat;
  background-size: 24px;
  width: 100%;
  height: 100%;
  padding-inline: 27px;
  border: 2px solid transparent;
  border-radius: 0;
}
#product .form-info form .form-select, #product .form-info form .form-select::placeholder {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
}
#product .form-info form input {
  width: 100%;
  height: 62px;
  background: #FFFFFF;
  border: none;
  padding-left: 27px;
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
#product .form-info form input::placeholder {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
#product .form-info form textarea {
  min-height: 100%;
  width: 100%;
  border: none;
  column-span: all;
  padding-left: 27px;
  padding-top: 27px;
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  resize: none;
}
#product .form-info form textarea::placeholder {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
#product .form-info form .input-group:nth-child(5), #product .form-info form .input-group:nth-child(6) {
  grid-column: span 2;
}
@media (max-width: 1100px) {
  #product .form-info form .input-group {
    grid-column: 1/3;
  }
}
#product .form-info form button {
  width: 100%;
  height: 62px;
  background: #005DB1;
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#product .produtos-slider {
  background: #fff;
  padding-top: 62px;
  padding-bottom: 163px;
}
@media (max-width: 1100px) {
  #product .produtos-slider {
    padding-bottom: 60px;
  }
}
#product .produtos-slider .content-text {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
#product .produtos-slider .content-text .subtitle {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 8px;
}
@media (max-width: 1100px) {
  #product .produtos-slider .content-text .subtitle {
    font-size: 15px;
  }
}
#product .produtos-slider .content-text .title {
  color: #005DB1;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 60px;
}
#product .produtos-slider .content-text .title span {
  font-weight: bold;
}
@media (max-width: 1100px) {
  #product .produtos-slider .content-text .title {
    font-size: 35px;
    line-height: 35px;
  }
}
#product .produtos-slider .wrapper-slider {
  width: 87vw;
  padding-inline: 10vw;
  margin: 0 auto;
  padding-top: 88px;
}
@media (max-width: 1500px) {
  #product .produtos-slider .wrapper-slider {
    padding-inline: 77px;
  }
}
@media (max-width: 1100px) {
  #product .produtos-slider .wrapper-slider {
    width: 100%;
    padding-inline: 0;
    padding-top: 30px;
  }
}
#product .produtos-slider .mySwiper3 .swiper-button-next,
#product .produtos-slider .mySwiper3 .swiper-rtl .swiper-button-prev {
  left: auto;
  width: 54px;
  height: 54px;
  background: #005DB1;
}
#product .produtos-slider .mySwiper3 .swiper-button-next::after,
#product .produtos-slider .mySwiper3 .swiper-rtl .swiper-button-prev::after {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
#product .produtos-slider .mySwiper3 .swiper-button-next:hover,
#product .produtos-slider .mySwiper3 .swiper-rtl .swiper-button-prev:hover {
  background: linear-gradient(45deg, #003c65 0%, #003c65 68%);
}
@media (max-width: 1100px) {
  #product .produtos-slider .mySwiper3 .swiper-button-next,
#product .produtos-slider .mySwiper3 .swiper-rtl .swiper-button-prev {
    right: 0;
    height: 65px;
  }
}
#product .produtos-slider .mySwiper3 .swiper-button-prev,
#product .produtos-slider .mySwiper3 .swiper-rtl .swiper-button-next {
  right: auto;
  width: 54px;
  height: 54px;
  background: #005DB1;
}
#product .produtos-slider .mySwiper3 .swiper-button-prev::after,
#product .produtos-slider .mySwiper3 .swiper-rtl .swiper-button-next::after {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
#product .produtos-slider .mySwiper3 .swiper-button-prev:hover,
#product .produtos-slider .mySwiper3 .swiper-rtl .swiper-button-next:hover {
  background: linear-gradient(45deg, #003c65 0%, #003c65 68%);
}
@media (max-width: 1100px) {
  #product .produtos-slider .mySwiper3 .swiper-button-prev,
#product .produtos-slider .mySwiper3 .swiper-rtl .swiper-button-next {
    left: 0;
    height: 65px;
  }
}
#product .produtos-slider .mySwiper3 .card-product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1100px) {
  #product .produtos-slider .mySwiper3 .card-product {
    max-width: 338px;
    margin: 0 auto;
    background: #f3f3f3;
  }
}
#product .produtos-slider .mySwiper3 .card-product:hover .wrapper-img img {
  transform: scale(1.1);
}
#product .produtos-slider .mySwiper3 .card-product .wrapper-img {
  width: 100%;
  height: 227px;
  overflow: hidden;
  flex-shrink: 0;
}
#product .produtos-slider .mySwiper3 .card-product .wrapper-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}
#product .produtos-slider .mySwiper3 .card-product img {
  object-fit: cover;
  transition: all 0.3s ease-out;
  height: 100%;
  width: 100%;
}
#product .produtos-slider .mySwiper3 .card-product .text-content {
  padding-left: 30px;
  padding-right: 27px;
}
@media (max-width: 1100px) {
  #product .produtos-slider .mySwiper3 .card-product .text-content {
    padding-left: 49px;
    padding-right: 100px;
  }
}
#product .produtos-slider .mySwiper3 .card-product .subtitle {
  color: #005db1;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 4.8px;
  line-height: 60px;
}
@media (max-width: 1100px) {
  #product .produtos-slider .mySwiper3 .card-product .subtitle {
    font-size: 15px;
  }
}
#product .produtos-slider .mySwiper3 .card-product .title {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
#product .produtos-slider .mySwiper3 .card-product p {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
@media (max-width: 1100px) {
  #product .produtos-slider .mySwiper3 .card-product p {
    font-size: 16px;
  }
}
#product .produtos-slider .mySwiper3 .card-product .detail {
  width: 100%;
  background: #005DB1;
  color: #fff;
  padding-left: 30px;
  padding-right: 33px;
  display: flex;
  justify-content: space-between;
  height: 64px;
  align-items: center;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  overflow: hidden;
  position: relative;
  isolation: isolate;
}
#product .produtos-slider .mySwiper3 .card-product .detail span {
  position: relative;
  z-index: 9;
}
#product .produtos-slider .mySwiper3 .card-product .detail::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  z-index: -1;
}
@media (max-width: 1100px) {
  #product .produtos-slider .mySwiper3 .card-product .detail {
    font-size: 18px;
  }
}
#product .produtos-slider .mySwiper3 .card-product .detail img {
  height: 16px;
  width: 23px;
  z-index: 9;
  position: relative;
}
#product .produtos-slider .mySwiper3 .card-product .detail:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}
#product .intro-banner {
  position: relative;
}
@media (max-width: 1100px) {
  #product .intro-banner {
    padding-top: 0px;
  }
}
#product .intro-banner .mySwiper .swiper-slide {
  width: 100%;
  height: calc(100dvh - 108px);
  min-height: 500px;
}
@media (max-width: 1100px) {
  #product .intro-banner .mySwiper .swiper-slide {
    min-height: inherit;
    height: auto;
    padding-top: 84px;
    padding-bottom: 144px;
  }
}
#product .intro-banner .mySwiper .swiper-button-next, #product .intro-banner .mySwiper .swiper-rtl .swiper-button-prev {
  right: 0;
  left: auto;
  top: 88.6%;
  width: 54px;
  height: 54px;
  background: #005DB1;
}
#product .intro-banner .mySwiper .swiper-button-next::after, #product .intro-banner .mySwiper .swiper-rtl .swiper-button-prev::after {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
@media (max-width: 1100px) {
  #product .intro-banner .mySwiper .swiper-button-next, #product .intro-banner .mySwiper .swiper-rtl .swiper-button-prev {
    right: 0;
    left: 65px;
    top: 55px;
    width: 65px;
    height: 65px;
    bottom: 0;
  }
}
#product .intro-banner .mySwiper .swiper-button-prev, #product .intro-banner .mySwiper .swiper-rtl .swiper-button-next {
  left: calc(100% - 108px);
  right: auto;
  top: 88.6%;
  width: 54px;
  height: 54px;
  background: #005DB1;
}
#product .intro-banner .mySwiper .swiper-button-prev::after, #product .intro-banner .mySwiper .swiper-rtl .swiper-button-next::after {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
@media (max-width: 1100px) {
  #product .intro-banner .mySwiper .swiper-button-prev, #product .intro-banner .mySwiper .swiper-rtl .swiper-button-next {
    left: 0;
    right: auto;
    top: 55px;
    width: 65px;
    height: 65px;
    bottom: 0;
  }
}
#product .intro-banner .wrapper-img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
#product .intro-banner .wrapper-img::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(83deg, #003C65 38.12%, rgba(0, 60, 101, 0) 89.54%);
  mix-blend-mode: multiply;
}
#product .intro-banner .wrapper {
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  height: 100%;
  padding-top: 10.052vw;
}
@media (max-width: 1310px) {
  #product .intro-banner .wrapper {
    max-width: 1180px;
  }
}
@media (max-width: 1100px) {
  #product .intro-banner .wrapper {
    padding-inline: 25px;
    padding-top: 80px;
  }
}
#product .intro-banner .banner-img {
  width: 100%;
  height: 100%;
  object-position: bottom;
  object-fit: cover;
}
#product .intro-banner .content {
  max-width: 775px;
  display: flex;
  flex-direction: column;
}
#product .intro-banner .subtitle {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 8px;
}
@media (max-width: 1100px) {
  #product .intro-banner .subtitle {
    font-size: 15px;
  }
}
#product .intro-banner .title {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 9.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 8rem;
  margin-top: 31px;
}
@media (max-width: 1100px) {
  #product .intro-banner .title {
    font-size: 50px;
    line-height: 48px;
    max-width: 100%;
  }
}
@media (max-width: 450px) {
  #product .intro-banner .title {
    font-size: 11vw;
    line-height: 1;
  }
}
#product .intro-banner .text {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-top: 54px;
}
@media (max-width: 1100px) {
  #product .intro-banner .text {
    font-size: 16px;
    line-height: 20px;
    max-width: 100%;
    margin-top: 20px;
  }
}
#product .intro-banner .text + .text {
  margin-top: 20px;
}
#product .intro-banner .btn-more {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  margin-top: 49px;
  width: fit-content;
}
@media (max-width: 1100px) {
  #product .intro-banner .btn-more {
    display: none;
  }
}
#product .intro-banner .video-btn {
  position: absolute;
  bottom: 72px;
  left: 0;
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 1500px) {
  #product .intro-banner .video-btn {
    bottom: 30px;
  }
}
@media (max-width: 1100px) {
  #product .intro-banner .video-btn {
    right: 21px;
    left: auto;
    bottom: -130px;
  }
}

#bannerHero {
  height: min(100vh, 720px);
  padding: 195px 77px 152px 316px;
  position: relative;
  isolation: isolate;
}
#bannerHero::after {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(83deg, #003C65 38.12%, rgba(0, 60, 101, 0) 89.54%);
  mix-blend-mode: multiply;
  z-index: -1;
}
#bannerHero .banner-img {
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -2;
}
#bannerHero .banner-container {
  max-width: 631px;
  color: var(--San-Martin-White, #FFF);
  z-index: 1;
}
#bannerHero .banner-container .main-sub-title {
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 375% */
  letter-spacing: 8px;
  text-transform: uppercase;
}
#bannerHero .banner-container .main-title {
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 95px;
  font-style: normal;
  font-weight: 600;
  line-height: 80px;
  /* 84.211% */
  margin-block: 12px 54px;
}
#bannerHero .banner-container .text {
  color: var(--San-Martin-White, #FFF);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
}

.breadcumbs {
  background: #F3F3F3;
  padding: 25px 77px;
}
@media (max-width: 1100px) {
  .breadcumbs {
    padding-left: 25px;
  }
}
.breadcumbs .wrapper {
  max-width: 1280px;
  margin: 0 auto;
}
.breadcumbs ul {
  display: flex;
  align-items: center;
  gap: 21px;
}
@media (max-width: 1100px) {
  .breadcumbs ul {
    gap: 10px;
  }
}
.breadcumbs ul li {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 21px;
}
@media (max-width: 1100px) {
  .breadcumbs ul li {
    gap: 16px;
    font-size: 16px;
  }
}
.breadcumbs ul .active {
  color: #005DB1;
}
.breadcumbs ul .active svg {
  fill: #005DB1;
}
@media (max-width: 1100px) {
  .breadcumbs ul .active svg {
    height: 15px;
  }
}
.breadcumbs ul .active svg path {
  fill: #005DB1;
}

#quality .breadcumbs {
  overflow: auto;
  -webkit-line-clamp: 1;
  white-space: nowrap;
}
#quality .compromissos {
  display: flex;
  justify-content: center;
  padding-inline: 77px;
}
@media (max-width: 1100px) {
  #quality .compromissos {
    padding-inline: 0;
    flex-direction: column;
    padding-top: 60px;
  }
}
#quality .compromissos-container {
  max-width: 1512px;
  display: flex;
  flex-direction: column;
  gap: 108px;
  margin-block: 68px 238px;
}
@media (max-width: 1100px) {
  #quality .compromissos-container {
    margin-block: inherit;
    gap: 44px;
  }
}
#quality .compromissos-container .img-and-text .content-text {
  max-width: 541px;
}
@media (max-width: 1100px) {
  #quality .compromissos-container .img-and-text .content-text {
    max-width: 100%;
    padding-inline: 25px;
  }
}
#quality .compromissos-container .img-and-text .wrapper {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
@media (max-width: 1100px) {
  #quality .compromissos-container .img-and-text .wrapper {
    flex-direction: column;
    gap: 0;
    max-width: 100%;
  }
}
#quality .compromissos-container .img-and-text .subtitle {
  color: #575756;
  margin-bottom: 20px;
}
#quality .compromissos-container .img-and-text .title {
  margin-bottom: 42px;
}
#quality .compromissos-container .img-and-text .title strong {
  font-weight: bold;
}
@media (max-width: 1100px) {
  #quality .compromissos-container .img-and-text .title {
    font-size: 35px;
    line-height: 35px;
    margin-top: 14px;
    margin-bottom: 42px;
  }
}
#quality .compromissos-container .img-and-text .text {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
@media (max-width: 1100px) {
  #quality .compromissos-container .img-and-text .text {
    color: #575756;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
  }
}
#quality .compromissos-container .img-and-text .img-holder {
  height: 516px;
  aspect-ratio: 1.44;
  transform: translate(107px, 0px);
  overflow: hidden;
}
@media (max-width: 1100px) {
  #quality .compromissos-container .img-and-text .img-holder {
    transform: translate(0, 0);
    width: 100%;
    height: 285px;
    margin-top: 43px;
  }
}
#quality .compromissos-container .img-and-text .img-holder:hover img {
  transform: scale(1.1);
}
#quality .compromissos-container .img-and-text .img-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}
#quality .compromissos-container .reverse .wrapper {
  flex-direction: row-reverse;
}
@media (max-width: 1100px) {
  #quality .compromissos-container .reverse .wrapper {
    flex-direction: column;
  }
}
#quality .compromissos-container .reverse .wrapper .content-text {
  padding-top: 30px;
}
#quality .compromissos-container .reverse .img-holder {
  transform: translate(-107px, 0px);
}
@media (max-width: 1100px) {
  #quality .compromissos-container .reverse .img-holder {
    transform: translate(0, 0);
  }
}
#quality .praticas {
  margin-left: 71px;
  height: 945px;
  position: relative;
}
@media (max-width: 1100px) {
  #quality .praticas {
    margin-left: 0;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    margin-top: 44px;
  }
}
#quality .praticas-floating-div {
  position: absolute;
  top: 148px;
  left: -71px;
  background-color: #fff;
  width: min(75vw, 1082px);
  height: 908px;
  padding: 108px 108px 145px;
  z-index: 1;
  filter: drop-shadow(20px 20px 30px rgba(0, 0, 0, 0.1));
  display: flex;
  justify-content: end;
}
@media (max-width: 1100px) {
  #quality .praticas-floating-div {
    top: 0;
    left: 0;
    height: auto;
    position: relative;
    padding: 0;
    width: 100%;
    padding-inline: 25px;
    filter: none;
    margin-bottom: 60px;
  }
}
#quality .praticas-floating-div .floating-wrapper {
  max-width: 656px;
}
#quality .praticas-floating-div .floating-wrapper .title {
  max-width: 305px;
  margin-block: 4px 42px;
}
#quality .praticas-floating-div .floating-wrapper .text {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
  margin-bottom: 42px;
}
@media (max-width: 1100px) {
  #quality .praticas-floating-div .floating-wrapper .text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
  }
}
#quality .praticas-floating-div .floating-wrapper .list {
  list-style-type: disc;
}
#quality .praticas-floating-div .floating-wrapper .list li + li {
  margin-top: 6px;
}
#quality .praticas-floating-div .floating-wrapper .list li {
  color: var(--San-Martin-Neutral, #575756);
  position: relative;
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
  display: flex;
}
@media (max-width: 1100px) {
  #quality .praticas-floating-div .floating-wrapper .list li {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    gap: 12px;
  }
}
#quality .praticas-floating-div .floating-wrapper .list li img {
  padding-right: 12px;
  align-self: center;
}
@media (max-width: 1100px) {
  #quality .praticas-floating-div .floating-wrapper .list li img {
    padding-right: 0;
    width: 8px;
    height: 8px;
  }
}
#quality .praticas .mask-text {
  font-size: 154px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  color: transparent;
  -webkit-text-stroke: 2px #8F8F8F;
  -webkit-background-clip: text;
  background-clip: text;
  position: relative;
  right: 62px;
  text-align: end;
  top: -113px;
}
@media (max-width: 1100px) {
  #quality .praticas .mask-text {
    top: 0;
    order: 1;
    left: 0;
    font-size: 13vw;
    right: inherit;
    text-align: center;
    -webkit-text-stroke: 1px #8F8F8F;
  }
}
#quality .praticas .bg-img {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}
@media (max-width: 1100px) {
  #quality .praticas .bg-img {
    position: relative;
    height: 442px;
    object-fit: cover;
    object-position: right;
  }
}
#quality .numeros {
  padding: 191px 77px 200px;
  background: var(--White-OFF, #F0F0F0);
  text-align: center;
  padding-inline: 77px;
}
@media (max-width: 1100px) {
  #quality .numeros {
    padding-top: 60px;
    padding-inline: 89.5px;
    padding-bottom: 60px;
  }
}
#quality .numeros .title {
  margin-inline: auto;
}
#quality .numeros-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  margin-inline: auto;
  max-width: 1280px;
  margin-top: 80px;
}
@media (max-width: 660px) {
  #quality .numeros-container {
    justify-content: center;
  }
}
#quality .numeros-container--item {
  max-width: 228px;
  text-align: center;
}
#quality .numeros-container--item .title {
  font-weight: 600;
  line-height: 50px;
  /* 100% */
}
#quality .numeros-container--item .text {
  color: var(--Support, #8F8F8F);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 120% */
  margin-top: 22px;
}
#quality .politica {
  padding: 110px 124px;
  position: relative;
  min-height: 492px;
  display: flex;
  align-items: center;
}
#quality .politica .wrapper-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #005DB1;
}
#quality .politica .wrapper-img img {
  object-fit: cover;
  width: 100%;
  mix-blend-mode: multiply;
}
@media (max-width: 1100px) {
  #quality .politica {
    padding-inline: 0px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
#quality .politica-container {
  display: flex;
  gap: 50px;
  max-width: 1280px;
  margin-inline: auto;
  justify-content: center;
}
@media (max-width: 1100px) {
  #quality .politica-container {
    flex-direction: column;
  }
}
#quality .politica-container--text {
  text-align: center;
}
@media (max-width: 1100px) {
  #quality .politica-container--text {
    padding-inline: 25px;
  }
}
#quality .politica-container--text .title {
  margin-block: 4px 42px;
  color: #fff;
}
#quality .politica-container--text .text {
  color: var(--San-Martin-Neutral, #fff);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
  max-width: 1065px;
}
#quality .politica-container--img {
  height: fit-content;
  overflow: hidden;
}
@media (max-width: 1100px) {
  #quality .politica-container--img {
    height: 285px;
  }
}
#quality .politica-container--img:hover img {
  scale: 1.1;
}
#quality .politica-container--img img {
  transition: 0.3s all ease;
  width: 100%;
}
#quality .sub-title {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 375% */
  letter-spacing: 8px;
  text-transform: uppercase;
}
@media (max-width: 1100px) {
  #quality .sub-title {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    /* 400% */
    letter-spacing: 7.5px;
  }
}
#quality .title {
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
  /* 100% */
}
@media (max-width: 1100px) {
  #quality .title {
    font-size: 35px;
    font-style: normal;
    font-weight: 300;
    line-height: 35px;
    /* 100% */
  }
}
#quality .title strong {
  font-weight: 600;
}

#institutional #customEndScreen {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#institutional .vantagem {
  padding: 68px 77px 52.5px;
  display: flex;
  justify-content: center;
}
@media (width < 1100px) {
  #institutional .vantagem {
    padding: 44px 0 51px;
  }
}
#institutional .plyr--video {
  height: 100%;
}
#institutional .plyr--video video {
  width: 100% !important;
  left: 0 !important;
}
#institutional #player {
  height: 100%;
  position: relative;
}
#institutional .video {
  padding: 0 77px 108px;
  isolation: isolate;
}
#institutional .video-wrapper {
  max-width: 1276px;
  margin-inline: auto;
  position: relative;
  height: auto;
}
#institutional .video-wrapper .thumb-nail {
  max-width: 100%;
}
#institutional .video-wrapper .play-icon {
  position: absolute;
  inset: 50% 0 0 50%;
  translate: -50% -50%;
  z-index: 2;
  width: 76px;
  aspect-ratio: 1;
}
#institutional .video .mask-text {
  font-size: 190px;
  font-style: normal;
  font-weight: 600;
  line-height: 80%;
  color: transparent;
  -webkit-text-stroke: 2px #8F8F8F;
  -webkit-background-clip: text;
  background-clip: text;
  position: relative;
  text-align: center;
  margin-bottom: -30px;
  z-index: 2;
}
#institutional .video .mask-text:not(:first-child) {
  display: none;
}
@media (width < 1700px) {
  #institutional .video .mask-text {
    font-size: 140px;
    margin-bottom: -20px;
  }
}
@media (width < 1280px) {
  #institutional .video .mask-text {
    font-size: 115px;
    margin-bottom: -10px;
  }
}
@media (width < 1100px) {
  #institutional .video {
    padding: 0 0 44px;
  }
  #institutional .video-wrapper {
    padding-inline: 25px;
    max-width: min(500px, 100%);
    margin-inline: auto;
  }
  #institutional .video-wrapper .thumb-nail {
    height: 200px;
    width: 100%;
    object-fit: cover;
    margin-top: -14px;
  }
  #institutional .video-wrapper .play-icon {
    width: 40px;
    transform: translateY(-14px);
  }
  #institutional .video .mask-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    height: 55px;
    margin: auto;
    overflow: hidden;
    z-index: 3;
  }
  #institutional .video .mask-wrapper-container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    transition: all 1s ease;
  }
  #institutional .video .mask-wrapper-container > div {
    display: flex;
    animation: scrollText 20s infinite linear;
  }
  #institutional .video .mask-text {
    font-size: 70px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    position: initial;
    text-align: start;
    -webkit-text-stroke: 2px #b8b8b8;
    margin-bottom: 0;
    transition: all 2s ease;
  }
  #institutional .video .mask-text:not(:first-child) {
    display: inherit;
  }
  #institutional .video .mask-text + .mask-text {
    margin-left: 20px;
  }
}
#institutional .inovar {
  padding: 0 77px 108px;
  display: flex;
  justify-content: center;
}
@media (width < 1100px) {
  #institutional .inovar {
    padding: 0 0 60px;
  }
  #institutional .inovar .content-text {
    padding-top: 0;
  }
}
#institutional .timeline {
  margin-bottom: 108px;
}
#institutional .timeline--text {
  text-align: center;
  padding: 0 77px 64px;
}
#institutional .timeline--text .title {
  margin-block: 4px 32px;
}
#institutional .timeline--text .desc {
  max-width: 244px;
  margin-inline: auto;
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 111.111% */
}
#institutional .timeline--slider .swiper {
  max-width: 100%;
}
#institutional .timeline--slider .swiper-slide {
  width: 640px;
  height: 566px;
  isolation: isolate;
}
#institutional .timeline--slider .swiper-button-prev::after, #institutional .timeline--slider .swiper-button-next::after {
  display: none;
}
#institutional .timeline--slider .swiper-button-prev img, #institutional .timeline--slider .swiper-button-next img {
  max-width: 53px;
  aspect-ratio: 1;
}
#institutional .timeline--slider .swiper-button-prev {
  left: 38px;
}
@media (width < 1100px) {
  #institutional .timeline--slider .swiper-button-prev {
    left: 20px;
  }
}
#institutional .timeline--slider .swiper-button-next {
  right: 38px;
}
@media (width < 1100px) {
  #institutional .timeline--slider .swiper-button-next {
    right: 20px;
  }
}
#institutional .timeline--slider .swiper-button-next img {
  rotate: 180deg;
}
#institutional .timeline--slider .swiper .timeline-container {
  position: relative;
  height: 100%;
  width: 100%;
  transition: all 0.3s ease;
}
#institutional .timeline--slider .swiper .timeline-container:hover::after {
  background: linear-gradient(210deg, rgba(0, 93, 177, 0) 34.44%, rgba(0, 93, 177, 0.94) 63.22%);
}
#institutional .timeline--slider .swiper .timeline-container:hover::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(210deg, rgba(0, 93, 177, 0.42) 34.44%, rgba(0, 93, 177, 0.94) 63.22%);
  z-index: -1;
}
#institutional .timeline--slider .swiper .timeline-container:hover .title {
  padding-bottom: 20px;
}
#institutional .timeline--slider .swiper .timeline-container::after {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(210deg, rgba(0, 60, 101, 0.43) 40.4%, rgba(0, 60, 101, 0.8) 64.49%);
  mix-blend-mode: multiply;
  z-index: -1;
  transition: all 0.3s ease;
}
#institutional .timeline--slider .swiper .timeline-container > img {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -2;
}
#institutional .timeline--slider .swiper .timeline-container--text {
  padding: 52px 64px 33px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  z-index: 2;
}
#institutional .timeline--slider .swiper .timeline-container--text .sub-title {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 120% */
  text-transform: none;
  letter-spacing: normal;
  transition: all 0.3s ease;
}
#institutional .timeline--slider .swiper .timeline-container--text .title {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 95px;
  font-style: normal;
  font-weight: 600;
  line-height: 80px;
  /* 84.211% */
  transition: all 0.3s ease;
}
#institutional .timeline--slider .swiper .timeline-container--text .text {
  opacity: 0;
  transition: all 0.3s ease;
}
#institutional .timeline--slider .swiper .timeline-container--text .text li, #institutional .timeline--slider .swiper .timeline-container--text .text p {
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
}
#institutional .timeline--slider .swiper .timeline-container--text .text strong {
  font-size: inherit;
  font-family: inherit;
  font-weight: 600;
}
#institutional .timeline--slider .swiper .timeline-container--text .text li + li {
  margin-top: 12px;
}
#institutional .timeline--slider .swiper .timeline-container--text .text p {
  margin-top: 20px;
}
#institutional .timeline--slider .swiper .timeline-container--text .text.opacity {
  opacity: 1;
  transition: all 0.3s ease;
}
@media (width < 1100px) {
  #institutional .timeline {
    margin-bottom: 60px;
  }
  #institutional .timeline--text .title {
    margin-block: 4px 32px;
  }
  #institutional .timeline--text .desc {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  #institutional .timeline--slider .swiper-slide {
    height: 345px;
  }
  #institutional .timeline--slider .swiper-button-prev img, #institutional .timeline--slider .swiper-button-next img {
    max-width: 45px;
  }
  #institutional .timeline--slider .swiper .timeline-container--text .sub-title {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 146.667% */
  }
  #institutional .timeline--slider .swiper .timeline-container--text .title {
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
  }
  #institutional .timeline--slider .swiper .timeline-container--text .text li, #institutional .timeline--slider .swiper .timeline-container--text .text p {
    font-size: 12px;
    line-height: 1;
  }
}
#institutional .principios {
  padding: 0 77px 273px;
}
#institutional .principios-container {
  max-width: 1289px;
  margin-inline: auto;
  display: flex;
  justify-content: space-between;
  gap: 109px;
}
@media (width < 1500px) {
  #institutional .principios-container {
    gap: 32px;
  }
}
#institutional .principios-container--text {
  max-width: 326px;
}
#institutional .principios-container--text .title {
  margin-block: 17px 32px;
}
#institutional .principios-container--text p {
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
#institutional .principios-container--cards {
  display: flex;
  gap: 22px;
}
#institutional .principios-container--cards .card-item {
  padding: 40px 20px;
  height: 360px;
  flex-grow: 1;
  text-align: center;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.14);
}
#institutional .principios-container--cards .card-item-icon {
  width: 116px;
  aspect-ratio: 1;
  margin-inline: auto;
}
#institutional .principios-container--cards .card-item-title {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 100% */
  margin-block: 24px 20px;
}
#institutional .principios-container--cards .card-item-text {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
}
@media (width < 1100px) {
  #institutional .principios {
    padding: 0 25px 90px;
  }
  #institutional .principios-container {
    flex-direction: column;
    gap: 60px;
  }
  #institutional .principios-container--text {
    max-width: 100%;
    text-align: center;
  }
  #institutional .principios-container--text .title {
    margin-block: 8px 32px;
  }
  #institutional .principios-container--text .title br {
    display: none;
  }
  #institutional .principios-container--text p {
    font-size: 16px;
    line-height: 22px;
  }
  #institutional .principios-container--cards {
    flex-direction: column;
    gap: 20px;
  }
  #institutional .principios-container--cards .card-item {
    height: 312px;
  }
  #institutional .principios-container--cards .card-item-icon {
    width: 80px;
  }
  #institutional .principios-container--cards .card-item-title {
    font-size: 20px;
    line-height: 20px;
    margin-block: 24px 20px;
  }
  #institutional .principios-container--cards .card-item-text {
    font-size: 16px;
    line-height: 22px;
  }
}
#institutional .valores {
  padding: 0 110px 0;
  background: var(--White-OFF, #F0F0F0);
  height: 667px;
}
@media (width < 1500px) {
  #institutional .valores {
    padding: 0 77px 0;
    height: 100%;
  }
}
#institutional .valores-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
  gap: 20px;
  translate: 0 -170px;
}
@media (width < 1500px) {
  #institutional .valores-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
#institutional .valores-container .card-item {
  position: relative;
  padding: 40px 20px 62px 56px;
  height: 360px;
  width: 100%;
  background: var(--gradiente-cheio, linear-gradient(138deg, #005DB1 -11.68%, #0C3E62 68.14%));
  overflow: hidden;
  isolation: isolate;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
#institutional .valores-container .card-item:nth-child(7) .bg-icon, #institutional .valores-container .card-item:nth-child(8) .bg-icon {
  opacity: 1;
}
#institutional .valores-container .card-item:hover {
  background: #fff;
}
#institutional .valores-container .card-item:hover .bg-icon {
  opacity: 0;
}
#institutional .valores-container .card-item:hover .title {
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  margin-block: 8px 20px;
}
#institutional .valores-container .card-item:hover .title::after {
  background-color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  bottom: 0;
}
#institutional .valores-container .card-item:hover .hover-icon {
  width: 34px;
  height: 32px;
}
#institutional .valores-container .card-item:hover .text {
  color: var(--San-Martin-Neutral, #575756);
  font-size: 16px;
  line-height: 22px;
}
#institutional .valores-container .card-item .bg-icon {
  position: absolute;
  z-index: -1;
  inset: 0 0 auto auto;
  opacity: 0.1;
  transition: all 0.3s ease;
}
#institutional .valores-container .card-item .title {
  color: var(--San-Martin-White, #FFF);
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 166.667% */
  padding-bottom: 22px;
  position: relative;
  transition: all 0.3s ease;
}
#institutional .valores-container .card-item .title::after {
  content: "";
  position: absolute;
  width: 80px;
  height: 2px;
  background-color: #fff;
  bottom: -10px;
  left: 0;
}
@media (width < 1100px) {
  #institutional .valores {
    padding: 0;
    height: 100%;
  }
  #institutional .valores-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
    translate: 0 0;
  }
  #institutional .valores-container .card-item {
    padding: 40px 25px;
    height: 200px;
  }
  #institutional .valores-container .card-item .bg-icon {
    max-width: 100%;
    max-height: 100%;
  }
  #institutional .valores-container .card-item .title {
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 45px;
    padding-bottom: 12px;
  }
  #institutional .valores-container .card-item .title::after {
    width: 50px;
    bottom: 0;
  }
}
#institutional .unidades {
  padding: 121px 77px;
}
#institutional .unidades-container {
  max-width: 1301px;
  margin-inline: auto;
  display: flex;
  justify-content: space-between;
  gap: 32px;
}
#institutional .unidades-container--img img {
  max-width: 100%;
}
#institutional .unidades-container--text {
  max-width: 517px;
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 42px;
}
#institutional .unidades-container--text .title {
  margin-top: 4px;
}
#institutional .unidades-container--text p {
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
@media (width < 1100px) {
  #institutional .unidades {
    padding: 60px 25px;
  }
  #institutional .unidades-container {
    flex-direction: column-reverse;
    gap: 60px;
  }
  #institutional .unidades-container--img {
    max-width: min(390px, 100%);
    height: min(100%, 828px);
    margin-inline: auto;
  }
  #institutional .unidades-container--img img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  #institutional .unidades-container--text {
    max-width: 100%;
    gap: 32px;
  }
  #institutional .unidades-container--text .title br {
    display: none;
  }
  #institutional .unidades-container--text .text {
    font-size: 16px;
    line-height: 22px;
  }
}
#institutional .newsletter {
  padding: 128px 0 98px 0;
  position: relative;
  width: 90.5%;
  background: #F0F0F0;
  box-shadow: 10px 10px 24px 0px rgba(0, 0, 0, 0.15);
  margin-top: 105px;
  margin-bottom: -99px;
}
#institutional .newsletter h4 {
  color: #575756;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 8px;
  margin-right: 186px;
  margin-bottom: 22px;
  float: right;
}
#institutional .newsletter h3 {
  color: #005DB1;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 60px;
  margin-right: 19px;
  margin-bottom: 19px;
  float: right;
  clear: both;
}
#institutional .newsletter h3 strong {
  color: #005DB1;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
}
#institutional .newsletter p {
  color: #575756;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-right: 15px;
  max-width: 300px;
  float: right;
  clear: both;
}
#institutional .newsletter form {
  margin-top: 143px;
  padding: 0 79px;
}
#institutional .newsletter form input {
  width: 100%;
  padding: 0 20px;
  border: none;
  margin-bottom: 30px;
  background-color: #fff;
}
#institutional .newsletter form input,
#institutional .newsletter form input::placeholder {
  color: #575756;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
}
#institutional .newsletter form button {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  width: 100%;
  height: 62px;
  gap: 20px;
  background: #005DB1;
  transition: all 0.3s ease-out;
  position: relative;
  isolation: isolate;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 36px;
}
#institutional .newsletter form button span {
  position: relative;
  z-index: 9;
}
#institutional .newsletter form button::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  z-index: -1;
}
#institutional .newsletter form button:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}
@media (max-width: 1600px) {
  #institutional .newsletter form button {
    padding: 2px 20px;
    gap: 0;
  }
}
@media (max-width: 1100px) {
  #institutional .newsletter form button {
    width: 100%;
  }
}
#institutional .newsletter form .aceite {
  color: #8F8F8F;
  font-family: "Source Sans Pro";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  max-width: 350px;
  display: flex;
  align-items: center;
}
#institutional .newsletter form .aceite a {
  text-decoration: underline;
  color: inherit;
}
#institutional .newsletter form .aceite .checkbox.style-c {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#institutional .newsletter form .aceite .checkbox.style-c input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
#institutional .newsletter form .aceite .checkbox.style-c input:checked ~ .checkbox__checkmark {
  background-color: #0084B5;
}
#institutional .newsletter form .aceite .checkbox.style-c input:checked ~ .checkbox__checkmark:after {
  opacity: 1;
}
#institutional .newsletter form .aceite .checkbox.style-c:hover input ~ .checkbox__checkmark {
  background-color: transparent;
}
#institutional .newsletter form .aceite .checkbox.style-c:hover input:checked ~ .checkbox__checkmark {
  background-color: #0084B5;
}
#institutional .newsletter form .aceite .checkbox.style-c .checkbox__checkmark {
  position: absolute;
  top: -9px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  transition: background-color 0.25s ease;
  border-radius: 4px;
  border: solid 1px #8F8F8F;
}
#institutional .newsletter form .aceite .checkbox.style-c .checkbox__checkmark:after {
  content: "";
  position: absolute;
  left: 7px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.25s ease;
}
#institutional .newsletter form .aceite .checkbox.style-c .checkbox__body {
  color: #fff;
  line-height: 1.4;
  font-size: 16px;
}
#institutional .newsletter .mulher {
  position: absolute;
  bottom: 0;
  left: 17px;
}
@media (max-width: 1600px) {
  #institutional .newsletter .mulher {
    left: -150px;
  }
}
@media (width < 1500px) {
  #institutional .newsletter {
    width: 100%;
  }
  #institutional .newsletter form {
    padding: 0 60px 0 20px;
  }
  #institutional .newsletter form button {
    padding: 2px 20px;
  }
}
@media (width < 1100px) {
  #institutional .newsletter {
    padding: 60px 0 0 0;
    margin-top: 44px;
    margin-bottom: 0;
    width: 100% !important;
    text-align: center;
    box-shadow: none;
  }
  #institutional .newsletter h4 {
    font-size: 15px;
    line-height: 60px;
    /* 400% */
    letter-spacing: 7.5px;
    margin-right: 0;
    margin-bottom: 4px;
  }
  #institutional .newsletter h3 {
    font-size: 35px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px;
    margin-bottom: 20px;
  }
  #institutional .newsletter h3 strong {
    font-size: inherit;
    font-weight: 700;
  }
  #institutional .newsletter p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    max-width: 244px;
  }
  #institutional .newsletter h4, #institutional .newsletter h3, #institutional .newsletter p {
    float: none;
    margin-inline: auto;
  }
  #institutional .newsletter form {
    margin-top: 32px;
    padding-inline: 29px 21px;
  }
  #institutional .newsletter form input {
    margin-bottom: 0;
  }
  #institutional .newsletter form .aceite {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: start;
    margin-block: 27px 26px;
  }
  #institutional .newsletter form .aceite > div br {
    display: none;
  }
  #institutional .newsletter .col-md-12:not(:first-of-type) {
    padding: 0;
  }
  #institutional .newsletter .mulher {
    display: block;
    position: inherit;
    height: fit-content;
    left: 0;
  }
  #institutional .newsletter .mulher img {
    max-width: 100%;
  }
}
#institutional .sub-title {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 375% */
  letter-spacing: 8px;
  text-transform: uppercase;
}
@media (width < 1100px) {
  #institutional .sub-title {
    font-size: 15px;
    line-height: 60px;
    /* 400% */
    letter-spacing: 7.5px;
  }
}
#institutional .title {
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
  /* 100% */
}
#institutional .title strong {
  font-weight: bold;
}
@media (width < 1100px) {
  #institutional .title {
    font-size: 35px;
    line-height: 40px;
  }
  #institutional .title strong {
    font-size: inherit;
    line-height: inherit;
  }
}
#institutional .default-btn {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
  width: 216px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background: #005DB1;
  transition: all 0.3s ease-out;
  position: relative;
  isolation: isolate;
}
#institutional .default-btn span {
  position: relative;
  z-index: 9;
}
#institutional .default-btn::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  z-index: -1;
}
#institutional .default-btn:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}
@media (max-width: 1100px) {
  #institutional .default-btn {
    width: 100%;
  }
}
#institutional .img-and-text {
  overflow: hidden;
}
#institutional .img-and-text .content-text {
  max-width: 541px;
}
@media (max-width: 1100px) {
  #institutional .img-and-text .content-text {
    max-width: 100%;
    padding-inline: 25px;
  }
}
#institutional .img-and-text .wrapper {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
@media (max-width: 1100px) {
  #institutional .img-and-text .wrapper {
    max-width: 100%;
    margin-inline: 0;
    flex-direction: column;
    gap: 60px;
  }
}
#institutional .img-and-text .title {
  margin-bottom: 42px;
}
#institutional .img-and-text .title strong {
  font-weight: bold;
}
@media (max-width: 1100px) {
  #institutional .img-and-text .title {
    margin-bottom: 32px;
  }
}
#institutional .img-and-text .text {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
@media (max-width: 1100px) {
  #institutional .img-and-text .text {
    color: #575756;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
  }
}
#institutional .img-and-text .img-holder {
  height: 516px;
  aspect-ratio: 1.44;
  transform: translate(107px, 0px);
  overflow: hidden;
}
@media (max-width: 1400px) {
  #institutional .img-and-text .img-holder {
    transform: translate(40px, 0);
  }
}
@media (max-width: 1100px) {
  #institutional .img-and-text .img-holder {
    transform: translate(0, 0);
    width: min(100%, 600px);
    height: 285px;
    margin-inline: auto;
  }
}
#institutional .img-and-text .img-holder:hover img {
  transform: scale(1.1);
}
#institutional .img-and-text .img-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}
#institutional .reverse .wrapper {
  flex-direction: row-reverse;
}
@media (width < 1100px) {
  #institutional .reverse .wrapper {
    flex-direction: column;
  }
}
#institutional .reverse .img-holder {
  transform: translate(-107px, 0px);
}
@media (width < 1100px) {
  #institutional .reverse .img-holder {
    transform: translate(0, 0);
  }
}
@keyframes scrollText {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

#main:has(#institutional) + #footer .line-news {
  display: none;
}
#main:has(#institutional) + #footer .site-map {
  margin-top: 171px;
}
@media (max-width: 1100px) {
  #main:has(#institutional) + #footer .site-map {
    margin-top: 75px;
  }
}

.etica {
  padding-bottom: 120px;
}
@media (width < 1700px) {
  .etica-container {
    margin-inline: auto;
    gap: 32px;
  }
}
@media (max-width: 1100px) {
  .etica {
    padding: 0;
  }
}
.etica-container {
  max-width: 1280px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 110px;
  margin: 0 auto;
}
@media (max-width: 1100px) {
  .etica-container {
    flex-direction: column-reverse;
    gap: 60px;
  }
}
.etica-container--img img {
  max-width: 100%;
}
@media (max-width: 1100px) {
  .etica-container--img img {
    width: 100%;
    max-width: inherit;
    height: 285px;
  }
}
.etica-container--text {
  max-width: 517px;
  display: flex;
  flex-direction: column;
  gap: 42px;
}
@media (max-width: 1100px) {
  .etica-container--text {
    padding-inline: 25px;
  }
}
.etica-container--text .title {
  margin-top: 4px;
}
.etica-container--text p {
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
@media (max-width: 1100px) {
  .etica-container--text p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
  }
}

#safeContact .safe-cont {
  position: relative;
  margin-left: 71px;
  height: 856px;
  background: var(--White-OFF, #F0F0F0);
}
@media (max-width: 1100px) {
  #safeContact .safe-cont {
    display: flex;
    flex-direction: column-reverse;
    margin: 0;
    height: auto;
    background: #fff;
  }
}
#safeContact .safe-cont--floating-div {
  position: absolute;
  top: 0;
  left: 0;
  width: min(973px, 75vw);
  height: 641px;
  background: #fff;
  padding-inline: 134px;
  z-index: 2;
  display: flex;
  justify-content: end;
  translate: -71px 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.35);
}
@media (max-width: 1100px) {
  #safeContact .safe-cont--floating-div {
    padding: 0;
    width: 100%;
    translate: inherit;
    box-shadow: none;
    padding-inline: 25px;
    height: auto;
    position: relative;
    padding-top: 44px;
  }
}
#safeContact .safe-cont--floating-div .floating-div-container {
  width: min(100%, 520px);
  align-self: center;
}
#safeContact .safe-cont--floating-div .floating-div-container .title {
  margin-bottom: 42px;
}
@media (max-width: 1100px) {
  #safeContact .safe-cont--floating-div .floating-div-container .title {
    margin-bottom: 32px;
  }
}
#safeContact .safe-cont--floating-div .floating-div-container p + p {
  margin-top: 20px;
}
#safeContact .safe-cont--floating-div .floating-div-container p {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
  max-width: 433px;
}
@media (max-width: 1100px) {
  #safeContact .safe-cont--floating-div .floating-div-container p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
  }
}
#safeContact .safe-cont > img {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 1100px) {
  #safeContact .safe-cont > img {
    position: relative;
    height: 422px;
    margin-top: 60px;
  }
}
#safeContact .faq {
  padding: 80px 77px 152px;
  text-align: center;
  background: var(--White-OFF, #F0F0F0);
}
@media (max-width: 1100px) {
  #safeContact .faq {
    padding-inline: 25px;
    padding-bottom: 60px;
  }
}
#safeContact .faq .swiper-perguntas {
  display: none;
}
@media (max-width: 1100px) {
  #safeContact .faq .swiper-perguntas {
    display: block;
  }
}
#safeContact .faq .btns-perguntas {
  margin-top: 60px;
}
#safeContact .faq .btn-prev {
  transform: rotate(180deg) translate(-1px, 0px);
}
#safeContact .faq .btn-next {
  transform: translate(-1px, 0.5px);
}
#safeContact .faq .title {
  margin-block: 1px 80px;
}
#safeContact .faq-container {
  max-width: 1280px;
  margin-inline: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 26px;
}
@media (max-width: 1100px) {
  #safeContact .faq-container {
    display: none;
  }
}
#safeContact .faq-container--item {
  max-width: 272px;
  height: 312px;
  padding: 41px 14px;
  text-align: center;
  background: #fff;
}
@media (max-width: 1100px) {
  #safeContact .faq-container--item {
    margin: 0 auto;
  }
}
#safeContact .faq-container--item .card-icon {
  width: 116px;
  aspect-ratio: 1;
  margin-inline: auto;
}
#safeContact .faq-container--item .card-title {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 100% */
  margin-block: 24px 20px;
}
#safeContact .faq-container--item .card-text {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 111.111% */
}
#safeContact .cta {
  padding: 120px 77px;
  background-color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  position: relative;
  isolation: isolate;
  margin-bottom: 120px;
}
#safeContact .cta::after {
  content: "";
  position: absolute;
  inset: 0;
  background-color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  opacity: 0.5;
  z-index: -1;
}
@media (max-width: 1100px) {
  #safeContact .cta {
    padding-top: 60px;
    padding-inline: 25px;
    margin-bottom: 70px;
    padding-bottom: 60px;
  }
}
#safeContact .cta > img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
}
#safeContact .cta-container {
  max-width: 437px;
  margin-inline: auto;
  text-align: center;
}
@media (max-width: 1100px) {
  #safeContact .cta-container img {
    width: 190px;
  }
}
#safeContact .cta-container p {
  color: var(--San-Martin-White, #FFF);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 111.111% */
  margin-block: 32px 56px;
}
#safeContact .cta-container .light-btn {
  margin-inline: auto;
}
#safeContact .sub-title {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 375% */
  letter-spacing: 8px;
  text-transform: uppercase;
}
@media (max-width: 1100px) {
  #safeContact .sub-title {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 133.333% */
    letter-spacing: 7.5px;
    margin-bottom: 14px;
  }
}
#safeContact .title {
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
  /* 100% */
}
#safeContact .title strong {
  font-weight: 600;
}
@media (max-width: 1100px) {
  #safeContact .title {
    font-size: 35px;
    font-style: normal;
    font-weight: 300;
    line-height: 35px;
    /* 100% */
  }
}
#safeContact .default-btn {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
  width: 216px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #005DB1;
  transition: all 0.3s ease-out;
  position: relative;
  isolation: isolate;
}
#safeContact .default-btn span {
  position: relative;
  z-index: 9;
}
#safeContact .default-btn::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  z-index: -1;
}
#safeContact .default-btn:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}
@media (max-width: 1100px) {
  #safeContact .default-btn {
    width: 100%;
  }
}
#safeContact .light-btn {
  width: 276px;
  height: 62px;
  background-color: transparent;
  padding-inline: 30px 22px;
  border: 1px solid #fff;
  transition: all 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1100px) {
  #safeContact .light-btn {
    width: 100%;
  }
}
#safeContact .light-btn:hover {
  background-color: #fff;
}
#safeContact .light-btn:hover span {
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
}
#safeContact .light-btn:hover svg {
  fill: var(--San-Martin-primary---Blue-primary-500, #005DB1);
}
#safeContact .light-btn span {
  color: var(--San-Martin-White, #FFF);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
}
#safeContact .light-btn svg {
  fill: #fff;
}

#blog #bannerHero {
  height: auto;
  padding-bottom: 61px;
}
#blog #bannerHero .info {
  display: flex;
  justify-content: space-between;
  gap: 32px;
  max-width: 679px;
  margin-block-start: 32px;
}
#blog #bannerHero .info > article {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#blog #bannerHero .info-item {
  gap: 12px;
}
#blog #bannerHero .info-item-text {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
#blog #bannerHero .info .anchor-text {
  color: var(--White-OFF, #F0F0F0);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  margin-right: 20px;
}
#blog #bannerHero .info .anchor img {
  width: 17px;
  height: 11px;
}
#blog #bannerHero .info .divider {
  width: 1px;
  height: 16px;
  background: #fff;
  opacity: 0.8;
  margin-inline: 20px;
  align-self: center;
}
@media (width < 1100px) {
  #blog #bannerHero {
    padding-bottom: 49px;
  }
  #blog #bannerHero .info {
    display: none;
  }
}
#blog .breadcumbs {
  padding: 11px 77px;
  max-height: 70px;
}
#blog .breadcumbs .wrapper {
  display: flex;
  justify-content: space-between;
}
#blog .breadcumbs .wrapper .input-group {
  max-width: 301px;
  transition: all 0.5s ease;
}
#blog .breadcumbs .wrapper .form-control {
  border: 2px solid var(--San-Martin-primary---Blue-primary-500, #005DB1);
  padding: 14px 20px 14px 44px;
  height: 49px;
  min-width: 100%;
}
#blog .breadcumbs .wrapper .form-control, #blog .breadcumbs .wrapper .form-control::placeholder {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
}
#blog .breadcumbs .wrapper .input-group-text img {
  position: absolute;
  z-index: 10;
  left: 20px;
  top: 16px;
}
@media (width < 1100px) {
  #blog .breadcumbs {
    padding: 15px 25px;
  }
  #blog .breadcumbs .wrapper {
    gap: 32px;
  }
  #blog .breadcumbs .wrapper .input-group {
    max-width: 54px;
  }
  #blog .breadcumbs .wrapper .input-group.expand {
    max-width: 60% !important;
    flex-grow: 1;
  }
  #blog .breadcumbs .wrapper .input-group.default {
    max-width: 54px;
  }
  #blog .breadcumbs .wrapper form {
    min-width: 100%;
    height: 40px;
  }
  #blog .breadcumbs .wrapper .form-control {
    padding: 13px 20px;
    height: 100%;
    transition: all 0.5s ease;
  }
  #blog .breadcumbs .wrapper .form-control, #blog .breadcumbs .wrapper .form-control::placeholder {
    font-size: 14px;
    line-height: 1;
  }
  #blog .breadcumbs .wrapper .form-control::placeholder {
    opacity: 0;
  }
  #blog .breadcumbs .wrapper .form-control:focus {
    padding: 13px 45px;
  }
  #blog .breadcumbs .wrapper .form-control:focus::placeholder {
    opacity: 1;
  }
  #blog .breadcumbs .wrapper .input-group-text img {
    top: 13px;
  }
}
#blog .categories {
  padding: 80px 77px 83px;
}
#blog .categories-container {
  max-width: min(100%, 1280px);
  margin-inline: auto;
  padding: 5.5px 8px;
  display: flex;
  flex-wrap: nowrap;
}
#blog .categories-container .nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 56px;
  padding-inline: 37px;
  background-color: var(--White-OFF, #F0F0F0);
  transition: all 0.3s ease-in-out;
}
#blog .categories-container .nav-link:hover {
  background: var(--San-Martin-primary---Blue-primary-500, #005DB1);
}
#blog .categories-container .nav-link:hover span {
  color: #fff;
}
#blog .categories-container .nav-link span {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 100% */
  letter-spacing: 4.8px;
  text-transform: uppercase;
}
#blog .categories-container .nav-link.active {
  background: var(--San-Martin-primary---Blue-primary-500, #005DB1);
}
#blog .categories-container .nav-link.active span {
  color: #fff;
}
#blog .categories-container .swiper-slide + .swiper-slide a {
  border-left: 1px solid var(--Support, #8F8F8F);
}
#blog .categories-container .swiper-slide {
  width: fit-content;
}
@media (width < 1100px) {
  #blog .categories {
    padding: 0 0 44px 0;
  }
  #blog .categories-container {
    padding: 0;
  }
  #blog .categories-container .nav-link {
    height: 54px;
    padding-inline: 17px;
  }
  #blog .categories-container .nav-link:not(:has(.active)) {
    border-block: 1px solid var(--Support, #8F8F8F);
  }
  #blog .categories-container .nav-link span {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    /* 100% */
    letter-spacing: 3.36px;
  }
  #blog .categories-container .swiper-slide:nth-child(1) {
    max-width: 175px;
    border-left: 1px solid var(--Support, #8F8F8F);
  }
  #blog .categories-container .swiper-slide:nth-child(2) {
    max-width: 247px;
  }
  #blog .categories-container .swiper-slide:nth-child(3) {
    max-width: 240px;
  }
  #blog .categories-container .swiper-slide:nth-child(4) {
    max-width: 141px;
  }
  #blog .categories-container .swiper-slide:nth-child(5) {
    max-width: 203px;
  }
  #blog .categories-container .swiper-slide:last-child {
    border-right: 1px solid var(--Support, #8F8F8F);
  }
}
#blog .card-top {
  max-width: 1280px;
  margin-inline: auto;
  margin-bottom: 64.5px;
}
#blog .card-top .card-treinamentos {
  height: 470px;
}
@media (width < 1100px) {
  #blog .card-top {
    margin-bottom: 64px;
  }
  #blog .card-top .card-treinamentos {
    height: 390px;
  }
}
#blog .blog-wrapper {
  max-width: 1280px;
  margin-inline: auto;
  margin-bottom: 64px;
  display: flex;
  gap: 20px;
  overflow: hidden;
}
#blog .blog-wrapper .posts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
#blog .blog-wrapper .posts .card-item {
  width: 412px;
  height: 402px;
  position: relative;
  isolation: isolate;
}
#blog .blog-wrapper .posts .card-item::after {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(210deg, rgba(1, 20, 37, 0) 40.33%, rgba(1, 20, 37, 0.8) 65.7%);
  mix-blend-mode: multiply;
  z-index: 1;
}
#blog .blog-wrapper .posts .card-item .read-more {
  height: 60px !important;
  opacity: 1 !important;
}
@media (width < 850px) {
  #blog .blog-wrapper .posts > a {
    width: fit-content !important;
  }
  #blog .blog-wrapper .posts .card-item {
    width: min(400px, 100%) !important;
  }
}
#blog .blog-wrapper .aside {
  min-width: min(100%, 410px);
  flex-grow: 1;
}
#blog .blog-wrapper .aside-categories {
  width: 100%;
  margin-bottom: 56px;
  display: flex;
  flex-wrap: wrap;
}
#blog .blog-wrapper .aside-categories .nav-link,
#blog .blog-wrapper .aside-categories .title-cat {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-grow: 1;
  margin: 0;
  width: 100%;
  height: 56px;
  padding-inline: 40px 37px;
  background-color: var(--White-OFF, #F0F0F0);
  transition: all 0.3s ease-in-out;
}
#blog .blog-wrapper .aside-categories .nav-link:hover,
#blog .blog-wrapper .aside-categories .title-cat:hover {
  background: var(--San-Martin-primary---Blue-primary-500, #005DB1);
}
#blog .blog-wrapper .aside-categories .nav-link:hover span,
#blog .blog-wrapper .aside-categories .title-cat:hover span {
  color: #fff;
}
#blog .blog-wrapper .aside-categories .nav-link span,
#blog .blog-wrapper .aside-categories .title-cat span {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 100% */
  letter-spacing: 4.8px;
  text-transform: uppercase;
}
#blog .blog-wrapper .aside-categories .nav-link.active,
#blog .blog-wrapper .aside-categories .title-cat.active {
  background: var(--San-Martin-primary---Blue-primary-500, #005DB1);
}
#blog .blog-wrapper .aside-categories .nav-link.active span,
#blog .blog-wrapper .aside-categories .title-cat.active span {
  color: #fff;
}
#blog .blog-wrapper .aside-newsletter {
  width: 100%;
  max-height: 540px;
  padding: 40px;
  background: var(--gradiente-cheio, linear-gradient(138deg, #005DB1 -11.68%, #0C3E62 68.14%));
}
#blog .blog-wrapper .aside-newsletter .sub-title,
#blog .blog-wrapper .aside-newsletter .title,
#blog .blog-wrapper .aside-newsletter .text {
  color: #fff !important;
}
#blog .blog-wrapper .aside-newsletter .title {
  font-size: 32px;
  line-height: 43px;
  margin-block-end: 20px;
}
#blog .blog-wrapper .aside-newsletter .text {
  max-width: 270px;
  margin-block-end: 40px;
}
#blog .blog-wrapper .aside-newsletter form .form-control {
  height: 62px;
  width: 100%;
  padding: 1px 27px;
  border: 2px solid var(--San-Martin-White, #FFF);
  border-radius: 0;
  background-color: transparent;
}
#blog .blog-wrapper .aside-newsletter form .form-control, #blog .blog-wrapper .aside-newsletter form .form-control::placeholder {
  color: var(--San-Martin-White, #FFF);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  /* 428.571% */
}
#blog .blog-wrapper .aside-newsletter form .btn-light {
  width: 100%;
  height: 62px;
  background: #fff;
  transition: all 0.3s ease-in-out;
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block: 17px 27px;
  padding-inline: 30px;
}
#blog .blog-wrapper .aside-newsletter form .btn-light:hover {
  background: var(--White-OFF, #F0F0F0);
}
#blog .blog-wrapper .aside-newsletter form .btn-light img {
  translate: -45px 0;
}
#blog .blog-wrapper .aside-newsletter form .form-check {
  margin-block: 20px 56px;
  display: flex;
  align-items: center;
}
#blog .blog-wrapper .aside-newsletter form .form-check-input {
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 1.5px solid var(--White-OFF, #F0F0F0);
  background-color: rgba(217, 217, 217, 0);
  margin-right: 24px;
}
#blog .blog-wrapper .aside-newsletter form .form-check-label {
  color: var(--Support, #8F8F8F);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
#blog .blog-wrapper .aside-newsletter form .form-check-label a {
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  text-decoration-line: underline;
}
@media (width < 1100px) {
  #blog .blog-wrapper {
    margin-bottom: 44px;
    flex-direction: column;
    gap: 44px;
  }
  #blog .blog-wrapper .posts > a {
    width: min-content;
  }
  #blog .blog-wrapper .posts .card-item {
    min-width: min(400px, 100%);
    height: 194px;
    margin-inline: auto;
    padding-inline: 25px;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  #blog .blog-wrapper .posts .card-item::after {
    background: transparent !important;
  }
  #blog .blog-wrapper .posts .card-item .tag, #blog .blog-wrapper .posts .card-item .date, #blog .blog-wrapper .posts .card-item br {
    display: none;
  }
  #blog .blog-wrapper .posts .card-item .bg-img {
    position: inherit;
    display: block;
    width: 155.943px;
    height: 171.996px;
  }
  #blog .blog-wrapper .posts .card-item .content-text {
    gap: 12px;
    padding: 0;
  }
  #blog .blog-wrapper .posts .card-item .content-text .subtitle {
    color: var(--San-Martin-Neutral, #575756);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
    /* 291.667% */
    letter-spacing: 6px;
    margin: 0;
  }
  #blog .blog-wrapper .posts .card-item .content-text .title {
    color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    margin: 0;
  }
  #blog .blog-wrapper .posts .card-item .content-text .text {
    color: var(--San-Martin-Neutral, #575756);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
  #blog .blog-wrapper .posts .card-item .content-text .read-more {
    color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 39px;
    max-height: 39px !important;
  }
  #blog .blog-wrapper .posts .card-item .content-text .read-more svg {
    width: 16px;
    height: 10.647px;
  }
  #blog .blog-wrapper .posts .card-item .content-text .read-more svg path {
    fill: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  }
  #blog .blog-wrapper .aside {
    justify-self: center;
    padding-inline: 25px;
  }
  #blog .blog-wrapper .aside-categories {
    margin-bottom: 0;
  }
  #blog .blog-wrapper .aside-categories .nav-link,
#blog .blog-wrapper .aside-categories .title-cat {
    height: 54px;
    padding-inline: 40px;
  }
  #blog .blog-wrapper .aside-categories .nav-link span,
#blog .blog-wrapper .aside-categories .title-cat span {
    font-size: 14px;
    line-height: 14px;
    /* 100% */
    letter-spacing: 3.36px;
  }
  #blog .blog-wrapper .aside-categories .nav-link.active,
#blog .blog-wrapper .aside-categories .title-cat.active {
    background: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  }
  #blog .blog-wrapper .aside-categories .nav-link.active span,
#blog .blog-wrapper .aside-categories .title-cat.active span {
    color: #fff;
  }
  #blog .blog-wrapper .aside-newsletter {
    display: none;
  }
}
@media (width < 1100px) {
  #blog .btn-wrapper {
    display: none !important;
  }
}
#blog .newsletter {
  padding: 128px 0 98px 0;
  position: relative;
  width: 90.5%;
  background: #F0F0F0;
  box-shadow: 10px 10px 24px 0px rgba(0, 0, 0, 0.15);
  margin-top: 105px;
}
#blog .newsletter h4 {
  color: #575756;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 8px;
  margin-right: 186px;
  margin-bottom: 22px;
  float: right;
}
#blog .newsletter h3 {
  color: #005DB1;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 60px;
  margin-right: 19px;
  margin-bottom: 19px;
  float: right;
  clear: both;
}
#blog .newsletter h3 strong {
  color: #005DB1;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
}
#blog .newsletter p {
  color: #575756;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-right: 15px;
  max-width: 300px;
  float: right;
  clear: both;
}
#blog .newsletter form {
  margin-top: 143px;
  padding: 0 79px;
}
#blog .newsletter form input {
  width: 100%;
  padding: 0 20px;
  border: none;
  margin-bottom: 30px;
  background-color: #fff;
}
#blog .newsletter form input,
#blog .newsletter form input::placeholder {
  color: #575756;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
}
#blog .newsletter form button {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
  width: 100%;
  height: 62px;
  gap: 20px;
  background: #005DB1;
  transition: all 0.3s ease-out;
  position: relative;
  isolation: isolate;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 36px;
}
#blog .newsletter form button span {
  position: relative;
  z-index: 9;
}
#blog .newsletter form button::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  z-index: -1;
}
#blog .newsletter form button:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}
@media (max-width: 1600px) {
  #blog .newsletter form button {
    padding: 2px 20px;
    gap: 0;
  }
}
@media (max-width: 1100px) {
  #blog .newsletter form button {
    width: 100%;
  }
}
#blog .newsletter form .aceite {
  color: #8F8F8F;
  font-family: "Source Sans Pro";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  max-width: 350px;
  display: flex;
  align-items: center;
}
#blog .newsletter form .aceite a {
  text-decoration: underline;
  color: inherit;
}
#blog .newsletter form .aceite .checkbox.style-c {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#blog .newsletter form .aceite .checkbox.style-c input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
#blog .newsletter form .aceite .checkbox.style-c input:checked ~ .checkbox__checkmark {
  background-color: #0084B5;
}
#blog .newsletter form .aceite .checkbox.style-c input:checked ~ .checkbox__checkmark:after {
  opacity: 1;
}
#blog .newsletter form .aceite .checkbox.style-c:hover input ~ .checkbox__checkmark {
  background-color: transparent;
}
#blog .newsletter form .aceite .checkbox.style-c:hover input:checked ~ .checkbox__checkmark {
  background-color: #0084B5;
}
#blog .newsletter form .aceite .checkbox.style-c .checkbox__checkmark {
  position: absolute;
  top: -9px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  transition: background-color 0.25s ease;
  border-radius: 4px;
  border: solid 1px #8F8F8F;
}
#blog .newsletter form .aceite .checkbox.style-c .checkbox__checkmark:after {
  content: "";
  position: absolute;
  left: 7px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.25s ease;
}
#blog .newsletter form .aceite .checkbox.style-c .checkbox__body {
  color: #fff;
  line-height: 1.4;
  font-size: 16px;
}
#blog .newsletter .mulher {
  position: absolute;
  bottom: 0;
  left: 17px;
}
@media (max-width: 1600px) {
  #blog .newsletter .mulher {
    left: -150px;
  }
}
@media (width < 1500px) {
  #blog .newsletter {
    width: 95.5%;
  }
  #blog .newsletter form {
    padding: 0 60px 0 20px;
  }
  #blog .newsletter form button {
    padding: 2px 20px;
  }
  #blog .newsletter .mulher {
    left: -268px;
  }
}
@media (width < 1100px) {
  #blog .newsletter {
    padding: 60px 0 0 0;
    margin-top: 44px;
    width: 100% !important;
    text-align: center;
    box-shadow: none;
  }
  #blog .newsletter h4 {
    font-size: 15px;
    line-height: 60px;
    /* 400% */
    letter-spacing: 7.5px;
    margin-right: 0;
    margin-bottom: 4px;
  }
  #blog .newsletter h3 {
    font-size: 35px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px;
    margin-bottom: 20px;
  }
  #blog .newsletter h3 strong {
    font-size: inherit;
    font-weight: 700;
  }
  #blog .newsletter p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    max-width: 244px;
  }
  #blog .newsletter h4, #blog .newsletter h3, #blog .newsletter p {
    float: none;
    margin-inline: auto;
  }
  #blog .newsletter form {
    margin-top: 32px;
    padding-inline: 29px 21px;
  }
  #blog .newsletter form input {
    margin-bottom: 0;
  }
  #blog .newsletter form .aceite {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: start;
    margin-block: 27px 26px;
  }
  #blog .newsletter form .aceite > div br {
    display: none;
  }
  #blog .newsletter .col-md-12:not(:first-of-type) {
    padding: 0;
  }
  #blog .newsletter .mulher {
    display: block;
    position: inherit;
    height: fit-content;
    left: 0;
  }
  #blog .newsletter .mulher img {
    max-width: 100%;
  }
}
#blog .produtos-slider {
  padding-block: 120px 108px;
}
@media (width < 1100px) {
  #blog .produtos-slider {
    padding-block: 60px;
  }
  #blog .produtos-slider .btn-ver-mais {
    display: none;
  }
}
#blog .produtos-slider .card-product {
  background-color: #F3F3F3;
}
#blog .produtos-slider .card-product .title {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 100% */
}
#blog .back-to-top {
  max-width: 100px;
  display: block;
  margin-inline: auto;
  margin-bottom: 120px;
  color: #005DB1;
  font-size: 16px;
  font-weight: 600;
  line-height: 50px;
  text-align: center;
}
#blog .back-to-top:hover {
  color: inherit;
}
#blog .back-to-top:hover .icon {
  translate: 0 -2px;
}
#blog .back-to-top .icon {
  transform: rotate(180deg);
  display: block;
  margin-inline: auto;
  transition: all 0.2s ease-out;
}
#blog .back-to-top .icon path {
  fill: #005DB1;
}
@media (width < 1100px) {
  #blog .back-to-top {
    margin-bottom: 60px;
    font-size: 14px;
    line-height: 40px;
  }
}
#blog .all-cards {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}
#blog .all-cards:hover .bg-img {
  scale: 1.1;
}
#blog .all-cards .bg-img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  transition: all 0.3s ease;
}
#blog .all-cards .content-text {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 25px;
  padding-bottom: 30px;
  width: 75%;
  gap: 18px;
  position: relative;
  z-index: 9;
}
#blog .all-cards .subtitle {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 8px;
  text-transform: uppercase;
}
#blog .all-cards .title {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 100% */
}
#blog .all-cards .text {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
}
#blog .all-cards .tag {
  position: absolute;
  top: 0;
  left: 0;
  height: 51px;
  padding-inline: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #005DB1;
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 4.8px;
  text-transform: uppercase;
  z-index: 2;
}
@media (width < 1100px) {
  #blog .all-cards .tag {
    height: 27px;
    padding-inline: 26px;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 4.5px;
  }
}
#blog .all-cards .read-more {
  color: var(--san-martin-white, #FFF);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
  display: flex;
  gap: 32px;
  align-items: center;
  height: 0px;
  opacity: 0;
}
#blog .all-cards .date {
  position: absolute;
  right: 26px;
  bottom: 24px;
  color: #FFF;
  /* H6 - textBody */
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
  z-index: 99;
  opacity: 0;
}
#blog .all-cards::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(210deg, rgba(1, 20, 37, 0) 40.33%, rgba(1, 20, 37, 0.8) 65.7%);
  mix-blend-mode: multiply;
  transition: all 0.3s ease-out;
  will-change: opacity;
  opacity: 1;
  z-index: 1;
}
@media (width < 1100px) {
  #blog .all-cards .subtitle {
    font-size: 15px;
    line-height: 16px;
    /* 106.667% */
    letter-spacing: 7.5px;
  }
  #blog .all-cards .title {
    font-size: 20px;
    line-height: 25px;
  }
  #blog .all-cards .text {
    font-size: 16px;
    line-height: 25px;
  }
  #blog .all-cards .tag {
    height: 27px;
    padding-inline: 26px;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 4.5px;
  }
}
#blog .sub-title {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 375% */
  letter-spacing: 8px;
  text-transform: uppercase;
  margin: 0;
}
#blog .title {
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
  /* 100% */
}
#blog .title strong {
  font-weight: bold;
}
#blog .text {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 111.111% */
}
#blog .default-btn {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
  width: 216px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #005DB1;
  transition: all 0.3s ease-out;
  position: relative;
  isolation: isolate;
}
#blog .default-btn span {
  position: relative;
  z-index: 9;
}
#blog .default-btn::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  z-index: -1;
}
#blog .default-btn:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}
@media (max-width: 1100px) {
  #blog .default-btn {
    width: 100%;
  }
}
#blog p, #blog ul {
  margin: 0;
  padding: 0;
  font-family: inherit;
  color: inherit;
}

#blog-detail #bannerHero {
  height: auto;
  padding-bottom: 61px;
}
#blog-detail #bannerHero .info {
  display: flex;
  justify-content: space-between;
  gap: 32px;
  max-width: 679px;
  margin-block-start: 32px;
}
@media (width < 1100px) {
  #blog-detail #bannerHero .info {
    flex-direction: column;
  }
}
#blog-detail #bannerHero .info > article {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#blog-detail #bannerHero .info-item {
  gap: 12px;
}
@media (width < 1100px) {
  #blog-detail #bannerHero .info-item {
    gap: 8px;
  }
}
#blog-detail #bannerHero .info-item-text {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
@media (width < 1100px) {
  #blog-detail #bannerHero .info-item-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    /* 150% */
    white-space: nowrap;
  }
}
#blog-detail #bannerHero .info .anchor-text {
  color: var(--White-OFF, #F0F0F0);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  margin-right: 20px;
}
@media (width < 1100px) {
  #blog-detail #bannerHero .info .anchor-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
    /* 250% */
  }
}
#blog-detail #bannerHero .info .anchor img {
  width: 17px;
  height: 11px;
}
@media (width < 1100px) {
  #blog-detail #bannerHero .info .anchor img {
    width: 12px;
    height: 7px;
  }
}
#blog-detail #bannerHero .info .divider {
  width: 1px;
  height: 16px;
  background: #fff;
  opacity: 0.8;
  margin-inline: 20px;
  align-self: center;
}
@media (width < 1100px) {
  #blog-detail #bannerHero .info .divider {
    margin-inline: 0;
  }
}
#blog-detail .breadcumbs {
  padding: 11px 77px;
  max-height: 70px;
}
#blog-detail .breadcumbs .wrapper {
  display: flex;
  justify-content: space-between;
}
#blog-detail .breadcumbs .wrapper .input-group {
  max-width: 301px;
  transition: all 0.5s ease;
}
#blog-detail .breadcumbs .wrapper .active {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 110px;
  /* Adjust the width as needed */
  display: inline-block;
  transition: all 0.3s ease;
}
#blog-detail .breadcumbs .wrapper .form-control {
  border: 2px solid var(--San-Martin-primary---Blue-primary-500, #005DB1);
  padding: 14px 20px 14px 44px;
  height: 49px;
  min-width: 100%;
}
#blog-detail .breadcumbs .wrapper .form-control, #blog-detail .breadcumbs .wrapper .form-control::placeholder {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
}
#blog-detail .breadcumbs .wrapper .input-group-text img {
  position: absolute;
  z-index: 10;
  left: 20px;
  top: 16px;
}
@media (width < 1100px) {
  #blog-detail .breadcumbs {
    padding: 15px 25px;
  }
  #blog-detail .breadcumbs .wrapper {
    gap: 32px;
  }
  #blog-detail .breadcumbs .wrapper svg {
    max-height: 15px;
  }
  #blog-detail .breadcumbs .wrapper .input-group {
    max-width: 54px;
  }
  #blog-detail .breadcumbs .wrapper .input-group.expand {
    max-width: 60% !important;
    flex-grow: 1;
  }
  #blog-detail .breadcumbs .wrapper .input-group.default {
    max-width: 54px;
  }
  #blog-detail .breadcumbs .wrapper form {
    min-width: 100%;
    height: 40px;
  }
  #blog-detail .breadcumbs .wrapper .form-control {
    padding: 13px 20px;
    height: 100%;
    transition: all 0.5s ease;
  }
  #blog-detail .breadcumbs .wrapper .form-control, #blog-detail .breadcumbs .wrapper .form-control::placeholder {
    font-size: 14px;
    line-height: 1;
  }
  #blog-detail .breadcumbs .wrapper .form-control::placeholder {
    opacity: 0;
  }
  #blog-detail .breadcumbs .wrapper .form-control:focus {
    padding: 13px 45px;
  }
  #blog-detail .breadcumbs .wrapper .form-control:focus::placeholder {
    opacity: 1;
  }
  #blog-detail .breadcumbs .wrapper .input-group-text img {
    top: 13px;
  }
}
#blog-detail .conteudo {
  padding-top: 84px;
  margin-left: 20px;
  margin-bottom: 65px;
}
@media (max-width: 1100px) {
  #blog-detail .conteudo {
    margin-left: 0;
    padding-top: 70px;
    margin-bottom: 15px;
  }
}
@media (max-width: 1100px) {
  #blog-detail .conteudo figure img {
    width: 100%;
  }
}
#blog-detail .conteudo h3 {
  color: #575756;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 8px;
}
@media (max-width: 1100px) {
  #blog-detail .conteudo h3 {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    /* 400% */
    letter-spacing: 7.5px;
    padding-inline: 25px;
  }
}
#blog-detail .conteudo h2 {
  color: #005DB1;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 80px;
  max-width: 500px;
  line-height: 1;
}
@media (max-width: 1100px) {
  #blog-detail .conteudo h2 {
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    /* 114.286% */
    margin-bottom: 32px;
    padding-inline: 25px;
  }
}
#blog-detail .conteudo p {
  color: #575756;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 44px;
}
@media (max-width: 1100px) {
  #blog-detail .conteudo p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
    padding-inline: 25px;
  }
}
#blog-detail .data {
  margin-left: 20px;
}
#blog-detail .data .atualizado {
  color: #575756;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  height: 100%;
  display: flex;
  align-items: center;
}
@media (max-width: 1100px) {
  #blog-detail .data .atualizado {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    /* 157.143% */
    margin-bottom: 32px;
  }
  #blog-detail .data .atualizado .row {
    margin-inline: 0 !important;
  }
}
#blog-detail .data .itens {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 42px;
}
@media (max-width: 1100px) {
  #blog-detail .data .itens {
    justify-content: space-between;
    padding-inline: 25px;
  }
}
#blog-detail .data .itens .compartilhar {
  color: #8F8F8F;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  text-align: center;
}
#blog-detail .data .itens .compartilhar .icones {
  display: flex;
  gap: 24px;
  align-items: center;
}
#blog-detail .data .itens .compartilhar .icones svg path {
  transition: all 0.3s ease;
}
#blog-detail .data .itens .compartilhar .icones svg:hover path {
  fill: #005DB1;
}
#blog-detail .sidebar {
  margin-top: 156px;
  margin-left: 121px;
}
@media (max-width: 1100px) {
  #blog-detail .sidebar {
    margin-top: 32px;
    margin-left: 0;
    padding-inline: 17.5px;
  }
}
#blog-detail .sidebar h4 {
  color: #575756;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 8px;
  padding-bottom: 8px;
  border-bottom: solid 2px #F0F0F0;
  margin-bottom: 43px;
  width: 100%;
}
#blog-detail .sidebar .posts-similares .post {
  width: 100%;
  display: flex;
  margin-bottom: 42px;
}
@media (max-width: 1100px) {
  #blog-detail .sidebar .posts-similares .post {
    margin-bottom: 42px;
    display: grid;
    grid-template-columns: 155px 1fr;
    gap: 20px;
  }
}
#blog-detail .sidebar .posts-similares .post .img-post {
  width: 156px;
  height: 171px;
  position: relative;
  margin-right: 20px;
}
@media (max-width: 1100px) {
  #blog-detail .sidebar .posts-similares .post .img-post {
    width: 155px;
    height: 172px;
    margin-right: 0;
  }
}
#blog-detail .sidebar .posts-similares .post .img-post::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(210deg, rgba(1, 20, 37, 0) 40.33%, rgba(1, 20, 37, 0.8) 65.7%);
}
#blog-detail .sidebar .posts-similares .post .img-post img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#blog-detail .sidebar .posts-similares .post h6 {
  color: #575756;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 6px;
  margin-bottom: 7px;
}
#blog-detail .sidebar .posts-similares .post h5 {
  color: #005DB1;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  margin-bottom: 13px;
}
#blog-detail .sidebar .posts-similares .post .desc-post {
  max-width: 209px;
}
@media (max-width: 1100px) {
  #blog-detail .sidebar .posts-similares .post .desc-post {
    padding-top: 10px;
  }
}
#blog-detail .sidebar .posts-similares .post .desc-post p {
  color: #575756;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
#blog-detail .sidebar .posts-similares .post .data {
  color: #005DB1;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  margin-left: 0;
  margin-top: 12px;
}
#blog-detail .sidebar .bloco-news {
  padding: 40px;
  margin-top: 69px;
  background: var(--gradiente-cheio, linear-gradient(138deg, #005DB1 -11.68%, #0C3E62 68.14%));
}
@media (max-width: 1100px) {
  #blog-detail .sidebar .bloco-news {
    display: none;
  }
}
#blog-detail .sidebar .bloco-news h6 {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 8px;
  margin-bottom: 2px;
}
#blog-detail .sidebar .bloco-news h5 {
  color: #F0F0F0;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 43px;
  margin-bottom: 17px;
}
#blog-detail .sidebar .bloco-news h5strong {
  color: #F0F0F0;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 43px;
}
#blog-detail .sidebar .bloco-news p {
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 265px;
}
#blog-detail .sidebar .bloco-news form {
  margin-top: 51px;
}
#blog-detail .sidebar .bloco-news form input {
  width: 100%;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  padding: 0 27px;
  background-color: transparent;
  border: 2px solid #FFF;
}
#blog-detail .sidebar .bloco-news form input::placeholder {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
}
#blog-detail .sidebar .bloco-news form button {
  color: #005DB1;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  width: 100%;
  margin-top: 11px;
  text-align: left;
  padding: 0 31px;
  display: flex;
  gap: 94px;
  align-items: center;
  background: #FFF;
}
@media (max-width: 1600px) {
  #blog-detail .sidebar .bloco-news form button {
    justify-content: space-between;
    padding: 0 20px;
    gap: 0;
  }
}
#blog-detail .sidebar .bloco-news form .aceite {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #F0F0F0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 29px;
  gap: 14px;
}
#blog-detail .sidebar .bloco-news form .aceite .checkbox.style-c {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#blog-detail .sidebar .bloco-news form .aceite .checkbox.style-c input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
#blog-detail .sidebar .bloco-news form .aceite .checkbox.style-c input:checked ~ .checkbox__checkmark {
  background-color: #0084B5;
}
#blog-detail .sidebar .bloco-news form .aceite .checkbox.style-c input:checked ~ .checkbox__checkmark:after {
  opacity: 1;
}
#blog-detail .sidebar .bloco-news form .aceite .checkbox.style-c:hover input ~ .checkbox__checkmark {
  background-color: transparent;
}
#blog-detail .sidebar .bloco-news form .aceite .checkbox.style-c:hover input:checked ~ .checkbox__checkmark {
  background-color: #0084B5;
}
#blog-detail .sidebar .bloco-news form .aceite .checkbox.style-c .checkbox__checkmark {
  position: absolute;
  top: -9px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  transition: background-color 0.25s ease;
  border-radius: 4px;
  border: solid 1px #fff;
}
#blog-detail .sidebar .bloco-news form .aceite .checkbox.style-c .checkbox__checkmark:after {
  content: "";
  position: absolute;
  left: 7px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.25s ease;
}
#blog-detail .sidebar .bloco-news form .aceite .checkbox.style-c .checkbox__body {
  color: #fff;
  line-height: 1.4;
  font-size: 16px;
}
#blog-detail .sidebar .categorias {
  margin-top: 58px;
}
#blog-detail .sidebar .categorias h4 {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 4.8px;
  border-bottom: none;
  margin: 0;
  padding: 20px 40px;
  background-color: #005DB1;
}
@media (max-width: 1100px) {
  #blog-detail .sidebar .categorias h4 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    /* 100% */
    letter-spacing: 3.36px;
  }
}
#blog-detail .sidebar .categorias ul {
  background-color: #F0F0F0;
}
#blog-detail .sidebar .categorias ul li {
  padding: 20px 0;
  position: relative;
}
#blog-detail .sidebar .categorias ul li::after {
  content: "";
  position: absolute;
  width: 100%;
  max-width: 272px;
  height: 1px;
  background-color: #B0CDE7;
  bottom: 4px;
  right: 72px;
}
@media (max-width: 1100px) {
  #blog-detail .sidebar .categorias ul li::after {
    right: 34px;
  }
}
#blog-detail .sidebar .categorias ul li:last-child::after {
  display: none;
}
#blog-detail .sidebar .categorias ul li a {
  color: #575756;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 4.8px;
  padding: 20px 40px;
  transition: 0.3s ease-in;
}
@media (max-width: 1100px) {
  #blog-detail .sidebar .categorias ul li a {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    /* 100% */
    letter-spacing: 3.36px;
  }
}
#blog-detail .sidebar .categorias ul li a:hover {
  transition: 0.3s ease-in;
  color: #005DB1;
}
#blog-detail .newsletter {
  padding: 128px 0 98px 0;
  position: relative;
  width: 90.5%;
  background: #F0F0F0;
  box-shadow: 10px 10px 24px 0px rgba(0, 0, 0, 0.15);
  margin-top: 105px;
}
#blog-detail .newsletter h4 {
  color: #575756;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 8px;
  margin-right: 186px;
  margin-bottom: 22px;
  float: right;
}
#blog-detail .newsletter h3 {
  color: #005DB1;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 60px;
  margin-right: 19px;
  margin-bottom: 19px;
  float: right;
  clear: both;
}
#blog-detail .newsletter h3 strong {
  color: #005DB1;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
}
#blog-detail .newsletter p {
  color: #575756;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-right: 15px;
  max-width: 300px;
  float: right;
  clear: both;
}
#blog-detail .newsletter form {
  margin-top: 143px;
  padding: 0 79px;
}
#blog-detail .newsletter form input {
  width: 100%;
  padding: 0 20px;
  border: none;
  margin-bottom: 30px;
  background-color: #fff;
}
#blog-detail .newsletter form input,
#blog-detail .newsletter form input::placeholder {
  color: #575756;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
}
#blog-detail .newsletter form button {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
  width: 100%;
  height: 62px;
  gap: 20px;
  background: #005DB1;
  transition: all 0.3s ease-out;
  position: relative;
  isolation: isolate;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 36px;
}
#blog-detail .newsletter form button span {
  position: relative;
  z-index: 9;
}
#blog-detail .newsletter form button::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  z-index: -1;
}
#blog-detail .newsletter form button:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}
@media (max-width: 1600px) {
  #blog-detail .newsletter form button {
    padding: 2px 20px;
    gap: 0;
  }
}
@media (max-width: 1100px) {
  #blog-detail .newsletter form button {
    width: 100%;
  }
}
#blog-detail .newsletter form .aceite {
  color: #8F8F8F;
  font-family: "Source Sans Pro";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  max-width: 350px;
  display: flex;
  align-items: center;
}
#blog-detail .newsletter form .aceite a {
  text-decoration: underline;
  color: inherit;
}
#blog-detail .newsletter form .aceite .checkbox.style-c {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#blog-detail .newsletter form .aceite .checkbox.style-c input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
#blog-detail .newsletter form .aceite .checkbox.style-c input:checked ~ .checkbox__checkmark {
  background-color: #0084B5;
}
#blog-detail .newsletter form .aceite .checkbox.style-c input:checked ~ .checkbox__checkmark:after {
  opacity: 1;
}
#blog-detail .newsletter form .aceite .checkbox.style-c:hover input ~ .checkbox__checkmark {
  background-color: transparent;
}
#blog-detail .newsletter form .aceite .checkbox.style-c:hover input:checked ~ .checkbox__checkmark {
  background-color: #0084B5;
}
#blog-detail .newsletter form .aceite .checkbox.style-c .checkbox__checkmark {
  position: absolute;
  top: -9px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  transition: background-color 0.25s ease;
  border-radius: 4px;
  border: solid 1px #8F8F8F;
}
#blog-detail .newsletter form .aceite .checkbox.style-c .checkbox__checkmark:after {
  content: "";
  position: absolute;
  left: 7px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.25s ease;
}
#blog-detail .newsletter form .aceite .checkbox.style-c .checkbox__body {
  color: #fff;
  line-height: 1.4;
  font-size: 16px;
}
#blog-detail .newsletter .mulher {
  position: absolute;
  bottom: 0;
  left: 17px;
}
@media (max-width: 1600px) {
  #blog-detail .newsletter .mulher {
    left: -150px;
  }
}
@media (width < 1500px) {
  #blog-detail .newsletter {
    width: 95.5%;
  }
  #blog-detail .newsletter form {
    padding: 0 60px 0 20px;
  }
  #blog-detail .newsletter form button {
    padding: 2px 20px;
  }
  #blog-detail .newsletter .mulher {
    left: -268px;
  }
}
@media (width < 1100px) {
  #blog-detail .newsletter {
    padding: 60px 0 0 0;
    margin-top: 44px;
    width: 100%;
    text-align: center;
    box-shadow: none;
    display: flex;
    flex-direction: column-reverse;
  }
  #blog-detail .newsletter h4 {
    font-size: 15px;
    line-height: 60px;
    /* 400% */
    letter-spacing: 7.5px;
    margin-right: 0;
    margin-bottom: 4px;
  }
  #blog-detail .newsletter h3 {
    font-size: 35px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px;
    margin-bottom: 20px;
  }
  #blog-detail .newsletter h3 strong {
    font-size: inherit;
    font-weight: 700;
  }
  #blog-detail .newsletter p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    max-width: 244px;
  }
  #blog-detail .newsletter h4, #blog-detail .newsletter h3, #blog-detail .newsletter p {
    float: none;
    margin-inline: auto;
  }
  #blog-detail .newsletter form {
    margin-top: 32px;
    padding-inline: 29px 21px;
  }
  #blog-detail .newsletter form input {
    margin-bottom: 0;
  }
  #blog-detail .newsletter form .aceite {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: start;
    margin-block: 27px 26px;
  }
  #blog-detail .newsletter form .aceite > div br {
    display: none;
  }
  #blog-detail .newsletter .col-md-12:not(:first-of-type) {
    padding: 0;
  }
  #blog-detail .newsletter .mulher {
    display: block;
    position: inherit;
    height: fit-content;
    left: 0;
  }
  #blog-detail .newsletter .mulher img {
    max-width: 100%;
  }
}
#blog-detail .produtos-slider {
  background: #fff;
  padding-top: 62px;
  margin-bottom: 107px;
}
@media (max-width: 1100px) {
  #blog-detail .produtos-slider {
    margin-bottom: 60px;
  }
}
#blog-detail .produtos-slider .content-text {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
#blog-detail .produtos-slider .content-text .subtitle {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 8px;
}
@media (max-width: 1100px) {
  #blog-detail .produtos-slider .content-text .subtitle {
    font-size: 15px;
  }
}
#blog-detail .produtos-slider .content-text .title {
  color: #005DB1;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 60px;
}
#blog-detail .produtos-slider .content-text .title span {
  font-weight: bold;
}
@media (max-width: 1100px) {
  #blog-detail .produtos-slider .content-text .title {
    font-size: 35px;
    line-height: 35px;
  }
}
#blog-detail .produtos-slider .wrapper-slider {
  width: 87vw;
  padding-inline: 10vw;
  margin: 0 auto;
  padding-top: 88px;
}
@media (max-width: 1100px) {
  #blog-detail .produtos-slider .wrapper-slider {
    width: 100%;
    padding-inline: 0;
    padding-top: 30px;
  }
}
#blog-detail .produtos-slider .mySwiper3 .swiper-button-next,
#blog-detail .produtos-slider .mySwiper3 .swiper-rtl .swiper-button-prev {
  left: auto;
  width: 54px;
  height: 54px;
  background: #005DB1;
}
#blog-detail .produtos-slider .mySwiper3 .swiper-button-next::after,
#blog-detail .produtos-slider .mySwiper3 .swiper-rtl .swiper-button-prev::after {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
@media (max-width: 1100px) {
  #blog-detail .produtos-slider .mySwiper3 .swiper-button-next,
#blog-detail .produtos-slider .mySwiper3 .swiper-rtl .swiper-button-prev {
    right: 0;
    height: 65px;
  }
}
#blog-detail .produtos-slider .mySwiper3 .swiper-button-prev,
#blog-detail .produtos-slider .mySwiper3 .swiper-rtl .swiper-button-next {
  right: auto;
  width: 54px;
  height: 54px;
  background: #005DB1;
}
#blog-detail .produtos-slider .mySwiper3 .swiper-button-prev::after,
#blog-detail .produtos-slider .mySwiper3 .swiper-rtl .swiper-button-next::after {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
@media (max-width: 1100px) {
  #blog-detail .produtos-slider .mySwiper3 .swiper-button-prev,
#blog-detail .produtos-slider .mySwiper3 .swiper-rtl .swiper-button-next {
    left: 0;
    height: 65px;
  }
}
@media (max-width: 1100px) {
  #blog-detail .produtos-slider .mySwiper3 .card-product {
    max-width: 338px;
    margin: 0 auto;
    background: #f3f3f3;
  }
}
#blog-detail .produtos-slider .mySwiper3 .card-product:hover .wrapper-img img {
  transform: scale(1.1);
}
#blog-detail .produtos-slider .mySwiper3 .card-product .wrapper-img {
  width: 100%;
  height: 227px;
  overflow: hidden;
}
#blog-detail .produtos-slider .mySwiper3 .card-product img {
  object-fit: cover;
  transition: all 0.3s ease-out;
}
#blog-detail .produtos-slider .mySwiper3 .card-product .text-content {
  padding-left: 30px;
  padding-right: 27px;
}
@media (max-width: 1100px) {
  #blog-detail .produtos-slider .mySwiper3 .card-product .text-content {
    padding-left: 49px;
    padding-right: 100px;
  }
}
#blog-detail .produtos-slider .mySwiper3 .card-product .subtitle {
  color: #005DB1;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 4.8px;
}
@media (max-width: 1100px) {
  #blog-detail .produtos-slider .mySwiper3 .card-product .subtitle {
    font-size: 15px;
  }
}
#blog-detail .produtos-slider .mySwiper3 .card-product .title {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-top: 27px;
}
#blog-detail .produtos-slider .mySwiper3 .card-product p {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 22px;
}
@media (max-width: 1100px) {
  #blog-detail .produtos-slider .mySwiper3 .card-product p {
    font-size: 16px;
  }
}
#blog-detail .produtos-slider .mySwiper3 .card-product .detail {
  margin-top: 24px;
  width: 100%;
  background: #005DB1;
  color: #fff;
  padding-left: 30px;
  padding-right: 33px;
  display: flex;
  justify-content: space-between;
  height: 64px;
  align-items: center;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  overflow: hidden;
  position: relative;
  isolation: isolate;
}
#blog-detail .produtos-slider .mySwiper3 .card-product .detail span {
  position: relative;
  z-index: 9;
}
#blog-detail .produtos-slider .mySwiper3 .card-product .detail::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  z-index: -1;
}
@media (max-width: 1100px) {
  #blog-detail .produtos-slider .mySwiper3 .card-product .detail {
    font-size: 18px;
  }
}
#blog-detail .produtos-slider .mySwiper3 .card-product .detail img {
  height: 16px;
  width: 23px;
  z-index: 9;
  position: relative;
}
#blog-detail .produtos-slider .mySwiper3 .card-product .detail:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}
#blog-detail p,
#blog-detail ul,
#blog-detail a {
  margin: 0;
  padding: 0;
  font-family: inherit;
  color: inherit;
}
#blog-detail .voltar {
  color: #005DB1;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  width: 100%;
  display: block;
  margin-block: 120px;
}
@media (max-width: 1100px) {
  #blog-detail .voltar {
    margin-block: 60px;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

#bannerHero {
  height: min(100vh, 720px);
  min-height: 570px;
  padding: 195px 0 152px;
  position: relative;
  isolation: isolate;
}
@media (max-height: 650px) {
  #bannerHero {
    display: flex;
    align-items: center;
    padding: 108px 0 0 0 !important;
  }
}
@media (max-width: 1100px) {
  #bannerHero {
    padding: 120px 25px 50px !important;
    height: auto;
    min-height: auto;
  }
}
#bannerHero::after {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(83deg, #003C65 38.12%, rgba(0, 60, 101, 0) 89.54%);
  mix-blend-mode: multiply;
  z-index: -1;
}
#bannerHero .banner-img {
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -2;
}
#bannerHero .banner-container {
  max-width: 631px;
  color: var(--San-Martin-White, #FFF);
  z-index: 1;
}
@media (max-height: 600px) {
  #bannerHero .banner-container {
    padding-block: 32px;
  }
}
@media (max-width: 1100px) {
  #bannerHero .banner-container {
    padding-block: 0 !important;
  }
}
#bannerHero .banner-container .main-sub-title {
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 375% */
  letter-spacing: 8px;
  text-transform: uppercase;
}
#bannerHero .banner-container .main-title {
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 95px;
  font-style: normal;
  font-weight: 600;
  line-height: 80px;
  /* 84.211% */
  margin-block: 12px 54px;
}
#bannerHero .banner-container .text {
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
}
@media (width < 1100px) {
  #bannerHero {
    min-height: auto;
    padding: 144px 25px 49px;
  }
  #bannerHero .container {
    margin: 0;
    padding: 0;
  }
  #bannerHero .banner-container {
    max-width: 100%;
  }
  #bannerHero .banner-container .main-sub-title {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    /* 461.538% */
    letter-spacing: 6.5px;
  }
  #bannerHero .banner-container .main-title {
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    margin-block-start: 0;
    margin-bottom: 20px;
  }
  #bannerHero .banner-container .text {
    font-size: 16px !important;
    font-weight: 400;
    line-height: 22px !important;
    max-width: 631px;
  }
}

.breadcumbs {
  background: #F3F3F3;
  padding: 25px 77px;
}
@media (max-width: 1100px) {
  .breadcumbs {
    padding-inline: 25px;
  }
}
.breadcumbs .wrapper {
  max-width: 1280px;
  margin: 0 auto;
}
.breadcumbs ul {
  display: flex;
  align-items: center;
  gap: 21px;
}
@media (max-width: 1100px) {
  .breadcumbs ul {
    gap: 12px;
  }
}
.breadcumbs ul li {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 21px;
}
@media (max-width: 1100px) {
  .breadcumbs ul li {
    gap: 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
  }
  .breadcumbs ul li svg {
    height: 15px;
  }
}
.breadcumbs ul .active {
  color: #005DB1;
}
.breadcumbs ul .active svg {
  fill: #005DB1;
}
.breadcumbs ul .active svg path {
  fill: #005DB1;
}

#culture {
  overflow: hidden;
}
#culture #bannerHero .banner-container {
  max-width: 710px;
}
#culture #bannerHero .banner-container .text {
  max-width: 631px;
}
#culture .compromissos {
  display: flex;
  justify-content: center;
  padding-inline: 77px;
}
@media (width < 1100px) {
  #culture .compromissos {
    padding-inline: 0;
  }
}
#culture .compromissos-container {
  max-width: 1512px;
  display: flex;
  flex-direction: column;
  gap: 47px;
  margin-block: 68px 152px;
}
@media (width < 1100px) {
  #culture .compromissos-container {
    gap: 60px;
    margin-block: 44px 88px;
  }
}
#culture .compromissos-container .img-and-text .content-text {
  max-width: 585px;
}
@media (max-width: 1100px) {
  #culture .compromissos-container .img-and-text .content-text {
    max-width: 100%;
    padding-inline: 25px;
  }
}
#culture .compromissos-container .img-and-text .wrapper {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
@media (max-width: 1100px) {
  #culture .compromissos-container .img-and-text .wrapper {
    flex-direction: column;
    gap: 60px;
  }
}
#culture .compromissos-container .img-and-text .title {
  margin-block: 0 42px;
}
@media (max-width: 1100px) {
  #culture .compromissos-container .img-and-text .title {
    margin-block: 4px 32px;
  }
}
#culture .compromissos-container .img-and-text .text {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
@media (max-width: 1100px) {
  #culture .compromissos-container .img-and-text .text {
    color: #575756;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
  }
}
#culture .compromissos-container .img-and-text .right-img-hold {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
#culture .compromissos-container .img-and-text .img-holder {
  height: 516px;
  aspect-ratio: 1.44;
  transform: translate(107px, 0px);
  overflow: hidden;
}
@media (max-width: 1100px) {
  #culture .compromissos-container .img-and-text .img-holder {
    transform: translate(0, 0);
    width: 100%;
    height: 285px;
  }
}
#culture .compromissos-container .img-and-text .img-holder:hover img {
  transform: scale(1.1);
}
#culture .compromissos-container .img-and-text .img-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}
#culture .compromissos-container .img-and-text .img-holder .right-img {
  height: 19.323vw;
}
@media (max-width: 1100px) {
  #culture .compromissos-container .img-and-text .img-holder .right-img {
    height: 100%;
  }
}
#culture .compromissos-container .img-and-text .img-holder .text-big {
  font-size: 150px;
  color: transparent;
  -webkit-text-stroke: 1px #8f8f8f;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 220px;
}
@media (max-width: 1100px) {
  #culture .compromissos-container .img-and-text .img-holder .text-big {
    display: none;
  }
}
#culture .compromissos-container .img-and-text .img-holder.small {
  width: 744px;
  height: 341px;
}
#culture .compromissos-container .reverse .wrapper {
  flex-direction: row-reverse;
}
@media (max-width: 1100px) {
  #culture .compromissos-container .reverse .wrapper {
    flex-direction: column-reverse;
  }
}
#culture .compromissos-container .reverse .wrapper .content-text {
  padding-top: 30px;
}
@media (max-width: 1100px) {
  #culture .compromissos-container .reverse .wrapper .content-text {
    padding-top: 0;
  }
}
@media (max-width: 1100px) {
  #culture .compromissos-container .reverse .wrapper .img-holder {
    display: none;
  }
}
#culture .compromissos-container .reverse .img-holder {
  transform: translate(-107px, 0px);
}
@media (max-width: 1100px) {
  #culture .compromissos-container .reverse .img-holder {
    transform: translate(0);
  }
}
#culture .compromissos .mask-text {
  font-size: 154px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  color: transparent;
  -webkit-text-stroke: 2px #8F8F8F;
  -webkit-background-clip: text;
  background-clip: text;
  position: relative;
  right: -99px;
  text-align: end;
  top: -63px;
  width: 100%;
}
#culture .praticas {
  margin-right: 63px;
  height: 856px;
  position: relative;
}
@media (max-width: 1100px) {
  #culture .praticas {
    margin-right: 0;
    height: 422px;
  }
}
#culture .praticas-floating-div {
  position: absolute;
  top: -1px;
  left: -71px;
  background-color: #fff;
  width: min(75vw, 1037px);
  height: 572px;
  padding: 0px 107px 145px;
  z-index: 1;
  filter: drop-shadow(20px 70px 30px rgba(0, 0, 0, 0.1));
  display: flex;
  justify-content: end;
}
@media (max-width: 1100px) {
  #culture .praticas-floating-div {
    display: none;
  }
}
#culture .praticas-floating-div .floating-wrapper {
  max-width: 656px;
}
#culture .praticas-floating-div .floating-wrapper .title {
  max-width: 305px;
  margin-block: 4px 42px;
}
#culture .praticas-floating-div .floating-wrapper .text {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
  margin-bottom: 42px;
}
#culture .praticas .bg-img {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}
#culture .slide {
  position: relative;
  padding: 146px 0 201px 0;
  background: linear-gradient(138deg, #005DB1 -11.68%, #0C3E62 68.14%);
}
#culture .slide h2 {
  color: #FFF;
  text-align: center;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
}
#culture .slide h2 strong {
  color: #FFF;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
}
@media (max-width: 1100px) {
  #culture .slide h2 {
    font-size: 35px;
    line-height: 40px;
  }
  #culture .slide h2 strong {
    font-size: inherit;
    line-height: inherit;
    color: inerit;
  }
}
#culture .slide h3 {
  color: #F0F0F0;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 8px;
}
@media (max-width: 1100px) {
  #culture .slide h3 {
    font-size: 15px;
    line-height: 60px;
    letter-spacing: 50%;
  }
}
#culture .slide .mask-text {
  font-size: 154px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  color: transparent;
  -webkit-text-stroke: 2px #FFF;
  -webkit-background-clip: text;
  background-clip: text;
  position: absolute;
  right: 100px;
  text-align: end;
  top: -78px;
  width: 100%;
}
@media (max-width: 1100px) {
  #culture .slide .mask-text {
    -webkit-text-stroke: 1px #FFF;
    font-size: 70px;
    line-height: 50px;
    text-align: end;
    right: 15px;
    top: -21px;
  }
}
#culture .slide .blocos {
  margin: 0 auto;
  margin-top: 91px;
  max-width: 1166px;
  position: relative;
}
@media (max-width: 1100px) {
  #culture .slide .blocos {
    margin-top: 60px;
  }
  #culture .slide .blocos .col-lg-3 {
    display: flex !important;
    justify-content: center;
  }
}
#culture .slide .bloco {
  text-align: center;
  padding: 41px 14px;
  max-width: 272px;
  min-height: 342px;
  background: #FFF;
}
#culture .slide .bloco img {
  margin: 0 auto;
  margin-bottom: 24px;
}
#culture .slide .bloco .titulo {
  color: #575756;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 20px;
}
#culture .slide .bloco .descricao {
  color: #575756;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
#culture .slide .bloco-prev,
#culture .slide .bloco-next {
  position: absolute;
  top: 42%;
  cursor: pointer;
}
@media (max-width: 1100px) {
  #culture .slide .bloco-prev,
#culture .slide .bloco-next {
    position: initial;
  }
}
#culture .slide .bloco-prev svg, #culture .slide .bloco-prev svg path,
#culture .slide .bloco-next svg,
#culture .slide .bloco-next svg path {
  transition: all 0.3s ease;
}
#culture .slide .bloco-prev:hover svg,
#culture .slide .bloco-next:hover svg {
  fill: #fff;
}
#culture .slide .bloco-prev:hover path,
#culture .slide .bloco-next:hover path {
  fill: #005DB1;
}
#culture .slide .bloco-prev {
  left: -113px;
}
#culture .slide .bloco-next {
  right: -113px;
}
@media (max-width: 1100px) {
  #culture .slide .bloco-next {
    translate: 0 -1px;
  }
}
@media (max-width: 1100px) {
  #culture .slide {
    padding: 84px 0 60px;
  }
  #culture .slide .container {
    max-width: 100% !important;
  }
  #culture .slide .arrow-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block-start: 60px;
  }
}
#culture .banner-footer {
  padding: 124px 0 119px 0;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 1100px) {
  #culture .banner-footer {
    padding: 60px 0 60px;
  }
  #culture .banner-footer .col-lg-6 {
    padding-inline: 25px;
  }
}
#culture .banner-footer::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background: #8AB4DB;
  mix-blend-mode: multiply;
  z-index: 1;
}
#culture .banner-footer .col-lg-10.m-auto {
  max-width: 1018px;
  position: relative;
  z-index: 2;
}
#culture .banner-footer h3 {
  color: #F0F0F0;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 8px;
}
@media (max-width: 1100px) {
  #culture .banner-footer h3 {
    font-size: 15px;
    line-height: 60px;
    letter-spacing: 50%;
  }
}
#culture .banner-footer h2 {
  color: #FFF;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 55px;
  margin-bottom: 31px;
}
#culture .banner-footer h2 strong {
  color: #FFF;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
}
@media (max-width: 1100px) {
  #culture .banner-footer h2 {
    font-size: 35px;
    line-height: 40px;
  }
  #culture .banner-footer h2 strong {
    font-size: inherit;
    line-height: inherit;
    color: inerit;
  }
}
#culture .banner-footer .link {
  margin-top: 24px;
  width: 100%;
  max-width: 301px;
  background: #005DB1;
  color: #fff;
  padding-left: 30px;
  padding-right: 33px;
  display: flex;
  justify-content: space-between;
  height: 64px;
  align-items: center;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  overflow: hidden;
  position: relative;
  isolation: isolate;
}
#culture .banner-footer .link span {
  position: relative;
  z-index: 9;
}
#culture .banner-footer .link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  z-index: -1;
}
@media (max-width: 1100px) {
  #culture .banner-footer .link {
    font-size: 20px;
    width: 100%;
    margin-top: 26px;
    transform: translateY(172px);
  }
}
#culture .banner-footer .link img {
  height: 16px;
  width: 23px;
  z-index: 9;
  position: relative;
}
#culture .banner-footer .link:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}
#culture .banner-footer p {
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 393px;
  float: right;
  margin-top: 69px;
}
@media (max-width: 1100px) {
  #culture .banner-footer p {
    max-width: 100%;
    margin-top: 34px;
    transform: translateY(-102px);
  }
}
#culture .news {
  background: #fff;
  min-height: 95dvh;
  padding-top: 242px;
  position: relative;
}
#culture .news::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 20dvh;
  background: linear-gradient(90deg, #005DB1 -11.68%, #0C3E62 68.14%);
  bottom: 0;
  left: 0;
}
@media (max-width: 1100px) {
  #culture .news::after {
    display: none;
  }
}
#culture .news .title-big {
  font-size: 154px;
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #8f8f8f;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 220px;
  position: absolute;
  top: 18px;
  left: 24px;
  z-index: 9;
}
@media (max-width: 1100px) {
  #culture .news .title-big {
    font-size: 85px;
    line-height: 80px;
    left: 0;
    top: 40px;
    -webkit-text-stroke-width: 1px;
  }
}
#culture .news .container-news {
  max-width: 85vw;
  margin: 0 auto;
  position: relative;
  z-index: 9;
}
@media (max-width: 1100px) {
  #culture .news .container-news {
    max-width: 100%;
  }
}
#culture .news .container-news .parent {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 83.5dvh;
}
@media (max-width: 1100px) {
  #culture .news .container-news .parent {
    height: auto;
    display: flex;
    flex-direction: column;
  }
  #culture .news .container-news .parent .div1,
#culture .news .container-news .parent .div2,
#culture .news .container-news .parent .div3,
#culture .news .container-news .parent .div4,
#culture .news .container-news .parent .div5 {
    aspect-ratio: 1;
  }
}
#culture .news .container-news .div1 {
  grid-area: 1/1/5/5;
  position: relative;
}
#culture .news .container-news .div2 {
  grid-area: 1/5/3/7;
  position: relative;
}
#culture .news .container-news .div2 .content-text {
  width: 70%;
}
#culture .news .container-news .div3 {
  grid-area: 1/7/3/9;
  position: relative;
}
#culture .news .container-news .div3 .content-text {
  width: 70%;
}
#culture .news .container-news .div4 {
  grid-area: 3/5/5/7;
  position: relative;
}
#culture .news .container-news .div4 .content-text {
  width: 70%;
}
#culture .news .container-news .div5 {
  grid-area: 3/7/5/9;
  position: relative;
}
#culture .news .container-news .div5 .content-text {
  width: 70%;
}
#culture .news .container-news .div1,
#culture .news .container-news .div2,
#culture .news .container-news .div3,
#culture .news .container-news .div4,
#culture .news .container-news .div5 {
  position: relative;
}
#culture .news .container-news .div1::after,
#culture .news .container-news .div2::after,
#culture .news .container-news .div3::after,
#culture .news .container-news .div4::after,
#culture .news .container-news .div5::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(210deg, rgba(0, 93, 177, 0) 40.33%, rgba(0, 93, 177, 0.8) 65.7%);
  mix-blend-mode: multiply;
  transition: all 0.3s ease-out;
  will-change: opacity;
  opacity: 0;
}
#culture .news .container-news .div1:hover::after,
#culture .news .container-news .div2:hover::after,
#culture .news .container-news .div3:hover::after,
#culture .news .container-news .div4:hover::after,
#culture .news .container-news .div5:hover::after {
  opacity: 1;
}
#culture .news .wrapper-img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
#culture .news .wrapper-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#culture .news .wrapper-img::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(210deg, rgba(1, 20, 37, 0) 40.33%, rgba(1, 20, 37, 0.8) 65.7%);
  mix-blend-mode: multiply;
}
#culture .news .content-text {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 25px;
  padding-bottom: 30px;
  width: 75%;
  gap: 18px;
  position: relative;
  z-index: 9;
}
#culture .news .subtitle {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 8px;
  text-transform: uppercase;
}
#culture .news .title {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 100% */
}
#culture .news .text {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
}
#culture .news .tag {
  position: absolute;
  top: 0;
  left: 0;
  height: 51px;
  padding-inline: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #005DB1;
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 4.8px;
  text-transform: uppercase;
}
#culture .news .read-more {
  color: var(--san-martin-white, #FFF);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
  display: flex;
  gap: 32px;
  align-items: center;
  height: 0px;
  opacity: 0;
}
#culture .news .date {
  position: absolute;
  right: 26px;
  bottom: 24px;
  color: #FFF;
  /* H6 - textBody */
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
  z-index: 99;
  opacity: 0;
}
#culture .sub-title {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 375% */
  letter-spacing: 8px;
  text-transform: uppercase;
}
@media (max-width: 1100px) {
  #culture .sub-title {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    /* 400% */
    letter-spacing: 7.5px;
  }
}
#culture .title {
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
  /* 100% */
}
#culture .title strong {
  font-weight: bold;
}
@media (max-width: 1100px) {
  #culture .title {
    font-size: 35px;
    font-style: normal;
    font-weight: 300;
    line-height: 35px;
    /* 100% */
  }
}

#budget .info {
  padding: 68px 77px 126px;
  display: flex;
  justify-content: center;
}
@media (max-width: 1100px) {
  #budget .info {
    padding: 44px 0 91px;
  }
  #budget .info .img-and-text {
    overflow: auto;
  }
}
#budget .info .img-and-text {
  overflow: hidden;
}
#budget .info .img-and-text .content-text {
  max-width: 541px;
}
@media (max-width: 1100px) {
  #budget .info .img-and-text .content-text {
    max-width: 100%;
    padding-inline: 25px;
  }
}
#budget .info .img-and-text .wrapper {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
@media (max-width: 1100px) {
  #budget .info .img-and-text .wrapper {
    flex-direction: column;
    gap: 60px;
  }
}
#budget .info .img-and-text .subtitle {
  color: #575756;
  margin-bottom: 20px;
}
#budget .info .img-and-text .title {
  margin-bottom: 42px;
}
#budget .info .img-and-text .title strong {
  font-weight: bold;
}
@media (max-width: 1100px) {
  #budget .info .img-and-text .title {
    margin-bottom: 32px;
  }
}
#budget .info .img-and-text .text {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
@media (max-width: 1100px) {
  #budget .info .img-and-text .text {
    color: #575756;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
  }
}
#budget .info .img-and-text .img-holder {
  height: 516px;
  aspect-ratio: 1.44;
  transform: translate(107px, 0px);
  overflow: hidden;
}
@media (max-width: 1400px) {
  #budget .info .img-and-text .img-holder {
    transform: translate(40px, 0);
  }
}
@media (max-width: 1100px) {
  #budget .info .img-and-text .img-holder {
    transform: translate(0, 0);
    max-width: 500px;
    height: 285px;
    margin-inline: auto;
  }
}
#budget .info .img-and-text .img-holder:hover img {
  transform: scale(1.1);
}
#budget .info .img-and-text .img-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}
#budget .contact {
  padding: 151px 77px 158px 320px;
  background: #F0F0F0;
  position: relative;
}
#budget .contact-container {
  max-width: 1069px;
}
#budget .contact-container--text {
  max-width: 297px;
  margin-bottom: 56px;
}
#budget .contact-container--text .title {
  margin-block: 1px 26px;
}
#budget .contact-container--forms .form-row + .form-row {
  margin-top: 20px;
}
#budget .contact-container--forms .form-row {
  display: flex;
  gap: 20px;
}
#budget .contact-container--forms .form-row .form-control,
#budget .contact-container--forms .form-row .form-select {
  padding-inline: 27px;
  border: 2px solid transparent;
  border-radius: 0;
}
#budget .contact-container--forms .form-row .form-control, #budget .contact-container--forms .form-row .form-control::placeholder,
#budget .contact-container--forms .form-row .form-select,
#budget .contact-container--forms .form-row .form-select::placeholder {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  /* 333.333% */
}
#budget .contact-container--forms .form-row .form-control:focus,
#budget .contact-container--forms .form-row .form-select:focus {
  box-shadow: none;
  border-bottom: 2px solid #005DB1;
}
#budget .contact-container--forms .form-row .form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='gray' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right 24px center;
  background-repeat: no-repeat;
  background-size: 24px;
}
#budget .contact-container--forms .form-row .form-select option {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
}
#budget .contact-container--forms .form-row textarea {
  height: 220px;
}
#budget .contact-container--forms .one-third > .input-wrapper:first-child {
  flex-grow: 1;
}
#budget .contact-container--forms .one-third > .input-wrapper:last-child {
  width: 347px;
}
#budget .contact-container--forms .three > .input-wrapper {
  flex-grow: 1;
}
#budget .contact-container--forms .three > .input-wrapper:nth-child(2) {
  width: min(100%, 345px) !important;
}
#budget .contact-container--forms .half > .input-wrapper, #budget .contact-container--forms .full-width > .input-wrapper {
  flex-grow: 1;
}
#budget .contact-container--forms .form-check {
  margin-block: 26px 56px;
  display: flex;
  align-items: center;
}
#budget .contact-container--forms .form-check-input {
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 1.5px solid var(--Support, #8F8F8F);
  margin-right: 24px;
}
#budget .contact-container--forms .form-check-label {
  color: var(--Support, #8F8F8F);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
#budget .contact-container--forms .form-check-label a {
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  text-decoration-line: underline;
}
#budget .contact .mask-text {
  font-size: 154px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  color: transparent;
  -webkit-text-stroke: 2px #8F8F8F;
  -webkit-background-clip: text;
  background-clip: text;
  position: absolute;
  left: 56px;
  top: -95px;
  text-align: start;
}
@media (width < 1570px) {
  #budget .contact {
    padding-left: 77px;
  }
}
@media (width < 1100px) {
  #budget .contact {
    padding: 76px 25px 38px;
  }
  #budget .contact-container--text {
    text-align: center;
    max-width: 100%;
    margin-bottom: 60px;
  }
  #budget .contact-container--text .title {
    margin-block-end: 20px;
  }
  #budget .contact-container--text .text {
    font-size: 16px;
    line-height: 22px;
    max-width: 240px;
    margin-inline: auto;
  }
  #budget .contact-container--forms .form-row {
    flex-direction: column;
  }
  #budget .contact-container--forms .input-wrapper {
    flex-grow: 1 !important;
    width: 100% !important;
  }
  #budget .contact-container--forms .three > .input-wrapper:nth-child(2) {
    width: 100% !important;
  }
  #budget .contact-container--forms .form-check {
    margin-block: 20px 40px;
  }
  #budget .contact-container--forms .form-check-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  #budget .contact-container--forms .form-check-label a {
    font-size: inherit;
    font-family: inherit;
    color: inherit;
  }
  #budget .contact-container--forms button {
    width: 100%;
  }
  #budget .contact .mask-text {
    font-size: 70px;
    -webkit-text-stroke: 1px #8F8F8F;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    left: auto;
    right: 5px;
    top: -33px;
    text-align: end;
  }
}
#budget .produtos-slider {
  padding-block: 146px 152px;
}
@media (width < 1100px) {
  #budget .produtos-slider {
    padding-block: 60px;
  }
  #budget .produtos-slider .btn-ver-mais {
    display: none;
  }
}
#budget .produtos-slider .card-product {
  background-color: #F3F3F3;
}
#budget .produtos-slider .card-product .title {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 100% */
}
#budget .sub-title {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 375% */
  letter-spacing: 8px;
  text-transform: uppercase;
}
@media (width < 1100px) {
  #budget .sub-title {
    font-size: 15px;
    line-height: 60px;
    /* 400% */
    letter-spacing: 7.5px;
  }
}
#budget .title {
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
  /* 100% */
}
#budget .title strong {
  font-weight: bold;
}
@media (width < 1100px) {
  #budget .title {
    font-size: 35px;
    line-height: 40px;
  }
  #budget .title strong {
    font-size: inherit;
    line-height: inherit;
  }
}
#budget .text {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 111.111% */
}
#budget .default-btn {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
  width: 216px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #005DB1;
  transition: all 0.3s ease-out;
  position: relative;
  isolation: isolate;
}
#budget .default-btn span {
  position: relative;
  z-index: 9;
}
#budget .default-btn::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  z-index: -1;
}
#budget .default-btn:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}
@media (max-width: 1100px) {
  #budget .default-btn {
    width: 100%;
  }
}

#assistance .info {
  padding: 68px 77px 126px;
  display: flex;
  justify-content: center;
}
@media (max-width: 1100px) {
  #assistance .info {
    padding: 44px 0 91px;
  }
  #assistance .info .img-and-text {
    overflow: auto;
  }
}
#assistance .info .img-and-text {
  overflow: hidden;
}
#assistance .info .img-and-text .content-text {
  max-width: 541px;
}
@media (max-width: 1100px) {
  #assistance .info .img-and-text .content-text {
    max-width: 100%;
    padding: 0 25px !important;
  }
}
#assistance .info .img-and-text .wrapper {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
@media (max-width: 1100px) {
  #assistance .info .img-and-text .wrapper {
    flex-direction: column !important;
    gap: 60px !important;
  }
}
#assistance .info .img-and-text .subtitle {
  color: #575756;
  margin-bottom: 20px;
}
#assistance .info .img-and-text .title {
  margin-bottom: 42px;
}
#assistance .info .img-and-text .title strong {
  font-weight: bold;
}
@media (max-width: 1100px) {
  #assistance .info .img-and-text .title {
    margin-bottom: 32px;
  }
}
#assistance .info .img-and-text .text {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
@media (max-width: 1100px) {
  #assistance .info .img-and-text .text {
    color: #575756;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
  }
}
#assistance .info .img-and-text .img-holder {
  height: 516px;
  aspect-ratio: 1.44;
  transform: translate(-107px, 0px);
  overflow: hidden;
}
@media (max-width: 1400px) {
  #assistance .info .img-and-text .img-holder {
    transform: translate(-40px, 0);
  }
}
@media (max-width: 1100px) {
  #assistance .info .img-and-text .img-holder {
    transform: translate(0, 0);
    max-width: 500px;
    height: 285px;
    margin-inline: auto;
  }
}
#assistance .info .img-and-text .img-holder:hover img {
  transform: scale(1.1);
}
#assistance .info .img-and-text .img-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}
#assistance .info .reverse .wrapper {
  flex-direction: row-reverse;
}
#assistance .info .reverse .wrapper .content-text {
  padding-top: 30px;
}
#assistance .contact {
  padding: 151px 77px 158px 320px;
  background: #F0F0F0;
  position: relative;
}
#assistance .contact-container {
  max-width: 1069px;
}
#assistance .contact-container--text {
  max-width: 297px;
  margin-bottom: 56px;
}
#assistance .contact-container--text .title {
  margin-block: 1px 26px;
}
#assistance .contact-container--forms .form-row + .form-row {
  margin-top: 20px;
}
#assistance .contact-container--forms .form-row {
  display: flex;
  gap: 20px;
}
#assistance .contact-container--forms .form-row .form-control,
#assistance .contact-container--forms .form-row .form-select {
  padding-inline: 27px;
  border: 2px solid transparent;
  border-radius: 0;
}
#assistance .contact-container--forms .form-row .form-control, #assistance .contact-container--forms .form-row .form-control::placeholder,
#assistance .contact-container--forms .form-row .form-select,
#assistance .contact-container--forms .form-row .form-select::placeholder {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  /* 333.333% */
}
#assistance .contact-container--forms .form-row .form-control:focus,
#assistance .contact-container--forms .form-row .form-select:focus {
  box-shadow: none;
  border-bottom: 2px solid #005DB1;
}
#assistance .contact-container--forms .form-row .form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right 24px center;
  background-repeat: no-repeat;
  background-size: 24px;
}
#assistance .contact-container--forms .form-row .form-select option {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
}
#assistance .contact-container--forms .one-third > .input-wrapper:first-child {
  flex-grow: 1;
}
#assistance .contact-container--forms .one-third > .input-wrapper:last-child {
  width: 347px;
}
#assistance .contact-container--forms .three > .input-wrapper {
  flex-grow: 1;
}
#assistance .contact-container--forms .three > .input-wrapper:nth-child(2) {
  width: min(100%, 345px) !important;
}
#assistance .contact-container--forms .half > .input-wrapper, #assistance .contact-container--forms .full-width > .input-wrapper {
  flex-grow: 1;
}
#assistance .contact-container--forms .radio-container {
  margin-top: 20px;
  display: flex;
}
#assistance .contact-container--forms .radio-container p, #assistance .contact-container--forms .radio-container .form-check-label {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  /* 333.333% */
}
#assistance .contact-container--forms .radio-container p {
  margin-right: 80px;
}
#assistance .contact-container--forms .radio-container > div {
  gap: 40px;
}
#assistance .contact-container--forms .radio-container .form-check-input {
  border-radius: 50%;
}
#assistance .contact-container--forms .radio-container .form-check-input:checked {
  background: #005DB1;
}
#assistance .contact-container--forms .form-check {
  margin-block: 20px 56px;
  display: flex;
  align-items: center;
}
#assistance .contact-container--forms .form-check-input {
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 1.5px solid var(--Support, #8F8F8F);
  margin-right: 24px;
}
#assistance .contact-container--forms .form-check-label {
  color: var(--Support, #8F8F8F);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
#assistance .contact-container--forms .form-check-label a {
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  text-decoration-line: underline;
}
#assistance .contact .mask-text {
  font-size: 154px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  color: transparent;
  -webkit-text-stroke: 2px #8F8F8F;
  -webkit-background-clip: text;
  background-clip: text;
  position: absolute;
  right: 56px;
  top: -95px;
  text-align: end;
}
@media (width < 1570px) {
  #assistance .contact {
    padding-left: 77px;
  }
}
@media (width < 1100px) {
  #assistance .contact {
    padding: 76px 25px 60px;
  }
  #assistance .contact-container--text {
    text-align: center;
    max-width: 100%;
    margin-bottom: 60px;
  }
  #assistance .contact-container--text .title {
    margin-block-end: 20px;
  }
  #assistance .contact-container--text .text {
    font-size: 16px;
    line-height: 22px;
    max-width: 240px;
    margin-inline: auto;
  }
  #assistance .contact-container--forms .form-row {
    flex-direction: column;
  }
  #assistance .contact-container--forms .input-wrapper {
    flex-grow: 1 !important;
    width: 100% !important;
  }
  #assistance .contact-container--forms .three > .input-wrapper:nth-child(2) {
    width: 100% !important;
  }
  #assistance .contact-container--forms .radio-container {
    flex-direction: column;
  }
  #assistance .contact-container--forms .radio-container p {
    font-size: 18px;
    line-height: 60px;
  }
  #assistance .contact-container--forms .radio-container .form-check-label {
    font-size: 14px;
    line-height: 50px;
  }
  #assistance .contact-container--forms .radio-container > div {
    justify-content: space-between;
    gap: 20px;
    width: 100%;
  }
  #assistance .contact-container--forms .form-check {
    margin-block: 40px;
  }
  #assistance .contact-container--forms .form-check-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  #assistance .contact-container--forms .form-check-label a {
    font-size: inherit;
    font-family: inherit;
    color: inherit;
  }
  #assistance .contact-container--forms button {
    width: 100%;
  }
  #assistance .contact .mask-text {
    font-size: 70px;
    -webkit-text-stroke: 1px #8F8F8F;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    left: auto;
    right: 5px;
    top: -33px;
    text-align: end;
  }
}
#assistance .produtos-slider {
  padding-block: 146px 152px;
}
@media (width < 1100px) {
  #assistance .produtos-slider {
    padding-block: 60px 0;
  }
}
#assistance .produtos-slider .card-product {
  background-color: #F3F3F3;
}
#assistance .produtos-slider .card-product .title {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 100% */
}
#assistance .sub-title {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 375% */
  letter-spacing: 8px;
  text-transform: uppercase;
}
@media (width < 1100px) {
  #assistance .sub-title {
    font-size: 15px;
    line-height: 60px;
    /* 400% */
    letter-spacing: 7.5px;
  }
}
#assistance .title {
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
  /* 100% */
}
#assistance .title strong {
  font-weight: bold;
}
@media (width < 1100px) {
  #assistance .title {
    font-size: 35px;
    line-height: 40px;
  }
  #assistance .title strong {
    font-size: inherit;
    line-height: inherit;
  }
}
#assistance .text {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 111.111% */
}
#assistance .default-btn {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
  width: 216px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #005DB1;
  transition: all 0.3s ease-out;
  position: relative;
  isolation: isolate;
}
#assistance .default-btn span {
  position: relative;
  z-index: 9;
}
#assistance .default-btn::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  z-index: -1;
}
#assistance .default-btn:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}
@media (max-width: 1100px) {
  #assistance .default-btn {
    width: 100%;
  }
}

@media (width < 1100px) {
  #downloads {
    padding-top: 84px;
  }
}
@media (width < 1100px) {
  #downloads #bannerHero {
    padding-top: 55px;
    min-height: 420px;
  }
}
#downloads .info {
  padding: 64.8px 77px 156px 318px;
}
@media (width < 1570px) {
  #downloads .info {
    padding-left: 77px;
  }
}
@media (width < 1100px) {
  #downloads .info {
    padding-inline: 25px;
    padding-top: 44px;
    padding-bottom: 80px;
  }
}
#downloads .info-container {
  max-width: 520px;
}
#downloads .info-container .title {
  margin-block-end: 42px;
}
#downloads .info-container .text {
  line-height: 25px;
}
#downloads .downloads {
  padding: 123px 77px 199px;
  background: #F0F0F0;
  text-align: center;
  position: relative;
}
@media (width < 1100px) {
  #downloads .downloads {
    padding-inline: 25px;
    padding-bottom: 70px;
  }
}
#downloads .downloads .btns-swiper-card {
  display: none;
}
@media (width < 1100px) {
  #downloads .downloads .btns-swiper-card {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }
  #downloads .downloads .btns-swiper-card .btn-prev-card {
    transform: translate(2px, 0px);
  }
  #downloads .downloads .btns-swiper-card .btn-next-card {
    transform: rotate(180deg);
  }
}
#downloads .downloads .title {
  margin-block: 4px 60px;
}
@media (width < 1100px) {
  #downloads .downloads .title {
    margin-bottom: 20px;
  }
}
#downloads .downloads--filter {
  margin-bottom: 60px;
  max-width: 253px;
  margin-inline: auto;
}
@media (width < 1100px) {
  #downloads .downloads--filter {
    max-width: inherit;
    margin-inline: auto;
    margin: 0 auto;
  }
}
#downloads .downloads--filter .dropdown {
  position: relative;
}
@media (width < 1100px) {
  #downloads .downloads--filter .dropdown {
    margin-bottom: 60px;
  }
}
#downloads .downloads--filter .dropdown-toggle {
  height: 64px;
  width: 100%;
  padding: 20px;
  background: var(--Support, #8F8F8F);
  text-align: center;
  color: var(--San-Martin-White, #FFF);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 100% */
  letter-spacing: 4.8px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#downloads .downloads--filter .dropdown-toggle::after {
  display: none;
}
#downloads .downloads--filter .dropdown-toggle .counter {
  background-color: #fff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  width: 26px;
  height: 26px;
}
@media (width < 1100px) {
  #downloads .downloads--filter .dropdown-toggle .counter {
    background-color: #fff;
    border-radius: 50%;
    padding: 0px;
    display: grid;
    padding-left: 2px;
    place-items: center;
    width: 24px;
    height: 24px;
  }
}
#downloads .downloads--filter .dropdown-toggle .counter span {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 100% */
  translate: 2px 0;
  width: 50%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (width < 1100px) {
  #downloads .downloads--filter .dropdown-toggle .counter span {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    translate: 1px 0;
    letter-spacing: 4px;
  }
}
#downloads .downloads--filter .dropdown-menu {
  min-width: 253px;
  border-radius: 0;
}
#downloads .downloads--filter .dropdown-menu .form-check-input {
  width: 12px;
  height: 12px;
  margin-right: 12px;
}
#downloads .downloads--cards {
  max-width: 1282px;
  margin-inline: auto;
  z-index: -1;
  position: relative;
}
#downloads .downloads--cards .cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300.9px, 0.1fr));
  gap: 26px;
  justify-content: start;
}
@media (width < 1100px) {
  #downloads .downloads--cards .cards-container {
    display: block;
  }
}
#downloads .downloads--cards .cards-container .card-product {
  background-color: #fff;
  width: 100%;
  text-align: left;
}
#downloads .downloads--cards .cards-container .card-product:hover .wrapper-img img {
  transform: scale(1.1);
}
#downloads .downloads--cards .cards-container .card-product .wrapper-img {
  width: 100%;
  height: 222;
  overflow: hidden;
  margin-bottom: 20px;
  display: grid;
  place-items: center;
}
#downloads .downloads--cards .cards-container .card-product img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-out;
}
#downloads .downloads--cards .cards-container .card-product .text-content {
  padding-left: 30px;
  padding-right: 27px;
}
@media (max-width: 1100px) {
  #downloads .downloads--cards .cards-container .card-product .text-content {
    padding-left: 49px;
    padding-right: 100px;
  }
}
#downloads .downloads--cards .cards-container .card-product .subtitle {
  color: #005DB1;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 4.8px;
}
@media (max-width: 1100px) {
  #downloads .downloads--cards .cards-container .card-product .subtitle {
    font-size: 15px;
  }
}
#downloads .downloads--cards .cards-container .card-product .title {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-block: 20px 0;
}
#downloads .downloads--cards .cards-container .card-product p {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 19px;
}
@media (max-width: 1100px) {
  #downloads .downloads--cards .cards-container .card-product p {
    font-size: 16px;
  }
}
#downloads .downloads--cards .cards-container .card-product .detail {
  margin-top: 25px;
  width: 100%;
  background: #005DB1;
  color: #fff;
  padding-left: 30px;
  padding-right: 33px;
  display: flex;
  justify-content: space-between;
  height: 62px;
  align-items: center;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  overflow: hidden;
  position: relative;
  isolation: isolate;
}
#downloads .downloads--cards .cards-container .card-product .detail span {
  position: relative;
  z-index: 9;
}
#downloads .downloads--cards .cards-container .card-product .detail::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  z-index: -1;
}
@media (max-width: 1100px) {
  #downloads .downloads--cards .cards-container .card-product .detail {
    font-size: 18px;
  }
}
#downloads .downloads--cards .cards-container .card-product .detail img {
  height: 16px;
  width: 23px;
  z-index: 9;
  position: relative;
}
#downloads .downloads--cards .cards-container .card-product .detail:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}
#downloads .downloads .mask-text {
  font-size: 154px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  color: transparent;
  -webkit-text-stroke: 2px #8F8F8F;
  -webkit-background-clip: text;
  background-clip: text;
  position: absolute;
  right: 56px;
  top: -103px;
  text-align: end;
}
@media (max-width: 1100px) {
  #downloads .downloads .mask-text {
    right: 15px;
    font-size: 18vw;
    -webkit-text-stroke: 1px #8F8F8F;
    top: -40px;
  }
}
#downloads .news {
  padding-top: 242px;
}
@media (max-width: 1100px) {
  #downloads .news {
    padding-top: 150px;
  }
}
#downloads .news .title-big {
  font-size: 154px;
  top: 10px;
  left: 22px;
}
@media (max-width: 1100px) {
  #downloads .news .title-big {
    font-size: 20vw;
    top: 30px;
    left: 22px;
  }
}
#downloads .sub-title {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 375% */
  letter-spacing: 8px;
  text-transform: uppercase;
}
@media (max-width: 1100px) {
  #downloads .sub-title {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    /* 400% */
    letter-spacing: 7.5px;
  }
}
#downloads .title {
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
  /* 100% */
}
#downloads .title strong {
  font-weight: bold;
}
@media (max-width: 1100px) {
  #downloads .title {
    font-size: 35px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px;
    /* 114.286% */
  }
}
#downloads .text {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
@media (max-width: 1100px) {
  #downloads .text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
  }
}
#downloads .default-btn {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
  width: 216px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #005DB1;
  transition: all 0.3s ease-out;
  position: relative;
  isolation: isolate;
}
#downloads .default-btn span {
  position: relative;
  z-index: 9;
}
#downloads .default-btn::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  z-index: -1;
}
#downloads .default-btn:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}
@media (max-width: 1100px) {
  #downloads .default-btn {
    width: 100%;
  }
}

.cta-contato {
  position: relative;
  height: 509px;
  background-color: #005DB1;
  isolation: isolate;
}
.cta-contato::after {
  content: "";
  position: absolute;
  inset: 0;
  background: #005DB1;
  opacity: 0.6;
  z-index: -1;
}
.cta-contato .bg-img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -2;
}
.cta-contato .top-img {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
@media (max-width: 1100px) {
  .cta-contato .top-img {
    left: inherit;
    right: 0;
  }
}
.cta-contato-container {
  display: flex;
}
.cta-contato-container .blank {
  width: 681px;
}
@media (max-width: 1100px) {
  .cta-contato-container .blank {
    display: none;
  }
}
.cta-contato-container .wrapper {
  max-width: 241px;
  z-index: 2;
  padding-top: 150px;
}
@media (max-width: 1100px) {
  .cta-contato-container .wrapper {
    padding-inline: 25px;
  }
}
.cta-contato-container .wrapper .sub-title,
.cta-contato-container .wrapper .title,
.cta-contato-container .wrapper .title strong {
  color: #fff !important;
}
.cta-contato-container .wrapper .title {
  margin-block: 1px 26px;
}
.cta-contato-container .wrapper .btn-light {
  width: 216px;
  height: 62px;
  background: #fff;
  transition: all 0.3s ease-in-out;
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
  display: grid;
  place-items: center;
}
.cta-contato-container .wrapper .btn-light:hover {
  background: var(--White-OFF, #F0F0F0);
}
.cta-contato .mask-text {
  font-size: 154px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  color: transparent;
  -webkit-text-stroke: 2px #8F8F8F;
  -webkit-background-clip: text;
  background-clip: text;
  position: absolute;
  right: 56px;
  top: -103px;
  text-align: end;
}
@media (width < 1100px) {
  .cta-contato {
    height: 478px;
  }
  .cta-contato-container {
    flex-direction: column;
  }
  .cta-contato-container .blank {
    display: none;
  }
  .cta-contato-container .wrapper {
    max-width: 100%;
    padding-top: 136px;
    padding-inline: 25px;
  }
  .cta-contato-container .wrapper .title {
    margin-block: 4px 33px;
  }
  .cta-contato-container .wrapper .title strong {
    display: block;
  }
  .cta-contato .top-img {
    inset: auto 0 0 auto;
    z-index: 0;
  }
  .cta-contato .mask-text {
    font-size: 70px;
    line-height: 50px;
    width: 100%;
    text-align: center;
    -webkit-text-stroke: 1px #8F8F8F;
    top: -34px;
    right: 0;
  }
}

#contact .branches {
  padding: 65px 77px 199px;
  background: linear-gradient(138deg, #005DB1 -11.68%, #0C3E62 68.14%);
  position: relative;
  isolation: isolate;
}
#contact .branches > img {
  position: absolute;
  inset: 0 0 0 auto;
  z-index: -1;
}
#contact .branches-container {
  display: flex;
  gap: 50px;
  justify-content: space-between;
  max-width: 1280px;
  margin-inline: auto;
}
#contact .branches-container--select {
  max-width: 540px;
}
#contact .branches-container--select .title {
  color: #fff;
  margin-block: 0 38px;
}
#contact .branches-container--select .text {
  color: #F0F0F0;
  margin-bottom: 56px;
}
#contact .branches-container--select .select-wrapper {
  max-width: 301px;
}
#contact .branches-container--info {
  padding: 40px 56px;
  margin-top: 69px;
  max-width: 467px;
}
#contact .branches-container--info .sub-title {
  line-height: 24px;
  margin-bottom: 20px;
}
#contact .branches-container--info ul {
  margin-bottom: 42px;
}
#contact .branches-container--info ul li + li {
  margin-top: 20px;
}
#contact .branches-container--info ul a {
  color: var(--San-Martin-White, #FFF);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
  gap: 12px;
}
#contact .branches-container--info ul a ion-icon {
  color: #f0f0f0;
  font-size: 15px;
}
#contact .branches-container--info .como-chegar {
  gap: 32px;
  color: var(--San-Martin-White, #FFF);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
}
#contact .branches-container--info .como-chegar:hover {
  text-decoration: underline;
  text-underline-offset: 10px;
}
#contact .branches-container .sub-title {
  color: #F0F0F0;
}
@media (width < 1100px) {
  #contact .branches {
    padding: 44px 25px 60px;
    height: auto;
  }
  #contact .branches-container {
    flex-direction: column;
    gap: 60px;
    max-width: 100%;
  }
  #contact .branches-container--select {
    max-width: 100%;
  }
  #contact .branches-container--select .title {
    margin-block: 4px 32px;
  }
  #contact .branches-container--select .text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 32px;
  }
  #contact .branches-container--select .select-wrapper {
    max-width: 500px;
    margin-inline: auto;
  }
  #contact .branches-container--info {
    padding: 0;
    margin-top: 0;
  }
  #contact .branches-container--info ul {
    margin-bottom: 43px;
  }
  #contact .branches-container--info ul li + li {
    margin-top: 21.5px;
  }
  #contact .branches-container--info ul li p {
    font-size: 14px;
    line-height: 21px;
  }
  #contact .branches-container--info ul li:first-of-type p {
    font-size: 16px;
    line-height: 24px;
    max-width: 228px;
  }
}
#contact .contato {
  padding: 108px 77px 200px;
  background-color: #f0f0f0;
  position: relative;
}
#contact .contato-container {
  max-width: 1280px;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  gap: 56px;
}
#contact .contato-container--text {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}
#contact .contato-container--text .info {
  max-width: 326px;
}
#contact .contato-container--text .info .title {
  max-width: 272px;
  margin-block: 1px 26px;
}
#contact .contato-container--text .selects {
  width: 650px;
  display: flex;
  align-self: flex-end;
  gap: 19px;
}
#contact .contato-container--text .selects-container {
  flex-grow: 1;
}
#contact .contato-container--forms .form-row + .form-row {
  margin-top: 20px;
}
#contact .contato-container--forms .form-row {
  display: flex;
  gap: 20px;
}
#contact .contato-container--forms .form-row textarea {
  height: 220px;
}
#contact .contato-container--forms .form-row .input-wrapper {
  flex-grow: 1;
  width: 100%;
}
#contact .contato-container--forms .form-row .form-control {
  padding-inline: 27px;
  border: 2px solid transparent;
  border-radius: 0;
  width: 100%;
}
#contact .contato-container--forms .form-row .form-control, #contact .contato-container--forms .form-row .form-control::placeholder {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  /* 333.333% */
}
#contact .contato-container--forms .form-row .form-control:focus {
  box-shadow: none;
  border-bottom: 2px solid #005DB1;
}
#contact .contato-container--forms .form-check {
  margin-block: 20px 56px;
  display: flex;
  align-items: center;
}
#contact .contato-container--forms .form-check-input {
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 1.5px solid var(--Support, #8F8F8F);
  margin-right: 24px;
}
#contact .contato-container--forms .form-check-label {
  color: var(--Support, #8F8F8F);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
#contact .contato-container--forms .form-check-label a {
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  text-decoration-line: underline;
}
#contact .contato .mask-text {
  font-size: 154px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  color: transparent;
  -webkit-text-stroke: 2px #8F8F8F;
  -webkit-background-clip: text;
  background-clip: text;
  position: absolute;
  right: 56px;
  top: -83px;
  text-align: end;
}
@media (width < 1100px) {
  #contact .contato {
    padding: 83px 24px 60px;
  }
  #contact .contato-container {
    gap: 20px;
  }
  #contact .contato-container--text {
    text-align: center;
    max-width: 100%;
    margin-bottom: 0;
    flex-direction: column;
    gap: 60px;
  }
  #contact .contato-container--text .info {
    max-width: 100%;
  }
  #contact .contato-container--text .info .title {
    margin-block-end: 20px;
    max-width: 100%;
  }
  #contact .contato-container--text .info .text {
    font-size: 16px;
    line-height: 22px;
    max-width: 100%;
    margin-inline: auto;
  }
  #contact .contato-container--text .selects {
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
  #contact .contato-container--forms .form-row {
    flex-direction: column;
  }
  #contact .contato-container--forms .form-row textarea {
    height: 220px;
  }
  #contact .contato-container--forms .input-wrapper {
    flex-grow: 1 !important;
    width: 100% !important;
  }
  #contact .contato-container--forms .three > .input-wrapper:nth-child(2) {
    width: 100% !important;
  }
  #contact .contato-container--forms .form-check {
    margin-block: 20px 40px;
  }
  #contact .contato-container--forms .form-check-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  #contact .contato-container--forms .form-check-label a {
    font-size: inherit;
    font-family: inherit;
    color: inherit;
  }
  #contact .contato-container--forms button {
    width: 100%;
  }
  #contact .contato .mask-text {
    font-size: 66px;
    line-height: 50px;
    -webkit-text-stroke: 1px #8F8F8F;
    text-align: center;
    width: 100%;
    right: 0;
    top: -26px;
  }
}
#contact .produtos-slider {
  padding-block: 146px 152px;
}
@media (width < 1100px) {
  #contact .produtos-slider {
    padding-block: 60px;
  }
  #contact .produtos-slider .btn-ver-mais {
    display: none;
  }
}
#contact .produtos-slider .card-product {
  background-color: #F3F3F3;
}
#contact .produtos-slider .card-product .title {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 100% */
}
#contact .sub-title {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 375% */
  letter-spacing: 8px;
  text-transform: uppercase;
}
@media (width < 1100px) {
  #contact .sub-title {
    font-size: 15px;
    line-height: 60px;
    /* 400% */
    letter-spacing: 7.5px;
  }
}
#contact .title {
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
  /* 100% */
}
#contact .title strong {
  font-weight: bold;
}
@media (width < 1100px) {
  #contact .title {
    font-size: 35px;
    line-height: 40px;
  }
  #contact .title strong {
    font-size: inherit;
    line-height: inherit;
  }
}
#contact .text {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 111.111% */
}
#contact .form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='gray' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right 24px center;
  background-repeat: no-repeat;
  background-size: 24px;
  width: 100%;
  padding-inline: 27px;
  border: 2px solid transparent;
  border-radius: 0;
}
#contact .form-select, #contact .form-select::placeholder {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  /* 333.333% */
}
#contact .form-select:focus {
  box-shadow: none;
  border-bottom: 2px solid #005DB1;
}
#contact .default-btn {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
  width: 216px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #005DB1;
  transition: all 0.3s ease-out;
  position: relative;
  isolation: isolate;
}
#contact .default-btn span {
  position: relative;
  z-index: 9;
}
#contact .default-btn::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  z-index: -1;
}
#contact .default-btn:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}
@media (max-width: 1100px) {
  #contact .default-btn {
    width: 100%;
  }
}

#work-with-us .info {
  padding: 64px 77px 120px;
  position: relative;
  overflow: hidden;
}
@media (width < 1100px) {
  #work-with-us .info {
    padding: 60px 0 0;
  }
}
#work-with-us .info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#work-with-us .info-container .img-and-text .content-text {
  max-width: 541px;
}
@media (max-width: 1100px) {
  #work-with-us .info-container .img-and-text .content-text {
    max-width: 100%;
    padding-inline: 25px;
  }
}
#work-with-us .info-container .img-and-text .wrapper {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
@media (max-width: 1100px) {
  #work-with-us .info-container .img-and-text .wrapper {
    flex-direction: column !important;
    gap: 60px !important;
  }
}
#work-with-us .info-container .img-and-text .subtitle {
  color: #575756;
  margin-bottom: 20px;
}
#work-with-us .info-container .img-and-text .title {
  margin-bottom: 42px;
}
#work-with-us .info-container .img-and-text .title strong {
  font-weight: bold;
}
@media (max-width: 1100px) {
  #work-with-us .info-container .img-and-text .title {
    font-size: 35px;
    line-height: 35px;
    margin-top: 14px;
    margin-bottom: 42px;
  }
}
#work-with-us .info-container .img-and-text .text {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
@media (max-width: 1100px) {
  #work-with-us .info-container .img-and-text .text {
    color: #575756;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
  }
}
#work-with-us .info-container .img-and-text .img-holder {
  height: 516px;
  aspect-ratio: 1.44;
  transform: translate(107px, 0px);
  overflow: hidden;
}
@media (max-width: 1100px) {
  #work-with-us .info-container .img-and-text .img-holder {
    transform: translate(0, 0);
    width: 100%;
    height: 285px;
    margin-top: 43px;
  }
}
#work-with-us .info-container .img-and-text .img-holder:hover img {
  transform: scale(1.1);
}
#work-with-us .info-container .img-and-text .img-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}
#work-with-us .info-container .reverse .wrapper {
  flex-direction: row-reverse;
}
#work-with-us .info-container .reverse .wrapper .content-text {
  padding-top: 30px;
}
#work-with-us .info-container .reverse .img-holder {
  transform: translate(-107px, 0px);
}
@media (max-width: 1100px) {
  #work-with-us .info-container .reverse .img-holder {
    display: none;
  }
}
#work-with-us .info .mask-text {
  font-size: 154px;
  text-align: end;
  right: -149px;
  bottom: -74px;
  z-index: 5;
}
@media (max-width: 1500px) {
  #work-with-us .info .mask-text {
    right: 0;
  }
}
@media (max-width: 1100px) {
  #work-with-us .info .mask-text {
    font-size: 23vw;
    -webkit-text-stroke: 1px #8F8F8F;
  }
}
@media (max-width: 500px) {
  #work-with-us .info .mask-text {
    font-size: 70px;
    right: 15px;
  }
}
#work-with-us .beneficios {
  padding: 120px 77px 152px;
  background: var(--White-OFF, #F0F0F0);
  text-align: center;
}
@media (width < 1100px) {
  #work-with-us .beneficios {
    padding-top: 60px;
    background: linear-gradient(0deg, #f0f0f0 94.5%, #fff 82%);
    padding-inline: 45px;
    padding-bottom: 60px;
  }
}
#work-with-us .beneficios .title {
  margin-block: 1px 80px;
}
#work-with-us .beneficios-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-content: center;
  gap: 26px;
  max-width: 1280px;
  margin-inline: auto;
}
#work-with-us .beneficios-container .card-item {
  background-color: #fff;
  text-align: center;
  padding: 32px;
  width: 100%;
  height: 220px;
}
#work-with-us .beneficios-container .card-item img {
  width: 90px;
  aspect-ratio: 1;
  display: block;
  margin-inline: auto;
}
#work-with-us .beneficios-container .card-item-title {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 100% */
  margin-top: 24px;
}
#work-with-us .selecao {
  padding: 143px 77px 199px;
  background: var(--gradiente-cheio, linear-gradient(138deg, #005DB1 -11.68%, #0C3E62 68.14%));
  text-align: center;
}
@media (width < 1100px) {
  #work-with-us .selecao {
    padding: 60px 25px;
  }
}
#work-with-us .selecao .sub-title, #work-with-us .selecao .title {
  color: #fff;
}
#work-with-us .selecao .title {
  margin-block: 1px 120px;
}
@media (width < 1100px) {
  #work-with-us .selecao .title {
    margin-block-end: 60px;
  }
}
#work-with-us .selecao-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(301px, 1fr));
  justify-content: center;
  gap: 80px 56px;
  max-width: 1372px;
  margin-inline: auto;
}
@media (width < 1100px) {
  #work-with-us .selecao-container {
    gap: 56px;
  }
}
#work-with-us .selecao-container .card-item {
  background-color: transparent;
  width: 100%;
  text-align: start;
}
@media (width < 1100px) {
  #work-with-us .selecao-container .card-item {
    text-align: center;
  }
}
#work-with-us .selecao-container .card-item-counter {
  position: relative;
  color: var(--San-Martin-White, #FFF);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 60.48px;
  font-style: normal;
  font-weight: 600;
  line-height: 100.8px;
}
#work-with-us .selecao-container .card-item .divider {
  width: 89px;
  height: 3px;
  background-color: #f0f0f0;
}
@media (width < 1100px) {
  #work-with-us .selecao-container .card-item .divider {
    right: -37%;
    position: relative;
  }
}
#work-with-us .selecao-container .card-item-title {
  color: var(--San-Martin-White, #FFF);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 120% */
  max-width: 186px;
  margin-block: 20px 32px;
}
@media (width < 1100px) {
  #work-with-us .selecao-container .card-item-title {
    margin: 0 auto;
  }
}
#work-with-us .selecao-container .card-item-text {
  color: var(--San-Martin-White, #FFF);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
}
#work-with-us .floating {
  height: 718px;
  background-color: #fff;
  position: relative;
  margin-right: 71px;
}
@media (width < 1100px) {
  #work-with-us .floating {
    height: auto;
    margin: 0;
    flex-direction: column-reverse;
    display: flex;
    gap: 60px;
  }
}
#work-with-us .floating .bg-img {
  height: 856px;
  width: 100%;
  object-fit: cover;
}
@media (width < 1100px) {
  #work-with-us .floating .bg-img {
    height: 421px;
  }
}
#work-with-us .floating-container {
  position: absolute;
  left: 0;
  top: -50px;
  background-color: #fff;
  z-index: 3;
  width: min(948px, 70vw);
  height: 649px;
  padding: 131px 83px;
  display: flex;
  justify-content: end;
}
@media (width < 1100px) {
  #work-with-us .floating-container {
    position: relative;
    height: auto;
    width: 100%;
    padding: 0;
    top: 0;
    padding-inline: 25px;
    padding-top: 60px;
  }
}
#work-with-us .floating-container .text-wrapper {
  max-width: 544px;
}
#work-with-us .floating-container--title {
  display: flex;
  gap: 20px;
  margin-bottom: 42px;
}
@media (width < 1100px) {
  #work-with-us .floating-container--title {
    flex-direction: column;
  }
  #work-with-us .floating-container--title img {
    width: 60px;
    height: 60px;
  }
}
#work-with-us .floating-container--text .text {
  line-height: 25px;
}
#work-with-us .testimonials {
  padding: 258px 77px 0;
  background: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  max-height: 804px;
  text-align: center;
}
@media (width < 1100px) {
  #work-with-us .testimonials {
    padding: 0;
    max-height: inherit;
    padding-top: 60px;
  }
}
#work-with-us .testimonials .btns-monial {
  display: none;
  position: relative;
  background: var(--White-OFF, #F0F0F0);
  justify-content: center;
}
@media (width < 1100px) {
  #work-with-us .testimonials .btns-monial {
    display: flex;
    transform: translate(0px, -1px);
    display: flex;
    padding-bottom: 60px;
    padding-bottom: 0;
    z-index: 9999;
  }
}
#work-with-us .testimonials .btns-monial .btn-next {
  transform: rotate(180deg);
  right: 27px;
  left: auto;
}
@media (width < 1100px) {
  #work-with-us .testimonials .btns-monial .btn-next {
    translate: -2px 0;
  }
}
#work-with-us .testimonials .sub-title, #work-with-us .testimonials .title {
  color: #fff;
}
#work-with-us .testimonials .title {
  margin-block: 4px 107px;
}
@media (width < 1100px) {
  #work-with-us .testimonials .title {
    margin-bottom: 150px;
  }
}
#work-with-us .testimonials-container {
  padding: 65px 56px 63px 87px;
  max-width: 1280px;
  margin-inline: auto;
  margin-bottom: -119px;
  background: var(--White-OFF, #F0F0F0);
  position: relative;
  z-index: 3;
}
@media (width < 1100px) {
  #work-with-us .testimonials-container {
    padding-inline: 45px;
    margin-bottom: 0;
    margin-bottom: -130px;
    padding-bottom: 0;
  }
}
#work-with-us .testimonials-container .testimonial {
  display: flex;
  gap: 47px;
}
@media (width < 1100px) {
  #work-with-us .testimonials-container .testimonial {
    flex-direction: column;
    gap: 72px;
  }
}
#work-with-us .testimonials-container .testimonial--img {
  padding: 12px 20px;
  min-width: 302px;
  height: 316px;
  position: relative;
  display: flex;
  align-items: end;
}
@media (width < 1100px) {
  #work-with-us .testimonials-container .testimonial--img {
    justify-content: center;
  }
}
#work-with-us .testimonials-container .testimonial--img::after {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(210deg, rgba(1, 20, 37, 0) 40.33%, rgba(1, 20, 37, 0.8) 65.7%);
  mix-blend-mode: multiply;
  z-index: 1;
}
#work-with-us .testimonials-container .testimonial--img img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}
#work-with-us .testimonials-container .testimonial--img .desc {
  color: #fff;
  z-index: 2;
  text-align: start;
}
@media (width < 1100px) {
  #work-with-us .testimonials-container .testimonial--img .desc {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
#work-with-us .testimonials-container .testimonial--img .desc-nome {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  /* 130% */
}
#work-with-us .testimonials-container .testimonial--img .desc .divider {
  margin-block: 12px;
  background-color: #005DB1;
  width: 93px;
  height: 3px;
}
#work-with-us .testimonials-container .testimonial--img .desc-cargo {
  color: #fff;
  line-height: 25px;
}
@media (width < 1100px) {
  #work-with-us .testimonials-container .testimonial--img .desc-cargo {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    /* 138.889% */
    text-align: center;
  }
}
#work-with-us .testimonials-container .testimonial--text {
  display: grid;
  place-items: center;
  text-align: center;
  width: 100%;
}
#work-with-us .testimonials-container .testimonial--text .text {
  line-height: 25px;
  max-width: 464px;
}
#work-with-us .testimonials .swiper {
  max-width: 100%;
}
@media (width < 1100px) {
  #work-with-us .testimonials .swiper {
    transform: translate(0px, -165px);
  }
}
#work-with-us .testimonials .swiper-slide {
  max-width: 100%;
}
#work-with-us .testimonials .swiper-button-next::after, #work-with-us .testimonials .swiper-button-prev::after {
  display: none;
}
#work-with-us .testimonials .swiper-button-next {
  right: 30px;
}
#work-with-us .testimonials .swiper-button-next .off {
  display: none;
}
#work-with-us .testimonials .swiper-button-next:hover .on {
  display: none;
}
#work-with-us .testimonials .swiper-button-next:hover .off {
  display: block;
}
#work-with-us .testimonials .swiper-button-next img {
  rotate: 180deg;
}
#work-with-us .testimonials .swiper-button-prev {
  left: 360px;
}
#work-with-us .testimonials .swiper-button-prev .off {
  display: none;
}
#work-with-us .testimonials .swiper-button-prev:hover .on {
  display: none;
}
#work-with-us .testimonials .swiper-button-prev:hover .off {
  display: block;
}
#work-with-us .be-part {
  padding: 240px 77px 204px;
}
@media (width < 1100px) {
  #work-with-us .be-part {
    padding-inline: 0;
    padding-top: 60px;
    padding-bottom: 60px;
    padding-top: 150px;
  }
}
#work-with-us .be-part-container {
  max-width: 1395px;
  margin-inline: auto;
  translate: -62px 0;
  display: flex;
  justify-content: space-between;
  gap: 32px;
}
@media (width < 1500px) {
  #work-with-us .be-part-container {
    translate: 0 0;
  }
}
@media (width < 1100px) {
  #work-with-us .be-part-container {
    flex-direction: column-reverse;
    gap: 60px;
  }
}
#work-with-us .be-part-container--img img {
  max-width: 100%;
}
#work-with-us .be-part-container--text {
  max-width: 517px;
  display: flex;
  flex-direction: column;
  gap: 42px;
}
@media (width < 1100px) {
  #work-with-us .be-part-container--text {
    width: 100%;
    padding-inline: 30px;
    margin: 0 auto;
    gap: 34px;
  }
}
#work-with-us .be-part-container--text .title {
  margin-top: 4px;
}
#work-with-us .be-part-container--text p {
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
#work-with-us .be-part-container--text .default-btn {
  width: 301px;
  padding-inline: 30px;
}
@media (width < 1100px) {
  #work-with-us .be-part-container--text .default-btn {
    width: 100%;
    padding-inline: 30px;
    margin: 0 auto;
  }
}
#work-with-us .escritorios {
  padding: 0 77px 283px;
  position: relative;
}
@media (width < 1100px) {
  #work-with-us .escritorios {
    padding-inline: 0;
    padding-bottom: 60px;
  }
}
#work-with-us .escritorios-container {
  max-width: 1395px;
  margin-inline: auto;
  translate: 58px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  gap: 32px;
}
@media (width < 1500px) {
  #work-with-us .escritorios-container {
    translate: 0 0;
  }
}
@media (width < 1100px) {
  #work-with-us .escritorios-container {
    flex-direction: column-reverse;
  }
}
#work-with-us .escritorios-container--img .swiper {
  max-width: 744px;
}
#work-with-us .escritorios-container--img .swiper-slide {
  width: 744px;
  height: 516px;
}
@media (width < 1100px) {
  #work-with-us .escritorios-container--img .swiper-slide {
    height: 400px;
  }
}
#work-with-us .escritorios-container--img .swiper-button-next, #work-with-us .escritorios-container--img .swiper-button-prev {
  z-index: 2;
}
#work-with-us .escritorios-container--img .swiper-button-next::after, #work-with-us .escritorios-container--img .swiper-button-prev::after {
  display: none;
}
#work-with-us .escritorios-container--img .swiper-button-next {
  right: 35px;
  top: auto;
  bottom: 40px;
}
@media (width < 1100px) {
  #work-with-us .escritorios-container--img .swiper-button-next {
    top: 79px;
    bottom: inherit;
    right: inherit;
    left: 82px;
  }
}
#work-with-us .escritorios-container--img .swiper-button-next img {
  rotate: 180deg;
}
#work-with-us .escritorios-container--img .swiper-button-prev {
  left: auto;
  top: auto;
  right: 162px;
  bottom: 40px;
}
@media (width < 1100px) {
  #work-with-us .escritorios-container--img .swiper-button-prev {
    bottom: inherit;
    top: 79.5px;
    right: inherit;
    left: 30px;
  }
}
#work-with-us .escritorios-container--img .swiper-button-prev, #work-with-us .escritorios-container--img .swiper-button-next {
  width: 53px;
  height: 53px;
}
#work-with-us .escritorios-container--img .swiper-button-prev svg, #work-with-us .escritorios-container--img .swiper-button-prev svg path, #work-with-us .escritorios-container--img .swiper-button-next svg, #work-with-us .escritorios-container--img .swiper-button-next svg path {
  transition: all 0.3s ease;
}
#work-with-us .escritorios-container--img .swiper-button-prev:hover svg, #work-with-us .escritorios-container--img .swiper-button-next:hover svg {
  fill: #fff;
}
#work-with-us .escritorios-container--img .swiper-button-prev:hover path, #work-with-us .escritorios-container--img .swiper-button-next:hover path {
  fill: #005DB1;
}
#work-with-us .escritorios-container--img .img-wrapper {
  position: relative;
  padding: 40px;
  min-width: 100%;
  min-height: 100%;
  isolation: isolate;
  display: flex;
  align-items: flex-end;
}
#work-with-us .escritorios-container--img .img-wrapper::after {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(39deg, rgba(0, 0, 0, 0.68) 17.59%, rgba(0, 0, 0, 0) 92.38%);
  opacity: 0.7;
  z-index: 1;
}
#work-with-us .escritorios-container--img .img-wrapper img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}
#work-with-us .escritorios-container--img .img-wrapper .txt-container {
  max-width: 328px;
  z-index: 3;
}
#work-with-us .escritorios-container--img .img-wrapper .txt-container--title {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 120% */
  margin-block-end: 20px;
}
@media (width < 1100px) {
  #work-with-us .escritorios-container--img .img-wrapper .txt-container--title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    /* 131.25% */
    margin-block-end: 12px;
  }
}
#work-with-us .escritorios-container--img .img-wrapper .txt-container .text {
  line-height: 25px;
  color: #fff;
}
@media (width < 1100px) {
  #work-with-us .escritorios-container--img .img-wrapper .txt-container .text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
  }
}
#work-with-us .escritorios-container--text {
  max-width: 517px;
  display: flex;
  flex-direction: column;
  gap: 42px;
  padding-top: 26px;
}
@media (width < 1100px) {
  #work-with-us .escritorios-container--text {
    padding-inline: 25px;
  }
}
#work-with-us .escritorios-container--text .title {
  margin-top: 4px;
}
#work-with-us .escritorios-container--text p {
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
#work-with-us .escritorios-container--text .default-btn {
  width: 169px;
  background: transparent;
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
}
#work-with-us .escritorios-container--text .default-btn::after {
  background: #005DB1;
  content: "";
  display: none;
}
#work-with-us .escritorios-container--text .default-btn:hover {
  background: transparent;
  text-decoration: underline;
  text-underline-offset: 6px;
}
#work-with-us .escritorios .mask-text {
  font-size: 154px;
  right: 120px;
  top: -117px;
  text-align: end;
  z-index: 5;
}
@media (width < 1100px) {
  #work-with-us .escritorios .mask-text {
    display: none;
  }
}
#work-with-us .news .title-big {
  top: -205px;
  left: 15px;
}
@media (width < 1100px) {
  #work-with-us .news .title-big {
    left: 0;
    top: 0;
  }
}
#work-with-us .sub-title {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 375% */
  letter-spacing: 8px;
  text-transform: uppercase;
}
@media (width < 1100px) {
  #work-with-us .sub-title {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 133.333% */
    letter-spacing: 7.5px;
  }
}
#work-with-us .title {
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
  /* 100% */
}
#work-with-us .title strong {
  font-weight: bold;
}
@media (width < 1100px) {
  #work-with-us .title {
    font-size: 35px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px;
    /* 114.286% */
  }
}
#work-with-us .text {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 111.111% */
}
#work-with-us .default-btn {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
  width: 216px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #005DB1;
  transition: all 0.3s ease-out;
  position: relative;
  isolation: isolate;
}
#work-with-us .default-btn span {
  position: relative;
  z-index: 9;
}
#work-with-us .default-btn::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  z-index: -1;
}
#work-with-us .default-btn:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}
@media (max-width: 1100px) {
  #work-with-us .default-btn {
    width: 100%;
  }
}
#work-with-us .mask-text {
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  color: transparent;
  -webkit-text-stroke: 2px #8F8F8F;
  -webkit-background-clip: text;
  background-clip: text;
  position: absolute;
}

#work-with-us-arg .info {
  padding: 64px 25px 170px;
  position: relative;
  overflow: hidden;
}
@media (width < 1100px) {
  #work-with-us-arg .info {
    padding: 60px 0 100px;
  }
}
#work-with-us-arg .info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#work-with-us-arg .info-container .img-and-text .content-text {
  max-width: 541px;
}
@media (max-width: 1100px) {
  #work-with-us-arg .info-container .img-and-text .content-text {
    max-width: 100%;
    padding-inline: 25px;
  }
}
#work-with-us-arg .info-container .img-and-text .wrapper {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
@media (max-width: 1100px) {
  #work-with-us-arg .info-container .img-and-text .wrapper {
    flex-direction: column !important;
    gap: 60px !important;
  }
}
#work-with-us-arg .info-container .img-and-text .subtitle {
  color: #575756;
  margin-bottom: 20px;
}
#work-with-us-arg .info-container .img-and-text .title {
  font-weight: 600;
  margin-bottom: 42px;
}
#work-with-us-arg .info-container .img-and-text p {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
@media (max-width: 1100px) {
  #work-with-us-arg .info-container .img-and-text p {
    color: #575756;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
  }
}
#work-with-us-arg .info-container .img-and-text p + p {
  margin-top: 25px;
}
#work-with-us-arg .info-container .img-and-text .img-holder {
  height: 516px;
  aspect-ratio: 1.44;
  transform: translate(107px, 0px);
  overflow: hidden;
}
@media (max-width: 1100px) {
  #work-with-us-arg .info-container .img-and-text .img-holder {
    transform: translate(0, 0);
    width: 100%;
    height: 285px;
    margin-top: 43px;
  }
}
#work-with-us-arg .info-container .img-and-text .img-holder:hover img {
  transform: scale(1.1);
}
#work-with-us-arg .info-container .img-and-text .img-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}
#work-with-us-arg .info-container .img-and-text .default-btn {
  margin-top: 42px;
  justify-content: space-between;
  padding-inline: 30px 22px;
  min-width: 301px;
}
#work-with-us-arg .info-container .reverse .wrapper {
  flex-direction: row-reverse;
}
#work-with-us-arg .info-container .reverse .wrapper .content-text {
  padding-top: 30px;
}
#work-with-us-arg .info-container .reverse .img-holder {
  transform: translate(-107px, 0px);
}
@media (max-width: 1100px) {
  #work-with-us-arg .info-container .reverse .img-holder {
    display: none;
  }
}
#work-with-us-arg .info .mask-text {
  font-size: 154px;
  text-align: end;
  right: -149px;
  bottom: -74px;
  z-index: 5;
}
@media (max-width: 1500px) {
  #work-with-us-arg .info .mask-text {
    right: 0;
  }
}
@media (max-width: 1100px) {
  #work-with-us-arg .info .mask-text {
    font-size: 23vw;
    -webkit-text-stroke: 1px #8F8F8F;
  }
}
@media (max-width: 500px) {
  #work-with-us-arg .info .mask-text {
    font-size: 70px;
    right: 15px;
  }
}
#work-with-us-arg .positions {
  padding-block: 0 142px;
}
#work-with-us-arg .positions--title {
  text-align: center;
  margin-bottom: 66px;
}
#work-with-us-arg .positions--cards .row {
  --bs-gutter-x: 27px;
  --bs-gutter-y: 27px;
}
#work-with-us-arg .positions--cards .card {
  padding: 40px 20px 27px 43px;
  border: none;
  border-radius: 0;
  box-shadow: 4px 4px 10.2px 0px rgba(0, 0, 0, 0.2509803922);
}
#work-with-us-arg .positions--cards .card--title {
  display: flex;
  margin-bottom: 47px;
}
#work-with-us-arg .positions--cards .card--title > * {
  flex-basis: 50%;
}
#work-with-us-arg .positions--cards .card--title h4 {
  color: #005DB1;
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
}
#work-with-us-arg .positions--cards .card--title ul {
  margin: 0;
}
#work-with-us-arg .positions--cards .card--title ul li {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
}
#work-with-us-arg .positions--cards .card--title ul li:first-child {
  color: #005DB1;
  font-weight: 600;
}
#work-with-us-arg .positions--cards .card--body p {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
}
#work-with-us-arg .detail {
  padding-block: 0 100px;
}
#work-with-us-arg .detail .accordion-button {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
  align-items: center;
  gap: 32px;
  padding-block: 47px 44px;
}
#work-with-us-arg .detail .accordion-button::after {
  display: none;
}
#work-with-us-arg .detail .accordion-button.collapsed img {
  rotate: 180deg;
}
#work-with-us-arg .detail .accordion-button, #work-with-us-arg .detail .accordion-button:focus {
  box-shadow: none;
  border-color: transparent;
  background-color: transparent;
}
#work-with-us-arg .detail .accordion-button img {
  transition: 0.3s;
}
#work-with-us-arg .detail .accordion-body .content {
  max-width: 770px;
}
#work-with-us-arg .detail .accordion-body .content p + h5,
#work-with-us-arg .detail .accordion-body .content h5 + p {
  margin-top: 25px;
}
#work-with-us-arg .detail .accordion-body .content p, #work-with-us-arg .detail .accordion-body .content h5 {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
}
#work-with-us-arg .detail .accordion-body .content h5 {
  font-weight: 600;
}
#work-with-us-arg .detail .accordion-body .default-btn {
  margin-top: 25px;
  justify-content: space-between;
  padding-inline: 30px 22px;
  min-width: 301px;
}
#work-with-us-arg .sub-title {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 375% */
  letter-spacing: 8px;
  text-transform: uppercase;
}
@media (width < 1100px) {
  #work-with-us-arg .sub-title {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 133.333% */
    letter-spacing: 7.5px;
  }
}
#work-with-us-arg .title {
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
  /* 100% */
}
#work-with-us-arg .title strong {
  font-weight: bold;
}
@media (width < 1100px) {
  #work-with-us-arg .title {
    font-size: 35px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px;
    /* 114.286% */
  }
}
#work-with-us-arg p {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 111.111% */
}
@media (max-width: 1100px) {
  #work-with-us-arg p {
    font-size: 16px !important;
  }
}
#work-with-us-arg .default-btn {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
  width: 216px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #005DB1;
  transition: all 0.3s ease-out;
  position: relative;
  isolation: isolate;
}
#work-with-us-arg .default-btn span {
  position: relative;
  z-index: 9;
}
#work-with-us-arg .default-btn::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  z-index: -1;
}
#work-with-us-arg .default-btn:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}
@media (max-width: 1100px) {
  #work-with-us-arg .default-btn {
    width: 100%;
  }
}
#work-with-us-arg .light-btn {
  width: 204px;
  height: 62px;
  background-color: transparent;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 13px;
}
@media (max-width: 1100px) {
  #work-with-us-arg .light-btn {
    width: 100%;
  }
}
#work-with-us-arg .light-btn:hover svg {
  translate: 5px 0;
}
#work-with-us-arg .light-btn span {
  color: #005DB1;
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
}
#work-with-us-arg .light-btn svg {
  fill: #005DB1;
  transition: 0.3s;
  width: 18px;
  height: 12px;
}
#work-with-us-arg .mask-text {
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  color: transparent;
  -webkit-text-stroke: 2px #8F8F8F;
  -webkit-background-clip: text;
  background-clip: text;
  position: absolute;
}

#robot .cta-contato {
  margin-top: 100px;
}
#robot .robot-noResult {
  padding-top: 50px;
  padding-bottom: 50px;
}
#robot .robot-noResult h4 {
  font-size: 50px;
  line-height: 48px;
  font-family: "Source Sans Pro", sans-serif;
  color: #005DB1;
}
#robot .intro-banner {
  position: relative;
}
@media (max-width: 1100px) {
  #robot .intro-banner {
    padding-top: 0px;
  }
}
#robot .intro-banner .mySwiper .swiper-slide {
  width: 100%;
  height: min(100dvh, 836px);
}
@media (max-width: 1100px) {
  #robot .intro-banner .mySwiper .swiper-slide {
    height: auto;
    padding-top: 84px;
    padding-bottom: 144px;
  }
}
#robot .intro-banner .mySwiper .swiper-button-next, #robot .intro-banner .mySwiper .swiper-rtl .swiper-button-prev {
  right: 0;
  left: auto;
  top: 88.6%;
  width: 54px;
  height: 54px;
  background: #005DB1;
}
#robot .intro-banner .mySwiper .swiper-button-next::after, #robot .intro-banner .mySwiper .swiper-rtl .swiper-button-prev::after {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
@media (max-width: 1100px) {
  #robot .intro-banner .mySwiper .swiper-button-next, #robot .intro-banner .mySwiper .swiper-rtl .swiper-button-prev {
    right: 0;
    left: 65px;
    top: 55px;
    width: 65px;
    height: 65px;
    bottom: 0;
  }
}
#robot .intro-banner .mySwiper .swiper-button-prev, #robot .intro-banner .mySwiper .swiper-rtl .swiper-button-next {
  left: calc(100% - 108px);
  right: auto;
  top: 88.6%;
  width: 54px;
  height: 54px;
  background: #005DB1;
}
#robot .intro-banner .mySwiper .swiper-button-prev::after, #robot .intro-banner .mySwiper .swiper-rtl .swiper-button-next::after {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
@media (max-width: 1100px) {
  #robot .intro-banner .mySwiper .swiper-button-prev, #robot .intro-banner .mySwiper .swiper-rtl .swiper-button-next {
    left: 0;
    right: auto;
    top: 55px;
    width: 65px;
    height: 65px;
    bottom: 0;
  }
}
#robot .intro-banner .wrapper-img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
#robot .intro-banner .wrapper-img::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(83deg, #003C65 38.12%, rgba(0, 60, 101, 0) 89.54%);
  mix-blend-mode: multiply;
}
#robot .intro-banner .wrapper {
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  height: 100%;
  padding-top: 193px;
}
@media (max-width: 1310px) {
  #robot .intro-banner .wrapper {
    max-width: 1180px;
  }
}
@media (max-width: 1100px) {
  #robot .intro-banner .wrapper {
    padding-inline: 25px;
    padding-top: 80px;
  }
}
#robot .intro-banner .banner-img {
  width: 100%;
  height: 100%;
  object-position: bottom;
  object-fit: cover;
}
#robot .intro-banner .content {
  max-width: 631px;
  display: flex;
  flex-direction: column;
}
#robot .intro-banner .subtitle {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 8px;
}
@media (max-width: 1100px) {
  #robot .intro-banner .subtitle {
    font-size: 15px;
  }
}
#robot .intro-banner .title {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 95px;
  font-style: normal;
  font-weight: 600;
  line-height: 80px;
  margin-top: 31px;
}
@media (max-width: 1100px) {
  #robot .intro-banner .title {
    font-size: 50px;
    line-height: 48px;
    max-width: 320px;
    word-wrap: break-word;
  }
}
#robot .intro-banner .text {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-top: 54px;
}
@media (max-width: 1100px) {
  #robot .intro-banner .text {
    font-size: 16px;
    line-height: 20px;
    max-width: 250px;
    margin-top: 20px;
  }
}
#robot .intro-banner .text + .text {
  margin-top: 20px;
}
#robot .intro-banner .btn-more {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  margin-top: 49px;
  width: fit-content;
}
@media (max-width: 1100px) {
  #robot .intro-banner .btn-more {
    display: none;
  }
}
#robot .intro-banner .video-btn {
  position: absolute;
  bottom: 72px;
  left: 0;
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 1100px) {
  #robot .intro-banner .video-btn {
    right: 21px;
    left: auto;
    bottom: -130px;
  }
}
#robot .robot-container {
  display: flex;
  justify-content: space-between;
  gap: 32px;
  margin-inline: auto;
}
@media (width < 1500px) {
  #robot .robot-container {
    translate: 0 0;
  }
}
#robot .robot-container--img {
  height: fit-content;
}
#robot .robot-container--img img {
  max-width: 100%;
}
#robot .robot-container--text {
  display: flex;
  flex-direction: column;
  gap: 42px;
}
#robot .robot-container--text .title {
  margin-top: 4px;
}
#robot .robot-container--text p {
  line-height: 25px;
}
#robot .robot-container--text .default-btn {
  width: 301px;
  padding-inline: 30px;
}
#robot .robot .reverse {
  flex-direction: row-reverse;
}
@media (width < 1100px) {
  #robot .robot {
    padding: 44px 0 0 !important;
  }
  #robot .robot-container {
    flex-direction: column-reverse;
    gap: 60px;
    padding-inline: 0 !important;
  }
  #robot .robot-container--img {
    width: min(550px, 100%);
    margin-inline: auto;
  }
  #robot .robot-container--text {
    padding-inline: 25px !important;
    padding-block: 0 !important;
    align-self: flex-start !important;
    gap: 32px;
    max-width: 100% !important;
  }
  #robot .robot-container--text .text {
    font-size: 16px;
    line-height: 22px;
  }
  #robot .robot-container--text .default-btn {
    width: min(550px, 100%);
    margin-inline: auto;
  }
  #robot .robot .reverse {
    flex-direction: column-reverse;
  }
  #robot .robot:last-of-type {
    padding: 44px 0 94px !important;
  }
}
#robot .item-1 {
  padding: 96px 77px 73px;
}
#robot .item-1 .robot-container {
  max-width: 1395px;
  translate: 56px 0;
}
@media (width < 1500px) {
  #robot .item-1 .robot-container {
    translate: 0 0;
  }
}
#robot .item-1 .robot-container--text {
  max-width: 541px;
}
#robot .item-1 .robot-container--img {
  align-self: center;
}
#robot .item-2 {
  padding: 0 77px 71px;
}
#robot .item-2 .robot-container {
  max-width: 1503px;
  translate: -9px 0;
}
@media (width < 1500px) {
  #robot .item-2 .robot-container {
    translate: 0 0;
  }
}
#robot .item-2 .robot-container--text {
  max-width: 653px;
  align-self: center;
}
#robot .item-2 .robot-container--text .text {
  max-width: 541px;
}
#robot .item-3 {
  padding: 0 77px 77px;
}
#robot .item-3 .robot-container {
  max-width: 1395px;
  translate: 56px 0;
}
@media (width < 1500px) {
  #robot .item-3 .robot-container {
    translate: 0 0;
  }
}
#robot .item-3 .robot-container--text {
  max-width: 541px;
  translate: 0 -5px;
}
#robot .item-3 .robot-container--text .text {
  max-width: 541px;
}
#robot .item-4 {
  padding: 0 77px 80px;
}
#robot .item-4 .robot-container {
  max-width: 1503px;
  translate: -9px 0;
}
@media (width < 1500px) {
  #robot .item-4 .robot-container {
    translate: 0 0;
  }
}
#robot .item-4 .robot-container--text {
  width: 653px;
  padding-top: 26px;
}
#robot .item-4 .robot-container--text .text {
  max-width: 541px;
}
#robot .item-5 {
  padding: 0 77px 93px;
}
#robot .item-5 .robot-container {
  max-width: 1395px;
  translate: 56px 0;
}
@media (width < 1500px) {
  #robot .item-5 .robot-container {
    translate: 0 0;
  }
}
#robot .item-5 .robot-container--text {
  max-width: 541px;
  translate: 0 -5px;
}
#robot .item-5 .robot-container--text .text {
  max-width: 541px;
}
#robot .item-6 {
  padding: 0 77px 152px;
}
#robot .item-6 .robot-container {
  max-width: 1503px;
  translate: -9px 0;
}
@media (width < 1500px) {
  #robot .item-6 .robot-container {
    translate: 0 0;
  }
}
#robot .item-6 .robot-container--text {
  width: 653px;
  padding-top: 26px;
}
#robot .item-6 .robot-container--text .text {
  max-width: 541px;
}
#robot .news {
  padding-top: 250px;
}
#robot .news .title-big {
  top: 15px;
}
#robot .sub-title:not(.intro-banner .sub-title) {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 375% */
  letter-spacing: 8px;
  text-transform: uppercase;
  margin: 0;
}
@media (width < 1100px) {
  #robot .sub-title:not(.intro-banner .sub-title) {
    font-size: 15px !important;
    line-height: 60px !important;
    /* 400% */
    letter-spacing: 7.5px !important;
  }
}
#robot .title:not(.intro-banner .title) {
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
  /* 100% */
}
#robot .title:not(.intro-banner .title) strong {
  font-weight: bold;
}
@media (width < 1100px) {
  #robot .title:not(.intro-banner .title) {
    font-size: 35px !important;
    line-height: 40px !important;
  }
  #robot .title:not(.intro-banner .title) strong {
    font-size: inherit;
    line-height: inherit;
  }
}
#robot .text:not(.intro-banner .text) {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 111.111% */
}
#robot .default-btn {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
  width: 216px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #005DB1;
  transition: all 0.3s ease-out;
  position: relative;
  isolation: isolate;
}
#robot .default-btn span {
  position: relative;
  z-index: 9;
}
#robot .default-btn::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  z-index: -1;
}
#robot .default-btn:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}
@media (max-width: 1100px) {
  #robot .default-btn {
    width: 100%;
  }
}

#success {
  padding: 216px 265px 192px;
  position: relative;
}
@media (width < 1500px) {
  #success {
    padding: 216px 77px 192px;
  }
}
@media (width < 1100px) {
  #success {
    padding: 0;
    padding-top: 84px;
  }
}
#success .success {
  display: flex;
  justify-content: end;
  min-width: min(100%, 1334px);
  height: 779px;
}
@media (width < 1100px) {
  #success .success {
    flex-direction: column;
    height: auto;
  }
}
#success .success > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (width < 1100px) {
  #success .success > img {
    height: 390px;
  }
}
#success .floating {
  position: absolute;
  inset: auto auto 112px 155px;
  min-width: min(100%, 739px);
  padding: 120px;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
@media (width < 1500px) {
  #success .floating {
    inset: auto auto 112px 77px;
  }
}
@media (width < 1100px) {
  #success .floating {
    position: relative;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    min-width: unset;
    width: calc(100% - 50px);
    padding: 0;
    inset: inherit;
    margin: 0 auto;
    padding: 40px 15px;
    transform: translate(0px, -150px);
    margin-bottom: -85px;
  }
}
#success .floating .title {
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
  text-align: center;
}
#success .floating .title strong {
  font-weight: 600;
}
@media (width < 1100px) {
  #success .floating .title {
    font-size: 35px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px;
    /* 114.286% */
  }
}
#success .floating .text {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  /* 125% */
  margin-block: 32px 40px;
  max-width: 245px;
  margin-inline: auto;
  text-align: center;
}
@media (width < 1100px) {
  #success .floating .text {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    /* 131.25% */
  }
}
#success .floating .icon-wrapper {
  margin-top: 40px;
}
#success .floating .btn-wrapper {
  gap: 32px;
}
@media (width < 1100px) {
  #success .floating .btn-wrapper {
    flex-direction: column;
  }
}
#success .floating .btn-wrapper a {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
  width: 100%;
  height: 62px;
  flex-grow: 1;
  position: relative;
  isolation: isolate;
}
#success .floating .default-btn {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #005DB1;
  transition: all 0.3s ease-out;
  position: relative;
  isolation: isolate;
}
#success .floating .default-btn span {
  position: relative;
  z-index: 9;
}
#success .floating .default-btn::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0) 92.38%);
  transition: all 0.3s ease-out;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
  will-change: transform;
  z-index: -1;
}
#success .floating .default-btn:hover::after {
  transform-origin: 0 0;
  transform: scaleX(1);
}
@media (max-width: 1100px) {
  #success .floating .default-btn {
    width: 100%;
  }
}
#success .floating .light-btn {
  background: #fff;
  border: 1px solid var(--San-Martin-primary---Blue-primary-500, #005DB1);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease-in-out;
}
#success .floating .light-btn span {
  color: #005DB1;
}
#success .floating .light-btn:hover {
  background: var(--San-Martin-primary---Blue-primary-500, #005DB1);
}

#services .intro-banner {
  position: relative;
}
@media (max-width: 1100px) {
  #services .intro-banner {
    padding-top: 0px;
  }
}
#services .intro-banner .mySwiper .swiper-slide {
  width: 100%;
  height: min(100dvh, 836px);
}
@media (max-width: 1100px) {
  #services .intro-banner .mySwiper .swiper-slide {
    height: auto;
    padding-top: 84px;
    padding-bottom: 144px;
  }
}
#services .intro-banner .mySwiper .swiper-button-next, #services .intro-banner .mySwiper .swiper-rtl .swiper-button-prev {
  right: 0;
  left: auto;
  top: 88.6%;
  width: 54px;
  height: 54px;
  background: #005DB1;
}
#services .intro-banner .mySwiper .swiper-button-next::after, #services .intro-banner .mySwiper .swiper-rtl .swiper-button-prev::after {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
@media (max-width: 1100px) {
  #services .intro-banner .mySwiper .swiper-button-next, #services .intro-banner .mySwiper .swiper-rtl .swiper-button-prev {
    right: 0;
    left: 65px;
    top: 55px;
    width: 65px;
    height: 65px;
    bottom: 0;
  }
}
#services .intro-banner .mySwiper .swiper-button-prev, #services .intro-banner .mySwiper .swiper-rtl .swiper-button-next {
  left: calc(100% - 108px);
  right: auto;
  top: 88.6%;
  width: 54px;
  height: 54px;
  background: #005DB1;
}
#services .intro-banner .mySwiper .swiper-button-prev::after, #services .intro-banner .mySwiper .swiper-rtl .swiper-button-next::after {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
@media (max-width: 1100px) {
  #services .intro-banner .mySwiper .swiper-button-prev, #services .intro-banner .mySwiper .swiper-rtl .swiper-button-next {
    left: 0;
    right: auto;
    top: 55px;
    width: 65px;
    height: 65px;
    bottom: 0;
  }
}
#services .intro-banner .wrapper-img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
#services .intro-banner .wrapper-img::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(83deg, #003C65 38.12%, rgba(0, 60, 101, 0) 89.54%);
  mix-blend-mode: multiply;
}
#services .intro-banner .wrapper {
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  height: 100%;
  padding-top: 193px;
}
@media (max-width: 1310px) {
  #services .intro-banner .wrapper {
    max-width: 1180px;
  }
}
@media (max-width: 1100px) {
  #services .intro-banner .wrapper {
    padding-inline: 25px;
    padding-top: 80px;
  }
}
#services .intro-banner .banner-img {
  width: 100%;
  height: 100%;
  object-position: bottom;
  object-fit: cover;
}
#services .intro-banner .content {
  max-width: 631px;
  display: flex;
  flex-direction: column;
}
#services .intro-banner .subtitle {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 8px;
}
@media (max-width: 1100px) {
  #services .intro-banner .subtitle {
    font-size: 15px;
  }
}
#services .intro-banner .title, #services .intro-banner title > p {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 95px;
  font-style: normal;
  font-weight: 600;
  line-height: 80px;
  margin-top: 31px;
}
@media (max-width: 1100px) {
  #services .intro-banner .title, #services .intro-banner title > p {
    font-size: 50px;
    line-height: 48px;
    max-width: 320px;
    word-wrap: break-word;
  }
}
#services .intro-banner .title p, #services .intro-banner title > p p {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 95px;
  font-style: normal;
  font-weight: 600;
  line-height: 80px;
  margin-top: 31px;
}
@media (max-width: 1100px) {
  #services .intro-banner .title p, #services .intro-banner title > p p {
    font-size: 50px;
    line-height: 48px;
    max-width: 320px;
    word-wrap: break-word;
  }
}
#services .intro-banner .text {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-top: 54px;
}
@media (max-width: 1100px) {
  #services .intro-banner .text {
    font-size: 16px;
    line-height: 20px;
    max-width: 250px;
    margin-top: 20px;
  }
}
#services .intro-banner .text + .text {
  margin-top: 20px;
}
#services .intro-banner .btn-more {
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  margin-top: 49px;
  width: fit-content;
}
@media (max-width: 1100px) {
  #services .intro-banner .btn-more {
    display: none;
  }
}
#services .intro-banner .video-btn {
  position: absolute;
  bottom: 72px;
  left: 0;
  color: #FFF;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 1100px) {
  #services .intro-banner .video-btn {
    right: 21px;
    left: auto;
    bottom: -130px;
  }
}
#services .assistance {
  padding: 100px 77px 120px;
}
@media (width < 1100px) {
  #services .assistance {
    padding-inline: 0px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
#services .assistance-container {
  max-width: 1395px;
  margin-inline: auto;
  translate: 56px 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 32px;
}
@media (width < 1500px) {
  #services .assistance-container {
    translate: 0 0;
  }
}
@media (width < 1100px) {
  #services .assistance-container {
    display: flex;
    flex-direction: column-reverse;
  }
}
#services .assistance-container--img img {
  max-width: 100%;
}
#services .assistance-container--text {
  max-width: 541px;
  transform: translateY(-4px);
}
@media (width < 1100px) {
  #services .assistance-container--text {
    transform: translateY(0px);
    padding-inline: 25px;
  }
}
#services .assistance-container--text .title {
  margin-block: 4px 42px;
}
#services .assistance-container--text .text {
  font-family: "Source Sans Pro", sans-serif;
  color: #575756;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
}
@media (width < 1100px) {
  #services .assistance-container--text .text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
  }
}
#services .assistance-container--text .text + .text {
  margin-top: 20px;
}
#services .assistance-container--text .info {
  margin-top: 40px;
}
@media (width < 1100px) {
  #services .assistance-container--text .info {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
}
#services .assistance-container--text .info-item + #services .assistance-container--text .info-item {
  margin-top: 12px;
}
#services .assistance-container--text .info-item {
  gap: 12px;
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 125% */
}
@media (width < 1100px) {
  #services .assistance-container--text .info-item {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
  }
}
#services .assistance-container--text .info-item i {
  font-size: 24px;
  color: #005DB1;
}
#services .treinamentos {
  padding: 0 77px 90px;
}
@media (width < 1100px) {
  #services .treinamentos {
    padding-inline: 0px;
    padding-bottom: 0px;
  }
}
#services .treinamentos-container {
  max-width: 1470px;
  margin-inline: auto;
  translate: -22px 0;
  display: flex;
  justify-content: space-between;
  gap: 32px;
}
@media (width < 1500px) {
  #services .treinamentos-container {
    translate: 0 0;
  }
}
@media (width < 1100px) {
  #services .treinamentos-container {
    display: flex;
    flex-direction: column-reverse;
  }
}
#services .treinamentos-container--img img {
  max-width: 100%;
}
#services .treinamentos-container--text {
  width: 620px;
}
@media (width < 1100px) {
  #services .treinamentos-container--text {
    padding-inline: 25px;
    width: auto;
  }
}
#services .treinamentos-container--text .title {
  margin-block: 4px 42px;
}
#services .treinamentos-container--text .text {
  font-family: "Source Sans Pro", sans-serif;
  color: #575756;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 138.889% */
}
#services .treinamentos-container--text .text + .text {
  margin-top: 20px;
}
#services .produtos-slider {
  padding-block: 62px 152px;
}
@media (width < 1100px) {
  #services .produtos-slider {
    padding-bottom: 115px;
  }
}
#services .produtos-slider .card-product {
  background-color: #F3F3F3;
}
#services .produtos-slider .card-product .title {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 100% */
}
#services .news {
  padding-top: 150px;
}
@media (width < 1100px) {
  #services .news {
    padding-top: 30px;
  }
}
#services .news .title-big {
  top: -83px;
}
#services .sub-title {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 375% */
  letter-spacing: 8px;
  text-transform: uppercase;
}
@media (max-width: 1100px) {
  #services .sub-title {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    /* 400% */
    letter-spacing: 7.5px;
  }
}
#services .title {
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
  /* 100% */
}
#services .title strong {
  font-weight: bold;
}
@media (max-width: 1100px) {
  #services .title {
    font-size: 35px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px;
    /* 114.286% */
  }
}

#policy .objective {
  padding: 96px 77px 80px;
}
#policy .objective-container {
  max-width: 1280px;
  margin-inline: auto;
}
#policy .objective-container h3 {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 375% */
  letter-spacing: 8px;
  text-transform: uppercase;
}
#policy .objective-container h2 {
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
  /* 36px */
  margin-block: 60px 32px;
}
#policy .objective-container h2 strong {
  font-weight: 600;
  color: inherit;
  font-family: inherit;
}
#policy .objective-container h2:first-of-type {
  font-size: 50px !important;
  line-height: 50px !important;
  margin-block: 6px 60px !important;
}
#policy .objective-container p, #policy .objective-container a, #policy .objective-container li {
  color: #575756;
  font-family: "Source Sans Pro", sans-serif, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
#policy .objective-container a {
  text-decoration: underline;
}
#policy .objective-container ul, #policy .objective-container ol {
  list-style-type: disc;
}
#policy .objective-container ul li, #policy .objective-container ol li {
  position: relative;
}
#policy .objective-container ul li + li, #policy .objective-container ol li + li {
  margin-top: 20px;
}
#policy .objective-container ul {
  padding-left: 20px;
}
#policy .objective-container ul li::after {
  content: "●";
  color: inherit;
  position: absolute;
  left: -20px;
  top: 0;
}
#policy .objective-container ol {
  counter-reset: num;
  margin-block: 32px;
}
#policy .objective-container ol li {
  counter-increment: num;
  padding-left: 20px;
}
#policy .objective-container ol li::after {
  content: counter(num) ".";
  position: absolute;
  top: 0;
  left: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin-right: 20px;
}
#policy .objective-container p + p {
  margin-top: 40px;
}
#policy .objective-container p + ul,
#policy .objective-container ul + p {
  margin-top: 60px;
}
#policy .objective-container .table {
  margin-block: 60px 80px;
}
#policy .objective-container .table table {
  border-color: var(--San-Martin-primary---Blue-primary-50, #E6EFF7);
}
#policy .objective-container .table table tr > td:first-child {
  min-width: 308px;
  padding-inline: 0 23px;
}
#policy .objective-container .table table tr > td:first-child strong, #policy .objective-container .table table tr > td:first-child p {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 100% */
  margin: 0;
  max-width: 280px;
}
@media (width < 1300px) {
  #policy .objective-container .table table tr > td:first-child {
    min-width: min(230px, 100%);
  }
}
#policy .objective-container .table table th {
  border: none;
  position: relative;
  color: var(--San-Martin-Neutral, #575756);
  text-align: start;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 100% */
  padding-bottom: 20px;
  margin-bottom: 44px;
}
#policy .objective-container .table table th::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 2px;
  background-color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
}
#policy .objective-container .table table td {
  min-width: min(100%, 260px);
  height: 200px;
  padding: 20px;
}
#policy .objective-container .table table td li {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 21px !important;
  max-width: 220px;
}
#policy .objective-container .table table td + td {
  border-left: 2px solid var(--San-Martin-primary---Blue-primary-50, #E6EFF7);
}
#policy .objective-container .dados-container {
  padding-block: 20px;
}
#policy .objective-container .dados-container .title {
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 120% */
}
#policy .objective-container .dados-container .divider {
  width: 40px;
  height: 2px;
  background-color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  margin-block: 20px 31.5px;
}
#policy .objective-container .dados-container--wrapper {
  display: flex;
  align-items: end;
  gap: 20px;
}
#policy .objective-container .dados-container--wrapper .info h5 {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  /* 125% */
  margin-bottom: 12px;
}
#policy .objective-container .dados-container--wrapper .rs {
  width: 281px;
}
#policy .objective-container .dados-container--wrapper .cnpj {
  width: 276px;
}
#policy .objective-container .dados-container--wrapper .endereco {
  max-width: 380px;
}
#policy .objective-container .dados-container--wrapper .nome {
  width: 610px;
}
#policy .objective-container .dados-container--wrapper .cadastro {
  align-self: center;
  width: 483px;
}
#policy .objective-container .dados-container--wrapper .cadastro h5 {
  margin: 0 !important;
}
#policy .objective-container .dados-container--wrapper .button .arrow-btn {
  gap: 32px;
  color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 300% */
  text-decoration: none;
}
#policy .objective-container .bottom {
  padding-top: 20px;
  border-top: 2px solid var(--San-Martin-primary---Blue-primary-50, #E6EFF7);
  gap: 40px;
  max-width: 1064px;
}
#policy .objective-container .bottom-text {
  max-width: 468px;
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  /* 138.889% */
  margin: 0;
  flex-grow: 1;
}
#policy .objective-container .bottom-desc {
  color: var(--San-Martin-Neutral, #575756);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  flex-grow: 1;
}
#policy .objective-container .bottom-desc strong {
  font-weight: 600;
}
#policy .objective-container .bottom-desc span {
  margin-inline: 12px;
}
@media (width < 1100px) {
  #policy .objective {
    padding: 44px 25px 80px;
  }
  #policy .objective-container h3 {
    font-size: 15px;
    line-height: 60px;
    letter-spacing: 50%;
  }
  #policy .objective-container h2 {
    font-size: 24px;
    line-height: 28px;
    margin-block: 40px 32px;
  }
  #policy .objective-container h2:first-of-type {
    font-size: 35px !important;
    line-height: 40px !important;
    margin-block: 4px 40px !important;
  }
  #policy .objective-container .table {
    margin-block: 32px;
    overflow: auto;
  }
  #policy .objective-container .table table tr > td:first-child {
    max-width: 260px !important;
    padding-inline: 0 62px !important;
  }
  #policy .objective-container .table table tr > td:first-child strong, #policy .objective-container .table table tr > td:first-child p {
    font-size: 18px;
    line-height: 21px;
    /* 100% */
    margin: 0;
    max-width: 155px;
  }
  #policy .objective-container .table table th {
    font-size: 16px;
    line-height: 18px;
  }
  #policy .objective-container .table table th::after {
    width: 70px;
  }
  #policy .objective-container .table table td li {
    font-size: 16px;
    line-height: 21px;
    max-width: 300px;
  }
  #policy .objective-container .dados-container .title {
    font-size: 20px;
    line-height: 1;
  }
  #policy .objective-container .dados-container .title .divider {
    width: 40px;
  }
  #policy .objective-container .dados-container--wrapper {
    flex-direction: column;
    align-items: start;
  }
  #policy .objective-container .dados-container--wrapper .info h5 {
    font-size: 18px;
    line-height: 21px;
  }
  #policy .objective-container .dados-container--wrapper .info .text {
    font-size: 16px;
    line-height: 22px;
  }
  #policy .objective-container .dados-container--wrapper .nome,
#policy .objective-container .dados-container--wrapper .cnpj,
#policy .objective-container .dados-container--wrapper .rs,
#policy .objective-container .dados-container--wrapper .endereco,
#policy .objective-container .dados-container--wrapper .cadastro {
    width: 100%;
  }
  #policy .objective-container .bottom {
    flex-direction: column;
    gap: 32px;
    border: none;
    align-items: start !important;
  }
  #policy .objective-container .bottom-text {
    font-size: 18px;
    line-height: 25px;
  }
  #policy .objective-container .bottom-desc {
    font-size: 16px;
    line-height: 22px;
  }
}
#policy .news {
  padding-top: 150px;
}
#policy .news .title-big {
  top: -83px;
}