#safeContact {
    .safe-cont {
        position: relative;
        margin-left: 71px;
        height: 856px;
        background: var(--White-OFF, #F0F0F0);
        @media (max-width: 1100px) {
            display: flex;
            flex-direction: column-reverse;
            margin: 0;
            height: auto;
            background: #fff;
        }
        &--floating-div {
            position: absolute;
            top: 0;
            left: 0;
            width: Min(973px, 75vw);
            height: 641px;
            background: #fff;
            padding-inline: 134px;
            z-index: 2;
            display: flex;
            justify-content: end;
            translate: -71px 0;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.35);
            @media (max-width: 1100px) {
                padding: 0;
                width: 100%;
                translate: inherit;
                box-shadow: none;
                padding-inline: 25px;
                height: auto;
                position: relative;
                padding-top: 44px;
            }

            .floating-div-container {
                width: Min(100%, 520px);
                align-self: center;

                .title { margin-bottom: 42px; @media (max-width: 1100px) { margin-bottom: 32px; } }
                p + p { margin-top: 20px; }
                p {
                    color: var(--San-Martin-Neutral, #575756);
                    font-family: $font-main, sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px; /* 138.889% */
                    max-width: 433px;
                    @media (max-width: 1100px) {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; /* 137.5% */
                    }
                }
            }
        }
        & > img {
            position: absolute;
            inset: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            @media (max-width: 1100px) {
                position: relative;
                height: 422px;
                margin-top: 60px;
            }
        }
    }
    .faq {
        padding: 80px 77px 152px;
        text-align: center;
        background: var(--White-OFF, #F0F0F0);
        @media (max-width: 1100px) {
            padding-inline: 25px;
            padding-bottom: 60px;
        }
        .swiper-perguntas{
            display: none;
            @media (max-width: 1100px) {
                display: block;
            }
        }
        .btns-perguntas{
            margin-top: 60px;
        }
        .btn-prev{
            transform: rotate(180deg) translate(-1px, 0px);
        }
        .btn-next{
            transform: translate(-1px, .5px);
        }
        .title { margin-block: 1px 80px; }
        &-container {
            max-width: 1280px;
            margin-inline: auto;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 26px;
            @media (max-width: 1100px) {
                display: none;
            }
            &--item {
                max-width: 272px;
                height: 312px;
                padding: 41px 14px;
                text-align: center;
                background: #fff;
                @media (max-width: 1100px) {
                    margin: 0 auto;
                }
                .card-icon {
                    width: 116px;
                    aspect-ratio: 1;
                    margin-inline: auto;
                }
                .card-title {
                    color: var(--San-Martin-Neutral, #575756);
                    font-family: $font-main, sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px; /* 100% */
                    margin-block: 24px 20px;
                }
                .card-text {
                    color: var(--San-Martin-Neutral, #575756);
                    font-family: $font-main, sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 111.111% */
                }
            }
        }
    }
    .cta {
        padding: 120px 77px;
        background-color: var(--San-Martin-primary---Blue-primary-500, #005DB1);;
        position: relative;
        isolation: isolate;
        margin-bottom: 120px;

        &::after {
            content: '';
            position: absolute;
            inset: 0;
            background-color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
            opacity: .5;
            z-index: -1;
        }
        @media (max-width: 1100px) {
            padding-top: 60px;
            padding-inline: 25px;
            margin-bottom: 70px;
            padding-bottom: 60px;
        }
        & > img {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -2;
        }
        &-container {
            max-width: 437px;
            margin-inline: auto;
            text-align: center;
            img{
                @media (max-width: 1100px) {
                    width: 190px;
                }
            }
            p {
                color: var(--San-Martin-White, #FFF);
                font-family: $font-main, sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 111.111% */

                margin-block: 32px 56px;
            }
            .light-btn { margin-inline: auto; }
        }
    }

    .sub-title {
        color: var(--San-Martin-Neutral, #575756);
        font-family: $font-main, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 375% */
        letter-spacing: 8px;
        text-transform: uppercase;
        @media (max-width: 1100px) {
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 133.333% */
            letter-spacing: 7.5px;
            margin-bottom: 14px;
        }
    }
    .title {
        color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
        font-family: $font-main, sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 300;
        line-height: 50px; /* 100% */
        strong { font-weight: 600; }
        @media (max-width: 1100px) {
            font-size: 35px;
            font-style: normal;
            font-weight: 300;
            line-height: 35px; /* 100% */
        }
    }
    .default-btn {
        color: #FFF;
        font-family: $font-main;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 300% */
        width: 216px;
        height: 62px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $blue;
        transition: all 0.3s ease-out;
        position: relative;
        isolation: isolate;
        span{
            position: relative;
            z-index: 9;
        }
        &::after{
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0.00) 92.38%);
            transition: all 0.3s ease-out;
            transform: scaleX(0);
            transform-origin: 100% 100%;
            transition: transform .6s cubic-bezier(.53,.21,0,1);
            will-change: transform;
            z-index: -1;
        }
        &:hover{
            &::after{
                transform-origin: 0 0;
                transform: scaleX(1);
            }
        }
        @media (max-width: 1100px){
            width: 100%;
        }
    }
    .light-btn {
        width: 276px;
        height: 62px;
        background-color: transparent;
        padding-inline: 30px 22px;
        border: 1px solid #fff;
        transition: all .3s ease;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 1100px) {
            width: 100%;
        }
        &:hover {
            background-color: #fff;

            span { color: var(--San-Martin-primary---Blue-primary-500, #005DB1); }
            svg { fill: var(--San-Martin-primary---Blue-primary-500, #005DB1); }
        }
        span {
            color: var(--San-Martin-White, #FFF);
            font-family: $font-main, sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 60px; /* 300% */
        }
        svg {
            fill: #fff;
        }
    }
}
