#work-with-us-arg {
    .info {
        padding: 64px 25px 170px;
        position: relative;
        overflow: hidden;
        @media (width < 1100px) {
            padding: 60px 0 100px;
        }
        &-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .img-and-text{
                .content-text{
                    max-width: 541px;
                    @media (max-width: 1100px){
                        max-width: 100%;
                        padding-inline: 25px;
                    }
                }
                .wrapper{
                    width: fit-content;
                    display: flex;
                    justify-content: space-between;
                    gap: 40px;
                    @media (max-width: 1100px) {
                        flex-direction: column !important;
                        gap: 60px !important;
                    }
                }
                .subtitle{
                    color: #575756;
                    margin-bottom: 20px;
                }
                .title{
                    font-weight: 600;
                    margin-bottom: 42px;
                }
                p{
                    color: #575756;
                    font-family: $font-main;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;
                    
                    @media (max-width: 1100px){
                        color: #575756;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 25px;
                    }
                }
                p + p {
                    margin-top: 25px;
                }
                .img-holder{
                    height: 516px;
                    aspect-ratio: 1.44;
                    transform: translate(107px, 0px);
                    overflow: hidden;
                    @media (max-width: 1100px){
                        transform: translate(0, 0);
                        width: 100%;
                        height: 285px;
                        margin-top: 43px;
                    }
                    &:hover{
                        img{
                            transform: scale(1.1);
                        }
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: all 0.3s ease;
                    }
                }
                .default-btn {
                    margin-top: 42px;

                    justify-content: space-between;
                    padding-inline: 30px 22px;
                    min-width: 301px;
                }
            }
            .reverse{
                .wrapper{
                    flex-direction: row-reverse;

                    .content-text { padding-top: 30px; }
                }
                .img-holder{
                    transform: translate(-107px, 0px);
                    @media (max-width: 1100px){
                        display: none;
                    }
                }
            }
        }
        .mask-text {
            font-size: 154px;
            text-align: end;
            right: -149px;
            bottom: -74px;
            z-index: 5;
            @media (max-width: 1500px){
                right: 0;
            }
            @media (max-width: 1100px){
                font-size: 23vw;
                -webkit-text-stroke: 1px #8F8F8F;
            }
            @media (max-width: 500px){
                font-size: 70px;
                right: 15px;
            }
        }
    }
    .positions {
        padding-block: 0 142px;

        &--title {
            text-align: center;
            margin-bottom: 66px;
        }
        &--cards {
            .row {
                --bs-gutter-x: 27px;
                --bs-gutter-y: 27px;
            }
            .card {
                padding: 40px 20px 27px 43px;
                border: none;
                border-radius: 0;
                box-shadow: 4px 4px 10.2px 0px #00000040;

                &--title {
                    display: flex;
                    margin-bottom: 47px;

                    & > * {
                        flex-basis: 50%;
                    }
                    h4 {
                        color: #005DB1;
                        font-family: $font-main, sans-serif;
                        font-size: 30px;
                        font-weight: 600;
                        line-height: 30px;
                    }
                    ul {
                        margin: 0;

                        li {
                            color: #575756;
                            font-family: $font-main, sans-serif;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 15px;

                            &:first-child {
                                color: #005DB1;
                                font-weight: 600;
                            }
                        }
                    }
                }
                &--body {
                    p {
                        color: #575756;
                        font-family: $font-main;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 25px;
                    }
                }
            }
        }
    }
    .detail {
        padding-block: 0 100px;

        .accordion {
            &-button {
                color: #575756;
                font-family: $font-main, sans-serif;
                font-size: 30px;
                font-weight: 600;
                line-height: 30px;

                align-items: center;
                gap: 32px;

                padding-block: 47px 44px;

                &::after {
                    display: none;
                }
                &.collapsed {
                    img {
                        rotate: 180deg;
                    }
                }
                &, &:focus {
                    box-shadow: none;
                    border-color: transparent;
                    background-color: transparent;
                }
                img { transition: .3s; }
            }
            &-body {
                .content {
                    max-width: 770px;
                    
                    p + h5,
                    h5 + p {
                        margin-top: 25px;
                    }
                    p, h5 {
                        color: #575756;
                        font-family: $font-main, sans-serif;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 25px;
                    }
                    h5 {
                        font-weight: 600;
                    }
                }
                .default-btn {
                    margin-top: 25px;
                    
                    justify-content: space-between;
                    padding-inline: 30px 22px;
                    min-width: 301px;
                }
            }
        }
    }

    .sub-title {
        color: var(--San-Martin-Neutral, #575756);
        font-family: $font-main, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 375% */
        letter-spacing: 8px;
        text-transform: uppercase;
        @media ( width < 1100px ) {
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 133.333% */
            letter-spacing: 7.5px;
        }
    }
    .title {
        color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
        font-family: $font-main, sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 300;
        line-height: 50px; /* 100% */
        strong { font-weight: bold; }
        @media ( width < 1100px ) {
            font-size: 35px;
            font-style: normal;
            font-weight: 300;
            line-height: 40px; /* 114.286% */
        }
    }
    p {
        color: #575756;
        font-family: $font-main, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 111.111% */

        @media (max-width: 1100px) {
            font-size: 16px !important;
        }
    }
    .default-btn {
        color: #FFF;
        font-family: $font-main;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 300% */
        width: 216px;
        height: 62px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $blue;
        transition: all 0.3s ease-out;
        position: relative;
        isolation: isolate;
        span{
            position: relative;
            z-index: 9;
        }
        &::after{
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0.00) 92.38%);
            transition: all 0.3s ease-out;
            transform: scaleX(0);
            transform-origin: 100% 100%;
            transition: transform .6s cubic-bezier(.53,.21,0,1);
            will-change: transform;
            z-index: -1;
        }
        &:hover{
            &::after{
                transform-origin: 0 0;
                transform: scaleX(1);
            }
        }
        @media (max-width: 1100px){
            width: 100%;
        }
    }
    .light-btn {
        width: 204px;
        height: 62px;
        background-color: transparent;
        transition: all .3s ease;
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 13px;
        @media (max-width: 1100px) {
            width: 100%;
        }
        &:hover {
            svg {
                translate: 5px 0;
            }
        }
        span {
            color: #005DB1;
            font-family: $font-main, sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 60px;
        }
        svg {
            fill: #005DB1;
            transition: .3s;

            width: 18px;
            height: 12px;
        }
    }
    .mask-text {
        // font-size: 154px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        color: transparent;
        -webkit-text-stroke: 2px #8F8F8F;
        -webkit-background-clip: text;
        background-clip: text;

        position: absolute;
    }
}