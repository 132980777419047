#institutional {
    #customEndScreen{
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: black;
        color: white;
        text-align: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .vantagem {
        padding: 68px 77px 52.5px;
        display: flex;
        justify-content: center;

        @media (width < 1100px) {
            padding: 44px 0 51px;
        }
    }
    .plyr--video{
        height:100%;
        video{
            width: 100% !important;
            left: 0 !important;
        }
    }
    #player {
        height: 100%;
        position: relative;
      }
    .video {
        padding: 0 77px 108px;
        isolation: isolate;

        &-wrapper {
            max-width: 1276px;
            margin-inline: auto;
            position: relative;
            height: auto;

            .thumb-nail {
                max-width: 100%;
            }
            .play-icon {
                position: absolute;
                inset: 50% 0 0 50%;
                translate: -50% -50%;
                z-index: 2;

                width: 76px;
                aspect-ratio: 1;
            }
        }
        .mask-text {
            font-size: 190px;
            font-style: normal;
            font-weight: 600;
            line-height: 80%;
            color: transparent;
            -webkit-text-stroke: 2px #8F8F8F;
            -webkit-background-clip: text;
            background-clip: text;
            position: relative;
            text-align: center;
            margin-bottom: -30px;
            z-index: 2;

            &:not(:first-child) { display: none; }
            @media (width < 1700px) {
                font-size: 140px;
                margin-bottom: -20px;
            }
            @media (width < 1280px) {
                font-size: 115px;
                margin-bottom: -10px;
            }
        }

        @media (width < 1100px) {
            padding: 0 0 44px;

            &-wrapper {
                padding-inline: 25px;
                max-width: Min(500px, 100%);
                margin-inline: auto;

                .thumb-nail {
                    height: 200px;
                    width: 100%;
                    object-fit: cover;
                    margin-top: -14px;
                }
                .play-icon {
                    width: 40px;
                    transform: translateY(-14px);
                }
            }
            .mask-wrapper {
                display: flex;
                position: relative;
                width: 100%;
                height: 55px;
                margin: auto;
                overflow: hidden;
                z-index: 3;

                &-container {
                    display: flex;
                    position: absolute;
                    top: 0;
                    left: 0;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%;
                    height: 100%;
                    white-space: nowrap;
                    // transform: scale(2);
                    transition: all 1s ease;

                    & > div {
                        display: flex;
                        animation: scrollText 20s infinite linear;
                    }
                }
            }
            .mask-text {
                font-size: 70px;
                font-style: normal;
                font-weight: 600;
                line-height: 50px;

                position: initial;
                text-align: start;
                -webkit-text-stroke: 2px #b8b8b8;
                margin-bottom: 0;
                transition: all 2s ease;

                &:not(:first-child) { display: inherit; }
            }
            .mask-text + .mask-text { margin-left: 20px; }
        }
    }
    .inovar {
        padding: 0 77px 108px;
        display: flex;
        justify-content: center;

        @media (width < 1100px) {
            padding: 0 0 60px;

            .content-text { padding-top: 0; }
        }
    }
    .timeline {
        margin-bottom: 108px;

        &--text {
            text-align: center;
            padding: 0 77px 64px;

            .title { margin-block: 4px 32px; }
            .desc {
                max-width: 244px;
                margin-inline: auto;

                color: var(--San-Martin-Neutral, #575756);
                font-family: $font-main, sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 111.111% */
            }
        }
        &--slider {
            .swiper {
                max-width: 100%;

                &-slide {
                    width: 640px;
                    height: 566px;
                    isolation: isolate;
                }
                &-button-prev,
                &-button-next {
                    &::after { display: none }

                    img {
                        max-width: 53px;
                        aspect-ratio: 1;
                    }
                }
                &-button-prev {
                    left: 38px;
                    @media (width < 1100px) { left: 20px; }

                }
                &-button-next {
                    right: 38px;
                    @media (width < 1100px) { right: 20px; }
                    img { rotate: 180deg; }
                }
                .timeline-container {
                    position: relative;
                    height: 100%;
                    width: 100%;
                    transition: all .3s ease;

                    &:hover {
                        &::after {
                            background: linear-gradient(210deg, rgba(0, 93, 177, 0.00) 34.44%, rgba(0, 93, 177, 0.94) 63.22%);
                        }
                        &::before {
                            content: '';
                            position: absolute;
                            inset: 0;
                            background: linear-gradient(210deg, rgb(0 93 177 / 42%) 34.44%, rgba(0, 93, 177, 0.94) 63.22%);
                            z-index: -1;
                        }
                        .title { padding-bottom: 20px; }
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        inset: 0;
                        background: linear-gradient(210deg, rgb(0 60 101 / 43%) 40.4%, rgba(0, 60, 101, 0.8) 64.49%);
                        mix-blend-mode: multiply;
                        z-index: -1;
                        transition: all .3s ease;
                    }
                    & > img {
                        position: absolute;
                        inset: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        z-index: -2;
                    }
                    &--text {
                        padding: 52px 64px 33px;
                        height: 100%;

                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        color: #fff;
                        z-index: 2;

                        .sub-title {
                            color: #fff;

                            font-family: $font-main, sans-serif;
                            font-size: 25px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 30px; /* 120% */
                            text-transform: none;
                            letter-spacing: normal;
                            transition: all .3s ease;
                        }
                        .title {
                            color: #fff;

                            font-family: $font-main, sans-serif;
                            font-size: 95px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 80px; /* 84.211% */
                            transition: all .3s ease;
                        }
                        .text {
                            opacity: 0;
                            transition: all .3s ease;

                            li, p {
                                font-family: $font-main, sans-serif;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 25px; /* 138.889% */
                            }
                            strong {
                                font-size: inherit;
                                font-family: inherit;
                                font-weight: 600;
                            }
                            li + li { margin-top: 12px; }
                            p { margin-top: 20px; }
                            &.opacity {
                                opacity: 1;
                                transition: all .3s ease;
                            }
                        }
                    }
                }
            }
        }

        @media (width < 1100px) {
            margin-bottom: 60px;

            &--text {
                .title { margin-block: 4px 32px; }
                .desc {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                }
            }
            &--slider {
                .swiper {
                    &-slide { height: 345px; }
                    &-button-prev,
                    &-button-next {
                        img { max-width: 45px; }
                    }
                    &-button-prev {
                    }
                    &-button-next {
                    }
                    .timeline-container {
                        &--text {
                            .sub-title {
                                font-size: 15px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 22px; /* 146.667% */
                            }
                            .title {
                                font-size: 60px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 60px;
                            }
                            .text {
                                li, p {
                                    font-size: 12px;
                                    line-height: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .principios {
        padding: 0 77px 273px;

        &-container {
            max-width: 1289px;
            margin-inline: auto;

            display: flex;
            justify-content: space-between;
            gap: 109px;

            @media (width < 1500px) {
                gap: 32px;
            }
            &--text {
                max-width: 326px;

                .title { margin-block: 17px 32px; }
                p {
                    font-family: $font-main, sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;
                }
            }
            &--cards {
                display: flex;
                gap: 22px;

                .card-item {
                    padding: 40px 20px;
                    height: 360px;
                    flex-grow: 1;
                    text-align: center;
                    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.14);

                    &-icon {
                        width: 116px;
                        aspect-ratio: 1;
                        margin-inline: auto;
                    }
                    &-title {
                        color: var(--San-Martin-Neutral, #575756);

                        font-family: $font-main, sans-serif;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px; /* 100% */
                        margin-block: 24px 20px;
                    }
                    &-text {
                        color: var(--San-Martin-Neutral, #575756);

                        font-family: $font-main, sans-serif;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 25px; /* 138.889% */
                    }
                }
            }
        }
        @media (width < 1100px) {
            padding: 0 25px 90px;

            &-container {
                flex-direction: column;
                gap: 60px;

                &--text {
                    max-width: 100%;
                    text-align: center;

                    .title {
                        margin-block: 8px 32px;

                        br { display: none; }
                    }
                    p {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
                &--cards {
                    flex-direction: column;
                    gap: 20px;

                    .card-item {
                        height: 312px;

                        &-icon { width: 80px; }
                        &-title {
                            font-size: 20px;
                            line-height: 20px;
                            margin-block: 24px 20px;
                        }
                        &-text {
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }
                }
            }
        }
    }
    .valores {
        padding: 0 110px 0;
        background: var(--White-OFF, #F0F0F0);
        height: 667px;

        @media (width < 1500px) {
            padding: 0 77px 0;
            height: 100%;
        }
        &-container {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            place-items: center;
            gap: 20px;
            translate: 0 -170px;

            @media (width < 1500px) {
                grid-template-columns: repeat(3, 1fr);
            }
            .card-item {
                position: relative;
                padding: 40px 20px 62px 56px;
                height: 360px;
                width: 100%;
                background: var(--gradiente-cheio, linear-gradient(138deg, #005DB1 -11.68%, #0C3E62 68.14%));
                overflow: hidden;
                isolation: isolate;
                transition: all .3s ease;

                display: flex;
                flex-direction: column;
                justify-content: end;

                &:nth-child(7),
                &:nth-child(8) {
                    .bg-icon {
                        opacity: 1;
                    }
                }
                &:hover {
                    background: #fff;

                    .bg-icon { opacity: 0; }
                    .title {
                        color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
                        margin-block: 8px 20px;

                        &::after {
                            background-color: var(--San-Martin-primary---Blue-primary-500, #005DB1);
                            bottom: 0;
                        }
                    }
                    .hover-icon {
                        width: 34px;
                        height: 32px;
                    }
                    .text {
                        color: var(--San-Martin-Neutral, #575756);
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
                .bg-icon {
                    position: absolute;
                    z-index: -1;
                    inset: 0 0 auto auto;
                    opacity: .1;
                    transition: all .3s ease;
                }
                .title {
                    color: var(--San-Martin-White, #FFF);
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 60px; /* 166.667% */
                    padding-bottom: 22px;
                    position: relative;
                    transition: all .3s ease;

                    &::after {
                        content: '';
                        position: absolute;
                        width: 80px;
                        height: 2px;
                        background-color: #fff;
                        bottom: -10px;
                        left: 0;
                    }
                }
            }
        }
        @media (width < 1100px) {
            padding: 0;
            height: 100%;

            &-container {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 0;
                translate: 0 0;

                .card-item {
                    padding: 40px 25px;
                    height: 200px;

                    .bg-icon {
                        max-width: 100%;
                        max-height: 100%;
                    }
                    .title {
                        font-size: 25px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 45px;
                        padding-bottom: 12px;

                        &::after {
                            width: 50px;
                            bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .unidades {
        padding: 121px 77px;

        &-container {
            max-width: 1301px;
            margin-inline: auto;

            display: flex;
            justify-content: space-between;
            gap: 32px;

            &--img {
                img {
                    max-width: 100%;
                }
            }
            &--text {
                max-width: 517px;
                display: flex;
                flex-direction: column;
                align-self: center;
                gap: 42px;

                .title { margin-top: 4px; }
                p {
                    font-family: $font-main, sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;
                }
            }
        }
        @media (width < 1100px) {
            padding: 60px 25px;

            &-container {
                flex-direction: column-reverse;
                gap: 60px;

                &--img {
                    max-width: Min(390px, 100%);
                    height: Min(100%, 828px);
                    margin-inline: auto;

                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                &--text {
                    max-width: 100%;
                    gap: 32px;

                    .title {
                        br {display: none;}
                    }
                    .text {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
            }
        }
    }
    .newsletter {
        padding: 128px 0 98px 0;
        position: relative;
        width: 90.5%;
        background: #F0F0F0;
        box-shadow: 10px 10px 24px 0px rgba(0, 0, 0, 0.15);
        margin-top: 105px;
        margin-bottom: -99px;

        h4 {
            color: #575756;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 60px;
            letter-spacing: 8px;
            margin-right: 186px;
            margin-bottom: 22px;
            float: right;
        }
        h3 {
            color: #005DB1;
            font-size: 50px;
            font-style: normal;
            font-weight: 300;
            line-height: 60px;
            margin-right: 19px;
            margin-bottom: 19px;
            float: right;
            clear: both;

            & strong {
                color: #005DB1;
                font-size: 50px;
                font-style: normal;
                font-weight: 600;
                line-height: 60px;
            }
        }
        p {
            color: #575756;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-right: 15px;
            max-width: 300px;
            float: right;
            clear: both;
        }
        form {
            margin-top: 143px;
            padding: 0 79px;

            input {
                width: 100%;
                padding: 0 20px;
                border: none;
                margin-bottom: 30px;
                background-color: #fff;
            }

            input,
            input::placeholder {
                color: #575756;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 60px;
            }

            button {
                color: #FFF;
                font-family: $font-main;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                width: 100%;
                height: 62px;
                gap: 20px;
                background: $blue;
                transition: all 0.3s ease-out;
                position: relative;
                isolation: isolate;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 2px 36px;
                span{
                    position: relative;
                    z-index: 9;
                }
                &::after{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0.00) 92.38%);
                    transition: all 0.3s ease-out;
                    transform: scaleX(0);
                    transform-origin: 100% 100%;
                    transition: transform .6s cubic-bezier(.53,.21,0,1);
                    will-change: transform;
                    z-index: -1;
                }
                &:hover{
                    &::after{
                        transform-origin: 0 0;
                        transform: scaleX(1);
                    }
                }
                @media (max-width: 1600px){
                    padding: 2px 20px;
                    gap: 0;
                }
                @media (max-width: 1100px){
                    width: 100%;
                }
            }

            .aceite {
                color: #8F8F8F;
                font-family: 'Source Sans Pro';
                font-size: 17px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                max-width: 350px;
                display: flex;
                align-items: center;

                a {
                    text-decoration: underline;
                    color: inherit;
                }

                .checkbox.style-c {
                    display: inline-block;
                    position: relative;
                    padding-left: 30px;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }

                .checkbox.style-c input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }

                .checkbox.style-c input:checked~.checkbox__checkmark {
                    background-color: #0084B5;
                }

                .checkbox.style-c input:checked~.checkbox__checkmark:after {
                    opacity: 1;
                }

                .checkbox.style-c:hover input~.checkbox__checkmark {
                    background-color: transparent;
                }

                .checkbox.style-c:hover input:checked~.checkbox__checkmark {
                    background-color: #0084B5;
                }

                .checkbox.style-c .checkbox__checkmark {
                    position: absolute;
                    top: -9px;
                    left: 0;
                    height: 20px;
                    width: 20px;
                    background-color: transparent;
                    transition: background-color 0.25s ease;
                    border-radius: 4px;
                    border: solid 1px #8F8F8F;
                }

                .checkbox.style-c .checkbox__checkmark:after {
                    content: "";
                    position: absolute;
                    left: 7px;
                    top: 2px;
                    width: 5px;
                    height: 10px;
                    border: solid #fff;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                    opacity: 0;
                    transition: opacity 0.25s ease;
                }

                .checkbox.style-c .checkbox__body {
                    color: #fff;
                    line-height: 1.4;
                    font-size: 16px;
                }
            }
        }
        .mulher {
            position: absolute;
            bottom: 0;
            left: 17px;

            @media(max-width: 1600px){
                left: -150px;
            }
        }

        @media (width < 1500px) {
            width: 100%;

            form {
                padding: 0 60px 0 20px;

                button { padding: 2px 20px; }
            }
            .mulher {  }
        }
        @media (width < 1100px) {
            padding: 60px 0 0 0;
            margin-top: 44px;
            margin-bottom: 0;
            width: 100% !important;
            text-align: center;
            box-shadow: none;

            h4 {
                font-size: 15px;
                line-height: 60px; /* 400% */
                letter-spacing: 7.5px;
                margin-right: 0;
                margin-bottom: 4px;
            }
            h3 {
                font-size: 35px;
                font-style: normal;
                font-weight: 300;
                line-height: 40px;
                margin-bottom: 20px;

                & strong {
                    font-size: inherit;
                    font-weight: 700;
                }
            }
            p {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                max-width: 244px;
            }
            h4, h3, p {
                float: none;
                margin-inline: auto;
            }
            form {
                margin-top: 32px;
                padding-inline: 29px 21px;

                input { margin-bottom: 0; }
                .aceite {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    text-align: start;
                    margin-block: 27px 26px;

                    & > div {
                        br { display: none; }
                    }
                }
            }
            .col-md-12:not(:first-of-type) {
                padding: 0;
            }
            .mulher {
                display: block;
                position: inherit;
                height: fit-content;
                left: 0;

                img { max-width: 100%; }
            }
        }
    }

    .sub-title {
        color: var(--San-Martin-Neutral, #575756);

        font-family: $font-main, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 375% */
        letter-spacing: 8px;
        text-transform: uppercase;

        @media (width < 1100px) {
            font-size: 15px;
            line-height: 60px; /* 400% */
            letter-spacing: 7.5px;
        }
    }
    .title {
        color: var(--San-Martin-primary---Blue-primary-500, #005DB1);

        font-family: $font-main, sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 300;
        line-height: 50px; /* 100% */

        strong { font-weight: bold; }

        @media (width < 1100px) {
            font-size: 35px;
            line-height: 40px;

            strong {
                font-size: inherit;
                line-height: inherit;
            }
        }
    }
    .default-btn {
        color: #FFF;
        font-family: $font-main;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px; /* 300% */
        width: 216px;
        height: 62px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        background: $blue;
        transition: all 0.3s ease-out;
        position: relative;
        isolation: isolate;
        span{
            position: relative;
            z-index: 9;
        }
        &::after{
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: linear-gradient(39deg, rgba(0, 60, 101, 0.68) 17.59%, rgba(0, 60, 101, 0.00) 92.38%);
            transition: all 0.3s ease-out;
            transform: scaleX(0);
            transform-origin: 100% 100%;
            transition: transform .6s cubic-bezier(.53,.21,0,1);
            will-change: transform;
            z-index: -1;
        }
        &:hover{
            &::after{
                transform-origin: 0 0;
                transform: scaleX(1);
            }
        }
        @media (max-width: 1100px){
            width: 100%;
        }
    }
    // componente texto imagem
    .img-and-text{
        overflow: hidden;

        .content-text{
            max-width: 541px;
            @media (max-width: 1100px){
                max-width: 100%;
                padding-inline: 25px;
            }
        }
        .wrapper{
            width: fit-content;
            display: flex;
            justify-content: space-between;
            gap: 40px;
            @media (max-width: 1100px){
                max-width: 100%;
                margin-inline: 0;
                flex-direction: column;
                gap: 60px;
            }
        }
        .title{
            margin-bottom: 42px;
            strong{
                font-weight: bold;
            }
            @media (max-width: 1100px){
                margin-bottom: 32px;
            }
        }
        .text{
            color: #575756;
            font-family: $font-main;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            @media (max-width: 1100px){
                color: #575756;
                font-size: 16px;
                font-weight: 400;
                line-height: 25px;
            }
        }
        .img-holder{
            height: 516px;
            aspect-ratio: 1.44;
            transform: translate(107px, 0px);
            overflow: hidden;
            @media (max-width: 1400px){
                transform: translate(40px, 0);
            }
            @media (max-width: 1100px){
                transform: translate(0, 0);
                width: Min(100%, 600px);
                height: 285px;
                margin-inline: auto;
            }
            &:hover{
                img{
                    transform: scale(1.1);
                }
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.3s ease;
            }
        }
    }
    .reverse{
        .wrapper{
            flex-direction: row-reverse;
            @media (width < 1100px) {
                flex-direction: column;
            }
        }
        .img-holder{
            transform: translate(-107px, 0px);
            @media (width < 1100px) {
                transform: translate(0, 0);
            }
        }
    }

    @keyframes scrollText {
        from { transform: translateX(0%); }
        to { transform: translateX(-50%); }
    }
}
#main:has(#institutional) + #footer {
    .line-news { display: none; }
    .site-map {
        margin-top: 171px;

        @media (max-width: 1100px) {
            margin-top: calc(120px - 45px);
        }
    }
}

.etica {
    padding-bottom: 120px;
    @media (width < 1700px) {
        &-container {
            margin-inline: auto;
            gap: 32px;
        }
    }
    @media (max-width: 1100px) {
        padding: 0;
      }
    &-container {
        max-width: 1280px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        gap: 110px;
        margin: 0 auto;
        @media (max-width: 1100px) {
            flex-direction: column-reverse;
            gap: 60px;
        }
        &--img {
            img {
                max-width: 100%;
                @media (max-width: 1100px) {
                  width: 100%;
                  max-width: inherit;
                  height: 285px;
                }
            }
        }
        &--text {
            max-width: 517px;
            display: flex;
            flex-direction: column;
            gap: 42px;
            @media (max-width: 1100px) {
                padding-inline: 25px;
            }
            .title { margin-top: 4px; }
            p {
                font-family: $font-main, sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 25px;
                @media (max-width: 1100px) {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 137.5% */
                }
            }
        }
    }
}
