// --------------------/ 2 - Enter Animations /-----------------//
.animated-element[data-animation] {
    opacity: 1 !important;
    transform: translate(0, 0) !important;
}
.scroll-down-animation {
    animation-name: scrollDown;
    animation-iteration-count: infinite;
    animation-duration: 3s;
}
// default = (opacity 0) to (opacity 1)
[data-animation] {
    opacity: 0 !important;
    transition: 1s;
}
// (left) to (right)
[data-animation="right"] {
    transform: translateX(-50px);
}
// (right) to (left)
[data-animation="left"] {
    transform: translateX(50px);

    @media (max-width: 999px) {
        transform: translateX(-50px) !important;
    }
}
// (down) to (up)
[data-animation="up"] {
    transform: translateY(50px);
}
// (up) to (down)
[data-animation="down"] {
    transform: translateY(-50px);
}
// Sequencial Staggers
[data-sequencial] {
    [data-sequencial-stagger] {
        opacity: 0;
    }
    [data-sequencial-stagger="right"] {
        transform: translateX(-50px);
    }
    [data-sequencial-stagger="left"] {
        transform: translateX(50px);
    }
    [data-sequencial-stagger="up"] {
        transform: translateY(50px);
    }
    [data-sequencial-stagger="down"] {
        transform: translateY(50px);
    }
}

[data-animation-banner] {
    opacity: 0 !important;
    transition: 1s;

    &.animated-element{
         opacity: 1 !important;
         transform: translate(0, 0) !important;
    }
}

// Images
body:not(.no-js) .reveal{
    position: relative;
    overflow: hidden;
}
body:not(.no-js) .image-wrap {
    transition: 1s ease-out;
    transition-delay: 0.2s;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
    visibility: hidden;
}
body:not(.no-js) .image-wrap img {
    transform: scale(1.3);
    transition: 2s ease-out;
    position: relative !important;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
body:not(.no-js) .image-wrap .enter-image-style {
    transform: scale(1.3);
    transition: 2s ease-out;
    position: relative !important;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
body:not(.no-js) .animating .image-wrap {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    visibility: visible;
    transform: skewY(0);
}
body:not(.no-js) .animating img {
    transform: scale(1);
    transition: 4s ease-out;
}
body:not(.no-js) .animating .enter-image-style {
    transform: scale(1);
    transition: 4s ease-out;
}
